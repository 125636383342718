import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import {Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import { ImagesUrl, ServerUrl,  Token, config, allowDelete, allowEdit, menuLink} from '../component/include'
import Select from 'react-select'

import { useDispatch, useSelector } from 'react-redux'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'

const StaffAttendance =()=> {
    const [errors, setErrors] = useState({});
const [department, setDepartment]=useState([])
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [position, setPosition]=useState([])
    
    const [settings, setSettings]= useState([])


    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [staff, setStaff]= useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [attendance, setAttendance] = useState({
        department:[],
        timeIn:'',
        timeOut:'',
        autoFill:'',
        attendanceDate:''
    })

    const [CurrentSession, setCurrentSession] = useState('')
    const  handleSession =()=>{
    
        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            setCurrentSession(result.data[0].startYear)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        } 
    const fetchDepartment =()=>{
        const fd = new FormData();
    fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_staff_settings/department/ID/1'
        axios.post(url, fd, config)
        .then(result =>setDepartment(result.data))
    }
    const fetchPosition =()=>{
        const fd = new FormData();
    fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_staff_settings/position/ID/1'
        axios.post(url, fd, config)
        .then(result =>setPosition(result.data))
    }
const getPosition =(code)=>{
    const answer =  position.filter(item=>item.code===code)
    const reply = answer.length!==0?answer[0].positionName:''
    return reply
}

    const handleSelect = (option, action)=>{
        setAttendance({...attendance, [action.name]:option })
    };



    const fetchStaff =()=>{
        setNotice({...notice, isLoading: true}) 
        var sql ="SELECT  code, staffName,  position, gender, passport from tbl_staff where status ='true' "    

        if(attendance.department.length!==0 ){
            sql = sql + '  and department = "'+attendance.department.value+'" '
        }
        
    sql = sql + 'order by staffName ASC '
    const fd = new FormData();
    fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{
        var options = result.data;
            var value = [];
            for (var i = 0, l = options.length; i < l; i++) {
              value.push({code:options[i].code, staffName:options[i].staffName, position:options[i].position, gender:options[i].gender, passport:options[i].passport, timeIn:attendance.timeIn, timeOut:attendance.timeOut, attendance:'L'});
            }

    setStaff(value)
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    })
    .finally(()=>{
        setNotice({...notice, isLoading: false})
    })
    }

    const fetchSettings =()=>{
        var sql ="SELECT  timeIn, timeOut, autoAbsent from tbl_basic_details"    
        const fd = new FormData();
        fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{
        
            var date = new Date();
            var day = date.getDate();
            var month = date.getMonth() + 1;
            var year = date.getFullYear();
            
            if (month < 10) month = "0" + month;
            if (day < 10) day = "0" + day;
            
            var today = year + "-" + month + "-" + day; 
        setAttendance({...attendance,
            attendanceDate:today, timeIn:result.data[0].timeIn, timeOut:result.data[0].timeOut
            })
     

    setSettings(result.data[0])
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    })
    }
    
   

    const handleChange =(event)=>{
        const {name, value} = event.target
        setAttendance({...attendance, [name] : value })
    }

    const handleChangeTime=(e, index)=>{
        const std =JSON.parse(JSON.stringify(staff))  
        std[index][e.target.name] = e.target.value
        setStaff(std);
    }

    const getTime =(value)=>{
        var timeString = String(value)
       var hourEnd = timeString.indexOf(":");
       var H = timeString.substr(0,2);
       var h = H % 12 || 12;
       var ampm =  (H<12 || H===24)?"AM":"PM";
        if (h < 10) h = "0" + h
       var result = h + timeString.substr(hourEnd, 3)+" "+ampm
     
    
return result;
    }

    const handleMarkAttendance=(e, index)=>{
        const std =JSON.parse(JSON.stringify(staff))         
       std[index].attendance = std[index].attendance ==='P'?'H':std[index].attendance ==='H'?'A':std[index].attendance ==='A'?'L':'P';
       setStaff(std);
    }
const handleMarkAll=()=>{
    var options = staff;
            var value = [];
            for (var i = 0, l = options.length; i < l; i++) {
              value.push({code:options[i].code, staffName:options[i].staffName, position:options[i].position, gender:options[i].gender, passport:options[i].passport, timeIn:options[i].timeIn, timeOut:options[i].timeOut,  attendance:options[i].attendance ==='P'?'H':options[i].attendance ==='H'?'A':options[i].attendance ==='A'?'L':'P'});
            }
       setStaff(value);
}
   

    const handleSubmit = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(attendance));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};   
        if(!values.attendanceDate){
            errors.attendanceDate ='Please select Date';
        }   
        if(staff.length===0){
            errors.staff ='There must be at least one staff to mark attendnace';
        } 
        
        return errors;
        }

        useEffect(()=>{
            if(Object.keys(errors).length === 0 && isSubmitting){
            submit()
            }
            },[errors])


         function submit(){  
            const fd = new FormData();
            fd.append('jwt', Token); 
             fd.append('staffList', JSON.stringify(staff, null, 2));
            fd.append('attendanceDate', attendance.attendanceDate);
            fd.append('sessionYear', CurrentSession);
            
            setNotice({...notice,  isLoading: true}) 
        
               let url = ServerUrl+'/save_controller/tbl_staff_attendance'
              axios.post(url, fd, config)
              .then(response =>{
                if(response.data.type ==='success'){
                    Alerts('Saved!', 'success', response.data.message)
                        } else if(response.data.type ==='info'){
                            Alerts('Info!', 'default', response.data.message)}
                            else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
                        }   
                })
                .catch((error)=>{
                  Alerts('Error!', 'danger', error.message)
                }).finally(()=>{
                    setNotice({...notice, 
                        isLoading: false
                    })
                    setStaff('')
                    setIsSubmitting(false); 
                }) 
        }


useEffect(()=>{
   
    setIsAuthenticated(menuLink.includes(location.pathname))
    fetchDepartment()
    fetchPosition()
    fetchSettings()
    handleSession()
},[]);


        return (  
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Staff Attendance">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-calendar"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Attendance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Staff Attendance</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


                
                <div className="row  ">
						
	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Create Attendance</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
      
  <div className="row">
  <section className="col-md-6">
    
    <div className="form-group">
                    <label > Department</label> 
                    <Select options={
                        department&&department.map((list, idx)=> {
                           return {key:idx, value: list.code, label: list.departmentName }
                         })} 
onChange={handleSelect}  name="department" value={attendance.department}  /> 				
                                        </div>
        </section>

     <section className="col-md-6">
           <div className="form-group">
         <label> Attendance Date</label>
         <input type="date" className="form-control" onChange={handleChange} value={attendance.attendanceDate} name="attendanceDate"  />
            </div>
     
     </section>
     
     </div>
     
     <div className="row">
     
     <section className="col-md-6">
     <div className="form-group">
         <label> Time IN</label>
<a href="/setup/school_details" className="pull-right"   ><i className="fa fa-plus"></i> Modify School Timing</a>
         <input type="time" className="form-control" onChange={handleChange} value={attendance.timeIn} name="timeIn"  />
            </div>
     
     </section>
     <div className="col-md-6">
     <div className="form-group">
         <label> Time Out</label>
         <input type="time" className="form-control" name="timeOut" onChange={handleChange} value={attendance.timeOut}   />
            </div>
     
     </div>
     </div>
     <div  className="row">  
        <div className="col-md-2 pull-right"> 
     <button type="button" className="btn btn-primary waves-effect" onClick={()=>fetchStaff()}   > Create Attendance</button>
     </div>
     </div> 
    </div>
                               
       </div>	</div>

       </div>



{staff.length!==0?
                <div className="row  ">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Mark Attendance</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
        
        <section className="col-md-6">
        <span className="pcoded-badge label bg-simple-c-pink">Color Icon For Absent</span>
        <span className="pcoded-badge label label-info">Color Icon For Half Day</span>
        <span className="pcoded-badge label bg-simple-c-green">Color Icon For Present</span>
        </section>

        <div className="card-block">
            
        <div className="row" style={{backgroundColor:'yellow'}}>
        {staff&&staff.map((stf, id)=>
        <section key={id} className="col-md-3"><br/>
            <button type="button" onClick={(e)=>handleMarkAttendance(e, id)} className={stf.attendance==='P'?'btn btn-success':stf.attendance==='A'?'btn btn-danger':stf.attendance==='H'?'btn btn-info':'btn btn-default'} style={{width:'100%'}}> 
            <img className="img-100" style={{height:'100px', width:'100px'}} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/staff/'+stf.gender+'.png'}} src={`${ImagesUrl}/staff/${stf.passport}`}  alt="" />

            <div ><b>{stf.staffName.length>20?stf.staffName.slice(0, 20)+'...':stf.staffName}</b></div>
            <label>({(getPosition(stf.position).slice(0, 20))})</label>
            <div>Office Hours</div>
            
<div style={{width:'50%', textAlign:'left', float:'left', fontSize:'13px'}}>From <br/>{getTime(settings.timeIn)}<br/>Time In</div>
<div style={{width:'50%', textAlign:'right', float:'right', fontSize:'13px'}}>To <br/>{getTime(settings.timeOut)} <br/> Time Out</div>
            </button>
                <div className="input-group" style={{width:'100%'}}>
                    <input type="time" name="timeIn" onChange={(e)=>handleChangeTime(e, id)}  style={{height: '30px'}} value={stf.timeIn}  />

                    <input type="time" name="timeOut" onChange={(e)=>handleChangeTime(e, id)} style={{height: '30px'}}  value={stf.timeOut}   />
                </div>
            </section>
            
)}
          
</div>

		
        <div className="row">
        <footer className="col-md-12 pull-right">
	<hr/>
	<button type="button"  onClick={handleMarkAll} className="btn btn-inverse "><i className="fa fa-edit"></i> PresentAll/HalfdayAll/AbsentAll</button>&nbsp;&nbsp;
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-success "><i className="fa fa-save"></i> Submit</button>
								</footer>
				</div>	
     </div>
 </div> 
</div>

</div>:''}
	




   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(StaffAttendance) 