import React, {useState, useEffect, useCallback} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config, classID, sectionID} from '../../component/include'
import Select from 'react-select'
import { longDate, getRank, getResultHead, getClassField} from '../component/globalFunction'

import { useSelector } from 'react-redux'

 const CommentEntry =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 

    const [csvFile, setCsvFile]=useState({
        studentCsv:'',
        fileName:'Please select .csv files (allowed file size 800KB)'
    })

    const [classType, setClassType] = useState([]);

    const [comments, setComments]=useState([])
    const [result, setResult] = useState({
        studentClass:[],
        examtype:[],
        classAssessment:[],
        orderBy:{value:'order by s.studentName ASC', label:'order by Name'},
    })

    const steps = [{title: 'Comment Setup'}, {title: 'Comment Entry'}]
    const [classTeacher, setClassTeacher] = useState([]);
    const [examtype, setExamtype]=useState([])
    const [assessment, setAssessment]=useState([])
    const [category, setCategory]=useState([])
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const [classAssessment, setClassAssessment]= useState([]) 
    const [studentList, setStudentList]=useState([])
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [settings, setSettings]=useState([])
const [activeStep, setActiveStep] = useState(1);

const staff  = useSelector(state => state.rootReducer.staff);




const handleOnClickStepper = (step) => {
        setActiveStep(step); 
    }

    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
    }, [activeStep])

  
   
    
const fetchExamType =()=>{
    var sql ="SELECT CONCAT(t.session, ' ', t.term) as exam, r.examCode  from tbl_exam_result r, tbl_exam_type t where r.examCode = t.code and t.status='Active'  group by r.examCode order by exam ASC" ;
const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{
setExamtype(result.data)
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
})
}



const fetchAssessment =()=>{
    const fd = new FormData();
    fd.append('jwt', Token);
    let url = ServerUrl + '/fetchField/tbl_standards_setting/assessment/ID/1';
    axios.post(url, fd, config)
    .then(result =>setAssessment(result.data.sort((a, b) =>(a.categoryCode > b.categoryCode) ? 1 : -1)))
}

const fetchCategory =()=>{
    const fd = new FormData();
    fd.append('jwt', Token);
    let url = ServerUrl + '/fetchField/tbl_standards_setting/category/ID/1';
    axios.post(url, fd, config)
    .then(result =>setCategory(result.data))
}



const fetchComment =()=>{
    const fd = new FormData();
    fd.append('jwt', Token);
    let url = ServerUrl + '/fetchField/tbl_exam_settings/comments/ID/1';
    axios.post(url, fd, config)
    .then(result =>setComments(result.data))
}




const getAssessmentName =(code, field)=>{
    const result = assessment.filter(item=>item.code===code)
    const answer = result.length!==0?result[0][field]:''
    return String(answer)
}

setTimeout(()=>{
    setClassType(String(getClassField(classID, schoolClass, 'arms')))
},3000)

const fetchClassAssessment =(type)=>{
    
    if(sectionID !==undefined && sectionID !=='')
setNotice({...notice, isLoading: true}) 
var sql ="SELECT  assessmentCode  from tbl_assessment_result where examCode ='"+type+"' and sectionID ='"+sectionID+"' group by assessmentCode" ;

const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
if(result.data.length!==0){

    var option = result.data
    var response = []
    for (var i = 0; i < option.length; i++){
        response.push(
            {
                code:option[i].assessmentCode,
                categoryCode:getAssessmentName(option[i].assessmentCode, 'categoryCode'),
                assessmentName:getAssessmentName(option[i].assessmentCode, 'assesmentName')

            })
    }
    setClassAssessment(response)
}

})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
setNotice({...notice, isLoading: false})
})
}


const DownloadStudentList =()=>{
    let error = {};   
    if(result.examtype.length===0){
        error.examtype ='Please select exam type';
    } 
    
    setErrors(error)

    if(Object.keys(error).length === 0){

       document.getElementById('exportExcel').click()

    }else{
        window.scrollTo(0,0)
    }


}

const fetchStudentList =()=>{
    
    let error = {}; 
    
    if(result.examtype.length===0){
        error.examtype ='Please select exam type';
    } 
    
    setErrors(error)

    if(Object.keys(error).length === 0){

        if(sectionID !==undefined && sectionID !=='')
setNotice({...notice, isLoading: true}) 


let sql =   'SELECT count(*) as subject, rank() over (order by sum(e.total) desc) position, cast(sum(case when e.total >0 and e.absent="No" then e.total else 0 end)/sum(case when e.total >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) as average, s.admissionNumber, s.gender, s.termination, s.termination_date, s.termination_remark, sum(e.total) as obtained, sum(e.maxMark) as obtainable, s.registerNumber, s.studentName, e.maxMark, e.absent, r.teacherRemark, r.headRemark, r.code from tbl_students s, tbl_exam_result e, tbl_result_remarks r where s.code = e.studentCode and s.admissionStatus ="Studying" and s.code = r.studentCode and r.examType ="'+result.examtype.value+'" and r.sectionID ="'+sectionID+'" and  e.examCode ="'+ result.examtype.value+'" and e.sectionID ="'+sectionID+'" group by e.studentCode  order by s.studentName ASC '



const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token);
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
    if(result.data.length!==0 && Array.isArray(result.data)){
       
setStudentList(result.data.sort((a,b)=>Number(a.obtained)>Number(b.obtained)?-1:1))
setActiveStep(2)
    }
    else{
        setStudentList([]) 
    }

})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
setNotice({...notice, isLoading: false})

})
    }
}

  
const handleSelect = (option, action)=>{
    
    fetchClassTeaher()
    setResult({...result, [action.name]: option });
   setErrors({...errors, [action.name]:''})
    if(action.name==='examtype'){
    fetchClassAssessment(option.value)
   }
   setStudentList([])
  
}



const handleChangeComment =(event, index)=>{
    const student =JSON.parse(JSON.stringify(studentList))
    const {name, value} = event.target
    student[index][name] = value;
    setStudentList(student); 
       } 


       const fetchSettings =()=>{
        const fd = new FormData();
    fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_result_slip/settings/ID/1';
        axios.post(url, fd, config).then(result=>{
            setSettings(result.data[0])
          
        })
    }
 
const handleSubmit = event =>{
    event.preventDefault();    
    let error = {}; 
     
    if(result.examtype.length===0){
        error.examtype ='Please select exam type';
    } 
    if(studentList.length===0){
        error.studentClass ='There are no student in the selected class';
    }
    setErrors(error)

    if(Object.keys(error).length === 0){

submit()
    }
}


var headName =getResultHead(classType)==='Secondary'?
settings.headofSecondary: getResultHead(classType)==='Primary'?
settings.headofPrimary: settings.headofNursery

    function submit(){
    
setNotice({...notice,  isLoading: true})    


        
var teacher = classTeacher;
var list = [];
for (var i = 0; i< teacher.length;  i++) {
    list.push(teacher[i].code);
        }

    const fd = new FormData(); 
    fd.append('jwt', Token);
   fd.append('commentList', JSON.stringify(studentList, null, 2));
   fd.append('headofSchool', settings.headofSchool);
   fd.append('headName', headName);
   fd.append('teacherName', JSON.stringify(list, null, 2));
   fd.append('resultHead', getResultHead(classType));

   

    let url = ServerUrl+'/update_controller/tbl_result_remarks';
          
        axios.post(url, fd, config)
        .then(response =>{
            if(response.data.type ==='info'){
                Alerts('Updated!', 'default', response.data.message)
                    } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
                    }   
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                
                  setNotice({...notice,  isLoading: false })
                  
                setIsSubmitting(false);
                  setActiveStep(1)
              })   
  }
    




  
const  handleSubmitFile=(event)=>{ 
    event.preventDefault(); 

    if(!csvFile.studentCsv){
        //errors.studentCsv ='You have not choose any file to upload';
        Alerts('Error!', 'danger', 'You have not choose any file to upload')
    }else{
    
    const fd = new FormData();
        fd.append('jwt', Token); 
        fd.append('studentCsv', csvFile.studentCsv);

     setNotice({...notice,  isLoading: true}) 

       let url = ServerUrl+'/update_controller/importcomment'

      axios.post(url, fd, config).then(response =>{
        if(response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
                } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
        }).finally(()=>{ 
            setNotice({...notice,  isLoading: false}) 
     setCsvFile({...csvFile, studentCsv:'', fileName:'Please select .csv files (allowed file size 800KB)'}); 
     document.getElementById('studentCsv').value=''
        }) 
} 

}

const  handleFile = function(fieldName){
    return function(newValue){  
       
        setErrors({...errors, [fieldName]:''})
   readURL(newValue);
   }
   
} 


const readURL = (input)=>{
   
    let displayMessage = '';
    const PreviewImage = input.target.name;
        var fileName = document.getElementById(PreviewImage).value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    

    if (extFile==="csv" || extFile==="CSV"){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 0.9) {
        displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 800 Kb';
       document.getElementById(input.target.name).value = '';
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
               const value = input.target.files[0]
        setCsvFile({...csvFile, studentCsv:value, fileName:value.name})
       
        }
    }
}else{

    displayMessage = 'Only csv|CSV  files are allowed with maximum of 500kb'
    document.getElementById(input.target.name).value = '';
    setCsvFile({...csvFile, studentCsv:'', fileName:'Please select .csv files (allowed file size 800Kb)'})
}  

if(displayMessage.length > 0){
    Alerts('Error!', 'danger', displayMessage)
  }

} 





    const fetchClassTeaher =()=>{
        var newStaff = staff&&staff.filter((item=>item.classID===classID&&item.sectionID===sectionID))
        setClassTeacher(newStaff)
    }



    useEffect(()=>{
        fetchAssessment()
        fetchExamType()
        fetchComment() 
        fetchCategory()
        fetchSettings();
    },[]);
  

        return ( <> {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 

        <PageHeader title="Comment Entry">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Comment Entry </a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Comment Entry</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-primary btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '1px'}}>
{activeStep === 1 ? 
	   <div className="row setup-content" id="examType">
		  
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

      
   <div className="card-block">
      
  <div className="row">
     
     <section className="col-md-12">
           <div className="form-group">
         <label> Exam Type <span style={{color:'red'}}>*</span></label>
         <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam }
                             })
                        } 
                       
onChange={handleSelect} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

            </div>
     
     </section>
     
     </div>

     <div  className="row"> 
     
     <section className="col-md-12"> 
     <footer className="pull-right">
	
	<button type="button" id="submit" onClick={fetchStudentList} className="btn btn-primary "><i className="fa fa-graduation-cap"></i> Display Student</button>
    </footer>						
     </section>

     </div> 
    </div>
                               
       </div>	     
       
       	</div>
       
       </div>	
			
       </div>
    
</div> :
<div className="row setup-content" id="extras">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">

   <div className="row">
       <div className='col-md-12 '>
           <div className='pull-right'>
               

           </div></div>
   <div className="table-responsive">
       
        <table className="table table-bordered">
            <thead>
                <tr>
                <th>SN</th> 
                    <th>Student Details</th>
                    <th>Score</th> 
                    <th>Average</th> 
                    <th>Rank</th> 
                    <th>Class Teacher</th> 
                
   <th>Head of {getResultHead(classType)}</th>  
                    
                </tr>
            </thead>
            <tbody>{studentList.length!==0?studentList.map((st, idx)=> 
                <tr key={idx}>
<td>{idx+1}</td>
                    <td style={{maxWidth:'350px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.admissionNumber} - {st.studentName} {st.termination==='Yes'?<div><br/><span className="pcoded-badge label label-danger">Terminated on {longDate(st.termination_date)} {st.termination_remark}</span></div>:''} </td>

                    <td>{st.obtained}</td>
                    <td>{st.average}</td>
                    
            <td>{st.position}<sup>{getRank(st.position)}</sup></td>
                    

                    <td style={{maxWidth:'350px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>
                        



                        <textarea name='teacherRemark' placeholder='Enter Comment' value={st.teacherRemark} className="form-control" onChange={(e)=>handleChangeComment(e, idx)} rows={3}>{st.teacherRemark}
    
                        </textarea>
    
                            <select name="teacherRemark" value={st.resultHead}  className="form-control" onChange={(e)=>handleChangeComment(e, idx)}   >
    
                            <option  value=''>Select Comment</option>
    
                            {comments&&comments.map((item,index)=>
                            <option key={index} value={item.comment}  >{item.comment}</option>)}
                            </select>
                        
                        </td>


                     <td style={{maxWidth:'350px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>
                        



                    <textarea name='headRemark' placeholder='Enter Comment' value={st.headRemark} className="form-control" onChange={(e)=>handleChangeComment(e, idx)} rows={3}>{st.headRemark}

                    </textarea>

                        <select name="headRemark" value={st.remark}  className="form-control" onChange={(e)=>handleChangeComment(e, idx)}   >

                        <option  value=''>Select Comment</option>

                        {comments&&comments.map((item,index)=>
                        <option key={index} value={item.comment}  >{item.comment}</option>)}
                        </select>
                    
                    </td>
                </tr>):[]}
            </tbody> 
        </table>
 </div>	 </div>   
<div style={{color:'red'}}>{errors.examtype && errors.examtype}</div>
<div style={{color:'red'}}>{errors.studentClass && errors.studentClass}</div>
       </div>			
       </div>       
       </div>

</div>}

<button onClick={activeStep === steps.length ? handleSubmit: handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Update Comment </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }

                </div>
                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>


                </div>
          {/* The Page Body close here */}

            </div>
            </div>

</>  );
        
}

export default React.memo(CommentEntry) 