import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, selectStyles, allowDelete, allowEdit, menuLink} from '../component/include'
import Select from 'react-select'
import { longDate } from '../component/globalFunction'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'

const AllPosts =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false
        
    }); 
    
    
  let location =  useLocation()
  const [isAuthenticated, setIsAuthenticated] = useState(true)
  const [category, setCategory] = useState([])
  const [search, setSearch]=useState({
    month:'',
    category:{label:'', value:''},
    filterText:''

})


    const [content, setContent] = useState([]);

    const fetchPost =  ()=>{
        var sql ="Select  p.ID, p.code, p.post_title, p.post_author, p.comment_status, p.post_status, p.view_count, p.post_date, p.post_category, p.quote, p.post_slug, p.post_modified, s.staffName, c.name, 'false' as status from  tbl_blog_category c, tbl_blog_posts p LEFT JOIN tbl_staff s ON  s.code = p.post_author where c.code=p.post_category "


       if(search.category.value!==''){
        sql = sql + " and p.post_category ="+search.category.value
       }

       if(search.month!==''){
        sql = sql + " and cast(p.post_date as date) ="+search.month
       }

sql = sql + " order by p.ID DESC"


setNotice({...notice, isDataFetching: true}) 

    const fd = new FormData();
    fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller';


 axios.post(url, fd, config)
      .then(response =>{

			if(response.data.length!==0 && Array.isArray(response.data)){
             setContent(response.data.sort((a, b) =>(a.ID > b.ID) ? -1 : 1))

            }else{
              setContent([])
        
                  } 
    })
       .catch((error)=>{
                 setErrors({...errors, errorMessage:error.message})
              }).finally(()=>{
                setNotice({...notice, 
                    isDataFetching: false})
                 }) 

                }
   



    const handleChange=(event)=>{
        let {name, value}=event.target
        setSearch({...search, [name]:value});
        setErrors({...errors, [name]:''})
    }
    

    const handleSelect = (option, action)=>{
        setSearch({...search, [action.name]: option});
        setErrors({...errors, [action.name]:'', periodList:''})             
    }

    
    const fetchCategory =()=>{

       
        var sql ="Select code, name, slug from tbl_blog_category order by name ASC";  
      
         const fd = new FormData();
         fd.append("sql", sql);
         fd.append("jwt", Token);
         let url = ServerUrl+'/fetchBySql_controller.php';
            axios.post(url, fd, config).then(result=>{  
              
         setCategory(result.data)
           })
           .catch((error)=>{
               Alerts('Error!', 'danger', error.message)
           })
     }
    
     

     const handleDelete = (ID)=>{  
        setNotice({...notice,  isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+ID)
              close.click();
           const fd = new FormData();
           fd.append('ID', 'ID')
            fd.append('data', ID)
            fd.append("jwt", Token);


            let url = ServerUrl+'/delete_controller/tbl_blog_posts';
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
                    setErrors({...errors, errorMessage: JSON.stringify(response.data)})
                   }   
           })
           .catch((error)=>{
            setErrors({...errors, errorMessage: error.message})
           }).finally(()=>{
            fetchPost()
               setNotice({...notice,  isLoading: false })
           }) 
    }


    const tableHeader = [
              
          {dataField: 'ID', text: 'Action',  formatter: (cell, row)=><div>
            
          {allowDelete==='Yes'? <a href="#!" data-toggle="modal"   data-target={`#warningdialog-${cell}`}  className="btn btn-danger btn-sm"    title="Delete"><i className="icofont icofont-delete-alt"></i></a>:[]}&nbsp;

          {allowEdit==='Yes'?<a href={"/cms/news/edit/"+row.code}  className="btn btn-warning btn-sm"    title="Edit "><i className="icofont icofont-edit"></i></a>:[]}

          <WarningModal  message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(cell)} mID={cell} /> </div>, editable: false, hidden:allowDelete==='No'&&allowEdit==='No'?true:false},

        {dataField: 'comment_status', text: 'Comment', editable:false, formatter:(cell, row)=>cell==='open'?<span className="badge  badge-success">Open </span>:<span className="badge  badge-danger">Close</span>},
        
        {dataField: 'post_title', text: 'Title', formatter: (cell)=><div>{cell}</div>},
          {dataField: 'staffName', text: 'Author',  editable:false},
          {dataField: 'name', text: 'Category',  editable:false},
          

            {dataField: 'post_date', text: 'Date Posted', editor: {
                type: Type.DATE },  formatter: (cell)=>longDate(cell)},


                {dataField: 'view_count',  text: 'View',  editable: false}, 
     ];

    

     const TableRecord=(props)=>{
        const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
          }) => ( 
          <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
              {
                columns
                  .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                  .map(column => (
                    
                    <button
                      type="button"
                      key={ column.dataField }
                      className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                      data-toggle="button"
                      aria-pressed={ column.toggle ? 'true' : 'false' }
                      onClick={ () => onColumnToggle(column.dataField) }
                    >
                      { column.text }
                    </button>
                  ))
              }
            </div>
            
          );

        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
                fd.append("jwt", Token); 
                fd.append('columnField', newValue);
                fd.append('column', column.dataField);
                fd.append('ID', ID);
              
               let url = ServerUrl+'/updateById_controller/tbl_blog_posts'
              axios.post(url, fd, config)
              //.then(result => console.log(result.data))
              .then()
              .catch((error)=>console.log(error)) 
              
            } 
         const { SearchBar } = Search;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '70', value: 70}, {text: '150', value: 150}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '100', value: 100},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 
                                 <div className="btn-group dropdown-split-success pull-right">
      
      <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'50px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>
                                 </div>
                                 
                     <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                        mode: 'dbclick',
                                        blurToSave: true,
                                        autoSelectText: true,
                              beforeSaveCell: (oldValue, newValue, row, column) => {
                                if(oldValue !==newValue){
                                handleUpdate(column, newValue, row.ID);
                                
                               return '';
                              }
                              
                            }
                                      }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      


useEffect(()=>{
    fetchPost()
    fetchCategory()
  
    setIsAuthenticated(menuLink.includes(location.pathname))
},[]);



        return (  <>
        
        {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
   {isAuthenticated?  <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="All Article">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-desktop"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Blog</a> </li>
                    <li className="breadcrumb-item"><a href="#!">All Article</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                
                {errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}


<div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Search Post</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <form method='post' onSubmit={fetchPost}> 
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
       
     
      <section className="col-md-6">
     	
      <div className="form-group">
						<label >Category</label>
                        
            <Select options={
                             category&&category.map((list, idx)=> {
                                return {key:idx, value: list.code, label: list.name}
                              })} 
                          
onChange={handleSelect}  name="category" className={errors.category? 'form-control form-control-danger' : ''}   value={search.category}  styles={selectStyles}  /> 				
											</div>
   
                                   

</section>





<section className="col-md-6">        

 <div className="form-group">
<label >Month </label>
<input type="month" required className={errors.startDate ? 'form-control form-control-danger' : 'form-control'} name="month"  onChange={handleChange} value={search.month} />
<span style={{color:'red'}}>{errors.startDate && errors.startDate}</span>
   </div>
           

</section>


</div>

 </div> </div> 
							
</div>

</div>   

     
<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="submit"    className="btn btn-primary "><i className="fa fa-save"></i> Search</button>
								</footer>		</section>		
							</div>
                            </form>
     </div>  </div>
							
                            </div>
                            
                            </div>

                            <div className="row" >

                            <div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Listing All Articles</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12">
        <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
   <TableRecord data={content}   columns={tableHeader}  />} 
</div>
	</div>
    </div></div>
</div>
</div>
   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(AllPosts) 