import React, {useState, useEffect} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, allowDelete, allowEdit, menuLink} from '../component/include'
import Select from 'react-select'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'

const ManageBooks =()=> {
    const [errors, setErrors] = useState({});
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    })
    
    const [books, setBooks] = useState({
        purpose:[],
        subject:[],
        bookName:'',
        authorName:'',
        publisher:'',
        price:'0',
        barcode:'',
        book:[],
        quantity:'',
        remarks:'',
        bookDate:''
    })

    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [subject, setSubject]= useState([])    
    const [purpose, setPurpose]= useState([])
    const [allBooks, setAllBooks]= useState([])
    const [newBookList, setNewBookList] = useState([])

    const fetchSubject =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_subject'
        axios.post(url, fd, config).then(result=>setSubject(result.data))
    }
    const fetchPurpose =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_library_settings/purpose/ID/1'
        axios.post(url, fd, config)
        .then(result =>setPurpose(result.data))
    }
    const fetchAllBooks =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_books'
        axios.post(url, fd, config).then(result=>setAllBooks(result.data))
    }

    const handleChange = event =>{
		const {name, value} = event.target
        setBooks({...books, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const handleSelect = (option, action)=>{
        setBooks({...books, [action.name]: option});
        setErrors({...errors, [action.name]:''})
         }

    const handleSubmit = event =>{
        event.preventDefault();
    setErrors(ValidateInput(books));
        setIsSubmitting(true);
    } 


        const ValidateInput=(values)=>{
            let errors = {};   
           
            if(!values.bookName){
                errors.bookName ='Please enter Book Name';
            }
    
            if(values.purpose.length===0){
                errors.purpose ='Please select book purpose';
            }
            if(values.subject.length===0){
                errors.subject ='Please select Book Subject';
            }
            if(!values.authorName){
                errors.authorName ='Please enter Author Name';
            }
            return errors;
            }


function submit(){  
    let formData = books;
    const fd = new FormData();
    fd.append('jwt', Token); 
     fd.append('purposeCode', books.purpose.value);
    fd.append('subjectCode', books.subject.value);
    for(let k in formData){
        fd.append(k,formData[k]);
    };
    setNotice({...notice,  isLoading: true}) 
       let url = ServerUrl+'/save_controller/tbl_books'
	  axios.post(url, fd, config)
	  .then(response =>{
            if (response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
            } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
        }  
                   
           })
           .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        .finally(()=>{
          setNotice({...notice, isLoading: false}) 
          fetchAllBooks()
      setIsSubmitting(false); 
      setBooks({ 
        purpose:[],
        subject:[],
        bookName:'',
        authorName:'',
        publisher:'',
        price:'0',
        barcode:''}); 
      })
}


const getBookName =(code, field)=>{
    const result = allBooks&&allBooks.filter(list=>list.code===code);
    const answer = result.map(c=>c[field]);
    return  answer
}

    const handleSubmitBookList=()=>{
if(books.book.length===0){
    setErrors({...errors, book:'Please select book name'});
}else if(!books.quantity || Number(books.quantity)<=0){
    setErrors({...errors, quantity:'Please enter valid Quantity'});
}else{
        let result = []
        for (var i =1; i<=books.quantity; i++){
            result.push({code:i, bookCode:books.book.value, price:getBookName(books.book.value, 'price'), bookName:getBookName(books.book.value, 'bookName'), authorName:getBookName(books.book.value, 'authorName')})
        }
        setNewBookList(result)
        window.scrollTo(0,document.body.scrollHeight);
}
    }


    

const handleSubmitBook=()=>{  
      
if(newBookList.length===0){
    setErrors({...errors, book:'Please select book name'});
}else{
    const fd = new FormData();
    fd.append('jwt', Token);
  var options = newBookList
const selectBook = allBooks.filter(item=>item.code===books.book.value)[0].bookList
let result = []
for (var i = 0, l = options.length; i < l; i++) {
    result.push({
        code:'b'+Math.random().toString(36).substr(2,9),
        bookCode:options[i].bookCode, 
        remarks:books.remarks, 
        bookDate:books.bookDate,
        accessionNumber:'',
        isbn:'',
        placePublication:'',
        datePublication:'',
        sellerDonor:'',
        publishPrice:options[i].price,
        purchasePrice:'',
        billNo:'',
        noPages:'',
        callNo:'',
        volumeNo:'',
        bindingNature:'',
        division:'',
        bookRack:'',
        bookRow:'',
        category:'',
        bookType:'',
        status:''
    })
}
const finalBook = JSON.parse(selectBook).concat(result)

 fd.append('bookCode', books.book.value);
fd.append('bookList', JSON.stringify(finalBook, null, 2));
fd.append('total', finalBook.length);

setNotice({...notice, 
    isLoading: true}) 
     let url = ServerUrl+'/update_controller/tbl_books_list'
    axios.post(url, fd, config)
    .then(response =>{
          if (response.data.type ==='info'){
              Alerts('Update!', 'default', response.data.message)
          } else{
              Alerts('Error!', 'danger', JSON.stringify(response.data))
      }  
                 
         })
         .catch((error)=>{
          Alerts('Error!', 'danger', JSON.stringify(error.message)) 
      })
      .finally(()=>{
          fetchAllBooks();
        setBooks({...books, 
            book:[],
            quantity:'',
            remarks:'',
            bookDate:'',
            price:''})
            setNewBookList('')
            setNotice({...notice, 
                isLoading: false}) 
      })
    
      } 
     
}

const handleRemove=(code)=>{
    setNewBookList(newBookList.filter(prev=>prev.code!==code))
}

useEffect(()=>{
    setIsAuthenticated(menuLink.includes(location.pathname)) 
    fetchAllBooks();
    fetchPurpose();
    fetchSubject();
},[]);



useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])
     


        return (   <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Library Books">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-book"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Library</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Manage Books</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
							
                               
                               <div className="col-sm-5">
					

                            <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> New Book (Step 1)</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<div className="row">
									
										<section className="col-md-12">
                                    
                                    
                                        <div className="form-group">
						<label >Subject<span style={{color:'red'}}>*</span></label>
                        <Select options={
                            subject&&subject.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.subjectName, abr:list.abbreviation }
                             })} 
onChange={handleSelect} className={errors.subject ? 'form-control form-control-danger' : ''} name="subject" value={books.subject}  /> <span style={{color:'red'}}>{errors.subject && errors.subject}</span>	
			</div>


            <div className="form-group">
						<label >Purpose<span style={{color:'red'}}>*</span></label>
                        <Select options={
                            purpose&&purpose.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.purposeName }
                             })} 
onChange={handleSelect} className={errors.purpose ? 'form-control form-control-danger' : ''} name="purpose" value={books.purpose}  /> <span style={{color:'red'}}>{errors.purpose && errors.purpose}</span>	
			</div>

										<div className="form-group">
						<label >Book Name<span style={{color:'red'}}>*</span></label>
		<input type="text" name="bookName" value={books.bookName} onChange={handleChange} className={errors.bookName  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.bookName && errors.bookName}</span>	
			</div>

            <div className="form-group">
						<label >Author Name<span style={{color:'red'}}>*</span></label>
		<input type="text" name="authorName" value={books.authorName} onChange={handleChange} className={errors.authorName  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.authorName && errors.authorName}</span>	
			</div>

            <div className="form-group">
						<label >Publisher</label>
		<input type="text" name="publisher" value={books.publisher} onChange={handleChange} className='form-control' /> 
			</div>

            <div className="form-group">
						<label >Cost</label>
		<input type="number" name="price" value={books.price} onChange={handleChange} className='form-control' /> 
			</div>
										</section>
										</div>	

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary ">Save</button>
	
								</footer>				
							</div>
							</div>


                            </div>
		
	

	<div className="col-sm-7">



    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Add Book List (Step 2)</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		<div className="row">
									
										<section className="col-md-12">
                                    
                                        <div className="form-group">
						<label >Select Book <span style={{color:'red'}}>*</span></label>
                        <Select options={
                            allBooks&&allBooks.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.bookName, aut:list.authorName }
                             })} 
                             getOptionLabel={option =>`${option.label} (${option.aut})`}
onChange={handleSelect} className={errors.book ? 'form-control form-control-danger' : ''} name="book" value={books.book}  /> <span style={{color:'red'}}>{errors.book && errors.book}</span>	
			</div>


            <div className="form-group">
						<label >Quantity<span style={{color:'red'}}>*</span></label>
		<input type="number" name="quantity" min="1" value={books.quantity} onChange={handleChange} className={errors.quantity  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.quantity && errors.quantity}</span>	
			</div>

</section>
				</div>	

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmitBookList} className="btn btn-primary ">Proceed</button>
	
								</footer>				
							</div>
                            </div>
							</div></div>




        
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Add New Book List (Step 3)</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12">
        <div className="card z-depth-0">
            <div className="card-block">
        <div className="table-responsive">

        <table  className="table table-bordered table-striped table-hover ">
	<thead>
		<tr>
		<th>Action</th>
		<th>Book Name</th>
		<th>Author Name </th>
	</tr>
	</thead>
	<tbody>
              {newBookList.length!==0? newBookList.map((rst, id)=>
              <tr key={id}>
                  <td><button className="btn-danger" onClick={()=>handleRemove(rst.code)}  >x</button></td>
                  <td>{rst.bookName}</td>
                  <td>{rst.authorName}</td>
              </tr>
              ):[]}
										
				</tbody>
										
			</table>

</div>
<div className="card-block">
		<div className="row">
									
				<section className="col-md-12">
                                    
                                        <div className="form-group">
						<label >Date</label>
		<input type="date" name="bookDate"  value={books.bookDate} onChange={handleChange} className='form-control' /> 
			</div>

            <div className="form-group">
						<label >Remark</label>
		<input type="text" name="remarks"  value={books.remarks} onChange={handleChange} className='form-control' /> 
			</div>
	</section>
				</div>	

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmitBook} className="btn btn-primary ">Confirm</button>
	
								</footer>				
							</div>
	</div>
    </div></div></div>
</div>


</div>

</div>
	

   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(ManageBooks) 