import React, {useState, useEffect, useRef} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config, applicantUrl,  allowDelete, allowEdit, menuLink, domain} from '../component/include'

import { useLocation } from 'react-router-dom'
import Error403 from '../settings/error403'
import Cookies from 'js-cookie'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { Link } from 'react-router-dom'
import { encrypt } from '../component/authentication'

const Agent =()=> {
    const [errors, setErrors] = useState({});
    let myRef = useRef()
    let location =  useLocation()
  const [isAuthenticated, setIsAuthenticated] = useState(true)
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    
    const [agent, setAgent] = useState({
        code:Math.random().toString(36).substring(2,9),
		email:'',
		password:'',
		gender:'Male',
        fullname:'',
        confirmPassword:'',
        reffered:[]
    })
    const [allAgent, setAllAgent]= useState([])
   
    const handleChange = event =>{
		const {name, value} = event.target
       setAgent({...agent, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const handleDelete = (ID)=>{  
        setNotice({...notice,  isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+ID)
              close.click();
           const fd = new FormData();
           fd.append('ID', 'ID')
            fd.append('data', ID)
            fd.append("jwt", Token);
            let url = ServerUrl+'/delete_controller/tbl_agent';
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
              fetchAgent()
               setNotice({...notice, 
                   isLoading: false
               })
           }) 
    }

const handleReset =()=>{
    setAgent({
        code:Math.random().toString(36).substring(2,9),
		email:'',
		password:'',
		gender:'Male',
        fullname:'',
        confirmPassword:'',
        reffered:[]
    })
}
    const LoginAsAgent =(row)=>{

        var inSixtyMinutes = Date.now() + 59 * 60 * 1000;
		var inOneMinutes = Date.now() + 58 * 60 * 1000;
		var inOneHours = new Date(new Date().getTime() + 59 * 60 * 1000);
		
        Cookies.set("_tmapp", inOneMinutes, { expires: inOneHours, domain: domain});
        Cookies.set("_btmapp", inSixtyMinutes, { expires: inOneHours, domain: domain });
        Cookies.set('appstnm', encrypt(row.fullname), {expires: inOneHours, domain: domain })
        Cookies.set('apptk', Token,  {expires: inOneHours, domain: domain }) 
        Cookies.set('appid', row.code,  {expires: inOneHours, domain: domain })  
			window.open(applicantUrl+'/dashboard', '_blank')

    }


    const tableHeader = [
        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div>
        {allowDelete==='Yes'?    <a href="#!" data-toggle="modal"  className='btn btn-danger btn-sm'  data-target={`#warningdialog-${cell}`} title="Delete"><i className="icofont icofont-delete-alt"></i></a>:[]} &nbsp;
            <a href="#!"   className='btn btn-inverse btn-sm' onClick={()=>LoginAsAgent(row)}  title="Login as this Agent"><i className="icofont icofont-login"></i></a> 

<WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(cell)} mID={cell} />
        </div>, editable: false},
        {dataField: 'fullname', text: 'Fulname',  sort: true,  validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Full Name is required'
              };
            }
            return true;
          } },
          {dataField: 'email_address', text: 'Email'},
          {dataField: 'total', text: 'Applications', editable: false},   
          {dataField: 'submitted', text: 'Submitted', editable: false},
          {dataField: 'admitted', text: 'Admitted', editable: false},  
     ];

    

         const fetchAgent =()=>{
             setNotice({...notice, isDataFetching:true})
            
        var sql ="SELECT a.ID, a.code, a.email_address, a.fullname, a.gender, IFNULL(sum(case when r.agent <> '' then 1 else 0 end ),0) as total, sum(case when r.admissionStatus ='Admitted' then 1 else 0 end ) as admitted, sum(case when r.submitted ='true' then 1 else 0 end ) as submitted, sum(case when r.submitted ='false' then 1 else 0 end ) as not_submitted FROM tbl_agent a LEFT JOIN tbl_admission_request r ON a.code = r.agent GROUP BY a.code order by a.ID DESC"

        const fd = new FormData();
    fd.append('jwt', Token);
    fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
                
                setAllAgent(result.data)
            })
        .catch((error)=>{
            //Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false})
        })
        }

       

    

     const handleSubmit = event =>{
        event.preventDefault();

        let errors = {};   
        let email = agent.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        
        let rs = allAgent.filter(item=>item.email_address===agent.email)
        
        if(!agent.fullname){
            errors.fullname ='Please enter fullname';
        }
        if(!email){
            errors.email ='Please enter valid email address';
        }

        if(rs.length!==0){
            errors.email ='Email entered already register';
        }

 
        if(agent.password.length <6){
              errors.password ='Please enter minimum of 6 characters';
          } 


          if(agent.password!==agent.confirmPassword){
            errors.password ='Password does not match.';
        }

    setErrors(errors);


    if(Object.keys(errors).length === 0){
        submit()
    } 
    
}
   

function submit(){  
    setNotice({...notice, 
        isLoading: true})     
    
  let formData = agent;
  const fd = new FormData();
  for(let k in formData){
  fd.append(k, formData[k])
  }
  fd.append("reffered_by", '')
  
  
  let url = ServerUrl+'/save_controller.php?tablename=tbl_agent_application';
  axios.post(url, fd, config)
  .then(response =>{
  if(response.data.type === 'success'){
            Alerts('Saved!', 'success', response.data.message)
                } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
            setAgent({...agent,
                code:Math.random().toString(36).substring(2,9),
                email:'',
                password:'',
                gender:'Male',
                fullname:'',
                confirmPassword:'',
                reffered:[]
            }); 
              fetchAgent()
              setNotice({...notice, 
                  isLoading: false
              })
          }) 
}


        
const ValidateExistence=()=>{
	let email = agent.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
	if(agent.email!=='' && email){
		const fd = new FormData();
		fd.append("jwt", Token);
		fd.append('data', agent.email);
		
		let url = ServerUrl + '/checkExistence/tbl_agent/email_address'
		axios.post(url, fd, config).then(response=>{
			if(response.data===true){
				//myRef.current.focus()
				setErrors({...errors, email:agent.email+' already choosen register'})
				setAgent({...agent, email:''});
			}
	  })
	}
}

useEffect(()=>{
    
    setIsAuthenticated(menuLink.includes(location.pathname))
    fetchAgent();
},[]);


const BasicTable=(props)=>{

    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData(); 
        fd.append('newValue', newValue);
        fd.append('column', column.dataField);
        fd.append('ID', ID);
        fd.append("jwt", Token);
       let url = ServerUrl+'/updateCustom_controller/tbl_agent'
      axios.post(url, fd, config)
       .then()
       .catch((error)=>alert(error)) 
       
     } 
  
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } entries</span>
      );
      
const { SearchBar } = Search;
     const options = {
         showTotal: false,
     paginationTotalRenderer: customTotal,
     sizePerPageList: [{text: '50', value: 50}, {text: '100', value: 100}, {text: '250', value: 250},
     { text: 'All', value: props.data.length }]
        
       };
  
     return <React.Fragment>  
    
             <ToolkitProvider search  className="table-responsive"
                 keyField='code' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           
                             <SearchBar
                             { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
                             
                             <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                   mode: 'dbclick',
                                   blurToSave: true,
                                   autoSelectText: true,
                         beforeSaveCell: (oldValue, newValue, row, column) => {
                           if(oldValue !==newValue){
                           handleUpdate(column, newValue, row.ID);
                           
                          return props.fetchdata;
                         }
                         
                       }
                                 }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  </React.Fragment>
  }

  
    
        return (  <>

   <div  className="main-body">
   {isAuthenticated?   <div className="page-wrapper">
                {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Application Forms">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><Link to="/admission/admission_request"> Application</Link></li> 
                    <li className="breadcrumb-item"><a href="#!">Setup Agent</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Create Agent</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<fieldset>
            
            <div className="row">
									
										<section className="col-md-6">


										<div className="form-group">
						<label >Fullname<span style={{color:'red'}}>*</span></label>
						<input type="text" role={'presentation'} autoComplete='off' name="fullname" value={agent.fullname} onChange={handleChange} className={errors.fullname  ? 'form-control form-control-danger form-control-lg' : 'form-control form-control-lg'} />
                        
                         <span style={{color:'red'}}>{errors.fullname && errors.fullname}</span>	
			
												
											</div>

                                            <div className="form-group">

<label >Password<span style={{color:'red'}}>*</span></label>
        <input type="password" id="password" name="password" role={'presentation'} autoComplete='off'  value={agent.password} placeholder="Password" onChange={handleChange} className={errors.password  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} />
        
        <span style={{color:'red'}}>{errors.password && errors.password}</span>
        </div>


                                            <div className="form-group">


<label className='text-bold'> Gender </label>
                                            <div className="form-radio">
						<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="gender" onChange={handleChange} value='Male' checked={agent.gender==='Male'?true:false} />
						<i className="helper"></i>Male
					</label>
				</div>
				<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="gender" onChange={handleChange} value='Female' checked={agent.gender==='Female'?true:false} />
						<i className="helper"></i>Female
					</label>
				</div>
				</div>

                                            </div>
                                            </section>
                                            <section className="col-md-6">
                                            <div className="form-group">

						<label >Email Address<span style={{color:'red'}}>*</span></label>
						<input type="email" id="email" name="email" onBlur={ValidateExistence} role={'presentation'} autoComplete='off'  value={agent.email} placeholder="Email Address" onChange={handleChange} className={errors.email  ? 'form-control form-control-lg formerror' : 'form-control form-control-lg'} /> 
                        <span style={{color:'red'}}>{errors.email && errors.email}</span>	
			
												
											</div>


                                            <div className="form-group">

                                    <label > Confirm Password<span style={{color:'red'}}>*</span></label>
                                            <input type="password" id="confirmPassword" name="confirmPassword" role={'presentation'} autoComplete='off'  value={agent.confirmPassword} placeholder="Password" onChange={handleChange} className={errors.password  ? 'form-control formerror form-control-lg' : 'form-control form-control-lg'} />
                                            
                                            <span style={{color:'red'}}>{errors.password && errors.password}</span>
                                            </div>

</section>


										</div>
										
										</fieldset>

	<footer className="pull-right">
	<button type="button"  onClick={handleReset} className="btn btn-danger ">Reset</button>&nbsp;
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-success active text-bold ">Create Account</button>
	
								</footer>				
							</div>
							</div>
							</div>
		
	
	

	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> All Agents have access to Application Portal @ <a target='_blank' href={applicantUrl}>{applicantUrl}</a></h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12 table-responsive">
        
        {notice.isDataFetching ? <TableLoader />:
   <BasicTable data={allAgent}   columns={tableHeader}  />} 
</div>
	</div>
    </div>
</div>

</div>
	
   </div>
          {/* The Page Body close here */}

            </div>: <Error403 />}
            </div>
            
           

</> 
 );
        
}

export default React.memo(Agent) 