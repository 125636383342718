import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, ImagesUrl, Currency, FormatNumber, selectStyles, allowDelete, allowEdit, menuLink} from '../component/include'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'
import { userToken } from '../component/authentication'
import { useDispatch, useSelector } from 'react-redux'
import { longDate } from '../component/globalFunction'

const RoomAvailability =()=> {
    
    const [isSubmitting, setIsSubmitting] = useState(false);
const [errors, setErrors] = useState({})
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})


const [search, setSearch] = useState({
    hostelCode:'',
    schoolSession:''

})

let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
const schoolInfo = useSelector(state =>state.rootReducer.schoolInfo);
const schoolSession = useSelector(state =>state.rootReducer.schoolSession);
const hostel = useSelector(state => state.rootReducer.hostel);
const [rooms, setRooms]= useState([]) 



const handleSelect = (option, action)=>{
    setSearch({...search, [action.name]: option});
    setRooms([])
    setErrors({...errors, [action.name]:''})
     }


const fetchRoom =()=>{
    setNotice({...notice,  isDataFetching: true})
    let sql = "Select r.ID, r.code, r.roomName, r.roomRate,  r.noBedSpace, (select count(*) from tbl_hostel_allocation h where h.roomCode = r.code   and h.currentSession = '"+search.schoolSession.value+"' and h.hostelCode = '"+search.hostelCode.value+"'  ) as totalStudent from tbl_hostel_rooms r where r.hostelCode =  '"+search.hostelCode.value+"'  order by r.code"

    const fd = new FormData();
    fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>setRooms(result.data))
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice,  isDataFetching: false })
    }) 
    }





    const handleSearch = event =>{
        event.preventDefault();
    setErrors(ValidateInput(search));
    setIsSubmitting(true);
    } 



    const ValidateInput=(values)=>{
        let errors = {};   
       
        if(values.hostelCode.length===0){
            errors.hostelCode ='Please select hostel';
          
        }
        if(values.schoolSession.length===0){
            errors.schoolSession ='Please select session';
          
        }

        return errors;
        }
    



 useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    fetchRoom()
    }
    },[errors])


useEffect(()=>{
    
  setIsAuthenticated(menuLink.includes(location.pathname))
}, [])

        return (   <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Room Availability Report">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-bed"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!" >Hostel</a> </li>
                            <li className="breadcrumb-item"><a href="#!" >Rooms Availability</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              

                {errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}







    <div  className="row  ">
							
                              <div className="col-sm-12">
					

                            <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Search Hostel</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
	
         	<div  className="row">


             <section className="col-md-6">
                                            <div className="form-group">
						<label > Session <span style={{color:'red'}}>*</span></label> 
						<Select  options={schoolSession.map((dat, id)=> {
                                    return {key:id, value:dat.startYear, label:dat.startYear};
                                  })}
                        onChange={handleSelect} className={errors.schoolSession? 'form-control form-control-danger' : ''} name="schoolSession" value={search.schoolSession}  styles={selectStyles} /> 
                        <span style={{color:'red'}}>{errors.schoolSession && errors.schoolSession}</span>
											</div>
                       </section>


             <section className="col-md-6">
                                    
                                    <div className="form-group">
                                    <label >Select Hostel <span style={{color:'red'}}>*</span></label>
          <Select options={
                               hostel&&hostel.map((list, idx)=> {
                                   return {key:idx, value: list.code, label: list.hostelName, location:list.location  }
                                 })} 
                            getOptionLabel={option =>`${option.label} ${option.location}`}
    onChange={handleSelect} className={errors.hostelCode? 'form-control form-control-danger' : ''} name="hostelCode" value={search.hostelCode}  /> <span style={{color:'red'}}>{errors.hostelCode && errors.hostelCode}</span>
    
              </div>
    
                </section>





                </div>					
                                        	
                <div className="row">
	<footer className="col-sm-12">
	
	<button type="button" id="submit" onClick={handleSearch} className="btn btn-dark"><i className="fa fa-search"></i> Search</button>
	
		</footer>		
        </div>	


							</div>
							</div>


                            </div>
                            
                            </div>
                           


                            {rooms.length!==0?
<div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <div className="row" key={id}>

<table className="table  table-full-width">
       <tbody>
       <tr>
       <td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
 </td>
                </tr>
            </tbody>
       </table>

</div> 
                       )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Room Availability Report</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

<table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td colSpan='2'>Hostel: <b>{search.hostelCode.label}</b></td>
                    <td >Session: <b>{search.schoolSession.label}</b></td>
                    <td>Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
                
            </tbody>
       </table>



        <table className="table table-striped table-bordered  table-full-width">
    
            <thead>
                <tr>
                <th>Room</th>
                <th> Total Bed Space</th>
                <th>Occupied Bed</th>
                <th>Available Bed</th>
                <th> Room Rate ({Currency})</th>
                </tr>
            </thead>
            <tbody >
           


					{rooms.length!==0?rooms.map((row, id)=>{
					return	<tr key={id}>
                        
					<td>{row.roomName}</td>
                    <td>{row.noBedSpace}</td>
                    <td>{row.totalStudent}</td>
					<td>{(Number(row.noBedSpace)-Number(row.totalStudent))}</td>
					<td>{FormatNumber(Number(row.roomRate))}</td>
					
						</tr>
					}):[]}
				</tbody>
        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">


        <form method='POST' action={ServerUrl+'/printout/hostel_room_summary.php?token='+userToken} target='_blank'>
                <input type='hidden' name='payment' value ={JSON.stringify(rooms, null, 2)} />

                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>

        </div></div>

 </div>
        </div>:''}



                           



   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(RoomAvailability) 