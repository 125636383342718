import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import PageHeader from '../component/pageheader'
import { Alerts } from '../component/notify'
import {ServerUrl, Token, config, allowDelete, allowEdit, menuLink} from '../component/include'
import axios from 'axios'
import {Line} from 'react-chartjs-2'
import { convertDate, getMonth } from '../component/globalFunction'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'

const Chart =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
   
const [student, setStudent]=useState([])
const [statistics, setStatistics] = useState({
  vehicle:0,
  route:0,
  destination:0,
  student:0
});

var date = new Date()
    var first = new Date(date.getFullYear(), date.getMonth(), 1, 23, 59, 59)
    var last = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    const fetchStudent =()=>{
      
        const fd = new FormData();
        var sql = "Select dateCreate as label, count(*) as data from tbl_transport_student where dateCreate  >=  '"+convertDate(first)+"' and dateCreate <= '"+convertDate(last)+"'   GROUP BY dateCreate order by label "
     
            let url = ServerUrl+'/fetchBySql_controller'
            fd.append("jwt", Token)
            fd.append("sql", sql)
            axios.post(url, fd, config).then(result =>{
                
              setStudent(result.data)
                
            }) 
    } 

   
    const dataStudent = {
      labels: student.map((data)=>data.label),
      datasets: [
        {
          label: 'Total ',
          fill: false,
          lineTension: 0.5,
          backgroundColor: 'rgba(75,192,192,1)',
          borderColor: 'rgba(0,0,0,1)',
          borderWidth: 2,
          data: student.map((data)=>data.data)
        }
      ]
    }; 

         

        
          const fetchStatistics =  ()=>{
      
    

            let sqls = "Select  (select count(*) from tbl_vehicle ) as vehicle, (select count(*) from tbl_route )  as route, (select count(*) from tbl_vehicle_destination )  as destination,  (Select count(*) from tbl_transport_student )  as student  "
            
             const fds = new FormData();
             fds.append("sql", sqls);
             fds.append('jwt', Token)
             let urls = ServerUrl+'/fetchBySql_controller';
             axios.post(urls, fds, config).then(result=>{ 
                
                setStatistics({
                  vehicle:result.data[0].vehicle,
                  route:result.data[0].route,
                  destination:result.data[0].destination,
                  student :result.data[0].student
                })
             })
         } 
         



      
   useEffect(()=>{
    setIsAuthenticated(menuLink.includes(location.pathname))
     fetchStudent()
     fetchStatistics()
},[]);

        return (    <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title='Chart'>
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-dashboard"></i> </a>
                            </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">






<div className="row">

<div className="col-xl-3 col-md-6">
                <div className="card bg-c-blue text-white">
                    <div className="card-block">
                        <div className="row align-items-center">
                            <div className="col">
                            <p className="m-b-5">Total Vehicle</p>
                        <h4 className="m-b-0">{statistics.vehicle}</h4>
                            </div>
                            <div className="col col-auto text-right">
                                <i className="fa fa-bus f-50 text-c-blue"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


<div className="col-xl-3 col-md-6">
        <div className="card bg-c-green text-white">
            <div className="card-block">
                <div className="row align-items-center">
                    <div className="col">
                        <p className="m-b-5">Total Route</p>
                        <h4 className="m-b-0">{statistics.route}</h4>
                    </div>
                    <div className="col col-auto text-right">
                        <i className=" fa fa-map f-50 text-c-green"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

<div className="col-xl-3 col-md-6">
            <div className="card bg-c-pink text-white">
                <div className="card-block">
                    <div className="row align-items-center">
                        <div className="col">
                            <p className="m-b-5">Destination</p>
                            <h4 className="m-b-0">{statistics.destination}</h4>
                        </div>
                        <div className="col col-auto text-right">
                            <i className="fa fa-users f-50 text-c-pink"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <div className="col-xl-3 col-md-6">
    <div className="card bg-c-yellow text-white">
        <div className="card-block">
            <div className="row align-items-center">
                <div className="col">
                    <p className="m-b-5">Total Assign</p>
                    <h4 className="m-b-0">{statistics.student}</h4>
                </div>
                <div className="col col-auto text-right">
                    <i className="fa fa-graduation-cap f-50 text-c-yellow"></i>
                </div>
            </div>
        </div>
    </div>
</div>



      </div>

    <br/>

    <div className="row">
      <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
		   <div className="card-header"><strong>
     Student Assigned ({getMonth(new Date().getFullYear())}) </strong>
				</div>
             <div className="card-body">

              <Line
          data={dataStudent}
          options={{
            legend:{
              display:false,
              position:'right'
            }
          }}
          
        /> 

             </div>
          </div>
        </div></div> 


   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Chart) 