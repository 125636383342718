import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, Currency,  staffCode, config, allowDelete, allowEdit, menuLink} from '../component/include'
import Select from 'react-select'
import {longDate, FormatNumber} from '../component/globalFunction'

import { useDispatch, useSelector } from 'react-redux'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { userToken } from '../component/authentication'

const RecordExpenses =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    const [allExpenses, setAllExpenses]= useState([])
    const [account, setAccount] = useState([])
    const [allExpensesHead, setAllExpensesHead]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
   
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [expenses, setExpenses] = useState({
        
        expensesHead:[],
        account:[],
        amount:0,
        expensesDate:'',
        remarks:'',
        staffCode:staffCode
    })

    const fetchAccount=()=>{
      var sql =  'select  a.accountName, a.code,   (coalesce(a.openingBalance,0) - coalesce((select sum(e.amount) from tbl_expenses e where e.account = a.code  group by e.account),0) + coalesce((select sum(i.amount) from tbl_income i where i.account = a.code  group by i.account),0) -  coalesce((select sum(s.totalAmount) from tbl_salary_payment  s where s.paymentAccount = a.code group by s.paymentAccount),0)) as openingBalance from tbl_account a'
          
          setNotice({...notice, isLoading: true}) 
          const fd = new FormData();
          fd.append('jwt', Token);
          fd.append("sql", sql);
          let url = ServerUrl+'/fetchBySql_controller'
          axios.post(url, fd, config).then(result=> setAccount(result.data))
          .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
          setNotice({...notice, isDataFetching: false})
  
          })  
      }
   
    const fetchExpensesHead =()=>{
      const fd = new FormData();
          fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_expenses_head'
        axios.post(url, fd, config).then(result=>setAllExpensesHead(result.data))
    }


    

    const handleChange = event =>{
		const {name, value} = event.target
       setExpenses({...expenses, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const handleSelect = (option, action)=>{
        setExpenses({...expenses, [action.name]: option});
        setErrors({...errors, [action.name]:''})
         }

       

        


        const handleDelete = (code)=>{  
            setNotice({...notice, 
               isLoading: true}) 
               let close =   document.getElementById('btnWarningDialog-'+code)
                  close.click();
                  const fd = new FormData();
                  fd.append('jwt', Token);
               fd.append('ID', 'code')
                fd.append('data', code)
        
                let url = ServerUrl+'/delete_controller/tbl_expenses'
               axios.post(url, fd, config)
               .then(response =>{
                if(response.data.type ==='success'){
                   Alerts('Saved!', 'success', response.data.message)
                       } else{
                   Alerts('Error!', 'danger', JSON.stringify(response.data))
                       }   
               })
               .catch((error)=>{
                 Alerts('Error!', 'danger', error.message)
               }).finally(()=>{
                fetchAllExpenses()
                fetchAccount()
                   setNotice({...notice, 
                       isLoading: false
                   })
               }) 
        }




   
    const tableHeader = [
        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div>
            
        <a href="#!" data-toggle="modal"   data-target={'#warningdialog-'+row.code} className='btn btn-danger'    title="Delete"><i className="icofont icofont-delete-alt"></i></a>

<WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code)} mID={row.code} /> 
        </div>, editable: false, hidden:allowDelete==='No'?true:false},
        
        {dataField: 'headName', text: 'Type', editable:false},
        {dataField: 'accountName', text: 'Account',  editable:false},       
        {dataField: 'amount', text: 'Amount', formatter:(cell)=>Currency+FormatNumber(cell), validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Amount is required'
              };
            }
            return true;
          } },
        {dataField: 'expensesDate', text: 'Date', formatter:(cell)=>longDate(cell), editor: {
            type: Type.DATE
          } },
          {dataField: 'remarks', text: 'Remarks', formatter:(cell)=><span style={{display:'block',  width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>}, 
     ];

    

     const ExpensesTable=(props)=>{

        const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
          }) => ( 
          <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
              {
                columns
                  .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                  .map(column => (
                    
                    <button
                      type="button"
                      key={ column.dataField }
                      className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                      data-toggle="button"
                      aria-pressed={ column.toggle ? 'true' : 'false' }
                      onClick={ () => onColumnToggle(column.dataField) }
                    >
                      { column.text }
                    </button>
                  ))
              }
            </div>
            
          );
  
        const handleUpdate=(column, newValue, ID)=>{       
          const fd = new FormData();
          fd.append('jwt', Token);
             fd.append('columnField', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
           
            let url = ServerUrl+'/updateById_controller/tbl_expenses'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>console.log(error)) 
           
         } 
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                 <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'250px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>

      <div className="dt-buttons btn-group pull-right"> 

    <div className="btn-group dropdown-split-primary ">
        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
      

            <form method='POST' action={ServerUrl+'/excel_controller.php?api=allExpenses'} target='_blank'>
                <input type='hidden' name='jwt' value ={Token} />
        <button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>
        
        </form>

        <form method='POST' action={ServerUrl+'/printout/record_expenses.php?token='+userToken} target='_blank'>

                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
        
        </form>

        </div>
    </div> 
    </div> 
      
                                 </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    



        const fetchAllExpenses =()=>{
            setNotice({...notice, 
                isDataFetching: true
            });
            const fd = new FormData();
          fd.append('jwt', Token);
            let sql = "Select e.ID, e.code, e.account, e.amount, e.expensesDate, e.remarks, h.headName, a.accountName from tbl_expenses e, tbl_expenses_head h, tbl_account a where e.expensesCode =h.code and a.code =e.account order by e.ID DESC"
     
            fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=> setAllExpenses(result.data))
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
        setNotice({...notice, isDataFetching: false})

        })  
        }


     const handleSubmit = event =>{
        event.preventDefault();
    setErrors(ValidateInput(expenses));
        setIsSubmitting(true);
    } 
    
const ValidateInput =(values)=>{
	let errors = {};   
    
    if(!values.expensesDate){
        errors.expensesDate ='Please select expenses date';
    }
    if(Number(values.amount)<1){
        errors.amount ='Please enter expenses amount';
    }
    
    if(values.expensesHead.length===0){
        errors.expensesHead ='Please select expenses Head';
    }
    if(values.account.length===0){
        errors.account ='Please select account type';
    }
    return errors;
	}
    
    
function submit(){  
      
       const fd = new FormData(); 
       
        fd.append('expensesCode', expenses.expensesHead.value);
        fd.append('account', expenses.account.value);
        fd.append('amount', expenses.amount);
        fd.append('expensesDate', expenses.expensesDate);
        fd.append('remarks', expenses.remarks);
        fd.append('jwt', Token);
        fd.append('staffCode', expenses.staffCode);
    setNotice({...notice,  isLoading: true}) 

       let url = ServerUrl+'/save_controller/tbl_expenses'
	  axios.post(url, fd, config)
	  .then(response =>{
        if(response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
                } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
         fetchAllExpenses()
         fetchAccount()
            setNotice({...notice, isLoading: false })
      setIsSubmitting(false); 
      setExpenses({
        expensesHead:[],
        account:[],
        amount:0,
        expensesDate:'',
        remarks:'',
        staffCode:staffCode
    })
})
   
}


useEffect(()=>{
  setIsAuthenticated(menuLink.includes(location.pathname))
    fetchAccount();
    fetchExpensesHead();
    fetchAllExpenses();
},[]);

useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])
    
   
        
        
        return (  
          <>
         <div  className="main-body">
         {isAuthenticated?   <div className="page-wrapper">
                       {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
               
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Record Expenses">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Finance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Record Expenses</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
						
                               
                               <div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Expenses</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<fieldset><div className="row">
									
										<section className="col-md-6">
                                        <div className="form-group">
             <label >Expenses Type<span style={{color:'red'}}>*</span> </label>
						<Select options={
                            allExpensesHead&&allExpensesHead.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.headName }
                             })} 
onChange={handleSelect} className={errors.expensesHead ? 'form-control form-control-danger' : ''} name="expensesHead" value={expenses.expensesHead}  /> <span style={{color:'red'}}>{errors.expensesHead && errors.expensesHead}</span>
									
        		
											</div>
                      </section>  
                      <section className="col-md-6">        
                           <div className="form-group">
						<label >Amount<span style={{color:'red'}}>*</span></label>
				<input type="number" min='0' name="amount" value={expenses.amount} onChange={handleChange} className={errors.amount  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.amount && errors.amount}</span>	
				</div>
        </section>  
                      <section className="col-md-6">  
                                        <div className="form-group">
						<label >Account <span style={{color:'red'}}>*</span> </label>
						<Select options={
                             account&&account.map((list, idx)=> {
                                return {key:idx, value: list.code, label: list.accountName, balance:list.openingBalance}
                             })} 
                             getOptionLabel={option =>` ${option.label} Balance: ${Currency+FormatNumber(option.balance)}`}
onChange={handleSelect} className={errors.account? 'form-control form-control-danger' : ''} name="account" value={expenses.account}  /> <span style={{color:'red'}}>{errors.account && errors.account}</span>
										
											</div>
                      </section>  
                      <section className="col-md-6">  

										

                                            <div className="form-group">
						<label >Date of expenses</label>
						<input type="date" name="expensesDate" value={expenses.expensesDate} onChange={handleChange} className={errors.expensesDate  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.expensesDate && errors.expensesDate}</span>	
			</div>
      </section>  
                      <section className="col-md-12">  
                                            <div className="form-group">
						<label >Remark</label>	
                <textarea  type="text" className="form-control " value={expenses.remarks} name="remarks" rows='3'  onChange={handleChange}  >{expenses.remarks}</textarea>
			</div>
										</section>
										</div>	</fieldset>

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-inverse ">Save</button>
	
								</footer>				
							</div>
							</div>
							</div>
		
	
	

	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Listing All expenses</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


        <div className="card z-depth-0">
            <div className="card-block">
        <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
   <ExpensesTable data={allExpenses}   columns={tableHeader}  />} 
</div>
	</div>
    </div></div> </div>
</div>

</div>
	
   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(RecordExpenses) 