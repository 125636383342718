import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, allowDelete, allowEdit, menuLink} from '../component/include'
import Select from 'react-select'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { userToken } from '../component/authentication'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useDispatch, useSelector } from 'react-redux'

const AssignStudent =()=> {
    const [errors, setErrors] = useState({});

    const [allAssign, setAllAssign]= useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [allRoute, setAllRoute]= useState([])
    const student = useSelector(state => state.rootReducer.students);
    const [destination, setDestination] = useState({
        code:Math.random().toString(36).substr(2,9),
        destinationCode:'',
        routeCode:'',
        student:'',
        remarks:''
    })


    const [allDestination, setAllDestination]= useState([])
   

    const fetchAllDestination =(code)=>{

        setNotice({...notice,   isLoading: true})
        let sql = "Select d.ID,  d.code, d.amount, d.remarks, d.pickDrop, r.startPoint, r.endPoint, v.vehicleName from tbl_vehicle_destination d, tbl_route r, tbl_vehicle v where v.code = r.vehicle and d.routeCode = r.code and d.routeCode ='"+code+"'"
    
        const fd = new FormData();
        fd.append('jwt', Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>setAllDestination(result.data))
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice,   isLoading: false})
        })

    }


const fetchAllRoute =()=>{

            setNotice({...notice,   isDataFetching: true})
            let sql = "Select r.ID, r.code, r.coordinator, r.status, r.startPoint, r.endPoint, v.vehicleName, v.vehicleNumber from tbl_route r, tbl_vehicle v where v.code = r.vehicle  "
        
            const fd = new FormData();
            fd.append('jwt', Token);
            fd.append("sql", sql);
            let url = ServerUrl+'/fetchBySql_controller'
            axios.post(url, fd, config).then(result=>setAllRoute(result.data))
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice,   isDataFetching: false})
            })

        }



        const fetchAllAssign =()=>{

            setNotice({...notice,   isDataFetching: true})
            let sql = "Select a.ID, a.code, a.remarks, a.dateCreate, r.startPoint, r.endPoint, d.pickDrop, s.studentName, s.admissionNumber from tbl_transport_student a, tbl_route r, tbl_students s, tbl_vehicle_destination d where r.code = a.route and d.code = a.destination and s.code = a.student order by a.route "
        
            const fd = new FormData();
            fd.append('jwt', Token);
            fd.append("sql", sql);
            let url = ServerUrl+'/fetchBySql_controller'
            axios.post(url, fd, config).then(result=>setAllAssign(result.data))
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice,   isDataFetching: false})
            })

        }



    const handleChange = event =>{
		const {name, value} = event.target
        setDestination({...destination, [name] : value });
       setErrors({...errors, [name]:''});
    }
    const handleSelectRoute = (option, action)=>{
        fetchAllDestination(option.value)
        setDestination({...destination, [action.name]: option, destinationCode:''});
        setErrors({...errors, [action.name]:''})
         }

    const handleSelect = (option, action)=>{
        setDestination({...destination, [action.name]: option});
        setErrors({...errors, [action.name]:''})
         }
   




        const handleDelete = (ID)=>{  
			setNotice({...notice,  isLoading: true}) 
			   let close =   document.getElementById('btnWarningDialog-'+ID)
				  close.click();
				  const fd = new FormData();
				  fd.append('jwt', Token);
			   fd.append('ID', 'ID')
				fd.append('data', ID)
		
				let url = ServerUrl+'/delete_controller/tbl_transport_student'
			   axios.post(url, fd, config)
			   .then(response =>{
				if(response.data.type ==='success'){
				   Alerts('Saved!', 'success', response.data.message)
					   } else{
				   Alerts('Error!', 'danger', JSON.stringify(response.data))
					   }   
			   })
			   .catch((error)=>{
				 Alerts('Error!', 'danger', error.message)
			   }).finally(()=>{
				   setNotice({...notice, 
					   isLoading: false
				   })
			   }) 
		}

  
    const tableHeader = [
        {dataField: 'ID', text: 'Action', formatter: (cell)=><div> <a href="#!" data-toggle="modal" className="btn btn-danger btn-sm"   data-target={`#warningdialog-${cell}`}     title="Delete"><i className="icofont icofont-delete-alt"></i></a>

<WarningModal  handleAction={()=>handleDelete(cell)} mID={cell} message='Once deleted, you will no be able to recover this record. Are you realy sure?' /> </div>},

{dataField: 'startPoint', editable:false, text: 'Route', formatter:(cell, row)=><div>{row.startPoint+" TO "+row.endPoint}</div>},

{dataField: 'pickDrop', editable:false, text: 'Location', formatter:(cell, row)=><div>{row.pickDrop}</div>},

{dataField: 'studentName', editable:false, text: 'Student', formatter:(cell, row)=><div>{row.admissionNumber+"  "+row.studentName}</div>},

          {dataField: 'remarks', text: 'Remark'}
     ];
	

	 const TableRoute=(props)=>{
        const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
          }) => ( 
          <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
              {
                columns
                  .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                  .map(column => (
                    
                    <button
                      type="button"
                      key={ column.dataField }
                      className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                      data-toggle="button"
                      aria-pressed={ column.toggle ? 'true' : 'false' }
                      onClick={ () => onColumnToggle(column.dataField) }
                    >
                      { column.text }
                    </button>
                  ))
              }
            </div>
            
          );
	   
const handleUpdate=(column, newValue, ID)=>{       
    const fd = new FormData();
            fd.append('jwt', Token);
        fd.append('newValue', newValue);
        fd.append('column', column.dataField);
        fd.append('ID', ID);
     
       let url = ServerUrl+'/updateCustom_controller/tbl_transport_student'
      axios.post(url, fd, config)
      //.then(result => console.log(result.data))
      .then()
      .catch((error)=>Alerts('Error!', 'danger', error.message)) 
    } 
	const { SearchBar } = Search;

	const customTotal = (from, to, size) => (
	<span >&nbsp;Showing { from } to { to } of { size } items</span>
	);
	const options = {
	showTotal: true,
	paginationTotalRenderer: customTotal,
	sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
	{ text: 'All', value: props.data.length }]
	
	};
	return  <ToolkitProvider search columnToggle 
				keyField='ID' data={ props.data } columns={ props.columns } >
					
					{
						props => (
							<div >
			  <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                
                                 <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'50px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>


      <div className="dt-buttons btn-group pull-right"> 
    
        
    
   {/*  <div className="btn-group dropdown-split-primary ">
        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
    
    
                <form method='POST' action={ServerUrl+'/excel_controller.php?api=student_transport_list'} target='_blank'>
                    <input type='hidden' name='jwt' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>
            
            </form>
    
            <form method='POST' action={ServerUrl+'/printout/student_transport_list.php?token='+userToken} target='_blank'>
    
                    <input type='hidden' name='jwtToken' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
            
            </form>
        </div>
    </div>  */}

    
    </div> 
        </div>

	<BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                   mode: 'dbclick',
                                   blurToSave: true,
                                   autoSelectText: true,
                         beforeSaveCell: (oldValue, newValue, row, column) => {
                           if(oldValue !==newValue){
                           handleUpdate(column, newValue, row.ID);
                           
                          return true;
                         }
                         
                       }
                                 }) }
                             />
			</div>
			)
					}
</ToolkitProvider>
}


         

  
     const handleSubmit = event =>{
        event.preventDefault();
         
    setErrors(ValidateInput(destination));
        setIsSubmitting(true);
    } 

    
const ValidateInput =(values)=>{
	let errors = {};   
    
    if(values.routeCode.length===0){
        errors.routeCode ='Please select transport route';
    }
    if(values.destinationCode.length===0){
        errors.destinationCode ='Please select pick up and drop location';
    }

    if(!Array.isArray(values.student)){
        errors.student ='Please select at least one student';
    }

    return errors;
	}

function submit(){  
      
    setNotice({...notice,  isLoading: true}) 
       const fd = new FormData(); 
    
      var value = [];
        var data = destination.student;
          for (var i = 0, l = data.length; i < l; i++) {
               value.push({code:data[i].value});
               }

      fd.append('student', JSON.stringify(value, null, 2));
      fd.append('destination', destination.destinationCode.value);
      fd.append('route', destination.routeCode.value);
      fd.append('remarks', destination.remarks);
    fd.append('jwt', Token);
    fd.append('code', Math.random().toString(36).substr(2,9));
    
       let url = ServerUrl+'/save_controller/tbl_transport_student';
	  axios.post(url, fd, config)
	  .then(response =>{


window.scrollTo(0,0)
        if (response.data.type ==='success'){
            setErrors({...errors, successMessage: response.data.message})
            
        } else{
            setErrors({...errors, errorMessage: JSON.stringify(response.data)})
            }        
			  
	  })
	  .catch((error)=>{
        setErrors({...errors, errorMessage: error.message})
    })
    .finally(()=>{
        fetchAllAssign()
        setNotice({...notice,  isLoading: false})
      setIsSubmitting(false); 
      setDestination({ 
        code:Math.random().toString(36).substr(2,9),
        destinationCode:'',
        routeCode:'',
        student:'',
        remarks:''
    }); 
   }) 
}


useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])

useEffect(()=>{
    setIsAuthenticated(menuLink.includes(location.pathname))
    fetchAllRoute();
    fetchAllAssign()

},[]);
    
        return (    <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Assign Student To Route">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Transport</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Destination</a> </li>
                    </PageHeader>
{/* The Page Body start here */}




<div className="page-body">


{errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}


    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Assign Student To Destination</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
           

	   <div className="row setup-content" id="Route">
		    
                                           
                                           <div className="col-sm-12">
                <div className="card z-depth-0">
                    <div className="card-header">
                        <h5><i className="fa fa-edit" ></i> Assign Student</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
                    <div className="card-block">
                    <fieldset>
                        <div className="row">



                                              <section className="col-md-6">
                    
                    <div className="form-group">
    <label >Select Route<span style={{color:'red'}}>*</span></label>

    <div className="form-group">
      <Select  options={
                            allRoute&&allRoute.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.vehicleName, startPoint:list.startPoint, endPoint:list.endPoint }
                             })} 
                        getOptionLabel={option =>`(${option.label}) ${option.startPoint+" to "+option.endPoint} `}
onChange={handleSelectRoute} className={errors.routeCode ? 'form-control form-control-danger' : ''} name="routeCode" value={destination.routeCode}  /> <span style={{color:'red'}}>{errors.routeCode && errors.routeCode}</span>

          </div>
</div>
</section>

                    <section className="col-md-6">
                    
                    <div className="form-group">
    <label >Select Pick Up & Drop Location <span style={{color:'red'}}>*</span></label>

    <div className="form-group">
      <Select  options={
                            allDestination&&allDestination.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.pickDrop  }
                             })} 
        onChange={handleSelect} className={errors.destinationCode ? 'form-control form-control-danger' : ''} name="destinationCode" value={destination.destinationCode}  /> <span style={{color:'red'}}>{errors.destinationCode && errors.destinationCode}</span>

          </div>
</div>
</section>


<section className="col-md-6">
                    
                    <div className="form-group">
    <label >Select Student<span style={{color:'red'}}>*</span></label>

    <div className="form-group">
      <Select  isMulti options={
                            student&&student.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.studentName, number:list.admissionNumber  }
                             })} 
                        getOptionLabel={option =>`(${option.number}) ${option.label}`}
onChange={handleSelect} className={errors.student ? 'form-control form-control-danger' : ''} name="student" value={destination.student}  /> <span style={{color:'red'}}>{errors.student && errors.student}</span>

          </div>
</div>
</section>


            <section className="col-md-6">
                                    
            
        <div className="form-group">
                    <label >Remark</label>
            <input type="text" name="remarks" value={destination.remarks} onChange={handleChange} className='form-control' />
        </div>
              </section>
        </div>

	</fieldset>
            
                <footer className="pull-right">
                
          
                <button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary "> <i className="fa fa-graduation-cap"></i>Assign Student</button>
                
                                            </footer>				
                                        </div>
                                        </div>
                                        
                                        </div>
                                        </div>


                                        <div className="row">
            
                <div className="col-md-12">
                <div className="card z-depth-0">
                    <div className="card-header">
                        <h5> <i className="fa fa-th" ></i> Listing Transport Student</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
                    <div className="card-block">
            
            
                      <div className="card z-depth-0">
                        <div className="card-block">
                    <div className="col-md-12 table-responsive">
                    {notice.isDataFetching ? <TableLoader />:
               <TableRoute data={allAssign} columns={tableHeader}  />} 
            </div>
                </div>
                </div></div></div>
            </div>
            </div>  

                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>
               
   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(AssignStudent) 