import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config, allowDelete, allowEdit, menuLink, API_URL} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, longDate, ordinalSurfix, getResultHead, getRank} from '../component/globalFunction'

import { useLocation } from 'react-router-dom'
import Error403 from '../settings/error403'
import { useDispatch, useSelector } from 'react-redux'

 const CommentEntry =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 

    const [csvFile, setCsvFile]=useState({
        studentCsv:'',
        fileName:'Please select .csv files (allowed file size 800KB)'
    })


    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);   
    
    const [comments, setComments]=useState([])
    const [result, setResult] = useState({
        studentClass:[],
        examtype:[],
        classAssessment:[],
        orderBy:{value:'Full', label:'End of Term'}
    })

    const steps = [{title: 'Comment Setup'}, {title: 'Comment Entry'}]
    const [classTeacher, setClassTeacher] = useState([]);
    const [examtype, setExamtype]=useState([])
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const [studentList, setStudentList]=useState([])
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [settings, setSettings]=useState([])
const [activeStep, setActiveStep] = useState(1);

const staff  = useSelector(state => state.rootReducer.staff);
const handleOnClickStepper = (step) => {
        setActiveStep(step); 
    }

    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
    }, [activeStep])

  
   

const fetchExamType =()=>{
    let url = API_URL+'/exam/result/examtype';
    axios.get(url,  config).then(result=>{

        let ans = result.data.examType
        .filter(item=>item.publish ==='No')
        setExamtype(ans)

        let settings = result.data.grademark;
        var commen = settings[0].comments
        setComments(JSON.parse(commen))

    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }



const fetchStudentList =()=>{
    let error = {}; 
    if(result.studentClass.length===0){
        error.studentClass ='Please select class';
    }   
    if(result.examtype.length===0){
        error.examtype ='Please select exam type';
    } 
    
    setErrors(error)

    if(Object.keys(error).length === 0){

setNotice({...notice, isLoading: true}) 

const fd = new FormData();
fd.append("orderBy", result.orderBy.value);
fd.append("sectionID", result.studentClass.value);
fd.append("examCode", result.examtype.value);

let url = API_URL+'/exam/result/comment';
axios.post(url, fd, config).then(response=>{
    
    if(response.data.data.length!==0 && Array.isArray(response.data.data)){
       
setStudentList(response.data.data)
setActiveStep(2)
    }
    else{
        setStudentList([]) 
    }

})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
setNotice({...notice, isLoading: false})

})
    }
}

  
const handleSelectType =(option, action)=>{     
    setResult({...result, [action.name]:option, studentClass:[], examtype:[]});
    setErrors({...errors, [action.name]:''})
    setStudentList([])
}

const handleSelect = (option, action)=>{
    setResult({...result, [action.name]: option });
   setErrors({...errors, [action.name]:''})
   
   if(action.name==='studentClass'){
   // fetchClassAssessment(result.examtype.value, option.value)
    fetchClassTeaher(option.classID, option.value)
   }else if(action.name==='examtype'){
    //fetchClassAssessment(option.value, result.studentClass.value)
   }
   setStudentList([])
  
}



const handleChangeComment =(event, index)=>{
    const student =JSON.parse(JSON.stringify(studentList))
    const {name, value} = event.target
    student[index][name] = value;
    setStudentList(student); 
       } 


       const fetchSettings =()=>{
        const fd = new FormData();
    fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_result_slip/settings/ID/1';
        axios.post(url, fd, config).then(result=>{
            setSettings(result.data)
          
        })
    }
 
const handleSubmit = event =>{
    event.preventDefault();    
    let error = {}; 
    if(result.studentClass.length===0){
        error.studentClass ='Please select class';
    }   
    if(result.examtype.length===0){
        error.examtype ='Please select exam type';
    } 
    if(studentList.length===0){
        error.studentClass ='There are no student in the selected class';
    }
    setErrors(error)

    if(Object.keys(error).length === 0){

submit()
    }
}





    function submit(){
    
setNotice({...notice,  isLoading: true})    


var headName = getResultHead(result.studentClass.classtype)==='Secondary'?
        String(settings.map((st)=>st.headofSchool)): 
        String(settings.map((st)=>st.headofPrimary))
        
var teacher = classTeacher;
var list = [];
for (var i = 0; i< teacher.length;  i++) {
    list.push(teacher[i].code);
        }

    const fd = new FormData(); 
   fd.append('commentList', JSON.stringify(studentList, null, 2));
   fd.append('headofSchool', String(settings.map((st)=>st.headofSchool)));
   fd.append('headName', headName);
   fd.append('teacherName', JSON.stringify(list, null, 2));
   fd.append('resultHead', getResultHead(result.studentClass.classtype));

    let url = API_URL+'/exam/result/update_comment';
          
        axios.post(url, fd, config)
        .then(response =>{
            if(response.data.status ===200){
                Alerts('Updated!', 'default', response.data.message)
                    } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
                    }   
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
              }).finally(()=>{
                
                  setNotice({...notice,  isLoading: false })
                  
                setIsSubmitting(false);
                  setActiveStep(1)
              })   
  }
    




    const fetchClassTeaher =(classID, sectionID)=>{
        var newStaff = staff&&staff.filter((item=>item.classID===classID&&item.sectionID===sectionID))
        setClassTeacher(newStaff)
    }

    useEffect(()=>{
      
    setIsAuthenticated(menuLink.includes(location.pathname))   
       
        fetchExamType()
        fetchSettings();
    },[]);
  

        return ( 
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 

        <PageHeader title="Comment Entry">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Comment Entry </a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Comment Entry</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-primary btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '1px'}}>
{activeStep === 1 ? 
	   <div className="row setup-content" id="examType">
		  
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

      
   <div className="card-block">
      
  <div className="row">
  <section className="col-md-4">
           <div className="form-group">
         <label> Report Type</label>
         <Select  options={[
             {value:'Half', label:'Half Term'},
                    { value: 'Full', label: 'End of Term'}
                ]} 
onChange={handleSelectType}  name="orderBy" value={result.orderBy}  /> 

            </div>
     
     </section>

     <section className="col-md-4">
           <div className="form-group">
         <label> Exam Type <span style={{color:'red'}}>*</span></label>
         <Select  options={examtype&&examtype.map((list, idx)=> {
                               return {key:idx, value: list.examCode, label: list.exam }
                             })
                        } 
                       
onChange={handleSelect} className={errors.examtype ? 'form-control form-control-danger' : ''} name="examtype" value={result.examtype}  /> <span style={{color:'red'}}>{errors.examtype && errors.examtype}</span>

            </div>
     
     </section>
     
     <section className="col-md-4">
           <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label>
        <Select  options={
                           schoolClass.map((list, idx)=> {
                            return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                            }) }
                          })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>

            </div>
     
     </section>
     </div>

     <div  className="row"> 
     
     <section className="col-md-12"> 
     <footer className="pull-right">
	
	<button type="button" id="submit" onClick={fetchStudentList} className="btn btn-primary "><i className="fa fa-graduation-cap"></i> Display Student</button>
    </footer>						
     </section>

     </div> 
    </div>
                               
       </div>	     
       
       	</div>
       
       </div>	
			
       </div>
    
</div> :
<div className="row setup-content" id="extras">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">

   <div className="row">
       <div className='col-md-12 '>
           <div className='pull-right'>
               

           </div></div>
   <div className="table-responsive">
       
        <table className="table table-bordered">
            <thead>
                <tr>
                <th>SN</th> 
                    <th>Student Details</th>
                    {getResultHead(result.studentClass.classtype)!=='Pre-Nursery'?<>  
                    <th>Score</th> 
                    <th>Average</th> 
                    <th>Rank</th> </>:''}
                    <th>Class Teacher</th> 
                    <th>{getResultHead(result.studentClass.classtype)==='Secondary'?'Principal':'Head Teacher'}</th>  
                    
                </tr>
            </thead>
            <tbody>{studentList.length!==0?studentList.map((st, idx)=> 
                <tr key={idx}>
<td>{idx+1}</td>
                    <td style={{maxWidth:'350px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.admissionNumber} - {st.studentName} {st.termination==='Yes'?<div><br/><span className="pcoded-badge label label-danger">Terminated on {longDate(st.termination_date)} {st.termination_remark}</span></div>:''} </td>

                    {getResultHead(result.studentClass.classtype)!=='Pre-Nursery'?<> 
                    <td>{parseFloat(st.obtained).toFixed(2)}</td>
                    <td>{st.average}</td>
                    
                    <td>{st.position}<sup>{getRank(st.position)}</sup></td>
                    </>:''}


                 {result.orderBy.value==='Full'?<>  <td style={{maxWidth:'350px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>
                        



                        <textarea name='teacherRemark' placeholder='Enter Comment' value={st.teacherRemark} className="form-control" onChange={(e)=>handleChangeComment(e, idx)} rows={3}>{st.teacherRemark}
    
                        </textarea>
    
                            <select name="teacherRemark" value={st.resultHead}  className="form-control" onChange={(e)=>handleChangeComment(e, idx)}   >
    
                            <option  value=''>Select Comment</option>
    
                            {comments&&comments.map((item,index)=>
                            <option key={index} value={item.comment}  >{item.comment}</option>)}
                            </select>
                        
                        </td>


                    <td style={{maxWidth:'350px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>
                        



                    <textarea name='headRemark' placeholder='Enter Comment'  value={st.headRemark} className="form-control" onChange={(e)=>handleChangeComment(e, idx)} rows={3}>{st.headRemark}

                    </textarea>

                        <select name="headRemark" value={st.remark}  className="form-control" onChange={(e)=>handleChangeComment(e, idx)}   >

                        <option  value=''>Select Comment</option>

                        {comments&&comments.map((item,index)=>
                        <option key={index} value={item.comment}  >{item.comment}</option>)}
                        </select>
                    
                    </td></>:



<>      <td style={{maxWidth:'350px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>
                        



                        <textarea name='catTeacherRemark' placeholder='Enter Comment' value={st.catTeacherRemark} className="form-control" onChange={(e)=>handleChangeComment(e, idx)} rows={3}>{st.catTeacherRemark}
    
                        </textarea>
    
                            <select name="catTeacherRemark" value={st.resultHead}  className="form-control" onChange={(e)=>handleChangeComment(e, idx)}   >
    
                            <option  value=''>Select Comment</option>
    
                            {comments&&comments.map((item,index)=>
                            <option key={index} value={item.comment}  >{item.comment}</option>)}
                            </select>
                        
                        </td>
                        

                    <td style={{maxWidth:'350px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>
                        



                    <textarea name='catHeadTeacherRemark' placeholder='Enter Comment'  value={st.catHeadTeacherRemark} className="form-control" onChange={(e)=>handleChangeComment(e, idx)} rows={3}>{st.catHeadTeacherRemark}

                    </textarea>

                        <select name="catHeadTeacherRemark" value={st.remark}  className="form-control" onChange={(e)=>handleChangeComment(e, idx)}   >

                        <option  value=''>Select Comment</option>

                        {comments&&comments.map((item,index)=>
                        <option key={index} value={item.comment}  >{item.comment}</option>)}
                        </select>
                    
                    </td></>}



                </tr>):[]}
            </tbody> 
        </table>
 </div>	 </div>   
<div style={{color:'red'}}>{errors.examtype && errors.examtype}</div>
<div style={{color:'red'}}>{errors.studentClass && errors.studentClass}</div>
       </div>			
       </div>       
       </div>

</div>}

<button onClick={activeStep === steps.length ? handleSubmit: handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Update Comment </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }

                </div>
                
                
                
                </div>
               
                </div>
                </div>
                </div> </div>


{/* 


                <div className="row  ">
								<div className="col-sm-6">
	<div className="card z-depth-0">
		<div className="card-block">
		<fieldset>
			<div className="row">
									
			<section className="col-md-12 ">
			<div className="well well-sm alert alert-success border-success background-success ">
					<h4 className="alert-heading ">Download Student File</h4>
					
<p><span >The first line in downloaded csv file should remain as it is. Please do not change the order of columns.</span><br/>
<span >The correct column order is (<b>
    Result Code, Admission Number, Student Name, Score, Average, Rank, Class Teacher Remark, Head Teacher Remark </b>)</span> <span >&amp; you must follow this.</span></p>
					<hr className="simple" /><div className="text-align-left">
						
                    <form method='POST' action={ServerUrl+'/csvSample_controller/comment'} target='_blank'>
        
                <input type='hidden' name='examCode' value ={result.examtype.value} />
                <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='className' value ={result.examtype.label +' '+ result.studentClass.label+' -comments'} />
                
                <input type='hidden' name='jwt' value ={Token} />
               
        <button type="submit" className="display-none"  id="exportExcel"> </button>
       
     <button type="button" onClick={DownloadStudentList} className="btn btn-sm btn-inverse"><i className="fa fa-download"></i> Download File</button>
        </form>
        
					</div>
				</div>					
				</section>
										</div>
										
				</fieldset>
				
							</div>
							</div>
							</div>
		
	
	

	<div className="col-sm-6">
	<div className="card z-depth-0">
	
		<div className="card-block">


		<div className="alert alert-success border-success">
						<fieldset className="well well-sm">
                        <h4 className="alert-heading ">Upload file with .csv extension</h4>
							<section>
                            <div className="form-group">
							
                    <input id="studentCsv"  onChange={handleFile('studentCsv')} className="form-control" name="studentCsv" type="file" accept=".csv" /> 
						<div className="note" style={{color:'red'}}>
			{csvFile.fileName}
		</div>	
										</div>          
                                        
							</section>
						</fieldset></div>
        <footer>
            <button type="button" id="submit" onClick={handleSubmitFile} className="btn  btn-primary pull-right"><i className="fa fa-save"></i> Upload Student Result</button>
        </footer>

	</div>

</div>

</div></div>
 */}

                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</>  );
        
}

export default React.memo(CommentEntry) 