import React, {useState, useEffect} from 'react'
import PageHeader from '../component/pageheader'
import {LoadEffect, TableLoader } from '../component/loader'
import {Alerts, WarningModal} from '../component/notify'
import {ServerUrl, Token, config, Currency,  ImagesUrl, allowDelete, allowEdit, menuLink} from '../component/include'
import axios from 'axios'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';
import { longDate, FormatNumber } from '../component/globalFunction'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'

import Error403 from '../settings/error403'
import { useSelector } from 'react-redux'
 const Assets =()=> {
    
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: true
    });
    let location =  useLocation()
    
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [isSubmittingRepair, setIsSubmittingRepair] = useState(false);   
const [isSubmitting, setIsSubmitting] = useState(false);
const staff = useSelector(state => state.rootReducer.staff);
    const [search, setSearch]= useState({
        fromDate:'',
        toDate:'',
        condition:'all',
        category:'all',
        assetName:'',
        department:'all',
        allocation:'all'
    })

    const [assign, setAssign]= useState({
        code:'',
       assetName:'',
        staff:'',
        staffName:'',
       dateAssign:''
    })
    const [repair, setRepair]= useState({
        code:'',
        reason:'',
        assetName:'',
        cost:'',
       dateRepair:''
    })

    const [status, setStatus] = useState({
        checkAll:false,
        checkOne:false
    });
    const [content, setContent] = useState([]);
    const [department, setDepartment]=useState([])
    const [errors, setErrors] = useState({});
    const [error, setError] = useState({});


    
const fetchDepartment =()=>{
	const fd = new FormData();
            fd.append('jwt', Token);
	let url = ServerUrl + '/fetchField/tbl_staff_settings/department/ID/1';
	axios.post(url, fd, config)
	.then(result =>setDepartment(result.data))
}

const getStaffName =(code)=>{
    const result = staff&&staff.filter(item=>item.code===code);
  const answer = result.length!==0?result[0].staffName:''
  return  answer
}


    const handleDelete =()=>{
          
        let dataId = []
        var newContent = JSON.parse(JSON.stringify(content));
        for (var i in newContent){
            if(newContent[i].status===true){
                dataId.push(newContent[i].code)
                
            }
        }
        let close =   document.getElementById('btnWarningDialog-delAll')
        if(dataId.length===0){
            Alerts('Error!', 'danger', 'Please select at least one asset to delete')
              
        }else{
            setNotice({...notice, isLoading: true}) 
        var data = JSON.stringify(dataId, null, 2)

            const fd = new FormData();
            fd.append('data', data)
            fd.append('jwt', Token)
        let url = ServerUrl+'/deleteMultiple_controller.php?tablename=tbl_assets';
              axios.post(url, fd, config)
              .then(response =>{
               if(response.data.type ==='success'){
                  Alerts('Default!', 'default', response.data.message)
                  
                      } else{
                        setErrors({...errors, errorMessage: JSON.stringify(response.data)})
                 // Alerts('Error!', 'danger', response.data)
                      }   
              })
              .catch((error)=>{
                setErrors({...errors, errorMessage: error.message})
              }).finally(()=>{
                  setNotice({...notice,  isLoading: false })
                  fetchContent()
              }) 
            }
            close.click();
    }

    const SearchTable =(event)=>{
        event.preventDefault();
        fetchContent();
     }
     

    const fetchContent =()=>{
        setNotice({...notice,   isDataFetching: true  });

        
        var sql ="select ID, code, assetName, category, assetType, barcode, serialNo, model, purchaseDate, purchaseAmount, warantyExpiryDate, currentLocation, department, lifeSpan, description, isMovable, isInsured, imageUrl, insuranceDate, usageHistory, remarks, assignTo, createBy, dateCreate from tbl_assets where ID <> '' " ;

        if(search.fromDate.length!==0 && search.toDate.length!==0 ){
            sql = sql + ' and purchaseDate between "'+search.fromDate+'" and "'+search.toDate+'"'
        }

        if(search.condition !== 'all'){
            sql = sql + " and assetType = '"+search.condition+"'"
        }
        if(search.allocation !== 'all'){

            if(search.allocation === 'Allocated'){
            sql = sql + " and assignTo <> '' "
            }else  if(search.allocation === 'Unallocated'){
                sql = sql + " and assignTo = '' "
            }

        }

        if(search.category !=='all'){
            sql = sql + '  and category = "'+search.category+'"'
        }


        if(search.assetName !==''){
            sql = sql + '  and assetName = "'+search.assetName+'"'
        }

        if(search.department !=='all'){
            sql = sql + '  and department = "'+search.department+'"'
        }

        sql = sql + '  order by ID DESC'

          
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{ 
            if(result.data.length!==0){
                var options = result.data;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {


    value.push({ID:options[i].ID, 
        code:options[i].code, 
        assetName:options[i].assetName,
        category:options[i].category,
        assetType:options[i].assetType,

        barcode:options[i].barcode,
        serialNo:options[i].serialNo,
        model:options[i].model,
        purchaseDate:options[i].purchaseDate,

        purchaseAmount:options[i].purchaseAmount,
        warantyExpiryDate:options[i].warantyExpiryDate,
        currentLocation:options[i].currentLocation,
        department:options[i].department,
        lifeSpan:options[i].lifeSpan,

        description:options[i].description,
        isMovable:options[i].isMovable,
        imageUrl:options[i].imageUrl,
        isInsured:options[i].isInsured,
        assignTo:options[i].assignTo,
        insuranceDate:options[i].insuranceDate,
        usageHistory:options[i].usageHistory,
        remarks:options[i].remarks,
        dateCreate:options[i].dateCreate,
        createBy:options[i].createBy,
         status:false});
    }
                setContent(value.sort((a, b) =>(a.ID > b.ID) ? 1 : -1))
            }else{
                setContent([])
            }
            
        })
        .catch((error)=>{
            setErrors({...errors, errorMessage: error.message})
            //Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, 
                isDataFetching: false
            });
        }) 

  }


  const handleAssetRepair = event =>{
	event.preventDefault();
	 
setError(ValidateRepair(repair));
	setIsSubmittingRepair(true);
} 


function submitRepair(){  
      

	let formData = repair; 
	const fd = new FormData();
            fd.append('jwt', Token);
	for(let k in formData){
        fd.append(k,formData[k]);
    }; 

    let open =   document.getElementById('closeRepairDialog');
    open.click()

 setNotice({...notice,  isLoading: true}) 

	let url = ServerUrl+'/save_controller/tbl_repair_asset'
   axios.post(url, fd, config)
   .then(response =>{

	window.scrollTo(0,0)
	if (response.data.type ==='success'){
		setErrors({...errors, successMessage: response.data.message})
		
	} else{
		setErrors({...errors, errorMessage: JSON.stringify(response.data)})
	
		}        
		  
  })
  .catch((error)=>{
	setErrors({...errors, errorMessage: error.message})
})
.finally(()=>{
    setRepair({
        code:'',
        reason:'',
        assetName:'',
        cost:'',
       dateRepair:''
    })
  setNotice({...notice, isLoading: false}) 
setIsSubmittingRepair(false)
})  

}


useEffect(()=>{
    if(Object.keys(error).length === 0 && isSubmittingRepair){
    submitRepair()
    }
	},[error])
	

const ValidateRepair =(values)=>{
let errors = {};   

if(!values.dateRepair){
	errors.dateRepair ='Please choose repair Date';
}

if(!values.cost || !Number(values.cost)){
	errors.cost ='Please repair cost';
}

if(!values.reason){
	errors.reason ='Please enter reason for repair';
}

return errors;
}



  const handleRepair =(row)=>{
    setRepair({...repair, code : row.code, assetName:row.assetName });
    let open =   document.getElementById('openRepairDialog');
    open.click()
}


     
     const handleRepairChange = (event)=>{
        let {name, value} = event.target;
        setRepair({...repair, [name] : value });
        setError({...error, [name]:''})
         }








const handleAssetAllocation = event =>{
	event.preventDefault();
	 
setErrors(ValidateInput(assign));
	setIsSubmitting(true);
} 


function submit(){  
      
		const newContent = content.filter(item=>item.code===assign.code)
		const newHistory = JSON.parse(newContent[0].usageHistory);

        let usage = {code:Math.random().toString(36).substr(2,9), staffName:assign.staffName, staffCode:assign.staff.value, asset:assign.code, dateAssign:assign.dateAssign, dateReturned:''}

		const hisortyList = newHistory.concat(usage)

	let formData = assign; 
	const fd = new FormData();
            fd.append('jwt', Token);
            fd.append('usageHistory', JSON.stringify(hisortyList));
            fd.append('assignTo', assign.staff.value);
	for(let k in formData){
        fd.append(k,formData[k]);
    }; 

    
    let open =   document.getElementById('closeAddDialog');
    open.click()

 setNotice({...notice,  isLoading: true}) 

	let url = ServerUrl+'/update_controller/tbl_assign_asset'
   axios.post(url, fd, config)
   .then(response =>{

	window.scrollTo(0,0)
	if (response.data.type ==='info'){
		setErrors({...errors, successMessage: response.data.message})
		
	} else{
		setErrors({...errors, errorMessage: JSON.stringify(response.data)})
	
		}        
		  
  })
  .catch((error)=>{
	setErrors({...errors, errorMessage: error.message})
})
.finally(()=>{
	fetchContent()
    setAssign({
        code:'',
        assetName:'',
        staff:'',
        staffName:'',
        dateAssign:''
    })
  setNotice({...notice, isLoading: false}) 
setIsSubmitting(false)
})  

}


useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
	},[errors])
	

const ValidateInput =(values)=>{
let errors = {};   

if(!values.dateAssign){
	errors.dateAssign ='Please choose assign Date';
}

if(values.staff.length===0){
	errors.staff ='Please select asset staff';
}

return errors;
}



  const handleAllocate =(row)=>{
    setAssign({...assign, code : row.code, assetName:row.assetName });
    let open =   document.getElementById('openAssetDialog');
    open.click()
}

  const handleSelect = (option, action)=>{
	setAssign({...assign, [action.name]: option, staffName:option.label});
	setErrors({...errors, [action.name]:''})
	 }



     const handleAssignChange = (event)=>{
        let {name, value} = event.target;
        setAssign({...assign, [name] : value });
        setErrors({...errors, [name]:''})
         }


  const handleChange =(event)=>{
    let {name, value} = event.target;
    setSearch({...search, [name] : value });
}

  const handleCheckOne =(e)=>{  
    
    let {name, value} = e.target

    const newContent = [...content]
    const otherContent = newContent.filter(item=>item.code!==name)
    const custom = newContent.filter(item=>item.code===name)
    custom[0].status = value==='true'?false:true
    const currentContent = custom.concat(otherContent)
  setStatus({...status, checkAll:false})
   setContent(currentContent.sort((a, b) =>(a.ID > b.ID) ? 1 : -1)) 
        
       }
    

  const handleCheckAll =()=>{
    var newContent = [...content]
    for (var i in newContent){
        newContent[i].status=!status.checkAll
    }
    setStatus({...status, checkAll:!status.checkAll})
    setContent(newContent.sort((a, b) =>(a.ID > b.ID) ? 1 : -1))
 }

 
  const generalCheck =()=><div className="checkbox-fade fade-in-success">
  <label>
<input type="checkbox" value="" name="general" checked={status.checkAll} onChange={handleCheckAll} />
      <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
  </label>
</div> 

 

const handleDeleteOne = (code)=>{  
    setNotice({...notice,  isLoading: true}) 
       let close =   document.getElementById('btnWarningDialog-'+code)
          close.click();
          const fd = new FormData();
          fd.append('jwt', Token); 
       fd.append('ID', 'code')
		fd.append('data', code)

        let url = ServerUrl+'/delete_controller/tbl_assets'
       axios.post(url, fd, config)
       .then(response =>{
        if(response.data.type ==='success'){
           Alerts('Saved!', 'success', response.data.message)
               } else{
           Alerts('Error!', 'danger', JSON.stringify(response.data))
               }   
       })
       .catch((error)=>{
         Alerts('Error!', 'danger', error.message)
       }).finally(()=>{
           fetchContent()
           setNotice({...notice, 
               isLoading: false
           })
       }) 
}

    const tableHeader = [
        {dataField: 'code',  text: generalCheck(), editable: false, formatter:(cell, row, index)=><div className="checkbox-fade fade-in-success">
        <label>
    <input type="checkbox" value={row.status} name={row.code}  checked={row.status} onChange={(e)=>handleCheckOne(e)} />
            <span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
        </label>
    </div>},

{dataField: 'imageUrl', text: 'Image', editable: false, formatter:(cell, row)=><img className="img-50" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/no.jpg"}} src={ImagesUrl +'/assets/'+cell} title={row.assetName}  alt={row.assetName} />},


{dataField: 'assetName', text: 'Name', formatter:(cell, row)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{row.assetName}</span>},

  {dataField: 'purchaseDate', text: 'Purchase Date',   editor: {
    type: Type.DATE
  }, formatter: (cell, row)=><span>{cell!=='0000-00-00'?longDate(cell):'-'}</span>},

  {dataField: 'purchaseAmount', text: 'Amount', formatter:(cell, row)=>Currency+FormatNumber(cell)},

  {dataField: 'assignTo', text: 'Assigned To', hidden:true, editable: false, formatter: (cell, row)=>cell !==''?<a href={"/staff/profile/"+cell} target="_blank" className="btn btn-sm btn-info">{getStaffName(cell)} <i class="fa fa-external-link" ></i></a>:''},  
  {dataField: 'barcode', text: 'Barcode'},
  {dataField: 'serialNo', text: 'Serial No', hidden:true,},
    {dataField: 'currentLocation', text: 'Location', hidden:true,},
    {dataField: 'isMovable', text: 'Is Movable', sort: true, editor: {
        type: Type.SELECT, 
        options:[
            { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }]
      }, formatter:(cell)=><span className={`badge 
    ${cell==='No'?'badge-danger':'badge-success'}`}>{cell}</span>},

    {dataField: 'isInsured', text: 'Is Insured', hidden:true, sort: true, formatter:(cell)=><span className={`badge 
    ${cell==='No'?'badge-danger':'badge-success'}`}>{cell}</span>,  editor: {
        type: Type.SELECT, 
        options:[
            { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' }]
      }},

    {dataField: 'insuranceDate', text: 'Insurance Date', hidden:true,  editor: {
        type: Type.DATE
      }, formatter: (cell, row)=><span>{cell!=='0000-00-00'?longDate(cell):'-'}</span>},

    {dataField: 'category', text: 'Category', sort: true,  editor: {
        type: Type.SELECT, 
        options:[{ value: 'Funitures & Fittings', label: 'Funitures & Fittings' },
        { value: 'Office Equipment', label: 'Office Equipment' },
        { value: 'Computers', label: 'Computers' },
        { value: 'Automobile', label: 'Automobile' },
        { value: 'Others', label: 'Others' }]
      }},

    {dataField: 'assetType', text: 'Condition', sort: true, editor: {
            type: Type.SELECT, 
            options:[
                { value: 'New', label: 'New' },
            { value: 'Old', label: 'Old' },
            { value: 'Used', label: 'Used' },
            { value: 'Missing', label: 'Missing' },
            { value: 'Out for Repair', label: 'Out for Repair' },
            { value: 'Lost / Stolen', label: 'Lost / Stolen' },
            { value: 'Broken - Not Repairable', label: 'Broken - Not Repairable' },
            { value: 'Damage - awaiting Service', label: 'Damage - awaiting Service' }]
          }},

          {dataField: 'warantyExpiryDate', text: 'Warranty Date', hidden:true,   editor: {
            type: Type.DATE
          }, formatter: (cell, row)=><span>{cell!=='0000-00-00'?longDate(cell):'-'}</span>},
    
          {dataField: 'model', text: 'Model', hidden:true},
          {dataField: 'remarks', text: 'Remarks', hidden:true},
          {dataField: 'description', text: 'Description', hidden:true},
          {dataField: 'lifeSpan', text: 'Life Span', formatter: (cell, row)=>cell !==''?cell+" Years":''},
          
        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div>
        
        <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDeleteOne(row.code)} mID={row.code} /> 

        <div className="btn-group dropdown-split-success ">
                  <button type="button" className="btn btn-sm btn-outline-success dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fa fa-hand"></i>Action
                  </button>
                  <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
                    
                      <a className="dropdown-item waves-effect waves-light"  href='#!' >  <i className='fa fa-print'></i> Preview Asset</a>

                      <a className="dropdown-item waves-effect waves-light"  href='#!' onClick={()=>handleRepair(row)} >  <i className='fa fa-clock-o'></i>Repair Asset </a>

                 <a className="dropdown-item waves-effect waves-light"  href='#!' onClick={()=>handleAllocate(row)} >  <i className='fa fa-user'></i>{row.assignTo!==''?'Re-allocate Asset':'Allocate Asset'} </a>
                      
                 {allowEdit==='Yes'?  <a className="dropdown-item waves-effect waves-light" target="_blank"  href={"/assets/update/"+row.code}><i className="icofont icofont-edit-alt"></i> Edit Asset</a>:[]}
          

                      <div className="dropdown-divider"></div>
                    {allowDelete==='Yes'?<a href="#!" data-toggle="modal" className="dropdown-item "  data-target={`#warningdialog-${row.code}`} title="Delete"><i className="fa fa-trash"></i>Delete Asset</a>:[]}
                  </div>
              </div>

       </div>,
        
        editable: false}
       
     ];

    
     const TableRecord=(props)=>{
        const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
          }) => ( 
          <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
              {
                columns
                  .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                  .map(column => (
                    
                    <button
                      type="button"
                      key={ column.dataField }
                      className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                      data-toggle="button"
                      aria-pressed={ column.toggle ? 'true' : 'false' }
                      onClick={ () => onColumnToggle(column.dataField) }
                    >
                      { column.text }
                    </button>
                  ))
              }
            </div>
            
          );

        const handleUpdate=(column, newValue, ID)=>{       
         const fd = new FormData(); 
             fd.append('newValue', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
             fd.append('jwt', Token);
            let url = ServerUrl+'/updateCustom_controller/tbl_assets'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then(  )
           .catch((error)=>Alerts('Error!', 'danger', error.message)) 
         } 
         const { SearchBar } = Search;
         const { ToggleList } = ColumnToggle;
         //const { ExportCSVButton } = CSVExport;
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
            showTotal: true,
          paginationTotalRenderer: customTotal,
          sizePerPageList: [{text: '70', value: 70}, {text: '150', value: 150}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
          { text: 'All', value: props.data.length }]
           
          };
         return  <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 
                                              
                                 <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'250px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>


              <div className="dt-buttons btn-group pull-right"> 


           {allowDelete==='Yes'? <button className="btn btn-outline-danger" data-toggle="modal"   data-target={`#warningdialog-${'delAll'}`} > <i className="icofont icofont-delete-alt"></i>Delete (selected)</button>:[]}
    <div className="btn-group dropdown-split-primary ">




        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
          
        <form method='POST' action={ServerUrl+'/excel_controller.php?api=studentsapplication'} target='_blank'>
        <input type='hidden' name='gender' value ={search.gender} />

<input type='hidden' name='fromDate' value ={search.fromDate} />
<input type='hidden' name='toDate' value ={search.toDate} />

<input type='hidden' name='applicationType' value ={search.applicationType} />
<input type='hidden' name='applicationID' value ={search.applicationID} />

<input type='hidden' name='admissionStatus' value ={search.admissionStatus} />
<input type='hidden' name='jwtToken' value ={Token} />

                <input type='hidden' name='jwt' value ={Token} />
               
        <button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>
        
        </form>

        <form method='POST' action={ServerUrl+'/printout/asset_summary.php?token='+new Date()} target='_blank'>
                <input type='hidden' name='gender' value ={search.gender} />

                <input type='hidden' name='fromDate' value ={search.fromDate} />
                <input type='hidden' name='toDate' value ={search.toDate} />

                <input type='hidden' name='applicationType' value ={search.applicationType} />
                <input type='hidden' name='applicationID' value ={search.applicationID} />

                <input type='hidden' name='admissionStatus' value ={search.admissionStatus} />
                <input type='hidden' name='jwtToken' value ={Token} />

        <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
        
        </form>

        </div>
    </div> 
    </div>  
                                 </div>
                                 
                     <BootstrapTable 
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      

     useEffect(()=>{
        setIsAuthenticated(menuLink.includes(location.pathname))
        fetchContent()
        fetchDepartment()
       },[]);

        return (<>
   <div  className="main-body">
   {isAuthenticated? <div className="page-wrapper">
                <PageHeader title="List of Assets">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Assets</a> </li>
                    </PageHeader>


                    {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
{/* The Page Body start here */}
<div className="page-body">
         
         <WarningModal message='Are you really sure? Once deleted you cannot get it back' handleAction={handleDelete} mID={'delAll'} /> 

        



 <button data-toggle="modal" id='openAssetDialog'   data-target='#assignAsset'  className='display-none'> </button>

<div className="modal fade " id='assignAsset' tabIndex="-1" role="dialog">
<div className="modal-dialog" role="document">
<div className="modal-content ">
<div className="modal-header">
  <h6 className="modal-title"><i className='fa fa-info'></i> Assign Assets to Staff</h6>
  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
</div>
<div className="modal-body">


<div className="row">

<section className="col-md-12">


<div className="form-group">
						<label className="bold ">Assets</label>
				<input type="text" name="code" value={assign.assetName} readOnly='true' onChange={handleAssignChange} className='form-control' />
												
			</div>	



<div className="form-group">
<label className="bold "> Select Staff <span style={{color:'red'}}>*</span></label>
<Select options={
                               staff&&staff.map((list, idx)=> {
                                   return {key:idx, value: list.code, label: list.staffName, phone:list.mobile  }
                                 })} 
    onChange={handleSelect} className={errors.staff ? 'form-control form-control-danger' : ''} name="staff" value={assign.staff}  />

 <span style={{color:'red'}}>{errors.staff && errors.staff}</span>

</div>


<div className="form-group">
						<label className="bold ">Allocation Date <span style={{color:'red'}}>*</span></label>
					<input type="date" name="dateAssign" value={assign.dateAssign} onChange={handleAssignChange} className={errors.dateAssign  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.dateAssign  && errors.dateAssign}</span>
											</div>



</section>

</div>


</div>
<div className="modal-footer">
  <button type="button"  id='closeAddDialog' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
  <button type="button" onClick={handleAssetAllocation} className="btn btn-inverse">Allocate Asset</button>
</div>
</div>
</div>
</div>





<button data-toggle="modal" id='openRepairDialog'   data-target='#repairAsset'  className='display-none'> </button>

<div className="modal fade " id='repairAsset' tabIndex="-1" role="dialog">
<div className="modal-dialog" role="document">
<div className="modal-content ">
<div className="modal-header">
  <h6 className="modal-title"><i className='fa fa-info'></i> Repair Asset</h6>
  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
<span aria-hidden="true">&times;</span>
</button>
</div>
<div className="modal-body">

<div className="row">

<section className="col-md-12">


<div className="form-group">
						<label className="bold ">Assets</label>
				<input type="text" name="code" value={repair.assetName} readOnly='true' onChange={handleRepairChange} className='form-control' />
												
			</div>	


<div className="form-group">
						<label className="bold ">Cost of Repair <span style={{color:'red'}}>*</span></label>
					<input type="number" name="cost" value={repair.cost} onChange={handleRepairChange} className={error.cost  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{error.cost  && error.cost}</span>
											</div>

<div className="form-group">
						<label className="bold ">Repair Date <span style={{color:'red'}}>*</span></label>
					<input type="date" name="dateRepair" value={repair.dateRepair} onChange={handleRepairChange} className={error.dateRepair  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{error.dateRepair  && error.dateRepair}</span>
											</div>


                                            <div className="form-group">
						<label className="bold ">Reason For Repair <span style={{color:'red'}}>*</span></label>
					<input type="reason" name="reason" value={repair.reason} onChange={handleRepairChange} className={error.reason  ? 'form-control form-control-danger' : 'form-control'} /><span style={{color:'red'}}>{error.reason  && error.reason}</span>
											</div>
</section>

</div>


</div>
<div className="modal-footer">
  <button type="button"  id='closeRepairDialog' className="btn btn-default waves-effect " data-dismiss="modal">Close</button>
  <button type="button" onClick={handleAssetRepair} className="btn btn-inverse">Submit</button>
</div>
</div>
</div>
</div>



















{errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}




			<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header" >
			<h5><i className="fa fa-search"></i> Search</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
				<div className="card-block" >
       	
 <div className="row">
<div className="col-sm-12">

	<div className="card z-depth-0">
		<section className="card-block">
        <div className="row">                   
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                            <label  className='pull-right text-bold'>From Date</label>
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}  name="fromDate" value={search.fromDate} />
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                    <label  className='pull-right text-bold'>To Date</label>
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                        <input type="date" className="form-control pickdate" onChange={handleChange}   name="toDate" value={search.toDate} />
                        </div>
                    </div>
                </div>
                </div>

                <div className="row"> 
                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                    <label  className='pull-right text-bold'>Department</label>
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                  <select  name="applicationType" value={search.applicationType} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        {department&&department.map((data, id)=><option key={id} value={data.code} >{data.departmentName}</option>)}
                    </select>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                    <label  className='pull-right text-bold'>Allocation </label>
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="allocation" value={search.allocation} className="form-control" onChange={handleChange}>
                    <option value="all">All</option>
                        <option value="Allocated">Allocated</option>
                        <option value="Unallocated">Unallocated</option>

                    </select>

                </div>
                    </div>
                </div>



                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                    <label  className='pull-right text-bold'>Condition</label>
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="condition" value={search.condition} className="form-control" onChange={handleChange}>
                    <option value="all">All</option>
                        <option value="New">New</option>
                        <option value="Old">Old</option>
                        <option value="Used">Used</option>
                        <option value="Missing">Missing</option>
                        <option value="Out for Repair">Out for Repair</option>
                        <option value="Lost / Stolen">Lost / Stolen</option>
                        <option value="Broken - Not Repairable">Broken - Not Repairable</option>
                        <option value="Damage - awaiting Service">Damage - awaiting Service</option>

                    </select>

                </div>
                    </div>
                </div>
            </div>
            <div className="row">                   
                

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                    <label  className='pull-right text-bold'>Asset Name</label>
                    </div>
                                    
                <div className="col-lg-8 col-md-8 col-sm-8">
                <input type="text" className="form-control " placeholder="Asset Name" onChange={handleChange}  name="assetName" value={search.assetName} />
                </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="form-group row">
                    <div className="col-lg-4 col-md-4 col-sm-4">
                    <label  className='pull-right text-bold'> Category</label>
                    </div>
                                    
                    <div className="col-lg-8 col-md-8 col-sm-8">
                    <select  name="category" value={search.category} className="form-control" onChange={handleChange}>
                        <option value="all">All</option>
                        <option value="Funitures & Fittings">Funitures & Fittings</option>
                        <option value="Office Equipment">Office Equipment</option>
                        <option value="Computers">Computers</option>
                        <option value="Automobile">Automobile</option>
                        <option value="Others">Others</option>
                    </select>
                        </div>
                    </div>
                </div>
                </div>
               

            <div className="row"> 
            
            <div className="col-lg-12 offset-lg-6 col-md-12 offset-md-6 col-sm-12 offset-sm-6 ">
           
	<button type="button" className="btn btn-inverse "  onClick={SearchTable}><i className="fa fa-search"></i> Search</button>
    </div>
            
            </div>

	</section>
</div></div></div>
			
							</div>

							</div>
							</div>
	                   
                            </div>



							<div className="row  ">
			<div className="col-sm-12">
								

	<div className="card z-depth-0">
		<div className="card-header " >
			<h5><i className="fa fa-list"></i> Asset Information</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
                     <li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
				</ul>
			</div>
		</div>
        <div className="card-block" >
       	 <div className="row">
          <div className="col-sm-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <TableRecord data={content}  columns={tableHeader}  />}
           
           
    
    </div></div></div>
			

							</div>
							</div>
	                   
                            </div>




                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 

 );
        
}

export default React.memo(Assets) 