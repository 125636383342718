import React, {useState, useEffect} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, allowDelete, allowEdit, menuLink} from '../component/include'
import Select from 'react-select'
import { useParams } from 'react-router'

import { useSelector } from 'react-redux'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
 const EditAsset =()=> {
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
	const [content, setContent] = useState([]);
	let params = useParams();
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
const [image, setImage] = useState('')
const [errors, setErrors] = useState({});
const [isSubmitting, setIsSubmitting] = useState(false);
const [department, setDepartment]=useState([])

const fetchDepartment =()=>{
	const fd = new FormData();
            fd.append('jwt', Token);
	let url = ServerUrl + '/fetchField/tbl_staff_settings/department/ID/1';
	axios.post(url, fd, config)
	.then(result =>setDepartment(result.data))
}



const fetchContent =()=>{
	setNotice({...notice,   isDataFetching: true  });
	var sql ="select ID, code, assetName, category, assetType, barcode, serialNo, model, purchaseDate, purchaseAmount, warantyExpiryDate, currentLocation, department, lifeSpan, description, isMovable, isInsured, imageUrl, insuranceDate, usageHistory, remarks, createBy, dateCreate from tbl_assets where code = '"+params.code+"' " ;
	  
	const fd = new FormData();
	fd.append("sql", sql);
	fd.append("jwt", Token);
	let url = ServerUrl+'/fetchBySql_controller';
	console.log(sql)
	axios.post(url, fd, config).then(result=>{ 
		if(result.data.length!==0){
			setContent(result.data)
		}
		
	})
	.catch((error)=>{
		setErrors({...errors, errorMessage: error.message})
		//Alerts('Error!', 'danger', error.message)
	}).finally(()=>{
		setNotice({...notice, 
			isDataFetching: false
		});
	}) 

}
const getDepartment =(code)=>{
    const result = department.filter(item=>item.code===code);
  const answer = result.length!==0?result[0].departmentName:''
  return  answer
}

const handleSubmit = event =>{
	event.preventDefault();
	 
setErrors(ValidateInput(content[0]));
	setIsSubmitting(true);
} 


function submit(){  
      
	let formData = content[0]; 
	const fd = new FormData();
            fd.append('jwt', Token);
	for(let k in formData){
        fd.append(k,formData[k]);
    }; 

	if(image!==''){
		fd.append('image', image);
	}

 setNotice({...notice,  isLoading: true}) 

	let url = ServerUrl+'/update_controller/tbl_assets'
   axios.post(url, fd, config)
   .then(response =>{

	window.scrollTo(0,0)
	if (response.data.type ==='success'){
		setErrors({...errors, successMessage: response.data.message})
		
	} else{
		setErrors({...errors, errorMessage: JSON.stringify(response.data)})
	
		}        
		  
  })
  .catch((error)=>{
	setErrors({...errors, errorMessage: error.message})
})
.finally(()=>{
	fetchContent()
	setImage('');
  document.getElementById('image').value= '';
  setNotice({...notice, isLoading: false}) 
setIsSubmitting(false)
})  

}


useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
	},[errors])
	

const ValidateInput =(values)=>{
let errors = {};   
if(!values.assetName){
	errors.assetName ='Please enter asset name';
	window.scrollTo(0,0);
}

if(!values.purchaseDate){
	errors.purchaseDate ='Please choose purchase Date';
}

if(values.assetType.length===0){
	errors.assetType ='Please select asset type';
}

if(values.category.length===0){
	errors.category ='Please select asset category';
}

return errors;
}



const  handleFile = function(fieldName){
    return function(newValue){  
      
   readURL(newValue);
   }
   
} 


const readURL = (input)=>{
   
    let displayMessage = '';
    const PreviewImage = input.target.name;
      //  let newImage = "viewPassport";
        var fileName = document.getElementById(PreviewImage).value;

    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 1) {
        displayMessage = input.target.name +': File uploaded is more than maximum size Allow. it exceeds 1 Mb';
       document.getElementById(input.target.name).value = '';
      
       setImage('')
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
            setImage(input.target.files[0])
            
        }
    }
    }else{

        displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 900kb'
        document.getElementById(input.target.name).value = '';
       
        setImage('')
    }   
    
    if(displayMessage.length > 0){
        setErrors({...errors, errorMessage: displayMessage})
        
      }
    
} 


const handleChange = (e)=>{
	const {name, value} = e.target
	const std =JSON.parse(JSON.stringify(content))   
	   std[0][name] = value;
	setContent(std);
	setErrors({...errors, [name]:''});
}

const handleSelect = (option, action)=>{
	const std =JSON.parse(JSON.stringify(content))   
	  std[0][action.name] = option.value;
	setContent(std);
	
};

	
useEffect(()=>{
	setIsAuthenticated(verifyRouter(location.pathname))
    fetchDepartment()
	fetchContent()
 },[]);
        return (  <>
   <div  className="main-body">
   {allowEdit==='Yes'?   <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}
				   {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
        <PageHeader title="Update Asset Information">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="/assets/view_all">Assets</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Registration</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
<div className="page-body">


	
{errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}

	
					{content&&content.map((asset, id)=>			
								<div key={id} className="row  ">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Asset Information</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
		<div className="card z-depth-0">
		<div className="card-block">
			<div className="row">
									
										<section className="col-md-6">
										
										<div className="form-group">
						<label >Asset Name<span style={{color:'red'}}>*</span></label>
						<input type="text" name="assetName" value={asset.assetName} onChange={handleChange} className={errors.assetName  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.assetName && errors.assetName}</span>	
							
											</div>
											<div className="form-group">
						<label >Select Category <span style={{color:'red'}}>*</span></label>
						<Select  options={[
                    { value: 'Funitures & Fittings', label: 'Funitures & Fittings' },
                    { value: 'Office Equipment', label: 'Office Equipment' },
					{ value: 'Computers', label: 'Computers' },
					{ value: 'Automobile', label: 'Automobile' },
					{ value: 'Others', label: 'Others' }
                ]} 
				className={errors.category ? 'form-control form-control-danger' : ''}  onChange={handleSelect}  name="category" value={{value:asset.category, label:asset.category}}   /> 
							<span style={{color:'red'}}>{errors.category && errors.category}</span>
											</div>

											<div className="form-group">
						<label >Select Condition <span style={{color:'red'}}>*</span></label>
						<Select  options={[
                    { value: 'New', label: 'New' },
                    { value: 'Old', label: 'Old' },
					{ value: 'Used', label: 'Used' },
					{ value: 'Out for Repair', label: 'Out for Repair' },
					{ value: 'Lost / Stolen', label: 'Lost / Stolen' },
					{ value: 'Broken - Not Repairable', label: 'Broken - Not Repairable' },
					{ value: 'Damage - awaiting Service', label: 'Damage - awaiting Service' }
					

                ]} 
				className={errors.assetType ? 'form-control form-control-danger' : ''}    onChange={handleSelect}  name="assetType" value={{value:asset.assetType, label:asset.assetType}}   /> 
					
					<span style={{color:'red'}}>{errors.assetType && errors.assetType}</span>
											</div>		
										</section>
										
					<section className="col-md-6">
					<div className="form-group">
						<label >Barcode</label>
						<input type="text" name="barcode"  autoComplete="false" value={asset.barcode} onChange={handleChange} className={errors.barcode  ? 'form-control form-control-danger' : 'form-control'} /> 
						
											</div>

											<div className="form-group">
						<label >Registered / Serial Number</label>
						<input type="text" name="serialNo" value={asset.serialNo} onChange={handleChange} className='form-control' />
												
											</div>

											<div className="form-group">
						<label >Model</label>
						<input type="text" name="model" value={asset.model} onChange={handleChange} className='form-control' />
												
											</div>
							</section></div></div></div>


								
      <div className="card z-depth-0">
	
    <div className="card-block">
        <div className="row">

        <div className="col-sm-12" id="staffAttendance" >
        <h4 >Asset Location </h4><hr/><br/>
        </div>
        </div>

    <div className="row">
    <section className="col-md-6">
	<div className="form-group">
						<label > Department</label> 
						<Select options={
                            department&&department.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.departmentName }
                             })} 
onChange={handleSelect}  name="department" value={{value:asset.department, label:getDepartment(asset.department)}}  /> 
												
			</div>


                <div className="form-group">
    <label >Current Location </label>
    <input type="text" className='form-control' name="currentLocation" onChange={handleChange} value={asset.currentLocation} />
                </div>


                <div className="form-group">
    <label >Description</label>
    <input type="text" className='form-control'  name="description" onChange={handleChange} value={asset.description} />

           </div>

</section>	
                            
                            <section className="col-md-6">
                                
							<div className="form-group">
						<label >Purchase Date <span style={{color:'red'}}>*</span></label>
					<input type="date" name="purchaseDate" value={asset.purchaseDate} onChange={handleChange} className={errors.purchaseDate  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.purchaseDate  && errors.purchaseDate}</span>
											</div>

											<div className="form-group">
						<label >Purchase Amount</label>
						<input type="number" name="purchaseAmount" value={asset.purchaseAmount} onChange={handleChange} className='form-control' /> 
												
											</div>

                <div className="form-group">
                    <label >Is Movable</label>
                    
                    <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={asset.isMovable==="Yes"?true:false} onChange={handleChange} value={"Yes"} name="isMovable" />
										<i className="helper"></i>Yes</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={asset.isMovable ==="No"?true:false} onChange={handleChange} value={"No"} name="isMovable"  />
										<i className="helper"></i>No</label></div>

                    </div>
                    
                    </div>
                            </section>

                            </div>
                            </div>
    
    </div>

			<div className="card z-depth-0">
		<div className="card-block">

		<div className="row">

<div className="col-sm-12" id="staffAttendance" >
<h4 >Fair Usage </h4><hr/><br/>
</div>
</div>
						<div className="row">			
										<section className="col-md-6">

										<div className="form-group">
						<label >Waranty Expiry Date</label>
						<input type="date" name="warantyExpiryDate" value={asset.warantyExpiryDate} onChange={handleChange} className='form-control' />
												
											</div>
										
											<div className="form-group">
						<label >Expected Life Span</label>
						<input type="number" name="lifeSpan" value={asset.lifeSpan} onChange={handleChange} className='form-control' />
												
											</div>		
											<div className="form-group">
						<label >Remarks</label>
						<input type="text" name="remarks" value={asset.remarks} onChange={handleChange} className='form-control' />
												
											</div>
										
										</section>
										<section className="col-md-6">
																					
										<div className="form-group">
                    <label >Is Insured?</label>
                    
                    <div className="form-radio">
                <div className="radio radio-inline"><label>
						<input type="radio" checked={asset.isInsured==="Yes"} onChange={handleChange} value={"Yes"} name="isInsured" />
										<i className="helper"></i>Yes</label></div>

                                        <div className="radio radio-inline"><label>
						<input type="radio" checked={asset.isInsured ==="No"} onChange={handleChange} value={"No"} name="isInsured"  />
										<i className="helper"></i>No</label></div>

                    </div>
                    
                    </div>
					<div className="form-group">
						<label >Insurance Date</label>
						<input type="date" name="insuranceDate" value={asset.insuranceDate} onChange={handleChange} className='form-control' />
												
											</div>

										 <div className="form-group">
                           <label >Asset Image</label><br/>
                  <input id="image"  onChange={handleFile('image')} className='form-control' name="image" type="file" accept="image/*" /> <span style={{color:'red'}}>{errors.image && errors.image}</span>
          <div className="note" style={{color:'red'}}>
          <strong>Size 900 kb,</strong>gif|jpg|png|jpeg Only Allow
      </div>	   	
              </div>
										</section>
										</div></div></div>

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit}  className="btn btn-primary "> <i className='fa fa-home'></i> Update Asset</button>
	
								</footer>				
							</div>
							</div>
							</div>
							</div>
							)}
                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(EditAsset) 