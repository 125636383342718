import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import {Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl,  Token,  config, allowDelete, allowEdit, menuLink} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, getAge, FormatNumber, getResultHead, getPosition, longDate, getRank, sort, getCAPoint, getBritishDate, getNumWorkDays} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useLocation } from 'react-router-dom'
import Error403 from '../settings/error403'
import { useSelector } from 'react-redux'


const Transcript =()=> {
    const [errors, setErrors] = useState({});
    const structure = useRef(null)
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [remark, setRemark]=useState([])
    const [classTeacher, setClassTeacher] = useState([]);
    const [settings, setSettings]=useState([])
const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo[0]);

let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
const [assesmentGrade, setAssesmentGrade]=useState([])
const staff  = useSelector(state => state.rootReducer.staff);
 const [studentList, setStudentList] = useState([]);

const [student, setStudent]=useState([])
const [disabledResult, setDisabledResult]=useState([])
const [studentAssessment, setStudentAssessment]=useState([])
const [assessment, setAssessment]=useState([])
const [house, setHouse]=useState([])
const [grade, setGrade] = useState([])
const [grademark, setGrademark]=useState([])
    const [scores, setScores]=useState([])
    const [onlyCategory, setOnlyCategory]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [remarkResults, setRemarkResults] = useState([])


    const [result, setResult] = useState({
        studentClass:[],
        examtype:[],
        student:[],
        resultHead:'Secondary',
        orderBy:'',
        session:[]
    })

    const [resultSession, setResultSession]=useState([])
    const [category, setCategory]=useState([])
    const [attendance, setAttendance]=useState([])

    const [firstTermAttendance, setFirstTermAttendance]=useState({
                attendanceStartFirst:'',
                attendanceEndFirst:'',
    })
    const [secondTermAttendance, setSecondTermAttendance]=useState({
        attendanceStartSecond:'',
        attendanceEndSecond:'',
    })
  

    const [atResult, setAtResult]=useState({
        attendanceStart:'',
        attendanceEnd:'',
        nextTermBegin:'',
        nextTermBeginDay:'',
        totalOpen:0,

    })




    const [firstTerm, setFirstTerm]=useState([])
    const [secondTerm, setSecondTerm]=useState([])
    const [thirdTerm, setThirdTerm]=useState([])






    const getScores =(code)=>{
        let scr =  [...scores];
        const rs = scr.filter(item=>item.studentCode===code)

//filter subject here
const newSubject=	rs.map(e=>e['subjectCode'])
                        .map((e,i,final)=>final.indexOf(e)===i&&i)
                        .filter(e=>rs[e])
                        .map(e=>rs[e])
        return newSubject.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1);
    
    }



    


const getGrade =(score, field)=>{
   
const ans = grademark.filter(itm=>itm.classtype===result.studentClass.classtype&& parseFloat(score) >= parseFloat(itm.minimum) && parseFloat(score) <= parseFloat(itm.maximum));
    const remark = ans.length!==0? getRemark(ans[0].gradeMark):''
    const grade = ans.length!==0? getGradeScore(ans[0].gradeCode):''
   

return field ==='grade'?String(grade):String(remark)
}




   const getResultScore =(studentCode, examCode,  subjectCode, field, dataList)=>{
    const ans = dataList.filter(item=>item.studentCode===studentCode && item.examCode===examCode && item.subjectCode===subjectCode)
    
return ans.length!==0?ans[0][field]:0
    }

    const getResultScoreNum =(studentCode, examCode,  subjectCode, field, dataList)=>{
        const ans = dataList.filter(item=>item.studentCode===studentCode && item.examCode===examCode && item.subjectCode===subjectCode)

        if(ans.length!==0 && Number(ans[0][field])>0){return 1}else{
    return 0}
        }


const mergeAllResult =async()=>{
    const answer = [...firstTerm, ...secondTerm, ...thirdTerm]
    //const answer = allRes.filter(item=>item.studentCode==='620610393c6db');

    var list = [];
    for (var i = 0; i< answer.length;  i++) {

        var first = Number(getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'total', answer))
        var second = Number(getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'total', answer))
        var third = Number(getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'total', answer))


        var overallTotal = Number(first+ second+ third)


        var firstNum = Number(getResultScoreNum(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'total', answer))
        var secondNum = Number(getResultScoreNum(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'total', answer))
        var thirdNum = Number(getResultScoreNum(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'total', answer))


        

            var totalCummulativeNum = Number(firstNum+ secondNum+ thirdNum)


        var average = (overallTotal/totalCummulativeNum).toFixed(2)
        
        var grade =getGrade(average, 'grade')
        var remark = getGrade(average, 'remark')



        list.push({
            subjectName:answer[i].subjectName,
            subjectCode:answer[i].subjectCode,
            studentCode:answer[i].studentCode,
            studentName:answer[i].studentName,

            firstTermExamCode:result.session.firstTerm, 
            firstTerm1stCA:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'firstCA', answer),
            firstTerm2ndCA:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'secondCA', answer),
            firstTermExam:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'exam', answer),
            firstTermTotal:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'total', answer),
            firstTermComment:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'comment', answer),
            firstTermGrade:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'grade', answer),
            firstTermAbsent:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'absent', answer),
            firstTermRemark:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'remark', answer),
            firstTermPosition:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'position', answer),
            firstTermAverage:getResultScore(answer[i].studentCode, result.session.firstTerm,  answer[i].subjectCode, 'totalAverage', answer),


            secondTermExamCode:result.session.secondTerm,
            secondTerm1stCA:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'firstCA', answer),
            secondTerm2ndCA:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'secondCA', answer),
            secondTermExam:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'exam', answer),
            secondTermTotal:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'total', answer),
            secondTermComment:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'comment', answer),
            secondTermGrade:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'grade', answer),
            secondTermAbsent:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'absent', answer),
            secondTermRemark:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'remark', answer),
            secondTermPosition:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'position', answer),
            secondTermAverage:getResultScore(answer[i].studentCode, result.session.secondTerm,  answer[i].subjectCode, 'totalAverage', answer),

            

            thirdTermExamCode:result.session.thirdTerm,
           thirdTerm1stCA:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'firstCA', answer),
           thirdTerm2ndCA:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'secondCA', answer),
           thirdTerm1stExam:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'exam', answer),
           thirdTermTotal:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'total', answer),
           thirdTermComment:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'comment', answer),
           thirdTermGrade:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'grade', answer),
           thirdTermAbsent:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'absent', answer),
           thirdTermRemark:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'remark', answer),
           thirdTermPosition:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'position', answer),
           thirdTermAverage:getResultScore(answer[i].studentCode, result.session.thirdTerm,  answer[i].subjectCode, 'totalAverage', answer),
           average:average,
           grade:grade,
           remark:remark,
           overallTotal:overallTotal 


        })

    }

   setScores(list)
}

    const fetchAnalysisA =(exam, section)=>{
        setNotice({...notice, isLoading: true}) 
       
        
        let final =   'SELECT st.subjectName, s.studentName, e.firstCA, e.secondCA, e.exam, e.total, e.grade, e.remark, e.position, e.uniqueCode, e.examCode, e.displayOrder, e.code, e.totalAverage, e.comment, e.classID, e.sectionID, e.subjectCode, e.maxMark, e.absent, e.studentCode from  tbl_exam_result e, tbl_subject st, tbl_students s where st.code= e.subjectCode and s.code =  e.studentCode and  e.examCode ="'+exam+'" and e.sectionID ="'+section+'" '
        
        const fd = new FormData();
        fd.append("sql", final);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(response=>{
             if(response.data.length!==0){
        setFirstTerm(response.data)
            }else{
                setFirstTerm([]) 
            }  
        }).finally(()=>{
            
            setNotice({...notice, isLoading: false}) 
        })
        }

    const fetchAnalysisB =(exam, section)=>{
        setNotice({...notice, isLoading: true}) 
       
        
        let final =   'SELECT st.subjectName, s.studentName, e.firstCA, e.secondCA, e.exam, e.total, e.grade, e.remark, e.position, e.uniqueCode, e.examCode, e.displayOrder, e.code, e.totalAverage, e.comment, e.classID, e.sectionID, e.subjectCode, e.maxMark, e.absent, e.studentCode from  tbl_exam_result e, tbl_subject st, tbl_students s where st.code= e.subjectCode and s.code =  e.studentCode and  e.examCode ="'+exam+'" and e.sectionID ="'+section+'" '
       
       const fd = new FormData();
       fd.append("sql", final);
       fd.append('jwt', Token)
       let url = ServerUrl+'/fetchBySql_controller';
       axios.post(url, fd, config).then(response=>{
            if(response.data.length!==0){
                setSecondTerm(response.data)
           }else{
               setSecondTerm([]) 
           }  
       }).finally(()=>{
           
           setNotice({...notice, isLoading: false}) 
       })

    }



    const fetchAnalysisC =(exam, section)=>{

        
        let final =   'SELECT st.subjectName, s.studentName, e.firstCA, e.secondCA, e.exam, e.total, e.grade, e.remark, e.position, e.uniqueCode, e.examCode, e.displayOrder, e.code, e.totalAverage, e.comment, e.classID, e.sectionID, e.subjectCode, e.maxMark, e.absent, e.studentCode from  tbl_exam_result e, tbl_subject st, tbl_students s where st.code= e.subjectCode and s.code =  e.studentCode and  e.examCode ="'+exam+'" and e.sectionID ="'+section+'" '

        
        const fd = new FormData();
        fd.append("sql", final);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(response=>{
             if(response.data.length!==0){
                setThirdTerm(response.data)
            }else{
                setThirdTerm([]) 
            }  
        }).finally(()=>{
            
            setNotice({...notice, isLoading: false}) 
        })
    }





    const fetchAllResultSession =()=>{

        var sql ="SELECT r.code, r.firstTerm, r.secondTerm, r.thirdTerm, r.sessionCode, s.startYear from tbl_result_session r, tbl_school_session s where r.sessionCode =s.ID " ;

        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
            setResultSession(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    }


    const handleSearch = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(result));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};   



        if(values.session.length===0){
            errors.session ='Please select session';
        }   
        if(values.studentClass.length===0){
            errors.studentClass ='Please select class';
        } 

        if(values.orderBy.length===0){
            errors.orderBy ='Please select order ';
        }

        return errors;
        }
        
        const fetchSettings =()=>{
            const fd = new FormData();
        fd.append('jwt', Token);
            let url = ServerUrl + '/fetchField/tbl_result_slip/settings/ID/1';
            axios.post(url, fd, config).then(result=>{
                setSettings(result.data[0])
              
            })
        }



   
    
    const fetchRemarks =()=>{
        var sql ="SELECT code, teacherRemark, headofSchool, teacherName, headRemark, teacherName, headName, resultHead, studentCode from tbl_result_remarks where  examType = '"+result.session.thirdTerm+"' and sectionID = '"+result.studentClass.value+"'" ;

const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token);
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(response=>{
    if(response.data.length!==0){
        setRemarkResults(response.data)
    }else{
        setRemarkResults('')
    }
    
})
.catch((error)=>{
    Alerts('Error!', 'danger', error.message)
})
}



const handleSelectSession =(option, action)=>{     
    setResult({...result, [action.name]:option, studentClass:[], orderBy:[]});
    fetchExamAttendance(option.thirdTerm)
    fetchExamAttendanceFirst(option.firstTerm)
    fetchExamAttendanceSecond(option.secondTerm)


    setStudentList([])
    setScores([])
    setStudent([])
    setErrors({...errors, [action.name]:''})

}

const handleSelectSorting =(option, action)=>{     
    setResult({...result, [action.name]:option});
    
    setErrors({...errors, [action.name]:''})
    mergeAllResult()
    fetchStudentAssessment() 
    getAttendance()
    fetchRemarks()
}



    const handleSelect =(option, action)=>{     

        
        setResult({...result, [action.name]:option, orderBy:[]});
        fetchAnalysisA(result.session.firstTerm, option.value)
        fetchAnalysisB(result.session.secondTerm, option.value)
        fetchAnalysisC(result.session.thirdTerm, option.value)
        fetchClassTeaher(option.classID, option.value)
            fetchGradeMark(option.classtype)
            setStudent([])
        setErrors({...errors, [action.name]:''})
            fetchStudentList(result.session.thirdTerm, option.value)
            
    }


    const fetchStatistics =()=>{
        setNotice({...notice, isLoading: true}) 
        let final =   'SELECT   rank() over (order by  CAST(sum(e.total) AS decimal(10,2)) desc) position, cast(sum(case when e.total >0 and e.absent="No" then e.total else 0 end)/sum(case when e.total >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) as average, s.code, s.passport, s.gender, s.house, s.dateOfBirth, s.admissionNumber, s.registerNumber, s.studentName, sum(case when e.total >0 and e.absent="No" then e.total else 0 end) as obtained, sum(case when e.total >0 and e.absent="No" then 100 else 0 end) as obtainable, e.subjectCode, e.classID, e.sectionID, e.maxMark, e.absent from tbl_students s, tbl_exam_result e where s.code = e.studentCode and  e.examCode ="'+result.session.thirdTerm+'" and e.sectionID ="'+result.studentClass.value+'" group by e.studentCode '


     const fd = new FormData();
    fd.append("sql", final);
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
         if(result.data.length!==0){
            
    setStudent(result.data)
        }else{
            setStudent([]) 
        }  
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
        
    }) 
    }

    

    const fetchDisbaledStudent =(section, exam)=>{

        var sql ="SELECT studentList from  tbl_disable_result where  examType ='"+exam+"' and sectionID ='"+section+"' Limit 1";
    
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
    
    if(result.data.length!==0 && Array.isArray(result.data)){
        const options = JSON.parse(result.data[0].studentList)
    
        if(options.length!==0){
            const rs = options.map((item)=>item.studentCode)
    setDisabledResult(rs)
        }else{
            
    setDisabledResult([])
        }
    
    }
    
    })
    
    }

    
    
        const fetchStudentList =(exam, section)=>{
            fetchDisbaledStudent(section, exam)
            setNotice({...notice, isLoading: true}) 
           var sql ="SELECT s.passport, s.code, s.gender, s.admissionNumber, s.house, s.dateOfBirth, s.registerNumber, s.studentName, e.examCode from tbl_students s, tbl_exam_result e where s.code = e.studentCode and e.examCode ='"+exam+"'  and  e.sectionID ='"+section+"' group by s.code"

        
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
            if(result.data.length!==0){
           setStudentList(result.data)

    }else{
        setStudentList([]) 
    } 
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
           
        })
        }

      
    
        const fetchAssessment =()=>{
            var sql ="SELECT category, assessment, assesmentGrade from tbl_standards_setting where ID="+1;
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
        if(result.data.length!==0){
        var categor = result.data[0].category
        var assessmen = result.data[0].assessment
    
       setCategory(JSON.parse(categor))
       setAssessment(JSON.parse(assessmen).sort((a, b) =>(a.categoryCode > b.categoryCode) ? 1 : -1))
      
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }

       
        const getAssessment = (code, field) =>{        
       
            const reply = assessment&&assessment.filter(list=>list.code===code);
            const answer = reply.length!==0?reply[0][field]:'';
            return  String(answer)
            }

          

            const getCategory=(code)=>{
                const result = category && category.filter(list=>list.code===code);
              const answer = result.map((c)=>c.categoryName);
              return  String(answer)
            }

        const fetchStudentAssessment =()=>{
            setNotice({...notice, isLoading: true}) 
            var sql ="SELECT  assessmentCode, grade, studentCode from tbl_assessment_result where examCode ='"+result.session.thirdTerm+"' and sectionID ='"+result.studentClass.value+"'" ;


         const fd = new FormData();
        fd.append("sql", sql);
            fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(response=>{
             if(response.data.length!==0){

                var assess = response.data;
    var list = [];
    for (var i = 0; i< assess.length;  i++) {
        list.push(
            {  code:assess[i].assessmentCode,
                assessmentName:getAssessment(assess[i].assessmentCode, 'assesmentName'),
                type:getCategory(getAssessment(assess[i].assessmentCode, 'categoryCode')),
                grade:assess[i].grade,
                studentCode:assess[i].studentCode
            });
            }

            const onlyCategory =	list.map(e=>e['type'])
                        .map((e,i,final)=>final.indexOf(e)===i&&i)
                        .filter(e=>list[e])
                        .map(e=>list[e])

            setOnlyCategory(onlyCategory)

        setStudentAssessment(list)
        
            }else{
                setOnlyCategory([])
        setStudentAssessment([])
            }
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false}) 
        }
        )
        }

       
        
        const fetchGradeMark =(classType)=>{
            const fd = new FormData();
            fd.append('jwt', Token);
            let url = ServerUrl + '/fetchField/tbl_exam_settings/gradeMark/ID/1';
            axios.post(url, fd, config)
            .then(result =>{

                if(result.data.length!==0){
                    var grade = result.data.filter(item=>item.classtype===classType)
                    setGrademark(grade.sort((a, b) =>(parseFloat(b.maximum) > parseFloat(a.maximum)) ? 1 : -1))
                }else{
                    setGrademark([])
                }
            })             
        }


        const fetchReMark =()=>{
            var sql ="SELECT grade, gradeRemark, gradeMark from tbl_exam_settings where ID="+1;
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token)
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{
        if(result.data.length!==0){
            
var grad = result.data[0].grade
        var gradeRemar = result.data[0].gradeRemark
        setRemark(JSON.parse(gradeRemar).sort((a, b) =>(a.remarkName > b.remarkName) ? 1 : -1))
        setGrade(JSON.parse(grad).sort(sort))
        }
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        })
        }


        const getGradeScore =(code)=>{
            const result = grade&&grade.filter(item=>item.code===code)
            return result.length!==0?result[0].gradeName:''
            }
        


            const fetchExamAttendance =(value)=>{
                let sql ='select startDate, endDate, nextTermBegin, nextTermBeginDay from tbl_result_attendance where exam = "'+value+'"'
        
                const fd = new FormData();
                fd.append('jwt', Token); 
                fd.append("sql", sql);
                let url = ServerUrl+'/fetchBySql_controller'
                axios.post(url, fd, config).then(response=>{
                    if(Array.isArray(response.data)&& response.data.length!==0){

                     

                         setAtResult({...atResult, attendanceStart:response.data[0].startDate, 
                            attendanceEnd:response.data[0].endDate,
                        nextTermBegin:response.data[0].nextTermBegin,
                        nextTermBeginDay: response.data[0].nextTermBeginDay }) 
                       
                    }else{
                        setAtResult({...atResult, attendanceStart:'', attendanceEnd:'', nextTermBegin:'',  nextTermBeginDay:''  })
                    } 
                })
                .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
        
            })
        }




        const fetchExamAttendanceFirst =(value)=>{
            let sql ='select startDate, endDate, nextTermBegin, nextTermBeginDay from tbl_result_attendance where exam = "'+value+'"'
    
            const fd = new FormData();
            fd.append('jwt', Token); 
            fd.append("sql", sql);
            let url = ServerUrl+'/fetchBySql_controller'
            axios.post(url, fd, config).then(response=>{
                if(Array.isArray(response.data)&& response.data.length!==0){
                    setFirstTermAttendance({ 
                        attendanceStartFirst:response.data[0].startDate, 
                        attendanceEndFirst:response.data[0].endDate})
                   
                }else{
                    setFirstTermAttendance({attendanceStartFirst:'', attendanceEndFirst:''  })
                } 
            })
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
    
        })
    }


    const fetchExamAttendanceSecond =(value)=>{
        let sql ='select startDate, endDate, nextTermBegin, nextTermBeginDay from tbl_result_attendance where exam = "'+value+'"'

        const fd = new FormData();
        fd.append('jwt', Token); 
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(response=>{
            if(Array.isArray(response.data)&& response.data.length!==0){
                setSecondTermAttendance({ 
                    attendanceStartSecond:response.data[0].startDate, 
                    attendanceEndSecond:response.data[0].endDate})
               
            }else{
                setSecondTermAttendance({attendanceStartSecond:'', attendanceEndSecond:''  })
            } 
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)

    })
}

        const fetchAllHolidays =()=>{
            var f = parseFloat(getNumWorkDays(firstTermAttendance.attendanceStartFirst, firstTermAttendance.attendanceEndFirst));
            var s = parseFloat(getNumWorkDays(secondTermAttendance.attendanceStartSecond, secondTermAttendance.attendanceEndSecond));
            var t = parseFloat(getNumWorkDays(atResult.attendanceStart, atResult.attendanceEnd));

            var total = f+s+t;
            const fd = new FormData();
            fd.append('jwt', Token);
            let sql = 'Select count(ID) as total from tbl_events_holidays where eventType= "Holidays" and startDate >= "'+firstTermAttendance.attendanceStartFirst+'" and startDate <= "'+atResult.attendanceEnd+'"'

                let url = ServerUrl+'/fetchBySql_controller'
                fd.append("sql", sql)
                axios.post(url, fd, config).then(response =>{

                    if(Array.isArray(response.data)&& response.data.length!==0){
                        
                        setAtResult({...atResult, totalOpen:(total - Number(response.data[0].total))*2 })
                        }else{
    
                            setAtResult({...atResult, totalOpen:total*2 })
                        }


                        
                }) 
        }



               


            const getAttendance =()=>{
                fetchAllHolidays()

                let final =   'SELECT s.code, s.admissionNumber, s.studentName, a.studentCode, sum(case when a.indicator  = "P" then 2 else 0 end) as P, sum(case when a.indicator  = "A" then 2 else 0 end) as A, sum(case when a.indicator  = "H" then 1 else 0 end) as H  from tbl_students s, tbl_student_attendance a where   s.code = a.studentCode and a.attendanceDate >= "'+firstTermAttendance.attendanceStartFirst+'" and a.attendanceDate <= "'+atResult.attendanceEnd+'" and a.attendanceType ="fullday" group by a.studentCode '

                
        
                const fd = new FormData();
                fd.append('jwt', Token); 
                fd.append("sql", final);
                let url = ServerUrl+'/fetchBySql_controller'
                axios.post(url, fd, config).then(response=>{
                    if(Array.isArray(response.data)&& response.data.length!==0){

                        setAttendance(response.data)
                    }else{
                        setAttendance([])
                    } 
                })
                .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
        
            })
        }


        const fetchHouse =()=>{
            const fd = new FormData();
            fd.append("jwt", Token);
            let url = ServerUrl + '/fetchField/tbl_student_master/house/ID/1';
            axios.post(url, fd, config)
            .then(result =>setHouse(result.data))
        }

        const fetchAssementGrade =()=>{
            var sql ="SELECT category, assessment, assesmentGrade from tbl_standards_setting where ID="+1;
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
        if(result.data.length!==0){
        var assesmentGrad = result.data[0].assesmentGrade
        setAssesmentGrade(JSON.parse(assesmentGrad).sort((a, b) =>(a.rateKey > b.rateKey) ? 1 : -1))
    
        }
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }


        const getHouseName = (code) =>{      
            
            
            const response = house&&house.filter(list=>list.code === code);
            return  response.length? String(response[0].houseName):'';
            }



    const getRemark =(code)=>{
        const ans = remark&&remark.filter(item=>item.code===code)
        return ans[0].remarkName
        }


            const fetchClassTeaher =(classID, sectionID)=>{
                var newStaff = staff&&staff.filter((item=>item.classID===classID&&item.sectionID===sectionID))
                setClassTeacher(newStaff)
            }


        useEffect(()=>{
            if(Object.keys(errors).length === 0 && isSubmitting){
               
        fetchStatistics()
            }
            },[errors])



     const handleStaffName=(cell)=>{
        if(cell!=='[]' && cell!=''){
        return JSON.parse(cell).map((itm, idx)=>{
            return <span  key={idx}> {' '+getStaffField(itm, 'staffName')}</span>
        } );
    }else{
        return ''
    } 
    }

    const handleStaffSignature=(cell)=>{
        if(cell!=='[]' && cell!=''){
        return JSON.parse(cell).map((itm, idx)=>{
            return <img id="viewPassport2" key={idx} className="img-100" style={{ maxHeight:'50px' }}  src={`${ImagesUrl}/staff/signature/${getStaffField(itm, 'signature')}`}   alt='' />
        } );
    }else{
        return ''
    } 
    }


       const getStaffField = (code, field) =>{   
    
        const res = staff&&staff.filter(list=>list.code===code);
        const answer = res.map((c)=>c[field] ); 
        return  String(answer);
       }


    



const getStudent =(code)=>{
   
    let scr =  [...scores];
        const rs = scr.filter(item=>item.studentCode===code&&Number(item.overallTotal)>0)
        
        var f =0; var s=0; var t=0;
        var fTot =0;var sTot =0; var tTot =0;
        rs.forEach(item=>{
            if(Number(item.thirdTermTotal)>0){
            t+=1;
            tTot+=Number(item.thirdTermTotal)
            }
            if(Number(item.secondTermTotal)>0){
                s+=1;
                sTot+=Number(item.secondTermTotal)
                }
                if(Number(item.firstTermTotal)>0){
                    f+=1;
                    fTot+=Number(item.firstTermTotal)
                    }
        }) 

        var totalScore = fTot+sTot+tTot;
        var total=f+s+t;

    return {obtainable:total*100, obtained:totalScore, average:(totalScore/total).toFixed(2)};

}


const getAt =(code)=>{
    let scr =  [...attendance];
   const rs = scr.filter(item=>item.code===code)

   if(rs.length!==0){
       return rs[0]
   }else{
    return {A:0, P:0}
   }
   

}


const getData =(code)=>{
     let scr =  [...remarkResults];
    const rs = scr.filter(item=>item.studentCode===code)
    return rs[0];

} 


const getStudentAssessment =(code)=>{
    let scr =  [...studentAssessment];
    const rs = scr.filter(item=>item.studentCode===code)
    return rs;

}

useEffect(()=>{
   
   setIsAuthenticated(menuLink.includes(location.pathname)) 
    fetchHouse()
    fetchSettings()
    fetchReMark()
    fetchAssessment()
    fetchAssementGrade()
    fetchAllResultSession() 
},[]);



        return (
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
               
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Transcript">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Class Result</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row no-print ">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Class Result</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		
        <div className="row">
       
        <section className="col-md-4">
                <div className="form-group">
         <label> Session<span style={{color:'red'}}>*</span></label>
         <Select  options={resultSession&&resultSession.map((list, id)=>{
             return {key:id, value:list.code, label:list.startYear, firstTerm:list.firstTerm, secondTerm:list.secondTerm, thirdTerm:list.thirdTerm}
         })}    

 onChange={handleSelectSession} className={errors.session ? 'form-control form-control-danger' : ''} name="session" value={result.session} id='session'  />

 <span style={{color:'red'}}>{errors.session && errors.session}</span>
            </div>
            </section>


                    <section className="col-md-4">
                    <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label>
         <Select  options={
                         schoolClass&&schoolClass.map((list, idx)=> {
                            return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                            }) }
                          })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>
            </div>
     
     </section>   
     <section className="col-md-4">
           <div className="form-group">
         <label> Order By</label>
         <Select  options={[
             {value:'order by s.studentName ASC', label:'order by Name'},
                    { value: 'order by s.registerNumber ASC', label: 'Order By Register Number'},
             { value: 'order by s.admissionNumber ASC', label: 'Order By Admission Number'},
                ]} 
onChange={handleSelectSorting} className={errors.orderBy ? 'form-control form-control-danger' : ''} name="orderBy" value={result.orderBy}  /> 
<span style={{color:'red'}}>{errors.orderBy && errors.orderBy}</span>

            </div>
     
     </section>


				</div>	


	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSearch} className="btn btn-inverse "><i className="fa fa-search"></i> Search Result</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	

{student.length!==0?studentList.map((std, index)=><div key={index}>
<div className="card z-depth-0">
		
        
    <div>    <div className="card-block">
	
                       <div className="row" >

<table className="table  table-full-width ">
       <tbody>
       <tr>
                    <td className="pull-right" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${schoolInfo.schoolLogo}`}  title="School Logo" alt=''  /></td>
                    <td className="text-center" >
                        

                 
                        <h4 style={{textAlign:'center'}}><b>{schoolInfo.schoolName}</b></h4>
                        <h6 style={{textAlign:'center'}}><b>Motto: {schoolInfo.schoolMotto}</b></h6>
                        <div style={{textAlign:'center'}}>  {schoolInfo.address}  </div>
                        <div style={{textAlign:'center'}}> {schoolInfo.mobile}, {schoolInfo.altMobile}, {schoolInfo.email} </div>
                        
                        <div style={{textAlign:'center'}}>  Website: {schoolInfo.website}  </div>
                             </td>

 <td > <img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+std.gender+'.png'}} src={`${ImagesUrl}/students/${std.passport}`}  title={std.studentName} alt=''  /></td>
                </tr>
            </tbody>
       </table>

</div> 
                

 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b> CUMMULATIVE TERM REPORT</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td colSpan={2}>NAME: <b>{std.studentName.toUpperCase()}</b></td>         
                 <td >ADMISSION NUMBER: <b> {std.admissionNumber} </b></td>
                 <td >CLASS: <b> {result.studentClass.label} </b></td>
                </tr>

                <tr>
                    
                <td>AGE: <b>{getAge(std.dateOfBirth)} </b></td>
              <td>TOTAL TIMES SCHOOL OPENED: <b> {atResult.totalOpen} </b></td>
                    <td>TOTAL TIMES PRESENT: <b>{getAt(std.code).P} </b></td> 
                 
                <td >NO. IN CLASS: <b>{studentList.length}</b></td>
               
                </tr>

               <tr>
                    
                <td >SCORE OBTAINED: <b>{parseFloat(getStudent(std.code).obtained).toFixed(2)} </b></td>
                    
                    <td >OUT OF: <b>{getStudent(std.code).obtainable}</b></td>
                
                <td >STUDENT'S AVERAGE: <b> {getStudent(std.code).average}  </b></td>
                <td >FINAL GRADE: <b> {getGrade(parseInt(getStudent(std.code).average), 'grade')+' ['+getGrade(parseInt(getStudent(std.code).average), 'remark')+']'}  </b></td>
                </tr> 
                
                   
                

            </tbody>
       </table></div>
       <div className="col-md-12 table-responsive">
        <table className="table table-striped table-bordered">
        

            <thead>
                <tr>
                    <th >SUBJECTS</th>
                   
                
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }} >SUMMER TERM CA ({getCAPoint(result.studentClass.classtype).ca})</th>
                    <th style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>SUMMER TERM EXAM ({getCAPoint(result.studentClass.classtype).exam})</th>
                    <th style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>SUMMER TERM TOTAL (100%)</th>
                    
                    {getPosition(result.studentClass.classtype)==='Position'? <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>SUBJ POSITION</th>:[]}
                    
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>SPRING TERM (100)</th>
                    
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>AUTUM TERM (100)</th>
                    
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>CUMM. AVG</th>
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>GRADE</th>
                    <th style={{maxWidth:'100px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>REMARK</th>
                </tr>
            </thead>
            <tbody>
                
            {getScores(std.code).map((st, idx)=>Number(st.overallTotal)>0 || Number(st.thirdTermTotal)>0 || Number(st.secondTermTotal)>0 || Number(st.firstTermTotal)>0?
                <tr key={idx}>
                
                    <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.subjectName.toUpperCase()}</td>
                   
                    
                    <td>{st.thirdTerm1stCA}</td>
                    <td>{st.thirdTerm1stExam}</td>
                    <td>{st.thirdTermTotal}</td>
                    
                    {getPosition(result.studentClass.classtype)==='Position'?
                    <td>{st.thirdTermPosition}<sup>{getRank(st.thirdTermPosition)}</sup></td>:[]}


                    <td>{st.secondTermTotal}</td>
                    <td>{st.firstTermTotal}</td>
                    
                    <td>{st.average}</td>
                     <td>{st.grade}</td>
                     <td>{st.remark}</td>
 
                
            </tr>:[])} 

            </tbody> 
        </table>
 </div>	 
 <div className="col-md-12">
     <table className='table table-bordered table-responsive'>
         <tbody>
             <tr>
             <td><b >KEY TO GRADINGS</b></td>
               
             </tr>
             <tr>
                 <td><b>{grademark.map((data, index)=><i key={index}>{getGradeScore(data.gradeCode) + ' =' +data.minimum + ' - ' + data.maximum+ ' ['  +getRemark(data.gradeMark) + '],  '}</i>)} </b></td>
                 
             </tr>
         </tbody>
     </table>

     {studentAssessment.length!==0?
 <div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td ><table className="table">
                        <thead>
                        <tr>
                            <th>AFFECTIVE DOMAINS</th>
                            <th>RATING</th>
                        </tr></thead>
                        <tbody>
                        {getStudentAssessment(std.code).map((data, index)=> data.type.toUpperCase()==='AFFECTIVE DOMAINS'?
                            <tr key={index}>
                                <td>{data.assessmentName}</td>
                                <td>{data.grade}</td>
                            </tr>:[])}
                        </tbody>
                    </table>
                    </td>
                    <td ><table className="table">
                        <thead>
                        <tr>
                            <th>PYSCHOMOTOR SKILLS</th>
                            <th>RATING</th>
                        </tr></thead>
                        <tbody>
                        {getStudentAssessment(std.code).map((data, index)=> data.type.toUpperCase()==='PYSCHOMOTOR SKILLS'?
                            <tr key={index}>
                                <td>{data.assessmentName}</td>
                                <td style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{data.grade}</td>
                            </tr>:[])}
                        </tbody>
                    </table>
                    
                    
                    <table className="table">
                        <thead>
                        <tr>
                            <th colSpan={2}>KEY TO RATINGS</th>
                        </tr></thead>
                        <tbody>
                        {assesmentGrade&&assesmentGrade.map((data, index)=> 
                            <tr key={index}>
                                <td>{data.rateKey}</td>
                                <td>{data.assesmentGrade}</td>
                            </tr>)}
                        </tbody>
                    </table>

                    </td>
                </tr>
                
            </tbody>
       </table></div></div>:''}

    
 <div className="col-md-12">
 
    
<table className='table table-bordered'>
        <tbody>
            <tr>
                <td  style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>CLASS TEACHER’S COMMENT(S) </b></td>
                <td colSpan={2} style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).teacherRemark} </td>
                
            </tr>
            <tr>
            <td  style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>CLASS TEACHER’S NAME/SIGNATURE </b></td>

            <td style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(getData(std.code).teacherName)}</td>
            <td style={{maxWidth:'5px' }}> {handleStaffSignature(getData(std.code).teacherName)}</td>
                
            </tr>
           
        </tbody>
    </table> 
   {getResultHead(result.studentClass.classtype)==='Secondary'?
    <table className='table table-bordered'>
        <tbody>
            <tr>
                <td style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}} ><b>PRINCIPAL’S COMMENT </b></td>

            <td colSpan={2} style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>

            </tr>
            <tr>
          <td style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>PRINCIPAL’S NAME/SIGNATURE </b></td>
                <td  style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofSchool, 'staffName')}</td>


               <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings.headofSchool, 'signature')}`}   alt='' /></td>
                
            </tr>
        </tbody>
    </table>:
    <table className='table table-bordered'>
        <tbody>
            <tr>
                <td style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}} ><b>HEAD TEACHER’S COMMENT </b></td>

            <td colSpan={2} style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>

            </tr>
            <tr>
          <td style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>HEAD TEACHER’S NAME/SIGNATURE </b></td>
                <td  style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofPrimary, 'staffName')}</td>


               <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100"  src={`${ImagesUrl}/staff/signature/${getStaffField(settings.headofPrimary, 'signature')}`}   alt='' /></td>
                
            </tr>
        </tbody>
    </table>}  

   <table className="table table-bordered  table-condensed ">
       <tbody>
                <tr>
                    <td >TERM STARTS: <b>{getBritishDate(atResult.attendanceStart)}</b></td>
                    
                    
                 <td >TERM ENDS: <b> {getBritishDate(atResult.attendanceEnd)} </b></td>
                    
                   
                 {getResultHead(result.studentClass.classtype)==='Secondary'?
                 <td >NEXT TERM BEGINS<br/> BOARDERS: <b>{getBritishDate(atResult.nextTermBegin)} </b><br/> DAY STUDENTS: <b>{getBritishDate(atResult.nextTermBeginDay)} </b></td>

                 :
<td >NEXT TERM BEGINS: <b>{getBritishDate(atResult.nextTermBeginDay)} </b></td>

}
                </tr>
                

            </tbody>
       </table> 
</div>
 </div>
 </div> 
 
 <div className="row no-print">
        <div className="col-md-12 pull-right">
        <form method='POST' action={ServerUrl+'/printout/view_transcript.php?token='+userToken} target='_blank'>

        <input type='hidden' name='onlyCategory' value ={JSON.stringify(onlyCategory, null, 2)} />
                <input type='hidden' name='scores' value ={JSON.stringify(scores.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1), null, 2)} /> 
                <input type='hidden' name='studentAssessment' value ={JSON.stringify(studentAssessment, null, 2)} />
                <input type='hidden' name='totalStudent' value ={studentList.length} />
                <input type='hidden' name='grademark' value ={JSON.stringify(grademark, null, 2)} />
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />
                
                <input type='hidden' name='totalOpen' value ={atResult.totalOpen} />
                <input type='hidden' name='examPoint' value ={getCAPoint(result.studentClass.classtype).exam} />
                <input type='hidden' name='caPoint' value ={getCAPoint(result.studentClass.classtype).ca} />
                <input type='hidden' name='attendanceEnd' value ={getBritishDate(atResult.attendanceEnd)} />
                <input type='hidden' name='attendanceStart' value ={getBritishDate(atResult.attendanceStart)} />
                <input type='hidden' name='nextTermBegin' value ={getBritishDate(atResult.nextTermBegin)} />
                <input type='hidden' name='nextTermBeginDay' value ={getBritishDate(atResult.nextTermBeginDay)} />
                <input type='hidden' name='resultHeadP' value ={getResultHead(result.studentClass.classtype)} />
                <input type='hidden' name='settings' value ={JSON.stringify(settings, null, 2)} />
                <input type='hidden' name='classID' value ={result.studentClass.classID} />
                <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='finalGrade' value ={result.studentClass.classtype} />
                <input type='hidden' name='resultHead' value ={getPosition(result.studentClass.classtype)} />
                <input type='hidden' name='assesmentGrade' value ={JSON.stringify(assesmentGrade, null, 2)} />
                <input type='hidden' name='attendance' value ={JSON.stringify(attendance, null, 2)} />
                <input type='hidden' name='studentList' value ={JSON.stringify(studentList, null, 2)} />
                <input type='hidden' name='remarkResults' value ={JSON.stringify(remarkResults, null, 2)} />

                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div></div>
 

 
 
{disabledResult&&disabledResult.includes(std.code)? 
<div style={{ 

height:'100%',
backgroundColor:'#E1E1E1',
width:'100%',
alignItems:'center',
justifyContent:'center',
flexDirection:'column',
display:'flex',
flex:1,
position:'absolute',
padding:'20px',
opacity:0.98


 }}>


<h3 style={{ color:'blue',  }}> Result not available at the momemt, please contact your child's class teacher</h3>

</div>
:''} 

        </div>

        </div>


/* 
 <div className="card z-depth-0" key={index}>
 <div className="card-block">
	
                <div className="row">

<table className="table  table-full-width ">
<tbody>
<tr>
                    <td className="pull-right" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${schoolInfo.schoolLogo}`}  title="School Logo" alt=''  /></td>
                    <td className="text-center" >
                        

                 
                        <h4 style={{textAlign:'center'}}><b>{schoolInfo.schoolName}</b></h4>
                        <h6 style={{textAlign:'center'}}><b>Motto: {schoolInfo.schoolMotto}</b></h6>
                        <div style={{textAlign:'center'}}>  {schoolInfo.address}  </div>
                        <div style={{textAlign:'center'}}> {schoolInfo.mobile}, {schoolInfo.altMobile}, {schoolInfo.email} </div>
                        
                        <div style={{textAlign:'center'}}>  Website: {schoolInfo.website}  </div>
                             </td>

 <td > <img id="viewSchoolLogo1" className="img-100" style={{ height:'100px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+std.gender+'.png'}} src={`${ImagesUrl}/students/${std.passport}`}  title={std.studentName} alt=''  /></td>
                </tr>
     </tbody>
</table>

</div> 

<div className="row">
 <div className="col-md-12">
 <h5 style={{textAlign:'center'}}><u><b>  END OF SUMMER TERM REPORT</b></u></h5>
 </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

<table className="table table-bordered  table-full-width">
<tbody>
         <tr>
             <td colSpan={2}>NAME: <b>{std.studentName.toUpperCase()}</b></td>         
          <td >ADMISSION NUMBER: <b> {std.admissionNumber} </b></td>
          <td >CLASS: <b> {result.studentClass.label} </b></td>
         </tr>

         <tr>
             
         <td>AGE: <b>{getAge(std.dateOfBirth)} </b></td>
         <td>TOTAL TIMES SCHOOL OPENED: <b> {parseFloat(getAt(std.code).P)+parseFloat(getAt(std.code).A)} </b></td>
             <td>TOTAL TIMES ABSENT: <b>{getAt(std.code).A} </b></td>
          
         <td >NO. IN CLASS: <b>{studentList.length}</b></td>
        
         </tr>
         

     </tbody>
</table></div>
<div className="col-md-12 table-responsive">
 <table className="table table-striped table-bordered">
     <thead>
 <tr>
                    <th >SUBJECTS</th>
                   
                
                    <th style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }} >SUMMER TERM </th>
                    
                    <th style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>SPRING TERM </th>
                    
                    <th style={{maxWidth:'200px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>AUTUM TERM</th>
                </tr>
            </thead>
 {getScores(std.code).map((st, idx)=> st.absent!=='Yes'?
          <tbody key={idx}>
         <tr >
             <td > <b>{st.subjectName.toUpperCase()}</b></td>
             <td style={{ overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.thirdTermComment}</td>
             <td style={{ overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.secondTermComment}</td>
             <td style={{  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{st.firstTermComment}</td>
         
     </tr></tbody>
         :[])} 
 </table>
</div>	 
<div className="col-md-12">
<table className='table table-bordered'>
 <tbody>
     <tr>
         <td  style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>CLASS TEACHER’S COMMENT(S) </b></td>
         <td colSpan={2} style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).teacherRemark} </td>
         
     </tr>
     <tr>
     <td  style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>CLASS TEACHER’S NAME/SIGNATURE </b></td>

     <td style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{handleStaffName(getData(std.code).teacherName)}</td>
     <td style={{maxWidth:'5px' }}> {handleStaffSignature(getData(std.code).teacherName)}</td>
         
     </tr>


     <tr>
         <td style={{maxWidth:'30px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}} ><b>HEAD TEACHER’S COMMENT </b></td>

         <td colSpan={2} style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{getData(std.code).headRemark} </td>

     </tr>
     <tr>
         <td style={{maxWidth:'25px', overflowWrap:'break-word', whiteSpace:'pre-wrap'}}><b>HEAD TEACHER’S NAME/SIGNATURE </b></td>
         <td  style={{maxWidth:'70px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{settings&& getStaffField(settings.headofPrimary, 'staffName')}</td>


        <td style={{maxWidth:'5px' }}><img id="viewPassport2" className="img-100" style={{ maxHeight:'50px' }}   src={`${ImagesUrl}/staff/signature/${getStaffField(settings.headofPrimary, 'signature')}`}   alt='' /></td>
         
     </tr>
 </tbody>
</table>

<table className="table table-bordered  table-condensed ">
<tbody>
         <tr>
             <td >TERM STARTS: <b>{getBritishDate(atResult.attendanceStart)}</b></td>
             
             
          <td >TERM ENDS: <b> {getBritishDate(atResult.attendanceEnd)} </b></td>
             
          {getResultHead(result.studentClass.classtype)==='Secondary'?
                 <td >NEXT TERM BEGINS<br/> BOARDERS: <b>{getBritishDate(atResult.nextTermBegin)} </b><br/> DAY STUDENTS: <b>{getBritishDate(atResult.nextTermBeginDay)} </b></td>

                 :
<td >NEXT TERM BEGINS: <b>{getBritishDate(atResult.nextTermBeginDay)} </b></td>

}
             </tr>
         

     </tbody>
</table>

</div>
</div> 
<div className="row no-print">
 <div className="col-md-12 pull-right">
 <form method='POST' action={ServerUrl+'/printout/result_pre_transcript.php?token='+userToken} target='_blank'>

 <input type='hidden' name='onlyCategory' value ={JSON.stringify(onlyCategory, null, 2)} />
                <input type='hidden' name='scores' value ={JSON.stringify(scores.sort((a,b)=>(a.subjectName > b.subjectName)?1:-1), null, 2)} /> 
                <input type='hidden' name='studentAssessment' value ={JSON.stringify(studentAssessment, null, 2)} />
                <input type='hidden' name='totalStudent' value ={studentList.length} />
                <input type='hidden' name='grademark' value ={JSON.stringify(grademark, null, 2)} />
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='student' value ={JSON.stringify(student, null, 2)} />
                
                <input type='hidden' name='examPoint' value ={getCAPoint(result.studentClass.classtype).exam} />
                <input type='hidden' name='caPoint' value ={getCAPoint(result.studentClass.classtype).ca} />
                <input type='hidden' name='attendanceEnd' value ={getBritishDate(atResult.attendanceEnd)} />
                <input type='hidden' name='attendanceStart' value ={getBritishDate(atResult.attendanceStart)} />
                <input type='hidden' name='nextTermBegin' value ={getBritishDate(atResult.nextTermBegin)} />
                <input type='hidden' name='nextTermBeginDay' value ={getBritishDate(atResult.nextTermBeginDay)} />
                <input type='hidden' name='resultHeadP' value ={getResultHead(result.studentClass.classtype)} />
                <input type='hidden' name='settings' value ={JSON.stringify(settings, null, 2)} />
                <input type='hidden' name='classID' value ={result.studentClass.classID} />
                <input type='hidden' name='sectionID' value ={result.studentClass.value} />
                <input type='hidden' name='finalGrade' value ={result.studentClass.classtype} />
                <input type='hidden' name='resultHead' value ={getPosition(result.studentClass.classtype)} />
                <input type='hidden' name='assesmentGrade' value ={JSON.stringify(assesmentGrade, null, 2)} />
                <input type='hidden' name='attendance' value ={JSON.stringify(attendance, null, 2)} />
                <input type='hidden' name='studentList' value ={JSON.stringify(studentList, null, 2)} />
                <input type='hidden' name='remarkResults' value ={JSON.stringify(remarkResults, null, 2)} />

                <input type='hidden' name='jwtToken' value ={Token} />
 <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
 
 </form>
 </div></div>
 
 </div>
 </div>
  */
 
 
 ):''}


        
   


   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Transcript) 