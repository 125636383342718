import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, Currency, FormatNumber, allowDelete, allowEdit, menuLink} from '../component/include'
import Select from 'react-select'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'

import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { userToken } from '../component/authentication'

const Vehicle =()=> {
    const [errors, setErrors] = useState({});
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [allVehicle, setAllVehicle]= useState([])
    const [vehicle, setVehicle] = useState({
        vehicleName:'',
        remarks:'',
        vehicleNumber:'',
        noSeat:'',
        maxAllow:''
    })


    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    const [isSubmittingFuel, setIsSubmittingFuel] = useState(false);
    const [isSubmittingReading, setIsSubmittingReading] = useState(false);
    const [notice, setNotice] = useState({
        isLoading : false, 
        isError : false,
        isSuccess: false, 
        isWarning: false,
        isInfo: false,
        isDataFetching: false,
        msgResponse:''
        
    }); 
    
   
    const [reading, setReading] = useState({
        readingVehicle:[],
        vehicleId:'',
        readingRemarks:'',
        readingDate:'',
        readingNumber:''
    })
    const [fuel, setFuel] = useState({
        fuelVehicle:[],
        vehicleId:'',
        fuelDate:'',
        fuelRate:'',
        fuelRemark:'',
        fuelQuantity:'',
        fuelReceipt:''
    })

    
    const [allVehicleFuel, setAllVehicleFuel]= useState([])
    const [allVehicleReading, setAllVehicleReading]= useState([])
  

    const fetchAllVehicle =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl + '/fetch_controller/tbl_vehicle';
        axios.post(url, fd, config).then(result=>setAllVehicle(result.data)) 
    }

    const fetchAllVehicleReading =()=>{
        
    const fd = new FormData();
    fd.append('jwt', Token);

    var sql ="Select r.ID, r.readingRemarks, r.readingDate, r.readingNumber, f.vehicleName from tbl_vehicle f, tbl_vehicle_reading r where f.ID  =  r.readingVehicle" ;

fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>setAllVehicleReading(result.data)) 

    }



    const fetchAllVehicleFuel =()=>{

        const fd = new FormData();
        fd.append('jwt', Token);

        var sql ="Select f.ID, f.fuelRate, f.fuelQuantity, f.fuelDate, v.vehicleName from tbl_vehicle v, tbl_vehicle_fuel f where v.ID  = f.fuelVehicle " ;
	
	fd.append("sql", sql);
	let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>setAllVehicleFuel(result.data)) 

    }


    const handleChange = event =>{
		const {name, value} = event.target
        setVehicle({...vehicle, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const handleChangeFuel = event =>{
		const {name, value} = event.target
        setFuel({...fuel, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const handleChangeReading = event =>{
		const {name, value} = event.target
        setReading({...reading, [name] : value });
       setErrors({...errors, [name]:''});
    }


    const handleSelectFuel = (option, action)=>{
        setFuel({...fuel, [action.name]: option, vehicleId:option.value});
        setErrors({...errors, [action.name]:''})
         }

         const handleSelectReading = (option, action)=>{
            setReading({...reading, [action.name]: option, vehicleId:option.value});
            setErrors({...errors, [action.name]:''})
             }

       







    const tableVehicleFuelHeader = [
        /* {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div>

 <a href="#!" data-toggle="modal" className="btn btn-danger delete btn-sm "  data-target={`#warningdialog-${cell+'tbl_vehicle_fuel'}`}     title="Delete"><i className="icofont icofont-delete-alt"></i></a>

<WarningModal  handleAction={()=>handleDelete(cell, 'tbl_vehicle_fuel')} mID={cell+'tbl_vehicle_fuel'} message='Once deleted, you will no be able to recover this record. Are you realy sure?' /> 

        </div>, editable: false} */
        {dataField: 'vehicleName', text: 'Name'},
          {dataField: 'fuelQuantity', text: 'Qty', sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Quantity is required'
              };
            }
            return true;
          } },
          {dataField: 'fuelRate', text: 'Rate', sort: true,formatter:(cell)=>Currency+FormatNumber(cell), validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Rate is required'
              };
            }
            return true;
          } },
          {dataField: 'fuelDate', text: 'Date', sort: true, editor: {
            type: Type.DATE
          }} 
     ];

     const TableVehicleFuel =(props)=>{
   

        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
                    fd.append('jwt', Token);
                fd.append('newValue', newValue);
                fd.append('column', column.dataField);
                fd.append('ID', ID);
             
               let url = ServerUrl+'/updateCustom_controller/tbl_vehicle_fuel'
              axios.post(url, fd, config)
              //.then(result => console.log(result.data))
              .then()
              .catch((error)=>Alerts('Error!', 'danger', error.message)) 
            } 
            const { SearchBar } = Search;
        
            const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
            );
            const options = {
            showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
            { text: 'All', value: props.data.length }]
            
            };
            return  <ToolkitProvider search columnToggle 
                        keyField='ID' data={ props.data } columns={ props.columns } >
                            
                            {
                                props => (
                                    <div >
                         <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                         


      <div className="dt-buttons btn-group pull-right"> 
    
        
    {/* 
    <div className="btn-group dropdown-split-primary ">
        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
    
    
                <form method='POST' action={ServerUrl+'/excel_controller.php?api=fuel_report'} target='_blank'>
                    <input type='hidden' name='jwt' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>
            
            </form>
    
            <form method='POST' action={ServerUrl+'/printout/fuel_report.php?token='+userToken} target='_blank'>
    
                    <input type='hidden' name='jwtToken' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
            
            </form>
        </div>
    </div>  */}
    </div> 
        </div>
        
            <BootstrapTable
                                     { ...props.baseProps }
                                                 striped
                                             hover
                                             condensed
                                             noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                         pagination={paginationFactory(options) }
                                         cellEdit={ cellEditFactory({
                                           mode: 'dbclick',
                                           blurToSave: true,
                                           autoSelectText: true,
                                 beforeSaveCell: (oldValue, newValue, row, column) => {
                                   if(oldValue !==newValue){
                                   handleUpdate(column, newValue, row.ID);
                                   
                                  return true;
                                 }
                                 
                               }
                                         }) }
                                     />
                    </div>
                    )
                            }
        </ToolkitProvider>
        }
        


    const handleDelete = (ID, table)=>{  
        setNotice({...notice,  isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+ID+table)
              close.click();
              const fd = new FormData();
              fd.append('jwt', Token);
           fd.append('ID', 'ID')
            fd.append('data', ID)
    
            let url = ServerUrl+'/delete_controller/'+table
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
               setNotice({...notice, 
                   isLoading: false
               })
           }) 
    }





    const tableVehicleHeader = [
        /* {dataField: 'ID', text: 'Action', formatter: (cell)=><div>

 <a href="#!" data-toggle="modal" className="btn btn-danger btn-sm delete"    data-target={`#warningdialog-${cell+'tbl_vehicle'}`}     title="Delete"><i className="icofont icofont-delete-alt"></i></a>

<WarningModal  handleAction={()=>handleDelete(cell, 'tbl_vehicle')} mID={cell+'tbl_vehicle'} message='Once deleted, you will no be able to recover this record. Are you realy sure?' /> 

        </div>, editable: false}, */
        {dataField: 'vehicleName', text: 'Vehicle Name', validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'vehicle Name is required'
              };
            }
            return true;
          } },
          {dataField: 'vehicleNumber', text: 'Vehicle Number', validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'vehicle Number is required'
              };
            }
            return true;
          } },
          {dataField: 'noSeat', text: 'Total Seat', validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'vehicle seat is required'
              };
            }
            return true;
          } },
          {dataField: 'maxAllow', text: 'Max Allowd', validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'vehicle maximum allow is required'
              };
            }
            return true;
          } },
          {dataField: 'remarks', text: 'Remarks'}, 
     ];


     const TableVehicle =(props)=>{
	   
        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
                    fd.append('jwt', Token);
                fd.append('newValue', newValue);
                fd.append('column', column.dataField);
                fd.append('ID', ID);
             
               let url = ServerUrl+'/updateCustom_controller/tbl_vehicle'
              axios.post(url, fd, config)
              //.then(result => console.log(result.data))
              .then()
              .catch((error)=>Alerts('Error!', 'danger', error.message)) 
            } 
            const { SearchBar } = Search;
        
            const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
            );
            const options = {
            showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
            { text: 'All', value: props.data.length }]
            
            };
            return  <ToolkitProvider search columnToggle 
                        keyField='ID' data={ props.data } columns={ props.columns } >
                            
                            {
                                props => (
                                    <div >
                                <div className="form-group">
        
                            <SearchBar
                                         { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                                         
            </div>
        
            <BootstrapTable
                                     { ...props.baseProps }
                                                 striped
                                             hover
                                             condensed
                                             noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                         pagination={paginationFactory(options) }
                                         cellEdit={ cellEditFactory({
                                           mode: 'dbclick',
                                           blurToSave: true,
                                           autoSelectText: true,
                                 beforeSaveCell: (oldValue, newValue, row, column) => {
                                   if(oldValue !==newValue){
                                   handleUpdate(column, newValue, row.ID);
                                   
                                  return true;
                                 }
                                 
                               }
                                         }) }
                                     />
                    </div>
                    )
                            }
        </ToolkitProvider>
        }
        




    



        const handleSubmitFuel = event =>{
            event.preventDefault();
        setErrors(ValidateInputFuel(fuel));
            setIsSubmittingFuel(true);
        } 
	const ValidateInputFuel =(values)=>{
        let errors = {};   

        if(!values.fuelRate){
            errors.fuelRate ='Please enter Fuel rate';
        }
        if(!values.fuelQuantity){
            errors.fuelQuantity ='Please enter fuel Quantity';
        }

        if(values.fuelVehicle.length===0){
            errors.fuelVehicle ='Please select vehicle name';
        }
        if(!values.fuelDate){
            errors.fuelDate ='Please enter fuel date';
        }
        return errors;
        }


        const handleSubmit = event =>{
            event.preventDefault();
        setErrors(ValidateInput(vehicle));
        setIsSubmitting(true);
      }
    

        const ValidateInput =(values)=>{
            let errors = {};   
            
            if(!values.vehicleName){
                errors.vehicleName ='Please enter vehicle name';
            }
            if(!values.vehicleNumber){
                errors.vehicleNumber ='Please enter plate number';
            }
            if(!values.noSeat){
                errors.noSeat ='Please enter total number of seat';
            }
            if(!values.maxAllow){
                errors.maxAllow ='Please enter maximum passenger allow';
            }
            return errors;
            }
        
            useEffect(()=>{
                if(Object.keys(errors).length === 0 && isSubmitting){
                submit()
                }
                },[errors])

function submit(){  
      
       const fd = new FormData(); 
       let formData = vehicle; 
      for(let k in formData){
          fd.append(k,formData[k]);
      }; 
    setNotice({...notice,  isLoading: true}) 
fd.append('jwt', Token);
       let url = ServerUrl+'/save_controller/tbl_vehicle'
	  axios.post(url, fd, config)
	  .then(response =>{
        if(response.data.type ==='success') {
						Alerts('Saved!', 'success', response.data.message)
					}  else{
						Alerts('Error!', 'danger', JSON.stringify(response.data))
				}      
			  
	  }).catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    })
    .finally(()=>{
        fetchAllVehicle()
        setNotice({...notice,  isLoading: false}) 
      setIsSubmitting(false); 
      setVehicle({ 
        vehicleName:'',
        remarks:'',
        vehicleNumber:'',
        noSeat:'',
        maxAllow:''
    
    }); 
      })
}


useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmittingFuel){
    submitFuel()
    }
    },[errors])

function submitFuel(){  
      
    const fd = new FormData(); 
       let formData = fuel; 
      for(let k in formData){
          fd.append(k,formData[k]);
      }; 
    setNotice({...notice,  isLoading: true}) 
fd.append('jwt', Token);
       let url = ServerUrl+'/save_controller/tbl_vehicle_fuel'
	  axios.post(url, fd, config)
	  .then(response =>{
        if(response.data.type ==='success') {
            Alerts('Saved!', 'success', response.data.message)
        }  else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
    }     
			  
	  })
	  .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    })
    .finally(()=>{
      setIsSubmittingFuel(false); 
      fetchAllVehicleFuel()
      setNotice({...notice,  isLoading: true})
      setFuel({ 
        fuelVehicle:[],
        vehicleId:'',
        fuelDate:'',
        fuelRate:'',
        fuelRemark:'',
        fuelQuantity:'',
        fuelReceipt:''}); 
    })
}



function submitReading(){  
      
    const fd = new FormData(); 
       let formData = reading; 
       for(let k in formData){
          fd.append(k,formData[k]);
      }; 
    setNotice({...notice,  isLoading: true}) 
fd.append('jwt', Token);
       let url = ServerUrl+'/save_controller/tbl_vehicle_reading'
	  axios.post(url, fd, config)
	  .then(response =>{
        if(response.data.type ==='success') {
            Alerts('Saved!', 'success', response.data.message)
        }  else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
    }     
	
    })
    .catch((error)=>{
      Alerts('Error!', 'danger', JSON.stringify(error.message)) 
  })
  .finally(()=>{
      setIsSubmittingReading(false); 
      
    fetchAllVehicleReading();
    setNotice({...notice, 
        isLoading: false
    })
      setReading({ 
        readingVehicle:'',
        readingRemarks:'',
        readingDate:'',
        readingNumber:''})
    })
}

useEffect(()=>{
  setIsAuthenticated(menuLink.includes(location.pathname))
    fetchAllVehicle();
    fetchAllVehicleFuel();
    fetchAllVehicleReading();
},[]);



    
        
    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmittingReading){
        submitReading()
        }
        },[errors])   


        return (    <>
          <div  className="main-body">
          {isAuthenticated?   <div className="page-wrapper">
                        {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Add Vehicle & Fuel">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-car"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Transport</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Vehicle</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
							
                               
                               <div className="col-sm-5">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Add Vehicle</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<div className="row">
									
										<section className="col-md-12">
                                       
										<div className="form-group">
						<label >Vehicle Name<span style={{color:'red'}}>*</span></label>
				<input type="text" name="vehicleName" value={vehicle.vehicleName} onChange={handleChange} className={errors.vehicleName  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.vehicleName && errors.vehicleName}</span>	
			</div>


            <div className="form-group">
						<label >Plate Number<span style={{color:'red'}}>*</span></label>
				<input type="text" name="vehicleNumber" value={vehicle.vehicleNumber} onChange={handleChange} className={errors.vehicleNumber  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.vehicleNumber && errors.vehicleNumber}</span>	
			</div>


            <div className="form-group">
						<label >No of Seats<span style={{color:'red'}}>*</span></label>
				<input type="number" pattern="\d*" name="noSeat" min="1" value={vehicle.noSeat} onChange={handleChange} className={errors.noSeat  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.noSeat && errors.noSeat}</span>	
			</div>


            <div className="form-group">
						<label >Maximum Allow<span style={{color:'red'}}>*</span></label>
				<input type="number" name="maxAllow" min="1" value={vehicle.maxAllow} onChange={handleChange} className={errors.maxAllow  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.maxAllow && errors.maxAllow}</span>	
			</div>

            <div className="form-group">
						<label >Remarks</label>
				<input type="text" name="remarks" value={vehicle.remarks} onChange={handleChange} className='form-control' />
			</div>

										</section>
										</div>	

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary ">Save</button>
	
								</footer>				
							</div>
							</div>
              </div>	


		
	

	<div className="col-sm-7">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Listing All Vehicle</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12">
        <div className="card z-depth-0">
            <div className="card-block">
        <div className="table-responsive">
        {notice.isDataFetching ? <TableLoader />:
   <TableVehicle data={allVehicle}  columns={tableVehicleHeader}  />} 
</div>
	</div>
    </div></div></div>
</div>


</div>




<div className="col-sm-12">




<div className="card z-depth-0">
<div className="card-header">
<h5><i className="fa fa-edit" ></i> Add Fuel</h5>
<div className="card-header-right">
<ul className="list-unstyled card-option">
<li><i className="feather icon-maximizes full-card"></i></li>
<li><i className="feather icon-minus minimize-card"></i></li>
<li><i className="feather icon-trash-2 close-card"></i></li>
</ul>
</div>
</div>
<div className="card-block">
<div className="row">

<section className="col-md-6">
           
<div className="form-group">
<label >Vehicle Name<span style={{color:'red'}}>*</span></label>
<Select options={
allVehicle&&allVehicle.map((list, idx)=> {
   return {key:idx, value: list.ID, label: list.vehicleName }
 })} 
onChange={handleSelectFuel} className={errors.fuelVehicle ? 'form-control form-control-danger' : ''} name="fuelVehicle" value={fuel.fuelVehicle}  /> <span style={{color:'red'}}>{errors.fuelVehicle && errors.fuelVehicle}</span>	
</div>
</section>
<section className="col-md-6">
<div className="form-group">
<label >Total Litre<span style={{color:'red'}}>*</span></label>
<input type="number" pattern="\d*" min="1" name="fuelQuantity" value={fuel.fuelQuantity} onChange={handleChangeFuel} className={errors.fuelQuantity  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.fuelQuantity && errors.fuelQuantity}</span>	
</div>


</section>
<section className="col-md-6">
<div className="form-group">
<label >Rate Per Litre <span style={{color:'red'}}>*</span></label>
<input type="number" name="fuelRate" value={fuel.fuelRate} onChange={handleChangeFuel} className={errors.fuelRate  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.fuelRate && errors.fuelRate}</span>
</div>


</section>
<section className="col-md-6">
<div className="form-group">
<label >Date Purchase<span style={{color:'red'}}>*</span></label>
<input type="date" name="fuelDate" value={fuel.fuelDate} onChange={handleChangeFuel} className={errors.fuelDate  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.fuelDate && errors.fuelDate}</span>	
</div>



</section>
<section className="col-md-6">
<div className="form-group">
<label >Receipt Number</label>
<input type="text" name="fuelReceipt" value={fuel.fuelReceipt} onChange={handleChangeFuel} className='form-control' />
</div>


</section>
<section className="col-md-6">
<div className="form-group">
<label >Remark</label>
<input type="text" name="fuelRemark" value={fuel.fuelRemark} onChange={handleChangeFuel} className='form-control' />
</div>
</section>
</div>	

<footer className="pull-right">

<button type="button" id="submit" onClick={handleSubmitFuel} className="btn btn-primary ">Save</button>

</footer>				
</div>
</div>


</div>

<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Fuel Report</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12">
        <div className="card z-depth-0">
            <div className="card-block">
        <div className="table-responsive">
        <TableVehicleFuel  data={allVehicleFuel} columns={tableVehicleFuelHeader}  />
</div>
	</div>
    </div></div></div>
</div>




</div>
</div>
	

   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Vehicle) 