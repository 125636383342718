import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import PageHeader from '../component/pageheader'
import { Alerts } from '../component/notify'

import {ServerUrl, Token, config, allowDelete, allowEdit, menuLink} from '../component/include'
import axios from 'axios'
import {Bar, Pie} from 'react-chartjs-2'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'

const Result_Chart =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
    const [staff, setStaff]= useState([])  

const [position, setPosition]=useState([])
const [statistics, setStatistics] = useState({
  staff:0,
  resigned:0,
  releived:0,
  working:0
});
let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    
const fetchPosition = async ()=>{
  const fd = new FormData();
fd.append('jwt', Token);
  let url = ServerUrl + '/fetchField/tbl_staff_settings/position/ID/1'
  axios.post(url, fd, config)
  .then(result =>{

 var sql = "select position, count(*) as data from tbl_staff where employmentStatus ='Working' and position <> '' GROUP BY position order by position "
     
 let urls = ServerUrl+'/fetchBySql_controller'
 fd.append("sql", sql)
            axios.post(urls, fd, config).then(response =>{
              /*   if(Array.isArray(response.data) && response.data.length!==0){
              let responseData = []
              let post = response.data
              for (var i = 0; i< post.length;  i++) {
                responseData.push(
                    {  label:result.data.filter(item=>item.code===post[i].position)[0].positionName,
                        data:post[i].data
                    });
                    } 
                setPosition(responseData)
                  } */
            }) 
          
          })
        }

    const fetchStaffGender =()=>{
      
        const fd = new FormData();
        var sql = "Select gender, count(*) as data from tbl_staff where employmentStatus ='Working' group by gender "
     
            let url = ServerUrl+'/fetchBySql_controller'
            fd.append("jwt", Token)
            fd.append("sql", sql)
            axios.post(url, fd, config).then(result =>{
                
                setStaff(result.data)
                
            }) 
    } 

   

         

        
          const fetchStatistics =  ()=>{
      
    

            let sqls = "Select  (select count(*) from tbl_staff ) as staff, (select count(*) from tbl_staff where employmentStatus ='Resigned' )  as Resigned, (select count(*) from tbl_staff where employmentStatus ='Releived' )  as Releived,  (select count(*) from tbl_staff where employmentStatus ='Working' )  as Working  "
            
             const fds = new FormData();
             fds.append("sql", sqls);
             fds.append('jwt', Token)
             let urls = ServerUrl+'/fetchBySql_controller';
             axios.post(urls, fds, config).then(result=>{ 
                
                setStatistics({
                  staff:result.data[0].staff,
                  resigned:result.data[0].Resigned,
                    releived:result.data[0].Releived,
                    working :result.data[0].Working
                })
             })
         } 
         





              const positionList = {
                labels: position.map((data)=>data.label),
                datasets: [
                  {
                             
                    label: 'Total ',
                    backgroundColor: [
                      '#B21F00', '#C9DE00', '#2FDE00', '#00A6B4', '#e6074a', '#404E67', '#5555ff', '#4d1648', '#3a8300', '#2f855a', '#492f85', '#41163d', '#960d8d', ' #976877', '#1c251c' ,'#3f7030', '#adc71b'],
                    hoverBackgroundColor: [
                    '#501800', '#4B5000', '#175000', '#003350', '#35014F', '#fe5d70','#99CC00', '#3C3C3D','#00000', '26164d', '#22543d', '#1b0c1a', '#960d36', '#6800B4', '#19cc22' ,' #053105', '#0e2507'],
                    data: position.map((data)=>data.data)
                  }
                ]
              }

              
              const genderStaff= {
                labels: staff.map((data)=>data.gender),
                datasets: [
                  {
                    label: 'Total',
                    backgroundColor: [ '#404E67', '#5555ff', ],
                    hoverBackgroundColor: ['#CACACA', '#3a8300'],
                    data: staff.map((data)=>data.data), //[65, 59, 80, 81, 56]
                  }
                ]
              }

   useEffect(()=>{
    setIsAuthenticated(menuLink.includes(location.pathname))
     fetchStaffGender()
     fetchPosition()
     fetchStatistics()
},[]);

        return (   <>
          <div  className="main-body">
          {isAuthenticated?   <div className="page-wrapper">
                        {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title='Chart'>
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-dashboard"></i> </a>
                            </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">






<div className="row">

<div className="col-xl-3 col-md-6">
                <div className="card bg-c-blue text-white">
                    <div className="card-block">
                        <div className="row align-items-center">
                            <div className="col">
                            <p className="m-b-5">Total Staff</p>
                        <h4 className="m-b-0">{statistics.staff}</h4>
                            </div>
                            <div className="col col-auto text-right">
                                <i className="fa fa-briefcase f-50 text-c-blue"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


<div className="col-xl-3 col-md-6">
        <div className="card bg-c-green text-white">
            <div className="card-block">
                <div className="row align-items-center">
                    <div className="col">
                        <p className="m-b-5">Current Staff</p>
                        <h4 className="m-b-0">{statistics.working}</h4>
                    </div>
                    <div className="col col-auto text-right">
                        <i className=" fa fa-users f-50 text-c-green"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

<div className="col-xl-3 col-md-6">
            <div className="card bg-c-pink text-white">
                <div className="card-block">
                    <div className="row align-items-center">
                        <div className="col">
                            <p className="m-b-5">Total Resigned</p>
                            <h4 className="m-b-0">{statistics.resigned}</h4>
                        </div>
                        <div className="col col-auto text-right">
                            <i className="fa fa-users f-50 text-c-pink"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <div className="col-xl-3 col-md-6">
    <div className="card bg-c-yellow text-white">
        <div className="card-block">
            <div className="row align-items-center">
                <div className="col">
                    <p className="m-b-5">Total Relived</p>
                    <h4 className="m-b-0">{statistics.releived}</h4>
                </div>
                <div className="col col-auto text-right">
                    <i className="fa fa-user f-50 text-c-yellow"></i>
                </div>
            </div>
        </div>
    </div>
</div>



      </div>

    <br/>


   
  <div className="row">
      <div className="col-7 col-lg-7 col-xl-7">
          <div className="card">
		   <div className="card-header"><strong>
     Staff Position</strong>
				</div>
             <div className="card-body">

                    <div className="card-body">

                    <Bar
          data={positionList}
          options={{ legend:{
              display:false,
              position:'right'
            }
          }}
        /> 
        </div>

             </div>
          </div>
        </div>

        <div className="col-5 col-lg-5 col-xl-5">

<div className="card">
<div className="card-header">
              <strong> Staff Gender</strong>
				</div>
             <div className="card-body">
                <div className="card-body" >

        <Pie data={genderStaff}             
            options={{
            legend:{
            display:true,
            position:'right'
            }
        }}
        />
        </div>

             </div>
  </div></div>


  </div> 


   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Result_Chart) 