import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, allowDelete, allowEdit, menuLink} from '../component/include'
import Select from 'react-select'


import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { userToken } from '../component/authentication'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
const CreateDestination =()=> {

    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [isSubmitting, setIsSubmitting] = useState(false);

    const [destination, setDestination] = useState({
        code:Math.random().toString(36).substr(2,9),
        routeCode:'',
        pickDrop:'',
        frequency:'',
        amount:'',
        remarks:''
    })
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [allRoute, setAllRoute]= useState([])
    
    const [allDestination, setAllDestination]= useState([])

    const handleChange = event =>{
		const {name, value} = event.target
        setDestination({...destination, [name] : value });
       setErrors({...errors, [name]:''});
    }

    const handleSelect = (option, action)=>{
        setDestination({...destination, [action.name]: option});
        setErrors({...errors, [action.name]:''})
         }

    const fetchAllRoute =()=>{

        setNotice({...notice,   isDataFetching: true})
        let sql = "Select r.ID, r.code, r.startPoint, r.endPoint, v.vehicleName, v.vehicleNumber from tbl_route r, tbl_vehicle v where v.code = r.vehicle "
    
        const fd = new FormData();
        fd.append('jwt', Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>setAllRoute(result.data))
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice,   isDataFetching: false})
        })

    }

    const fetchAllDestination =()=>{

        setNotice({...notice,   isDataFetching: true})
        let sql = "Select d.ID,  d.code, d.amount, d.remarks, d.frequency,  d.pickDrop, r.startPoint, r.endPoint, v.vehicleName from tbl_vehicle_destination d, tbl_route r, tbl_vehicle v where v.code = r.vehicle and d.routeCode = r.code"
    
        const fd = new FormData();
        fd.append('jwt', Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>setAllDestination(result.data))
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice,   isDataFetching: false})
        })

    }

    
    const TableRecord =(props)=>{
	   
        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
                    fd.append('jwt', Token);
                fd.append('newValue', newValue);
                fd.append('column', column.dataField);
                fd.append('ID', ID);
             
               let url = ServerUrl+'/updateCustom_controller/tbl_vehicle_destination'
              axios.post(url, fd, config)
              //.then(result => console.log(result.data))
              .then()
              .catch((error)=>Alerts('Error!', 'danger', error.message)) 
            } 
            const { SearchBar } = Search;
        
            const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
            );
            const options = {
            showTotal: true,
            paginationTotalRenderer: customTotal,
            sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
            { text: 'All', value: props.data.length }]
            
            };
            return  <ToolkitProvider search columnToggle 
                        keyField='ID' data={ props.data } columns={ props.columns } >
                            
                            {
                                props => (
                                    <div >
                                <div className="form-group">
        
                            <SearchBar
                                         { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                                         
           
           
      <div className="dt-buttons btn-group pull-right"> 
    
        
    {/* 
    <div className="btn-group dropdown-split-primary ">
        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
    
    
                <form method='POST' action={ServerUrl+'/excel_controller.php?api=hostel_visitor'} target='_blank'>
                    <input type='hidden' name='jwt' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>
            
            </form>
    
            <form method='POST' action={ServerUrl+'/printout/destination.php?token='+userToken} target='_blank'>
    
                    <input type='hidden' name='jwtToken' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
            
            </form>
        </div>
    </div>  */}
    </div> 
            </div>
        
            <BootstrapTable
                                     { ...props.baseProps }
                                                 striped
                                             hover
                                             condensed
                                             noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                         pagination={paginationFactory(options) }
                                         cellEdit={ cellEditFactory({
                                           mode: 'dbclick',
                                           blurToSave: true,
                                           autoSelectText: true,
                                 beforeSaveCell: (oldValue, newValue, row, column) => {
                                   if(oldValue !==newValue){
                                   handleUpdate(column, newValue, row.ID);
                                   
                                  return true;
                                 }
                                 
                               }
                                         }) }
                                     />
                    </div>
                    )
                            }
        </ToolkitProvider>
        }
        


     
    const handleDelete = (ID, table)=>{  
        setNotice({...notice,  isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+ID+table)
              close.click();
              const fd = new FormData();
              fd.append('jwt', Token);
           fd.append('ID', 'ID')
            fd.append('data', ID)
    
            let url = ServerUrl+'/delete_controller/'+table
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
               setNotice({...notice, isLoading: false })
           }) 
    }


    const handleRouteAction =  useCallback((cell, row)=>{
        
        return <React.Fragment>    
        <a href="#!" data-toggle="modal"   data-target={`#warningdialog-${cell}`} className='btn btn-danger btn-sm'    title="Delete"><i className="icofont icofont-delete-alt"></i></a>

        <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(cell, 'tbl_vehicle_destination')} mID={cell} /> 
        </React.Fragment> 
    },[])
    				
    const tableRouteHeader = [
        {dataField: 'ID', text: 'Action', formatter: handleRouteAction, editable: false},
        {dataField: 'vehicleName', editable:false, text: 'Vehicle', formatter:(cell, row)=><div>{row.startPoint+" TO "+row.endPoint}</div>},
        
          {dataField: 'pickDrop', text: 'Pick & Drop', sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Pick & Drop Location is required'
              };
            }
            return true;
          } },
          {dataField: 'remarks', text: 'Remarks', sort: true} 
     ];


     const handleSubmit = event =>{
        event.preventDefault();
    setErrors(ValidateInput(destination));
        setIsSubmitting(true);
    } 


        const ValidateInput=(values)=>{
            let errors = {};   
           
           
    
            if(values.routeCode.length===0){
                errors.routeCode ='Please select route';
            }

            if(!values.pickDrop){
                errors.pickDrop ='Please enter pick and drop location';
            }


            return errors;
            }


            useEffect(()=>{
                if(Object.keys(errors).length === 0 && isSubmitting){
                submit()
                }
                },[errors])


function submit(){  
   
    const fd = new FormData(); 
    let formData = destination; 
   for(let k in formData){
       fd.append(k,formData[k]);
   }; 
 setNotice({...notice,  isLoading: true}) 
fd.append('jwt', Token);
fd.append('route', destination.routeCode.value);
    let url = ServerUrl+'/save_controller/tbl_vehicle_destination'
   axios.post(url, fd, config)
   .then(response =>{
     if(response.data.type ==='success') {
        Alerts('Saved!', 'success', response.data.message)
    }  else{
        Alerts('Error!', 'danger', JSON.stringify(response.data))
        }      
           
   }).catch((error)=>{
     Alerts('Error!', 'danger', JSON.stringify(error.message)) 
 }).finally(()=>{
    setNotice({...notice,  isLoading: false}) 
    fetchAllDestination()
     setIsSubmitting(false);
     setDestination({
        code:Math.random().toString(36).substr(2,9),
        routeCode:'',
        pickDrop:'',
        frequency:'',
        amount:'',
        remarks:''
    })
 })
   
}



useEffect(()=>{
    setIsAuthenticated(menuLink.includes(location.pathname))
    fetchAllDestination()
  fetchAllRoute()
},[]);


     


        return (    <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Transport Destination">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-car"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Transport</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Create Destination</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
							
                               
                               <div className="col-sm-5">
					

                            <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Add Destination</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<div className="row">
									
        <section className="col-md-12">
                    
                    <div className="form-group">
    <label >Select Route<span style={{color:'red'}}>*</span></label>

    <div className="form-group">
      <Select  options={
                            allRoute&&allRoute.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.vehicleName, startPoint:list.startPoint, endPoint:list.endPoint  }
                             })} 
                        getOptionLabel={option =>`(${option.label}) ${option.startPoint+" TO "+option.endPoint}`}
onChange={handleSelect} className={errors.routeCode ? 'form-control form-control-danger' : ''} name="routeCode" value={destination.routeCode}  /> <span style={{color:'red'}}>{errors.routeCode && errors.routeCode}</span>

          </div>
</div>
</section>

<section className="col-md-12">
<div className="form-group">
						<label >Pick Up & Drop Location <span style={{color:'red'}}>*</span></label>
				<input type="text" name="pickDrop" value={destination.pickDrop} onChange={handleChange} className={errors.pickDrop  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.pickDrop && errors.pickDrop}</span>	
			</div>

            </section>




			<section className="col-md-12">
                                    
            
            <div className="form-group">
						<label >Remark</label>
				<input type="text" name="remarks" value={destination.remarks} onChange={handleChange} className='form-control' />
			</div>
										</section>
										</div>	

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary ">Save Destination</button>
	
								</footer>				
							</div>
							</div>


                            </div>
		
	

	<div className="col-sm-7">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Listing All Destination</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12">
        <div className="card z-depth-0">
            <div className="card-block">
        <div className="table-responsive">
        {notice.isDataFetching ? <TableLoader />:
   <TableRecord data={allDestination}  columns={tableRouteHeader}  />} 
</div>
	</div>
    </div></div></div>
</div>


</div>

</div>
	

   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(CreateDestination) 