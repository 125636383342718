import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, ImagesUrl, Currency, FormatNumber, allowDelete, allowEdit, menuLink} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import {Type} from 'react-bootstrap-table2-editor';
import Select from 'react-select'
import { userToken } from '../component/authentication'
import { useDispatch, useSelector } from 'react-redux'

const Hostel =()=> {
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [isSubmittingRoom, setIsSubmittingRoom] = useState(false);
    const [errorRoom, setErrorRoom] = useState({})
const [errors, setErrors] = useState({})
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})

let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
const [hostelDetails, setHostelDetails] = useState({
    hostelName:'',
    location:'',
    hostelAdmin:'',
    image:'',
    status:'Active',
    hostelCode:'',
    roomName:'',
    noBedSpace:'',
    roomRate:'',


})
const [allHostel, setAllHostel]= useState([]) 
const staff = useSelector(state => state.rootReducer.staff);
const [image, setImage] = useState('')
const [rooms, setRooms]= useState([]) 



const fetchRoom =()=>{
        
    let sql = "Select r.ID, r.code, r.roomName, r.roomRate, r.roomStatus,  r.noBedSpace, h.hostelName from tbl_hostel_rooms r, tbl_hostel h where r.hostelCode =  h.code order by r.hostelCode"

    const fd = new FormData();
    fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>setRooms(result.data))
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    })
    }

    const fetchHostel =()=>{
        
        let sql = "Select  h.ID, h.code, h.hostelName, h.location, h.hostelAdmin, h.imageUrl, (select count(*) from tbl_hostel_rooms r where h.code = r.hostelCode) as totalRoom,  h.status from tbl_hostel h"

        const fd = new FormData();
        fd.append('jwt', Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>setAllHostel(result.data))
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        })
        }


        const handleSelect = (option, action)=>{
            setHostelDetails({...hostelDetails, [action.name]: option});
            setErrors({...errors, [action.name]:''})
            setErrorRoom({...errorRoom, [action.name]:''})
             }

            
             const handleChange = (event)=>{
                   const {name, value} = event.target
            setHostelDetails({...hostelDetails, [name]: value});    
             setErrors({...errors, [name]:''});
             setErrorRoom({...errorRoom, [name]:''})
            }



    const handleSubmit = event =>{
        event.preventDefault();
    setErrors(ValidateInput(hostelDetails));
    setIsSubmitting(true);
    } 

    const ValidateInput=(values)=>{
        let errors = {};   
       
        if(!values.hostelName){
            errors.hostelName ='Please enter hostel Name';
        }

        if(!Array.isArray(values.hostelAdmin)){
            errors.hostelAdmin ='Please hostel administrator';
          
        }

        return errors;
        }
    


const  submit=()=>{  
    setNotice({...notice,   isLoading: true})

    var options = hostelDetails.hostelAdmin;
    var value = [];
    for (var i = 0, l = (options || []).length; i < l; i++) {
      value.push({code:options[i].value});
    }

       const fd = new FormData(); 
       fd.append("jwt", Token);
      fd.append("hostelAdmin", JSON.stringify(value, null, 2))
      fd.append("hostelName", hostelDetails.hostelName)
      fd.append("location", hostelDetails.location)
      fd.append("code", Math.random().toString(36).substr(2,9))
      
        if(image!==''){
            fd.append('image', image);
        }
         
             let url = ServerUrl+'/save_controller/tbl_hostel'
            axios.post(url, fd, config)
            .then(response =>{

            if (response.data.type ==='success'){
                setErrors({...errors, successMessage: response.data.message})
                
            } else{
                setErrors({...errors, errorMessage: JSON.stringify(response.data)})
            
                }  
                         
                 })
                 .catch((error)=>{
                    setErrors({...errors, errorMessage: error.message})
              })
              .finally(()=>{
                setImage('');
                document.getElementById('image').value= '';
                setIsSubmitting(false);
                fetchHostel()
                setNotice({...notice,  isLoading: false}) 
                setHostelDetails({...hostelDetails,
                    hostelName:'',
                    location:'',
                    hostelAdmin:''
                })
              })
    } 


    const handleSubmitRoom = event =>{
        event.preventDefault();
        setErrorRoom(ValidateInputRoom(hostelDetails));
        setIsSubmittingRoom(true);
    } 



    const ValidateInputRoom=(values)=>{
        let errors = {};   
       
        if(!values.roomName){
            errors.roomName ='Please enter Room Name';
        }

        if(Number(values.noBedSpace)<1){
            errors.noBedSpace ='Please enter total bed space in this room';
        }
        if(Number(values.roomRate)<1){
            errors.roomRate ='Please enter room rate';
        }
        if(values.hostelCode.length===0){
            errors.hostelCode ='Please select hostel';
          
        }

        return errors;
        }



        const  submitRoom=()=>{  
            setNotice({...notice,   isLoading: true})
        
            var options = hostelDetails.hostelAdmin;
            var value = [];
            for (var i = 0, l = (options || []).length; i < l; i++) {
              value.push({code:options[i].value});
            }

        
               const fd = new FormData(); 
               fd.append("jwt", Token);
               
              fd.append("code", Math.random().toString(36).substr(2,9))
              fd.append("hostelCode", hostelDetails.hostelCode.value)

              fd.append("roomRate", hostelDetails.roomRate)
              fd.append("roomName", hostelDetails.roomName)
              fd.append("noBedSpace", hostelDetails.noBedSpace)
              
                     let url = ServerUrl+'/save_controller/tbl_hostel_rooms'
                    axios.post(url, fd, config)
                    .then(response =>{
        
                    if (response.data.type ==='success'){
                        setErrors({...errors, successMessage: response.data.message})
                        window.scrollTo(0,0)
                    } else{
                        setErrors({...errors, errorMessage: JSON.stringify(response.data)})
                        window.scrollTo(0,0)
                        }  
                                 
                         })
                         .catch((error)=>{
                            setErrors({...errors, errorMessage: error.message})
                            window.scrollTo(0,0)
                      })
                      .finally(()=>{
                        fetchRoom()
                        fetchHostel()
                        setIsSubmittingRoom(false);
                        setNotice({...notice,  isLoading: false}) 
                        setHostelDetails({...hostelDetails,
                            hostelCode:'',
                            roomName:'',
                            noBedSpace:'',
                            roomRate:'',
                        })
                      })
            } 



    const handleDelete = (code, table)=>{  
        setNotice({...notice,  isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+code)
              close.click();
           const fd = new FormData();
           fd.append('ID', 'code')
            fd.append('data', code)
            fd.append("jwt", Token);
            let url = ServerUrl+'/delete_controller/'+table;
           axios.post(url, fd, config)
           .then(response =>{
            if (response.data.type ==='success'){
                setErrors({...errors, successMessage: response.data.message})
                
            } else{
                setErrors({...errors, errorMessage: JSON.stringify(response.data)})
            
                }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
              fetchHostel()
              fetchRoom()
               setNotice({...notice,  isLoading: false })
           }) 
    }




    const TableRoomRecord=(props)=>{

        const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
          }) => ( 
          <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
              {
                columns
                  .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                  .map(column => (
                    
                    <button
                      type="button"
                      key={ column.dataField }
                      className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                      data-toggle="button"
                      aria-pressed={ column.toggle ? 'true' : 'false' }
                      onClick={ () => onColumnToggle(column.dataField) }
                    >
                      { column.text }
                    </button>
                  ))
              }
            </div>
            
          );
    
        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
            fd.append('jwt', Token);
             fd.append('columnField', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
           
            let url = ServerUrl+'/updateById_controller/tbl_hostel_room'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>console.log(error)) 
           
         } 
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                
    
      <div className="dt-buttons btn-group pull-right"> 
    
          
      <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'50px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>
    
    
    <div className="btn-group dropdown-split-primary ">
        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
    
    
                <form method='POST' action={ServerUrl+'/excel_controller.php?api=hostel'} target='_blank'>
                    <input type='hidden' name='jwt' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>
            
            </form>
    
            <form method='POST' action={ServerUrl+'/printout/hostel.php?token='+userToken} target='_blank'>
    
                    <input type='hidden' name='jwtToken' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
            
            </form>
        </div>
    </div> 
    </div> 
        </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    
    var i =1;
    const tableRoomHeader = [
        {dataField: 'hostelName', text: 'SN', editable:false, formatter:(cell)=>i++},
                {dataField: 'hostelName', text: 'Hostel', editable:false},
                {dataField: 'roomName', text: 'Room'},  
                {dataField: 'roomRate', text: 'Room Rate', formatter:(cell)=>Currency+FormatNumber(cell)}, 
                {dataField: 'noBedSpace', text: 'Bed Space'},    
                {dataField: 'roomStatus', text: 'Status', formatter: (cell, row)=>row.roomStatus==='Active'? <span className="pcoded-badge label label-success">ACTIVE</span>: <span className="pcoded-badge label label-danger">INACTIVE</span>, editor: {
                    type: Type.SELECT, options:[
                        {value:'Active', label:'Active'},
                    {value:'Inactive', label:'Inactive'}]
                  }},
            
            
        {dataField: 'code', text: 'Action',hidden:true, formatter: (cell, row)=><div>
        
        <button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${cell}`}     title="Delete"><i className="icofont icofont-delete-alt"></i></button>
        &nbsp;&nbsp;
        
        <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(cell, 'tbl_hostel_room')} mID={cell} /> 
        </div>, editable: false}
         ]


const handleStaffName=(cell)=>{
    return JSON.parse(cell).map((itm, idx)=>{
        return <div  key={idx}>{getStaff(itm.code)}</div>
    } );
}

const getStaff = (code) =>{   
     const result = staff&&staff.filter(list=>list.code===code);
    const answer = result.length!==0?result.map(c=>`(${c.mobile}) ${c.staffName}` ):''
      return  answer;
    }


const tableHeader = [
{dataField: 'imageUrl', text: 'Cover', editable: false, hidden:true, formatter:(cell, row)=><img className="img-70" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/no.jpg"}} src={ImagesUrl+'/hostel/'+cell} title={row.bookName}   />},
        {dataField: 'hostelName', text: 'Hostel'},
        {dataField: 'location', text: 'Location'}, 
        {dataField: 'totalRoom', text: 'Total Rooms'},       
        {dataField: 'status', text: 'Status', formatter: (cell, row)=>row.status==='Active'? <span className="pcoded-badge label label-success">ACTIVE</span>: <span className="pcoded-badge label label-danger">INACTIVE</span>, editor: {
            type: Type.SELECT, options:[
                {value:'Active', label:'Active'},
            {value:'Inactive', label:'Inactive'}]
          }},
    {dataField: 'hostelAdmin', text: 'Admin', hidden:true, editable: false, formatter:(cell)=>handleStaffName(cell)}, 
    
{dataField: 'code', text: 'Action',hidden:true, formatter: (cell, row)=><div>

<button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${cell}`}     title="Delete"><i className="icofont icofont-delete-alt"></i></button>
&nbsp;&nbsp;

<WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(cell, 'tbl_hostel')} mID={cell} /> 
</div>, editable: false}
 ]
 



 const TableRecord=(props)=>{

    const CustomToggleList = ({
        columns,
        onColumnToggle,
        toggles
      }) => ( 
      <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
          {
            columns
              .map(column => ({
                ...column,
                toggle: toggles[column.dataField]
              }))
              .map(column => (
                
                <button
                  type="button"
                  key={ column.dataField }
                  className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                  data-toggle="button"
                  aria-pressed={ column.toggle ? 'true' : 'false' }
                  onClick={ () => onColumnToggle(column.dataField) }
                >
                  { column.text }
                </button>
              ))
          }
        </div>
        
      );

    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData();
        fd.append('jwt', Token);
         fd.append('columnField', newValue);
         fd.append('column', column.dataField);
         fd.append('ID', ID);
       
        let url = ServerUrl+'/updateById_controller/tbl_hostel'
       axios.post(url, fd, config)
       //.then(result => console.log(result.data))
       .then()
       .catch((error)=>console.log(error)) 
       
     } 
     const { SearchBar } = Search;
 
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
      );
     const options = {
         showTotal: true,
     paginationTotalRenderer: customTotal,
     sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
     { text: 'All', value: props.data.length }]
        
       };
  
     return <ToolkitProvider search columnToggle 
                 keyField='ID' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             &nbsp;
                           
                            

  <div className="dt-buttons btn-group pull-right"> 

  	
  <div className="btn-group dropdown-split-success">
  
  <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  <i className="fa fa-search-plus"></i> Columns
  </button>
  
  <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'50px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
    <CustomToggleList { ...props.columnToggleProps } />
  </div>
  </div>


</div> 
    </div>
                             
                      
             
                             <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                   mode: 'dbclick',
                                   blurToSave: true,
                                   autoSelectText: true,
                         beforeSaveCell: (oldValue, newValue, row, column) => {
                           if(oldValue !==newValue){
                           handleUpdate(column, newValue, row.ID);
                           
                          return '';
                         }
                         
                       }
                                 }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }


  const  handleFile = function(fieldName){
    return function(newValue){  
      
   readURL(newValue);
   }
   
} 

const readURL = (input)=>{
   
    let displayMessage = '';
    const PreviewImage = input.target.name;
      //  let newImage = "viewPassport";
        var fileName = document.getElementById(PreviewImage).value;

    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="gif" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="GIF"){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 1) {
        displayMessage = input.target.name +': File uploaded is more than maximum size Allow. it exceeds 1 Mb';
       document.getElementById(input.target.name).value = '';
      
       setImage('')
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
            setImage(input.target.files[0])
            
        }
    }
    }else{

        displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 900kb'
        document.getElementById(input.target.name).value = '';
       
        setImage('')
    }   
    
    if(displayMessage.length > 0){
        setErrors({...errors, errorMessage: displayMessage})
        
      }
    
} 


 useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])

    
 useEffect(()=>{
    if(Object.keys(errorRoom).length === 0 && isSubmittingRoom){
    submitRoom()
    }
    },[errorRoom])


useEffect(()=>{
  setIsAuthenticated(menuLink.includes(location.pathname))
    fetchHostel()
    fetchRoom()
},[]);



        return (   <>
          <div  className="main-body">
          {isAuthenticated?   <div className="page-wrapper">
                        {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Hostel Details">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-bed"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!" >Hostel</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              

                {errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}



                <div  className="row  ">
							
                               
                 <div className="col-sm-5">
					

                            <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Add New Hostel</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
	
         	<div  className="row">
             <section className="col-md-12">
										<div className="form-group">
						<label >Hostel Name<span style={{color:'red'}}>*</span></label>
		<input type="text" name="hostelName" value={hostelDetails.hostelName} onChange={handleChange} className={errors.hostelName  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.hostelName && errors.hostelName}</span>	
			</div>
            </section>


            <section className="col-md-12">
										<div className="form-group">
						<label >Hostel Location</label>
		<input type="text" name="location" value={hostelDetails.location} onChange={handleChange} className='form-control' /> 	
			</div>
            </section>

						

            <section className="col-md-12">
                                    
                                    <div className="form-group">
                                    <label >Hostel Admin<span style={{color:'red'}}>*</span></label>
          <Select isMulti options={
                               staff&&staff.map((list, idx)=> {
                                   return {key:idx, value: list.code, label: list.staffName, phone:list.mobile  }
                                 })} 
                            getOptionLabel={option =>`(${option.phone}) ${option.label}`}
    onChange={handleSelect} className={errors.hostelAdmin ? 'form-control form-control-danger' : ''} name="hostelAdmin" value={hostelDetails.hostelAdmin}  /> <span style={{color:'red'}}>{errors.hostelAdmin && errors.hostelAdmin}</span>
    
              </div>
    
                </section>


            <section className="col-md-12">

            <div className="form-group">
                           <label >Hotel Image</label><br/>
                  <input id="image"  onChange={handleFile('image')} className='form-control' name="image" type="file" accept="image/*" /> <span style={{color:'red'}}>{errors.image && errors.image}</span>
          <div className="note" style={{color:'red'}}>
          <strong>Size 900 kb,</strong>gif|jpg|png|jpeg Only Allow
      </div>	   	
              </div>
					</section>
										
                                        	

	<footer className="col-md-12">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-success pull-left"><i className="fa fa-save"></i> Save</button>
	
		</footer>				
							</div>
							</div>


                            </div>
                            
                            </div>




  <div className="col-sm-7">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Listing All Hostel</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

         {notice.isDataFetching ? <TableLoader />:
         <TableRecord data={allHostel}  columns={tableHeader}  /> 
        } 

            </div></div>
    
    </div>
    
    </div>
    </div>






    <div  className="row  ">
							
                              <div className="col-sm-12">
					

                            <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Add New Room</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
	
         	<div  className="row">

             <section className="col-md-6">
                                    
                                    <div className="form-group">
                                    <label >Select Hostel<span style={{color:'red'}}>*</span></label>
          <Select options={
                               allHostel&&allHostel.map((list, idx)=> {
                                   return {key:idx, value: list.code, label: list.hostelName, location:list.location  }
                                 })} 
    onChange={handleSelect} className={errorRoom.hostelCode? 'form-control form-control-danger' : ''} name="hostelCode" value={hostelDetails.hostelCode}  /> <span style={{color:'red'}}>{errorRoom.hostelCode && errorRoom.hostelCode}</span>
    
              </div>
    
                </section>

             <section className="col-md-6">
										<div className="form-group">
						<label >Room Name<span style={{color:'red'}}>*</span></label>
		<input type="text" name="roomName" value={hostelDetails.roomName} onChange={handleChange} className={errorRoom.roomName  ? 'form-control form-control-danger' : 'form-control'} /> 
        <span style={{color:'red'}}>{errorRoom.roomName && errorRoom.roomName}</span>	
			</div>
            </section>


            <section className="col-md-6">
										<div className="form-group">
						<label >No of Bed Space <span style={{color:'red'}}>*</span></label>
		<input type="number" name="noBedSpace" min="1" value={hostelDetails.noBedSpace} pattern="\d*" onChange={handleChange} className={errorRoom.noBedSpace  ? 'form-control form-control-danger' : 'form-control'} /> 	
        <span style={{color:'red'}}>{errorRoom.noBedSpace && errorRoom.noBedSpace}</span>
			</div>
            </section>


            <section className="col-md-6">
										<div className="form-group">
						<label >Room Rate <span style={{color:'red'}}>*</span></label>
		<input type="number" name="roomRate" min="1" value={hostelDetails.roomRate} pattern="\d*" onChange={handleChange} className={errorRoom.roomRate ? 'form-control form-control-danger' : 'form-control'}/> 	
        <span style={{color:'red'}}>{errorRoom.roomRate && errorRoom.roomRate}</span>
			</div>
            </section>	



                </div>					
                                        	
                <div className="row">
	<footer className="col-sm-12">
	
	<button type="button" id="submit" onClick={handleSubmitRoom} className="btn btn-dark"><i className="fa fa-bed"></i> Add Room</button>
	
		</footer>		
        </div>	


							</div>
							</div>


                            </div>
                            
                            </div>
                           

                            <div className="row">
  <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Listing All Rooms</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

         {notice.isDataFetching ? <TableLoader />:
         <TableRoomRecord data={rooms}  columns={tableRoomHeader}  /> 
        } 

            </div></div>
    
    </div>
    
    </div>
    </div>



   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Hostel) 