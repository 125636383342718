import React, {useState, useEffect} from 'react'
import {LoadEffect}  from '../component/loader'
import PageHeader from '../component/pageheader'
import { Alerts } from '../component/notify'
import Select from 'react-select'
import {ImagesUrl, ServerUrl, Token, config, classID, sectionID} from '../../component/include'
import axios from 'axios'
import {  getClassField, getResultHead} from '../component/globalFunction'
import { userToken } from '../component/authentication'
import { useSelector } from 'react-redux'

const Documents =()=> {
  const [student, setStudent] = useState([]);
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
  
const schoolClass  = useSelector(state => state.rootReducer.schoolClass); 
const [allDocument, setAllDocument]= useState([]) 
const [profile, setProfile]=useState({
  student:[],
  fromClass:'',
  fromSection:'',
  isDisplay:false,
  studentCode:'',
});

const handleFetchStudent =()=>{
  if(sectionID !==undefined && sectionID !=='')
      setNotice({...notice,  isLoading: true})
      var sql ="Select studentName, code, admissionNumber, startFromClass, startFromSection  from tbl_students where sectionID ='"+sectionID+"'"; 
      const fd = new FormData(); 
      fd.append("jwt", Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>setStudent(result.data))
    .catch((error)=>{
      Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
      setNotice({...notice,  isLoading: false})
    })
}
       
const getLink =(code, link, studentCode, classArm)=>{

  const linkHandler ={
    defe: ImagesUrl+'/students/documents/'+link,
    de2fe:ImagesUrl+'/students/documents/'+link,
    de3fe:ImagesUrl+'/students/documents/'+link,
    de34e:ImagesUrl+'/students/documents/'+link,
    de54e:ImagesUrl+'/students/documents/'+link,
    de56e:'#!',
    de576e:ServerUrl+'/printout/admission_letter/'+classArm,
    gdtd:'#!',
    gdt9d:ServerUrl+'/printout/student_profile.php?studentCode='+studentCode+'&token='+userToken,
    gdt10d:'#!'
  }
  return linkHandler[code];
  }

  
const fetchSchoolDocument =(studentCode, classArm)=>{
  setNotice({...notice,  isLoading: true})
  const fd = new FormData(); 
      fd.append("jwt", Token);
  let url = ServerUrl + '/fetch_controller/tbl_school_documents'
  axios.post(url, fd, config)
  .then(response =>{
    
    const option  = response.data
    const list  = []
    for (var i = 0; i< option.length;  i++) {
      list.push(
          {  
              code:option[i].code,
              link:getLink(option[i].code, option[i].file_url, studentCode, classArm),
              file_url:option[i].file_url,
             type:option[i].document_type,
              order:option[i].order_by,
          });
          }
    setAllDocument(list)
  }).finally(()=>{
    setNotice({...notice,  isLoading: false})
  })
}

              const background = ['bg-simple-c-blue', 'bg-simple-c-green', 'bg-simple-c-pink', 'bg-simple-c-yellow', 'bg-c-blue', 'bg-c-green', 'bg-c-pink', 'bg-c-yellow', 'bg-simple-c-blue', 'bg-simple-c-green', 'bg-simple-c-pink', 'bg-simple-c-yellow', 'bg-c-blue', 'bg-c-green', 'bg-c-pink', 'bg-c-yellow'];
             

              const handleSelect = (option, action)=>{
               
               const classtype = String(getClassField(option.startFromClass, schoolClass, 'arms'))

                const head = getResultHead(classtype)!=='Secondary'?'Primary':'Secondary'

                setProfile({...profile, [action.name]: option, classArm:head, isDisplay:true, studentCode:option.value});
                fetchSchoolDocument(option.value, head)
                
             }


   useEffect(()=>{
    handleFetchStudent()
},[]);

        return (  
            
   <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
                <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title='Student Documents'>
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-dashboard"></i> </a>
                            </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">



                
                <div className="row  no-print">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Select Student</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
        <div className="row">
        <section className="col-md-12">
    
        <div className="form-group">
        
        <label >Select Student</label>
        <Select  options={
          student&&student.map((list, idx)=> {
                  return {key:idx, value: list.code, label:list.studentName, admissionNumber:list.admissionNumber, startFromClass:list.startFromClass}
                })
                     }    
                    getOptionLabel={option =>option.label +' '+ option.admissionNumber}    
        onChange={handleSelect}  name="student" value={profile.student}  />   
                    </div>
			</section>

									</div>
										
             
							</div>
							</div>

							</div>
                </div>




                {profile.isDisplay&&<div className="row">
        <div className="col-12">
          <div className="card">
		   <div className="card-header">
            <strong> DOCUMENT LIST</strong>
				</div>
             <div className="card-body">
           

<div className="row">
{allDocument.map((item, index)=><div className="col-xl-6 col-md-12" key={index}>
        <form  method='POST' action={item.link} target='_blank' >

        <input type='hidden' name='studentCode' value ={profile.studentCode} />

        <input type='hidden' name='jwtToken' value ={Token} />
        <input type='hidden' name='classArm' value ={profile.classArm} />


              <div className= {`card social-card ${background[index]}`}>
                  <div className="card-block">
                      <div className="row align-items-center">
                          <div className="col-auto">
                              <i className={`feather icon-file-text f-34 social-icon text-c-blue`}></i>
                          </div>
                          <div className="col">
                              <h4 className="m-b-0">{item.type}</h4>    
                      <button type='submit' className={`btn btn-inverse btn-sm btn-round mt-1 `}><i className="feather icon-arrow-down"></i> Preview</button>
                          </div>
                      </div>
                  </div>
                  {/* <a href={item.link} className="download-icon" ><i className="feather icon-arrow-down"></i></a> */}
              </div>
              </form>
          </div>)}


          </div>



             </div>
          </div>
        </div></div>}






   </div>
          {/* The Page Body close here */}

            </div>
            </div>

</> 
 );
        
}

export default React.memo(Documents) 