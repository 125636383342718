import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config, allowDelete, allowEdit, menuLink} from '../component/include'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import {Type} from 'react-bootstrap-table2-editor';
import { useDispatch, useSelector } from 'react-redux'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { getTime } from '../component/globalFunction'

const ConferenceLog =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    
    const [conference, setConference] = useState({
        conferenceName:'',
        conferenceDate:'',
        startTime:'',
        endTime:'',
        intervalMins:''
    })
    
    const [allconference, setAllconference]= useState([])
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    const handleDelete = (ID)=>{  
        setNotice({...notice,  isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+ID)
              close.click();
           const fd = new FormData();
           fd.append('ID', 'ID')
            fd.append('data', ID)
            fd.append("jwt", Token);
            let url = ServerUrl+'/delete_controller/tbl_conference';
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
              fetchconference()
               setNotice({...notice, 
                   isLoading: false
               })
           }) 
    }

  

    const tableHeader = [

        {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
             <a href="#!" data-toggle="modal" className='btn btn-danger btn-sm'  data-target={`#warningdialog-${cell}`} title="Delete"><i className="icofont icofont-delete-alt"></i> </a> &nbsp;

<WarningModal message="Are you really sure, you want to this?" handleAction={()=>handleDelete(row.ID)} mID={cell} />
        </div>, editable: false,  hidden:allowDelete==='No'?true:false},
        {dataField: 'conferenceName', text: 'Conference', editable: false },
        {dataField: 'familyName', text: 'Parent', editable: false },
        {dataField: 'staffName', text: 'To See', editable: false },
        {dataField: 'visitTime', text: 'Time of Visit', editable: false },
        {dataField: 'purpose', text: 'Purpose', editable: false },
        {dataField: 'dateCreate', text: 'Date Posted', editable: false},
          {dataField: 'status', text: 'status', formatter: (cell)=>cell==='Confirmed'? <span className="pcoded-badge label label-success">{cell}</span>: <span className="pcoded-badge label label-danger">{cell}</span>},
     ];

  
       
   

const fetchconference =()=>{
    setNotice({...notice,  isLoading: true})

    var sql ="Select c.ID, c.code, c.purpose, c.visitTime, c.dateCreate, c.status, s.staffName, cf.conferenceName, f.familyName  from tbl_family f, tbl_conference_log c, tbl_staff s, tbl_conference cf where c.staffCode = s.code and cf.code = c.conferenceCode and f.code = c.parentCode order by c.ID DESC "; 

const fd = new FormData();
        fd.append('jwt', Token); 
fd.append("sql", sql);
let url = ServerUrl+'/fetchBySql_controller'
axios.post(url, fd, config).then(result=>{

if(result.data.length>0){
    setAllconference(result.data)
}
  

})
.catch((error)=>{
    Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
})
}




const BasicTable=(props)=>{

    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData(); 
        fd.append('newValue', newValue);
        fd.append('column', column.dataField);
        fd.append('ID', ID);
        fd.append("jwt", Token);
       let url = ServerUrl+'/updateCustom_controller/tbl_conference'
      axios.post(url, fd, config)
       .then()
       .catch((error)=>alert(error)) 
       
     } 
  
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } entries</span>
      );
      
const { SearchBar } = Search;
     const options = {
         showTotal: false,
     paginationTotalRenderer: customTotal,
     sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
     { text: 'All', value: props.data.length }]
        
       };
  
     return <React.Fragment>  
    
             <ToolkitProvider search  className="table-responsive"
                 keyField='code' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           
                             <SearchBar
                             { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
                             
                             <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                   mode: 'dbclick',
                                   blurToSave: true,
                                   autoSelectText: true,
                         beforeSaveCell: (oldValue, newValue, row, column) => {
                           if(oldValue !==newValue){
                           handleUpdate(column, newValue, row.ID);
                           
                          return props.fetchdata;
                         }
                         
                       }
                                 }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  </React.Fragment>
  }

  
useEffect(()=>{
    setIsAuthenticated(menuLink.includes(location.pathname))
    fetchconference();
},[]);
    
        return (  <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Conference Report">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Staff</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Conference Log</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
	
	<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Conference Staff</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">


		<div className="col-md-12 table-responsive">
        
        {notice.isDataFetching ? <TableLoader />:
   <BasicTable data={allconference}   columns={tableHeader}  />} 
</div>
	</div>
    </div>
</div>

</div>
	
   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(ConferenceLog) 