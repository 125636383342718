import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import PageHeader from '../component/pageheader'
import { LoadEffect } from '../component/loader'
import QuillText from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {Alerts} from '../component/notify'
import {ServerUrl, Token, config, allowDelete, allowEdit, menuLink} from '../component/include'
import axios from 'axios'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { FormLabel } from '../component/formControl'
 const Payment =()=> {
	 let myRef = useRef()
	const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
	});
	
	
let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);

const [onlinePayment, setOnlinePayment] = useState([])
const [bank, setBank] = useState([])

const fetchContent =()=>{
	setNotice({...notice, 
		isLoading: true
	});

	
	var sql ="Select ID, title, details, status, live_key, test_key from tbl_payment_gateway";    
	const fd = new FormData();
        fd.append('jwt', Token);
	fd.append("sql", sql);
	let url = ServerUrl+'/fetchBySql_controller'
	
	axios.post(url, fd, config).then(result=>{ 
		if(result.data.length!==0){
			setBank(result.data)
		}else{
			setBank([])
		}
		
	})
	.catch((error)=>{
		Alerts('Error!', 'danger', error.message)
	}).finally(()=>{
		setNotice({...notice, 
			isLoading: false
		});
	}) 

}


	const handleChangeInstruction =(data)=>{

		const newBank = bank.map((item)=>{
                   
			if(item.title ==='Bank Payment'){

				return {...item, 
					details:data
				}
			}

			 return item
			  })
			  setBank(newBank)  
	}
  



	const handleChangeOnline =(event, ID)=>{


		let {name, value} = event.target;
		if(name==='status'){
			value = value==='Active'?'Inactive':'Active'
		}
		const newBank = bank.map((item)=>{
                   
			if(item.ID ===ID){

				return {...item, 
					[name]:value
				}
			}

			 return item
			  })

			  setBank(newBank)
	}
	


	function updatePayment(formData){  
		setNotice({...notice, 
		  isLoading: true})     
		  
		  const fd = new FormData();
        fd.append('jwt', Token);
		  for(let k in formData){
			fd.append(k, formData[k])
		  }
		  let url = ServerUrl+'/update_controller/tbl_payment_gateway'  
			axios.post(url, fd, config)
			.then(response =>{
			 if(response.data.type ==='info'){
				
				Alerts('Saved!', 'default', response.data.message)
					} else{
				Alerts('Error!', 'danger', JSON.stringify(response.data))
					}   
			})
			.catch((error)=>{
			  Alerts('Error!', 'danger', error.message)
			}).finally(()=>{
				fetchContent()
				setNotice({...notice, 
					isLoading: false
				})
			}) 
	}


	
	
	useEffect(()=>{
		
		setIsAuthenticated(menuLink.includes(location.pathname)) 
        fetchContent()
       },[]);

        return (<>
    
			<div  className="main-body">
			{isAuthenticated?   <div className="page-wrapper">
						  {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
       <PageHeader title="Payment Gateway"
	   				menu="Payment Gateway"
					   />


{/* The Page Body start here */}
<div className="page-body">
		
			
<div className="row no-print ">
							

{bank.length!==0?bank.map((item, id)=>item.title==='Bank Payment'?
				<div className="col-sm-12" key={id}>
			
				<div className="card z-depth-0">
					<div className="card-header">
						<h5><i className="fa fa-edit" ></i> {item.title}</h5>
						<div className="card-header-right">
							<ul className="list-unstyled card-option">
								<li><i className="feather icon-maximize full-card"></i></li>
								<li><i className="feather icon-minus minimize-card"></i></li>
								<li><i className="feather icon-trash-2 close-card"></i></li>
							</ul>
						</div>
					</div><div className="card-block">
					<div className="card z-depth-0">
					<div className="card-block">
					
					<div className="row">
			
							  
					<section className="col-md-12">
								<div className="form-group">
						<label> Payment Instruction cannot be change<span style={{color:'red'}}>*</span></label>
						 <QuillText value={item.details} onChange={handleChangeInstruction}   />  
						</div>
					
					</section> 
					
					
							</div>
			
							<div className="form-group row">
			<div className="col-md-2">
					<FormLabel name ="Active" toolTip={'Indicating Weather the gateway is activate'}  />
			</div>
							
				<div className="col-md-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={item.status} name="status" checked={item.status==='Active'?true:false} onChange={(e)=>handleChangeOnline(e, item.ID)}  />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			
					</div>
					
			
				<footer className="pull-right">
				
				<button type="button"  onClick={()=>updatePayment(item)}  className="btn btn-inverse "><i className="fa fa-save"></i> Save</button>
											</footer>				
										</div>
										</div>
										</div></div>
			</div>:

			
							<div className="col-sm-12">
						
							<div className="card z-depth-0">
								<div className="card-header">
									<h5><i className="fa fa-edit" ></i> {item.title}</h5>
									<div className="card-header-right">
										<ul className="list-unstyled card-option">
											<li><i className="feather icon-maximize full-card"></i></li>
											<li><i className="feather icon-minus minimize-card"></i></li>
											<li><i className="feather icon-trash-2 close-card"></i></li>
										</ul>
									</div>
								</div><div className="card-block">
								<div className="card z-depth-0">
								<div className="card-block">
								
						
											<section className="col-md-12">
											<div className="form-group">
									<label> Public Live Key<span style={{color:'red'}}>*</span></label>
									<input type="text" disabled={item.status==='Active'?true:false} onChange={(e)=>handleChangeOnline(e, item.ID)}   name="live_key" value={item.live_key} className='form-control ' />
									</div>
								
								</section>   
								<section className="col-md-12">
											<div className="form-group">
									<label> Public Test Key<span style={{color:'red'}}>*</span></label>
									<input type="text" disabled={item.status==='Active'?true:false} onChange={(e)=>handleChangeOnline(e, item.ID)}  name="test_key" value={item.test_key} className='form-control ' />
									</div>
								
								</section> 
									

								
								<div className="form-group row">
			<div className="col-md-2">
					<FormLabel name ="Active" toolTip={'Indicating Weather the gateway is activate'}  />
			</div>
							
				<div className="col-md-4 ">
				<div className="checkbox-fade fade-in-success">
				<label>
			<input type="checkbox" value={item.status} name="status" checked={item.status==='Active'?true:false} onChange={(e)=>handleChangeOnline(e, item.ID)}  />
					<span className="cr"><i className="cr-icon icofont icofont-ui-check txt-success"></i></span>
				</label>
			</div>
					</div>
			
					</div>
							
						
						
							<footer className="pull-right">
							
							<button type="button" id="submit" onClick={()=>updatePayment(item)} className="btn btn-inverse "><i className="fa fa-save"></i> Save</button>
														</footer>				
													</div>
													</div>
													</div></div>
						</div>):[]}
			</div>
			


                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 

 );
        
}

export default React.memo(Payment) 