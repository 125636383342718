import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, ImagesUrl, Currency, FormatNumber, selectStyles, allowDelete, allowEdit, menuLink} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import {Type} from 'react-bootstrap-table2-editor';
import Select from 'react-select'
import { userToken } from '../component/authentication'
import { useDispatch, useSelector } from 'react-redux'
import { longDate } from '../component/globalFunction'

const RoomOccupancy =()=> {
    
    const [isSubmitting, setIsSubmitting] = useState(false);
const [errors, setErrors] = useState({})
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})


let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
const [search, setSearch] = useState({
    hostelCode:'',
    schoolSession:''

})

const schoolSession = useSelector(state =>state.rootReducer.schoolSession);
const hostel = useSelector(state => state.rootReducer.hostel);
const [rooms, setRooms]= useState([]) 



const handleSelect = (option, action)=>{
    setSearch({...search, [action.name]: option});
    setErrors({...errors, [action.name]:''})
     }


const fetchRoom =()=>{
    setNotice({...notice,  isDataFetching: true })
    let sql = "Select a.ID, r.roomName, s.studentName, s.admissionNumber, a.currentSession, a.amountPaid, a.allocationDate, a.vacateDate, a.status from tbl_hostel_allocation a, tbl_students s, tbl_hostel_rooms r where a.roomCode = r.code and  s.code = a.studentCode and  a.isApproved = 'true' and a.currentSession = '"+search.schoolSession.value+"' and a.hostelCode =  '"+search.hostelCode.value+"' order by r.code"

    const fd = new FormData();
    fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>setRooms(result.data))
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice,  isDataFetching: false })
    }) 
    }





    const handleSearch = event =>{
        event.preventDefault();
    setErrors(ValidateInput(search));
    setIsSubmitting(true);
    } 



    const ValidateInput=(values)=>{
        let errors = {};   
       
        if(values.hostelCode.length===0){
            errors.hostelCode ='Please select hostel';
          
        }
        if(values.schoolSession.length===0){
            errors.schoolSession ='Please select session';
          
        }

        return errors;
        }
    





    const TableRoomRecord=(props)=>{

        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
            fd.append('jwt', Token);
             fd.append('columnField', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
           
            let url = ServerUrl+'/updateById_controller/tbl_hostel_allocation'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>console.log(error)) 
           
         } 
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                
    
      <div className="dt-buttons btn-group pull-right"> 
    
    
    
    <div className="btn-group dropdown-split-primary ">
        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
    
    
                <form method='POST' action={ServerUrl+'/excel_controller.php?api=hostel'} target='_blank'>
                    <input type='hidden' name='jwt' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>
            
            </form>
    
            <form method='POST' action={ServerUrl+'/printout/hostel.php?token='+userToken} target='_blank'>
    
                    <input type='hidden' name='jwtToken' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
            
            </form>
        </div>
    </div> 
    </div> 
        </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }

    
    const tableRoomHeader = [
                {dataField: 'roomName', text: 'Room', editable:false},   
                {dataField: 'studentName', text: 'Student', editable:false, formatter:(cell,row)=>row.admissionNumber+" -"+row.studentName}, 
            {dataField: 'allocationDate', text: 'Allocation Date', editable:false, formatter:(cell)=>longDate(cell)},  
                {dataField: 'vacateDate', text: 'Vacation Date', editable:false, formatter:(cell)=>longDate(cell)},
                {dataField: 'status', text: 'status', editable:false},
                {dataField: 'ID', text: 'Action', editable:false, formatter:(cell)=><button className="btn btn-info btn-sm">
                    <i className="fa fa-print"></i> Print
                </button>}
         ]



 useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    fetchRoom()
    }
    },[errors])




    useEffect(()=>{
        setIsAuthenticated(menuLink.includes(location.pathname))
    }, [])
        return (   <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Room Occupancy Report">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-bed"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!" >Hostel</a> </li>
                            <li className="breadcrumb-item"><a href="#!" >Rooms Occupancy</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              

                {errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}



    <div  className="row  ">
							
                              <div className="col-sm-12">
					

                            <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Search Hostel</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
	
         	<div  className="row">


             <section className="col-md-6">
                                            <div className="form-group">
						<label > Session <span style={{color:'red'}}>*</span></label> 
						<Select  options={schoolSession.map((dat, id)=> {
                                    return {key:id, value:dat.startYear, label:dat.startYear};
                                  })}
                        onChange={handleSelect} className={errors.schoolSession? 'form-control form-control-danger' : ''} name="schoolSession" value={search.schoolSession}  styles={selectStyles} /> 
                        <span style={{color:'red'}}>{errors.schoolSession && errors.schoolSession}</span>
											</div>
                       </section>


             <section className="col-md-6">
                                    
                                    <div className="form-group">
                                    <label >Select Hostel <span style={{color:'red'}}>*</span></label>
          <Select options={
                               hostel&&hostel.map((list, idx)=> {
                                   return {key:idx, value: list.code, label: list.hostelName, location:list.location  }
                                 })} 
                            getOptionLabel={option =>`${option.label} ${option.location}`}
    onChange={handleSelect} className={errors.hostelCode? 'form-control form-control-danger' : ''} name="hostelCode" value={search.hostelCode}  /> <span style={{color:'red'}}>{errors.hostelCode && errors.hostelCode}</span>
    
              </div>
    
                </section>





                </div>					
                                        	
                <div className="row">
	<footer className="col-sm-12">
	
	<button type="button" id="submit" onClick={handleSearch} className="btn btn-dark"><i className="fa fa-search"></i> Search</button>
	
		</footer>		
        </div>	


							</div>
							</div>


                            </div>
                            
                            </div>
                           






                            <div className="row">
  <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Rooms Report</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

         {notice.isDataFetching ? <TableLoader />:
         <TableRoomRecord data={rooms}  columns={tableRoomHeader}  /> 
        } 

            </div></div>
    
    </div>
    
    </div>
    </div>



   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(RoomOccupancy) 