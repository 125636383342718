import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config, allowDelete, allowEdit, menuLink} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import cellEditFactory from 'react-bootstrap-table2-editor';
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'

import { useSelector } from 'react-redux'
import {Type} from 'react-bootstrap-table2-editor';
 const StaffMasterEntry =()=>{
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    
const [position, setPosition]=useState([])
const [document, setDocument]=useState([])
const [department, setDepartment]=useState([])

const group  = useSelector(state => state.rootReducer.group);
const [criteria, setCriteria] = useState([]);
const [master, setMaster] = useState({
    position:'',
    department:'',
    document:'',
    spotType:'',
    criteriaName:''
})
const [errors, setErrors] = useState({});
let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
const [activeStep, setActiveStep] = useState(1);


const fetchCriteria =()=>{
    const fd = new FormData(); 
    var sql ="Select ID, code, criteriaName, spotType from tbl_spot_criteria "; 
    fd.append("sql", sql);
    fd.append("jwt", Token);
    let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result => setCriteria(result.data))   
 }


    const fetchDocument =()=>{
        const fd = new FormData();
    fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_staff_settings/document/ID/1'
        axios.post(url, fd, config)
        .then(result =>setDocument(result.data))
    }
    
    const fetchDepartment =()=>{
        const fd = new FormData();
    fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_staff_settings/department/ID/1'
        axios.post(url, fd, config)
        .then(result =>setDepartment(result.data))
    }

    const fetchPosition =()=>{
        const fd = new FormData();
    fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_staff_settings/position/ID/1'
        axios.post(url, fd, config)
        .then(result =>setPosition(result.data))
    }
    
    
    const steps = [{title: 'Staff Position'}, {title: 'Staff Document'},  {title: 'School Department'}, {title: 'Criteria Setup'}]
    const handleOnClickStepper = (step) => {
        setActiveStep(step);        
        
    }
    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
       
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
       
    }, [activeStep])


   

    const handleSubmit =()=>{
    
        setNotice({...notice, 
            isLoading: true}) 
    
            const fd = new FormData();
            fd.append('jwt', Token);
            fd.append('position', JSON.stringify(position))
            fd.append('document', JSON.stringify(document))
            fd.append('department', JSON.stringify(department))
            fd.append('staffMaster', 'staffMaster')
             let url = ServerUrl+'/update_controller/tbl_staff_settings'
            axios.post(url, fd, config)
            .then(response =>{
                if (response.data.type ==='info'){
                    Alerts('Saved!', 'info', response.data.message)
                } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
            }  
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', JSON.stringify(error.message)) 
            })
            .finally(()=>{
              setNotice({...notice, isLoading: false}) 
              fetchDocument()
              fetchPosition()
              fetchDepartment()
              setActiveStep(1)
            }) 
    }




    
function submitPosition(event){ 
    event.preventDefault();
    if(!master.position){
        setErrors({...errors, position:'position field is Required'})
    }else{
    
        var code = 'p'+Math.random().toString(36).substr(2,9)
        setPosition(prev=>{
             return [...prev, {code:code, positionName:master.position}]
        })
        setMaster({...master, position:''})
    }
}

function submitDocument(event){  
    event.preventDefault();
    if(!master.document){
        setErrors({...errors, document:'Document field is Required'})
    }else{          

        var code = 'o'+Math.random().toString(36).substr(2,9)
        setDocument(prev=>{
             return [...prev, {code:code, documentName:master.document}]
        })
        setMaster({...master, document:''})
    }
}



function submitDepartment(event){ 
    event.preventDefault();
    if(!master.department){
        setErrors({...errors, department:'department field is Required'})
    }else{       
        var code = 'd'+Math.random().toString(36).substr(2,9)
        setDepartment(prev=>{
             return [...prev, {code:code, departmentName:master.department}]
        })
        setMaster({...master, department:''})
    }
}

  

 const handleChange = event =>{
    const {name, value} = event.target
   setMaster({...master, [name] : value });
   setErrors({...errors, [name]:''})
}

const handleDelete = (code, table)=>{ 
    if(table==='position'){
        setPosition(position.filter(item=>item.code!==code))
    }else if(table==='department'){
        setDepartment(department.filter(item=>item.code!==code))
    }else if(table==='document'){
        setDocument(document.filter(item=>item.code!==code))
    }
}

  
const tableCriteriaHeader = [
    {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'criteria')}><i className="fa fa-trash"></i></button>, editable: false, hidden:allowDelete==='No'?true:false},
        {dataField: 'spotType', text: 'Spot Type',  sort: true, editor: {
            type: Type.SELECT, options:[
                        {value:'Blue Spot', label:'Blue Spot (+)'},
                        {value:'Red Spot', label:'Red Spot (-)'}
            ] }},
          {dataField: 'criteriaName', text: 'Criteria',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'This field is required'
              };
            }
            return true;
          },editor: {
            type: Type.TEXTAREA }, formatter: (cell, row)=><span style={{maxWidth:'150px',  overflowWrap:'break-word', whiteSpace:'pre-wrap' }}>{cell}</span> } 
 ]


    const tablePositionHeader = [
        {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'position')}><i className="fa fa-trash"></i></button>, editable: false, hidden:allowDelete==='No'?true:false}, 
        {dataField: 'positionName', text: 'Staff Position',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Staff Position field is required'
              };
            }
            return true;
          } }     
     ];

    

     const tableDocumentHeader = [
       {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'document')}><i className="fa fa-trash"></i></button>, editable: false, hidden:allowDelete==='No'?true:false}, 
        {dataField: 'documentName', text: 'Document',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Document field is required'
              };
            }
            return true;
          } }     
     ];

     const tableDepartmentHeader = [
        {dataField: 'code', text: 'Action', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleDelete(row.code, 'department')}><i className="fa fa-trash"></i></button>, editable: false, hidden:allowDelete==='No'?true:false}, 
        {dataField: 'departmentName', text: 'Department',  sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Department is required'
              };
            }
            return true;
          } }     
     ];

     
 const BasicTable=(props)=>{
    const handleUpdate=(column, newValue, code)=>{   
        
        var table = props.table

        if(table==='position'){
            var allData = position.filter(item=>item.code!==code)
            let edit = position.filter(item=>item.code===code)
                edit[0].positionName = newValue
            setPosition(edit.concat(allData))

        }else if(table==='department'){
           const allData = department.filter(item=>item.code!==code)
           let  edit = department.filter(item=>item.code===code)
                edit[0].departmentName = newValue
                setDepartment(edit.concat(allData))

        }else if(table==='document'){
           const allData = document.filter(item=>item.code!==code)
           let  edit = document.filter(item=>item.code===code)
                edit[0].documentName = newValue
                setDocument(edit.concat(allData))
        }
       
     } 


     const { SearchBar } = Search;
         
          const customTotal = (from, to, size) => (
             <span >&nbsp;Showing { from } to { to } of { size } items</span>
           );
        const options = {
            showTotal: true,
        paginationTotalRenderer: customTotal,
        sizePerPageList: [{text: '10', value: 10}, {text: '20', value: 20}, {text: '50', value: 50},
        { text: 'All', value: props.data.length }]
           
          };
  
     return  <ToolkitProvider search  className="table-responsive"
                 keyField='code' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           
                             <SearchBar
                             { ...props.searchProps }  className="form-control" placeholder="Type to search!!!" />
                             
                             <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                   mode: 'dbclick',
                                   blurToSave: true,
                                   autoSelectText: true,
                         beforeSaveCell: (oldValue, newValue, row, column) => {
                           if(oldValue !==newValue){
                           handleUpdate(column, newValue, row.code);
                           
                          return '';
                         }
                         
                       }
                                 }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }


  const StudentTable=(props)=>{


    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData(); 
        fd.append("jwt", Token);
         fd.append('columnField', newValue);
         fd.append('column', column.dataField);
         fd.append('ID', ID);
        let url = ServerUrl+'/updateById_controller/tbl_spot_criteria'
       axios.post(url, fd, config)
       //.then(result => console.log(result.data))
       .then()
       .catch((error)=>console.log(error)) 
       
     } 
     const { SearchBar } = Search;
 
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
      );
     const options = {
         showTotal: true,
     paginationTotalRenderer: customTotal,
     sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
     { text: 'All', value: props.data.length }]
        
       };
  
     return <ToolkitProvider search columnToggle 
                 keyField='ID' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             &nbsp;
                           
                             </div>
                             
                      
             
                             <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                        noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                   mode: 'dbclick',
                                   blurToSave: true,
                                   autoSelectText: true,
                         beforeSaveCell: (oldValue, newValue, row, column) => {
                           if(oldValue !==newValue){
                           handleUpdate(column, newValue, row.ID);
                           
                          return '';
                         }
                         
                       }
                                 }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }



  const handleSubmitCriteria = event =>{
	event.preventDefault();
	 
	let errors = {};   

if(!master.criteriaName){
	errors.criteriaName ='Please enter criteria Name';
}

if(!master.spotType){
	errors.spotType ='Please select spot type';
}



setErrors(errors);

if(Object.keys(errors).length === 0 ){
    submit()
    }
} 


function submit(){   
	const fd = new FormData();
            fd.append('jwt', Token);
	
	 fd.append('criteriaName', master.criteriaName);
	 fd.append('spotType', master.spotType);
	 fd.append('code', 'cr'+Math.random().toString(36).substr(2,9));
 setNotice({...notice,  isLoading: true}) 

	let url = ServerUrl+'/save_controller/tbl_spot_criteria'
   axios.post(url, fd, config)
   .then(response =>{
	if (response.data.type ==='success'){
		Alerts('Saved!', 'success', response.data.message)
	} else{
		Alerts('Error!', 'danger', JSON.stringify(response.data))
}  
		   
   })
   .catch((error)=>{
	Alerts('Error!', 'danger', JSON.stringify(error.message)) 
})
.finally(()=>{
  setNotice({...notice, isLoading: false}) 
setMaster({...master, criteriaName:'', spotType:''})
fetchCriteria()
})  

}



useEffect(()=>{
    setIsAuthenticated(menuLink.includes(location.pathname))
    fetchCriteria()
    fetchDocument()
    fetchDepartment()
    fetchPosition();
 },[]);


        return (  <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 

        <PageHeader title="Staff">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Staff</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Settings</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Settings</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
		<div className="card-block">	
        <div className="container">
                    
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-inverse btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
<div style={{marginTop: '50px'}}>
{activeStep === 1 ? 
	   <div className="row setup-content" id="position">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12">

       <div className="form-group">
           <div className="input-group">
                       <input type="text" placeholder='Staff Position' name='position' onChange={handleChange} value={master.position} className={errors.position ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
                      <button className="btn btn-inverse" type="button" onClick={submitPosition}>Add</button>
              </div>
            <span style={{color:'red'}}>{errors.position && errors.position}</span>
           </div>		
      </section>
          </div>
                               </div>
                               
       </div>	
             <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12 table-responsive">
       {notice.isDataFetching ? <TableLoader />:
   <BasicTable data={position}  table={'position'} columns={tablePositionHeader}  />} 
       </section>
               </div>
               </div>
       
       </div>	</div>
       
       </div>	
			
       </div>
    
</div>
	  
	 
      :  activeStep === 2 ?  <div className="row setup-content" id="document">
		    
      <div className="col-md-12">

      <div className="card z-depth-0">

  <div className="card-block">
      <div className="card z-depth-0">

  <div className="card-block">
      <div className="row">
                          
      <section className="col-md-12">

      <div className="form-group">
          <div className="input-group">
                      <input type="text" placeholder='Staff Document' name='document' onChange={handleChange} value={master.document} className={errors.document ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
                     <button className="btn btn-inverse" type="button" onClick={submitDocument}>Add</button>
             </div>

              <span style={{color:'red'}}>{errors.document && errors.document}</span>
          </div>		
     </section>
         </div>
                              </div>
                              
      </div>	
            <div className="card z-depth-0">

  <div className="card-block">
      <div className="row">
                          
      <section className="col-md-12 table-responsive">
      {notice.isDataFetching ? <TableLoader />:
   <BasicTable data={document}  table={'document'} columns={tableDocumentHeader}  />} 
    </section>
              </div>
              </div>
      
      </div>	</div>
      
      </div>	
           
      </div>
   
</div>:
 activeStep === 3 ? 
<div className="row setup-content" id="department">
		    
       <div className="col-md-12">

       <div className="card z-depth-0">

   <div className="card-block">
       <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12">

       <div className="form-group">
           <div className="input-group">
                <input type="text" placeholder='School Department' name='department' onChange={handleChange} value={master.department} className={errors.department ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
                      <button className="btn btn-inverse" type="button" onClick={submitDepartment}>Add</button>
              </div>
               <span style={{color:'red'}}>{errors.department && errors.department}</span>
           </div>		
      </section>
          </div>
                               </div>
                               
       </div>	
             <div className="card z-depth-0">

   <div className="card-block">
       <div className="row">
                           
       <section className="col-md-12 table-responsive">
       {notice.isDataFetching ? <TableLoader />:
   <BasicTable data={department}  table={'department'} columns={tableDepartmentHeader}  />} 
       </section>
               </div>
               </div>
       
       </div>	</div>
       
       </div>	
			
       </div>
    
</div>

:<div className="row setup-content" id="extrasMark">
		    
<div className="col-md-12">

<div className="card z-depth-0">

<div className="card-block">
<div className="card z-depth-0">

<div className="card-block">
<div className="row">
                    
<section className="col-md-4">

<div className="form-group">
    
    <select name="spotType" value={master.spotType}  className={errors.spotType ? 'form-control form-control-danger' : 'form-control'} onChange={handleChange}   >
    
    <option  value='' selected disabled >Select Key</option>

<option value="Blue Spot" >Blue Spot (1)</option>
<option value="Red Spot" >Red Spot (-1)</option>
    </select>

    </div>
    </section>

    <section className="col-md-8">
    <div className="form-group">
    <div className="input-group">

                <input type="text" placeholder='Enter review criteria' name='criteriaName' onChange={handleChange} value={master.criteriaName} className={errors.criteriaName ? 'form-control form-control-danger' : 'form-control'} />&nbsp;
               <button className="btn btn-inverse" type="button" onClick={handleSubmitCriteria}>Add</button>
       </div>

        <span style={{color:'red'}}>{errors.criteriaName && errors.criteriaName}</span>
    </div>		
</section>
   </div>
                        </div>
                        
</div>	
      <div className="card z-depth-0">

<div className="card-block">
<div className="row">
                    
<section className="col-md-12 table-responsive">
{notice.isDataFetching ? <TableLoader />:
   <StudentTable data={criteria}   columns={tableCriteriaHeader}  />} 
   </section>
        </div>
        </div>

</div>	</div>

</div>	
     
</div>

</div>}

    <button onClick={activeStep === steps.length ? handleSubmit : handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Finish </i> : 'Next'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }
                </div>
                
            
                </div>
               
                </div>
                </div>
                </div> </div>
                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(StaffMasterEntry) 