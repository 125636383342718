import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, ImagesUrl, allowDelete, allowEdit, menuLink} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import Select from 'react-select'
import { userToken } from '../component/authentication'
const Books =()=> {
    
    const [isSubmitting, setIsSubmitting] = useState(false);
const [errors, setErrors] = useState({})
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
    
let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [books, setBooks]= useState([])    
    const [bookDetails, setBookDetails]= useState([])
    const [purpose, setPurpose]= useState([])
    const [subject, setSubject]= useState([]) 
    const [editVisible, setEditVisible]= useState(false) 
    
    const [image, setImage] = useState('')
    
    const fetchSubject =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_subject'
        axios.post(url, fd, config).then(result=>setSubject(result.data))
    }
    const fetchPurpose =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_library_settings/purpose/ID/1'
        axios.post(url, fd, config)
        .then(result =>setPurpose(result.data))
    }


    const fetchBooks =()=>{

        let sql = "Select  b.ID, b.code, b.bookName,  b.subjectId, b.imageUrl,  b.barcode, b.authorName, b.publisher, s.subjectName, b.purposeId, (select sum(issuedCount)  from tbl_book_issued where returnStatus ='No' and bookCode = b.code group by b.code) as totalIssued, b.totalBook  from tbl_books b, tbl_subject s  where b.subjectId = s.code order by b.subjectId"
        const fd = new FormData();
        fd.append('jwt', Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>{ 
            setBooks(result.data)
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        })

        }


    const getPurpose=(code)=>{
        const result = purpose&&purpose.filter(list=>list.code===code);
        const answer = result.map((c)=>c.purposeName);
        return  answer
    }

    const handleSelect = (option, action)=>{
        const std =JSON.parse(JSON.stringify(bookDetails))   
            const value =option.value;
		  std[0][action.name] = value;
          setBookDetails(std);
     setErrors({...errors, [action.name]:''});
    };

    const handleChange = (e)=>{
        const std =JSON.parse(JSON.stringify(bookDetails))   
            const value =e.target.value;
		   std[0][e.target.name] = value;
           setBookDetails(std);
           
     setErrors({...errors, [e.target.name]:''});
    }



    const handleSubmit = event =>{
        event.preventDefault();
    setErrors(ValidateInput(bookDetails[0]));
        setIsSubmitting(true);
    } 

    const ValidateInput=(values)=>{
        let errors = {};   
       
        if(!values.bookName){
            errors.bookName ='Please enter Book Name';
        }

        if(!values.purposeId){
            errors.purpose ='Please select book purpose';
        }
        if(!values.subjectId){
            errors.subject ='Please select Book Subject';
        }
        if(!values.authorName){
            errors.authorName ='Please enter Author Name';
        }
        return errors;
        }
    




    const handleEditBooks =(code)=>{
        setBookDetails(books.filter(item=>item.code===code))
        setEditVisible(true);
        window.scrollTo(0,0);
    }
    


const  submitUpdate=()=>{  
    let formData = bookDetails[0];
    const fd = new FormData();
    fd.append('jwt', Token);
        for(let k in formData){
            fd.append(k,formData[k]);
        }; 
      
        if(image!==''){
            fd.append('image', image);
        }
        setNotice({...notice,   isLoading: true}) 
             let url = ServerUrl+'/update_controller/tbl_books'
            axios.post(url, fd, config)
            .then(response =>{
                  if (response.data.type ==='info'){
                      Alerts('Update!', 'default', response.data.message)
                  } else{
                      Alerts('Error!', 'danger', JSON.stringify(response.data))
              }  
                         
                 })
                 .catch((error)=>{
                  Alerts('Error!', 'danger', JSON.stringify(error.message)) 
              })
              .finally(()=>{
                fetchBooks()
                setNotice({...notice, 
                    isLoading: false}) 
            setEditVisible(false)
              })
    } 




    const handleDelete = (code)=>{  
        setNotice({...notice, 
           isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+code)
              close.click();
              const fd = new FormData();
              fd.append('jwt', Token);
           fd.append('ID', 'code')
            fd.append('data', code)
    
            let url = ServerUrl+'/delete_controller/tbl_books'
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
               fetchBooks()
               setNotice({...notice, 
                   isLoading: false
               })
               setEditVisible(false)
           }) 
    }


const getSubject =(code)=>{
    const result = subject&&subject.filter(list=>list.code===code);
    const answer = result.map((c)=>`${c.subjectName} ${c.abbreviation}`);
    return  answer
    
}



const tableHeader = [
{dataField: 'imageUrl', text: 'Cover', editable: false, formatter:(cell, row)=><img className="img-70" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/no.jpg"}} src={ImagesUrl+'/library/books/'+cell} title={row.bookName}   />},
        {dataField: 'bookName', text: 'Book Name'},
        {dataField: 'authorName', text: 'Author Name'},       
        {dataField: 'publisher', text: 'Publisher'},
    {dataField: 'purposeId', text: 'Purpose', editable: false, formatter:(cell)=>getPurpose(cell)}, 
    {dataField: 'subjectName', text: 'Subject', editable: false},
    {dataField: 'totalBook', text: 'Total Books', editable: false},
    {dataField: 'totalIssued', text: 'Total Issued', editable: false},
    
{dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
   {allowDelete==='Yes'?
<button type="button" className="btn btn-danger btn-sm" data-toggle="modal"   data-target={`#warningdialog-${cell}`}     title="Delete"><i className="icofont icofont-delete-alt"></i></button>:[]} 
&nbsp;&nbsp;
   
   {allowEdit==='Yes'?<button type="button" className="btn btn-warning btn-sm" onClick={()=>handleEditBooks(row.code)}   title="Edit"> <i className="icofont icofont-edit-alt"></i></button>:[]}

<WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(cell)} mID={cell} /> 
</div>, editable: false, hidden:allowDelete==='No'&&allowEdit==='No'?true:false}
 ];
 



 const BookTable=(props)=>{

    const CustomToggleList = ({
        columns,
        onColumnToggle,
        toggles
      }) => ( 
      <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
          {
            columns
              .map(column => ({
                ...column,
                toggle: toggles[column.dataField]
              }))
              .map(column => (
                
                <button
                  type="button"
                  key={ column.dataField }
                  className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                  data-toggle="button"
                  aria-pressed={ column.toggle ? 'true' : 'false' }
                  onClick={ () => onColumnToggle(column.dataField) }
                >
                  { column.text }
                </button>
              ))
          }
        </div>
        
      );

    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData();
        fd.append('jwt', Token);
         fd.append('columnField', newValue);
         fd.append('column', column.dataField);
         fd.append('ID', ID);
       
        let url = ServerUrl+'/updateById_controller/tbl_books'
       axios.post(url, fd, config)
       //.then(result => console.log(result.data))
       .then()
       .catch((error)=>console.log(error)) 
       
     } 
     const { SearchBar } = Search;
 
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
      );
     const options = {
         showTotal: true,
     paginationTotalRenderer: customTotal,
     sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
     { text: 'All', value: props.data.length }]
        
       };
  
     return <ToolkitProvider search columnToggle 
                 keyField='ID' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             &nbsp;
                           
                             <div className="btn-group dropdown-split-success">
  
  <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
  <i className="fa fa-search-plus"></i> Columns
  </button>
  
  <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'250px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
    <CustomToggleList { ...props.columnToggleProps } />
  </div>
  </div>

  <div className="dt-buttons btn-group pull-right"> 
  <a href="/library/manage_books" className="btn btn-outline-success waves-effect waves-light" >
        <i className="fa fa-plus"></i>Add New </a>	


<div className="btn-group dropdown-split-primary ">
    <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i className="fa fa-download"></i>Export
    </button>
    <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>


            <form method='POST' action={ServerUrl+'/excel_controller.php?api=books'} target='_blank'>

                <input type='hidden' name='jwt' value ={Token} />
               
        <button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>
        
        </form>

        <form method='POST' action={ServerUrl+'/printout/books.php?token='+userToken} target='_blank'>

                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
        
        </form>
    </div>
</div> 
</div> 
  
                             </div>
                             
                      
             
                             <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                   mode: 'dbclick',
                                   blurToSave: true,
                                   autoSelectText: true,
                         beforeSaveCell: (oldValue, newValue, row, column) => {
                           if(oldValue !==newValue){
                           handleUpdate(column, newValue, row.ID);
                           
                          return '';
                         }
                         
                       }
                                 }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }


  const  handleFile = function(fieldName){
    return function(newValue){  
      
   readURL(newValue);
   }
   
} 

const readURL = (input)=>{
   
    let displayMessage = '';
    const PreviewImage = input.target.name;
      //  let newImage = "viewPassport";
        var fileName = document.getElementById(PreviewImage).value;

    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile==="jpg" || extFile==="jpeg" || extFile==="png" || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 1) {
        displayMessage = input.target.name +': File uploaded is more than maximum size Allow. it exceeds 1 Mb';
       document.getElementById(input.target.name).value = '';
      
       setImage('')
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
            setImage(input.target.files[0])
            
        }
    }
    }else{

        displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 400kb'
        document.getElementById(input.target.name).value = '';
       
        setImage('')
    }   
    
    if(displayMessage.length > 0){
        Alerts('Error!', 'danger', displayMessage)
      }
    
} 


 useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submitUpdate()
    }
    },[errors])



useEffect(()=>{
    
    setIsAuthenticated(menuLink.includes(location.pathname)) 
    fetchBooks();
    fetchPurpose();
    fetchSubject()
},[]);



        return (   <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Library Books">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-book"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Library</a> </li>
                            <li className="breadcrumb-item"><a href="#!">Books</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              
     
{bookDetails&&bookDetails.map((bk, index)=>
                <div key={index} className="row  ">
							
                               
                            {editVisible? <div className="col-sm-12">
					

                            <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Update Book</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
	
         	<div  className="row">
									
										<section className="col-md-4">
                                    
                                    
                                        <div className="form-group">
						<label >Subject<span style={{color:'red'}}>*</span></label>
                        <Select options={
                            subject&&subject.map((list, idx)=> {
                               return {key:idx, value:list.code, label:list.subjectName, abr:list.abbreviation}
                             })} 
onChange={handleSelect} className={errors.subject ? 'form-control form-control-danger' : ''} name="subjectId"  value={{value:bk.subjectId, label:getSubject(bk.subjectId)}}  /> <span style={{color:'red'}}>{errors.subject && errors.subject}</span>	
			</div>
            </section>
            <section className="col-md-4">

            <div className="form-group">
						<label >Purpose<span style={{color:'red'}}>*</span></label>
                        <Select options={
                            purpose&&purpose.map((list, id)=> {
                               return {key:id, value: list.code, label: list.purposeName}
                             })} 
onChange={handleSelect} className={errors.purpose ? 'form-control form-control-danger' : ''} name="purposeId" value={{value:bk.purposeId, label:getPurpose(bk.purposeId)}}  /> <span style={{color:'red'}}>{errors.purpose && errors.purpose}</span>	
			</div>
</section>
            <section className="col-md-4">
										<div className="form-group">
						<label >Book Name<span style={{color:'red'}}>*</span></label>
		<input type="text" name="bookName" value={bk.bookName} onChange={handleChange} className={errors.bookName  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.bookName && errors.bookName}</span>	
			</div></section>
</div>
            <div  className="row">

            <section className="col-md-4">
            <div className="form-group">
						<label >Author Name<span style={{color:'red'}}>*</span></label>
		<input type="text" name="authorName" value={bk.authorName} onChange={handleChange} className={errors.authorName  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.authorName && errors.authorName}</span>	
			</div></section>

            <section className="col-md-4">
            <div className="form-group">
						<label >Publisher</label>
		<input type="text" name="publisher" value={bk.publisher} onChange={handleChange} className='form-control' /> 
			</div>
</section>
            <section className="col-md-4">

            <div className="form-group">
                           <label >Cover Image</label><br/>
                  <input id="image"  onChange={handleFile('image')} className='form-control' name="image" type="file" accept="image/*" /> <span style={{color:'red'}}>{errors.image && errors.image}</span>
          <div className="note" style={{color:'red'}}>
          <strong >Size 900 kb,</strong>gif|jpg|png|jpeg Only Allow
      </div>	   	
              </div>
										</section>
										</div>	

	<footer className="pull-right">
	
	<button type="button" onClick={()=>setEditVisible(false)} className="btn btn-inverse "><i className="fa fa-refresh"></i> Cancel</button>&nbsp;&nbsp;
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-success ">Update</button>
	
								</footer>				
							</div>
							</div>


                            </div>:''}
                            
                            </div>)}




<div className="row  ">   <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Listing All Books</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

         {notice.isDataFetching ? <TableLoader />:
         <BookTable data={books}  columns={tableHeader}  /> 
        } 

            </div></div>


    
    </div>
    
    </div>
    </div>




   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Books) 