import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, allowDelete, allowEdit, menuLink} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useLocation } from 'react-router-dom'
import { userToken } from '../component/authentication'
import { useSelector } from 'react-redux'
import { longDate, ValidateIsOpen } from '../component/globalFunction'

import Error403 from '../settings/error403'
const RepairHistory =()=> {
    
const [errors, setErrors] = useState({})
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
const [assets, setAssets]= useState([]) 

const staff = useSelector(state => state.rootReducer.staff);

const getStaffName =(code)=>{
  const result = staff&&staff.filter(item=>item.code===code);
const answer = result.length!==0?result[0].staffName:''
return  answer
}

    const fetchAsset =()=>{
        setNotice({...notice,   isDataFetching: true})
        let sql = "Select a.ID, a.code, a.barcode, a.assetName, a.category, a.currentLocation, a.assignTo, a. warantyExpiryDate, a.lifeSpan, a.purchaseDate, a.purchaseAmount from tbl_assets a "


        const fd = new FormData();
        fd.append('jwt', Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>setAssets(result.data))
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice,   isDataFetching: false})
        })
        }



    const TableRecord=(props)=>{

        const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
          }) => ( 
          <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
              {
                columns
                  .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                  .map(column => (
                    
                    <button
                      type="button"
                      key={ column.dataField }
                      className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                      data-toggle="button"
                      aria-pressed={ column.toggle ? 'true' : 'false' }
                      onClick={ () => onColumnToggle(column.dataField) }
                    >
                      { column.text }
                    </button>
                  ))
              }
            </div>
            
          );
    
        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
            fd.append('jwt', Token);
             fd.append('columnField', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
           
            let url = ServerUrl+'/updateById_controller/tbl_repair_asset'
           axios.post(url, fd, config)
           .then(result => Alerts('success!', 'success', result.data.message))
           .then()
           .catch((error)=>console.log(error)) 
           
         } 
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                
                                 <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'50px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>


      <div className="dt-buttons btn-group pull-right"> 
    
        
    
    <div className="btn-group dropdown-split-primary ">
        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
    
    
                <form method='POST' action={ServerUrl+'/excel_controller.php?api=asset_repair'} target='_blank'>
                    <input type='hidden' name='jwt' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>
            
            </form>
    
            <form method='POST' action={ServerUrl+'/printout/asset_repairs.php?token='+userToken} target='_blank'>
    
                    <input type='hidden' name='jwtToken' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
            
            </form>
        </div>
    </div> 
    </div> 
        </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      
    var i =1;
    const tableHeader = [
        {dataField: 'ID', text: 'SN', editable:false, formatter:(cell)=>i++},
                
                {dataField: 'assetName', text: 'Asset', editable:false, formatter:(cell, row)=><div style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }} >{cell}</div>}, 

                {dataField: 'warantyExpiryDate', text: 'Expiry Date',  editable:false, formatter: (cell, row)=><span>{cell!=='0000-00-00'?longDate(cell):'-'}</span>},

                {dataField: 'lifeSpan', text: 'Life Span',  editable:false},
                {dataField: 'currentLocation', text: 'Location',  editable:false},
                {dataField: 'code', text: 'Status',  editable:false, formatter: (cell, row)=><span className={`badge 
                ${ValidateIsOpen(row.warantyExpiryDate, new Date())===true?'badge-danger':'badge-success'}`}>{ValidateIsOpen(row.warantyExpiryDate, new Date())===true?'Expired':'Active'}</span>},

                
                {dataField: 'assignTo', text: 'Assign To',  editable:false,  formatter: (cell, row)=>cell !==''?<a href={"/staff/profile/"+cell} target="_blank" className="btn btn-sm btn-info">{getStaffName(cell)} <i class="fa fa-external-link" ></i></a>:''}
         ]

    
useEffect(()=>{
  setIsAuthenticated(menuLink.includes(location.pathname))
    fetchAsset()
},[]);

        return (  
   <>
   <div  className="main-body">
   {isAuthenticated?     <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}
                   {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
        <PageHeader title="Asset Expiry">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                          
                            <li className="breadcrumb-item"><a href="/assets/view_all">Assets</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Asset Expiry</a> </li>

                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              

                {errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}

                           

                            <div className="row">
  <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Asset Information</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

         {notice.isDataFetching ? <TableLoader />:
         <TableRecord data={assets}  columns={tableHeader}  /> 
        } 

            </div></div>
    
    </div>
    
    </div>
    </div>



   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(RepairHistory) 