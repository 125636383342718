import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import {Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, allowDelete, allowEdit, menuLink} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import { useDispatch, useSelector } from 'react-redux'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
const AccountType =()=> {
    const [errors, setErrors] = useState({});
    
    const [isSubmitting, setIsSubmitting] = useState(false);                                      
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
    const [account, setAccount] = useState({
        accountType:'',
        status:'Active'
    })

    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [allAccount, setAllAccount]= useState([])

    const fetchAccount =()=>{
        const fd = new FormData();
        fd.append('jwt', Token); 
        let url = ServerUrl + '/fetch_controller/tbl_account_type'
        axios.post(url, fd, config).then(result=>setAllAccount(result.data))
    }
    const handleChange = event =>{
		const {name, value} = event.target
       setAccount({...account, [name] : value });
       setErrors({...errors, [name]:''});
    }
   
    const handleDelete = (code)=>{  
        setNotice({...notice, 
           isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+code)
              close.click();
              const fd = new FormData();
              fd.append('jwt', Token); 
           fd.append('ID', 'code')
            fd.append('data', code)
    
            let url = ServerUrl+'/delete_controller/tbl_account_type'
           axios.post(url, fd, config)
           .then(response =>{
            if(response.data.type ==='success'){
               Alerts('Saved!', 'success', response.data.message)
                   } else{
               Alerts('Error!', 'danger', JSON.stringify(response.data))
                   }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
            fetchAccount()
               setNotice({...notice, 
                   isLoading: false
               })
           }) 
    }



    const handleCheckbox =()=>{
        const value = account.status ==='Active'? 'Inactive':'Active';
         setAccount({...account, status : value});
        }



    const tableHeader = [
        {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div>
            <a href="#!" data-toggle="modal"   data-target={`#warningdialog-${row.code}`} className='btn  btn-danger bn-sm'    title="Delete"><i className="icofont icofont-delete-alt"></i></a>&nbsp;

<WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code)} mID={row.code} /> 
        </div>, editable: false, hidden:allowDelete==='No'?true:false},
        {dataField: 'account_type', text: 'Account Type',  sort: true,  validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Account Type is required'
              };
            }
            return true;
          } },
          {dataField: 'status', text: 'Status',  sort: true, editor: {
            type: Type.SELECT, options:[
                        {value:'Active', label:'Active'},
                        {value:'Inactive', label:'Inactive'}
            ] }, formatter: (cell, row)=>row.status==='Active'?<span className="pcoded-badge label label-success">{row.status}</span>: <span className="pcoded-badge label label-danger">{row.status}</span>}    
     ];

    
     const TableAccount=(props)=>{
       
const handleUpdate=(column, newValue, ID)=>{       
    const fd = new FormData();
        fd.append('jwt', Token); 
        fd.append('newValue', newValue);
        fd.append('column', column.dataField);
        fd.append('ID', ID);
      
       let url = ServerUrl+'/updateCustom_controller/tbl_account_type'
      axios.post(url, fd, config)
      //.then(result => console.log(result.data))
      .then()
      .catch((error)=>Alerts('Error!', 'danger', error.message)) 
    }  
    
    const { SearchBar } = Search;
    
    const customTotal = (from, to, size) => (
    <span >&nbsp;Showing { from } to { to } of { size } items</span>
    );
    const options = {
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
    { text: 'All', value: props.data.length }]
    
    };
    return  <ToolkitProvider search columnToggle 
                keyField='code' data={ props.data } columns={ props.columns } >
                    
                    {
                        props => (
                        
                        <div className="form-group">
                            <SearchBar
                            { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                
                                       
                <BootstrapTable
                            { ...props.baseProps }
                                        striped
                                    hover
                                    condensed
                                    noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                pagination={paginationFactory(options) }
                                cellEdit={ cellEditFactory({
                                mode: 'dbclick',
                                blurToSave: true,
                                autoSelectText: true,
                        beforeSaveCell: (oldValue, newValue, row, column) => {
                        if(oldValue !==newValue){
                        handleUpdate(column, newValue, row.ID);
                        
                        return true;
                        }
                        
                    }
                                }) }
                            />
                        </div>
                        )
                    }
</ToolkitProvider>
}


        

  
     const handleSubmit = event =>{
        event.preventDefault();
         
    setErrors(ValidateInput(account));
        setIsSubmitting(true);
    } 

const ValidateInput =(values)=>{
	let errors = {};   
		
    if(!values.accountType){
        errors.accountType ='Account type is required';
    }
    if(allAccount.filter(item=>item.account_type===values.accountType).length!==0){
        errors.accountType ='Account type already exist';
    }
    return errors;
	}
	
function submit(){  
    
    const fd = new FormData();
    fd.append('jwt', Token);  
       let formData = account; 
      for(let k in formData){
          fd.append(k,formData[k]);
      }; 
    setNotice({...notice, isLoading: true}) 

       let url = ServerUrl+'/save_controller/tbl_account_type'
	  axios.post(url, fd, config)
	  .then(response =>{
        if (response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
        } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
    }  
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    }).finally(()=>{
        setIsSubmitting(false); 
        fetchAccount()
        setNotice({...notice, 
            isLoading: false}) 
      setAccount({ accountType: '', status:'Active'}); 
    })
      
   
}


useEffect(()=>{
    setIsAuthenticated(menuLink.includes(location.pathname)) 
    fetchAccount();
},[]);

useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])
    
        
        
        return (  
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Account Type">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-home"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Finance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Account Type</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
							
                               
                               <div className="col-sm-5">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Create Account Type</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<fieldset>
            <div className="row">
									
										<section className="col-md-12">
										<div className="form-group">
						<label >Account Type<span style={{color:'red'}}>*</span></label>
						<input type="text" name="accountType" value={account.accountType} onChange={handleChange} className={errors.accountType  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.accountType && errors.accountType}</span>	
			
												
											</div>
										</section>
										</div>
                                        <div className="row">
										<section className="col-md-12">
	<hr/><label>Status</label>
                                        
     
<div className="border-checkbox-section">
					
			<div className="border-checkbox-group border-checkbox-group-primary">
				<input className="border-checkbox" type="checkbox" onChange={handleCheckbox} name="status" value={account.status} checked={account.status==='Active'? true:false} id="status" />
						<label className="border-checkbox-label" htmlFor="status">Active</label>
					</div>
					
					</div> 

                    </section>
							</div>	</fieldset>

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary ">Save</button>
	
								</footer>				
							</div>
							</div>
							</div>
		
	
	

	<div className="col-sm-7">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Listing All Account Type</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">

            <div className="card z-depth-0">
            <div className="card-block">
        <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
   <TableAccount data={allAccount}  columns={tableHeader}  />} 
</div>
	</div>
    </div></div></div>
</div>

</div>
	
   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(AccountType) 