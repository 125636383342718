import React, {useState, useEffect, useCallback, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl, Token, config, domain, teacherUrl, allowDelete, allowEdit, menuLink } from '../component/include'
import {formatGroupLabel, getAge, longDate} from '../component/globalFunction'
import Select from 'react-select'
import UsePagination from "../component/pageNumber";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { userToken } from '../component/authentication'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Cookies from 'js-cookie'
import {encrypt} from '../component/authentication'

const ViewStaff=()=> {
   
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const countries  = useSelector(state => state.rootReducer.countries);
    const states  = useSelector(state => state.rootReducer.states);

const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const [group, setUsergroup] = useState([])
	const selectStyles = { menu: styles => ({ ...styles, zIndex: 999 }) };
    const [allStaff, setAllStaff] = useState([]);
    const [position, setPosition]=useState([])
    const [staffId, setStaffId] = useState(null);
    const [editStaff, setEditStaff] = useState(false); 
    const [staff, setStaff] = useState([]);
    const steps = [{title: 'Staff Details'}, {title: 'Contact Info'},  {title: 'Qualification'}, {title: 'Document'}, {title: 'Role History'}]
    const [activeStep, setActiveStep] = useState(1);

    const [lga, setLga] = useState([]) 


    const [staffName, setStaffName]=useState([])
    const structure = useRef(null)
const [department, setDepartment]=useState([])
    const [documents, setDocument]=useState([])
    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [isSubmittingQualification, setIsSubmittingQualification] = useState(false);
    const [isSubmittingDocument, setIsSubmittingDocument] = useState(false); 
    const [isSubmittingHistory, setIsSubmittingHistory] = useState(false);
    let Initials = {
       
        institution:'',
        program:'',
        awardYear:'',
        grade:'',
        remarks:'',
        passport:'',
        userType:[],
        documentType:[],
        studentClass:[],
        documentTitle:'',
        documentFile:'',
        staffName:'',
            department:[],
            position:[],
            status:{ value: 'All', label: 'All' },
            fileNo:'',
            gender:{ value: 'All', label: 'All' },
            cardView:{value:'card', label:'Card View'},
            perPage:32,


            remarkHistory:'',
             dateStart:'',
              dateEnd:'',
               departmentHistory:[]
    }
    const [general, setGeneral] = useState(Initials)
    const [image, setImage] = useState({
        passport:'',
        cover:'',
        documentFile:''
    })
//pagination here 
    let [page, setPage] = useState(1);
    const PER_PAGE = Number(general.perPage);
  
    const count = Math.ceil(allStaff.length / PER_PAGE);
    const dataList = UsePagination(allStaff, PER_PAGE);
  
    const handleChangePage = (e, num) => {
      setPage(num);
      dataList.jump(num);
    };
    var pages = [...Array(dataList.maxPage).keys()].map(i =>  i+1);

    const handleFetchAllStaff =()=>{
        setNotice({...notice,  isLoading: true})
        var sql ="Select *  from tbl_staff where ID <> '' "; 

    if(general.fileNo!=='' ){
        sql = sql + '  and fileNo = "'+general.fileNo+'" '
    }
    if(general.position.length!==0 ){
        sql = sql + '  and position = "'+general.position.value+'" '
    }

    if(general.userType.length!==0 ){
        sql = sql + '  and userType = "'+general.userType.value+'" '
    }

    if(general.studentClass.length!==0 ){
        sql = sql + '  and sectionID = "'+general.studentClass.value+'" '
    }

    if(general.staffName!=='' ){
        sql = sql + '  and staffName Like "%'+general.staffName+'%" '
    }

    if(general.department.length!==0 ){
        sql = sql + '  and department = "'+general.department.value+'" '
    }
    if(general.gender.value!=='All' ){
        sql = sql + '  and gender = "'+general.gender.value+'" '
    }
    if(general.status.value!=='All' ){
        sql = sql + '  and employmentStatus = "'+general.status.value+'" '
    }

    sql = sql + "order by staffName ASC "
    const fd = new FormData();
            fd.append('jwt', Token); 
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{

    if(result.data.length>0){
        setAllStaff(result.data)
    }
      
    
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, 
            isLoading: false})
    })
    }

    const handleSelectSearch = (option, action)=>{
        setGeneral({...general, [action.name]:option })
        setAllStaff([])
    };
    const handleChangeSearch = (e)=>{
        const {name, value} = e.target
        setGeneral({...general, [name]:value })
        
    }




     const handleFetchLga =async(alias)=>{

        let url = 'https://locus.fkkas.com/api/regions/'+alias
       await  axios.get(url, config).then(result =>{
          if(result.data.data.length!==0){
              let response =result.data.data.sort((a, b) =>(a.name > b.name) ? 1 : -1)
              setLga(response)
            }else{
                setLga([])
            }
      
        })
  
     }


     const fetchNameByState =(alias, table, field)=>{
        const result = table.filter(list=>list.alias===alias);
      const answer = result.map(ans=>ans[field])
        return answer?String(answer):''
      }

    const fetchNameByField =(ID, table, field)=>{
        const result = table.filter(list=>list.id===ID);
      const answer = result.map(ans=>ans[field])
        return String(answer)
      }
    


    const handleChange = (e)=>{
        const std =JSON.parse(JSON.stringify(staff))   
            const value =e.target.value;
		   std[0][e.target.name] = value;
        setStaff(std);
    }

    const handleSelectGeneral = (option, action)=>{
        setGeneral({...general, [action.name]: option});
        setErrors({...errors, [action.name]:''})
    };

    const handleSelect = (option, action)=>{
        const std =JSON.parse(JSON.stringify(staff))   
          std[0][action.name] = option.value;
        setStaff(std);
        if(action.name==='province'){
            handleFetchLga(option.value)
          }

    };

  
    const fetchGroup =()=>{
        var sql ="Select code, status, groupName from tbl_usertype order by groupName ASC" ;
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config).then(result=>{  
            setUsergroup(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
    }

    const fetchDepartment =()=>{
        var sql ="SELECT department, document, position  from  tbl_staff_settings where ID="+1;
      const fd = new FormData();
      fd.append("sql", sql);
      fd.append('jwt', Token)
      let url = ServerUrl+'/fetchBySql_controller';
      axios.post(url, fd, config).then(result=>{
      if(result.data.length>0){
        setDepartment(JSON.parse(result.data[0].department))
        setPosition(JSON.parse(result.data[0].position))
      setDocument(JSON.parse(result.data[0].document))
      }
      })
      .catch((error)=>{
      Alerts('Error!', 'danger', error.message)
      })
      }



    const getUserType =(code)=>{
        const result = group&&group.filter(list=>list.code===code);
      const answer = result.length!==0?result[0].groupName:'';
      return  answer
    }

const getPosition =(code)=>{
    const answer =  position.filter(item=>item.code===code)
    const reply = answer.length!==0?answer[0].positionName:''
    return reply
}


const getDepartment =(code)=>{
    const result = department.filter(item=>item.code===code);
  const answer = result.length!==0?result[0].departmentName:''
  return  answer
}


const handleDelete = (code)=>{  
    setNotice({...notice,  isLoading: true}) 
       let close =   document.getElementById('btnWarningDialog-'+code)
          close.click();
          const fd = new FormData();
          fd.append('jwt', Token); 
       fd.append('ID', 'code')
		fd.append('data', code)

        let url = ServerUrl+'/delete_controller/tbl_staff'
       axios.post(url, fd, config)
       .then(response =>{
        if(response.data.type ==='success'){
           Alerts('Saved!', 'success', response.data.message)
               } else{
           Alerts('Error!', 'danger', JSON.stringify(response.data))
               }   
       })
       .catch((error)=>{
         Alerts('Error!', 'danger', error.message)
       }).finally(()=>{
           handleFetchAllStaff()
           setNotice({...notice, 
               isLoading: false
           })
       }) 
}


const handleEditStaff =(row)=>{
    setStaffId(row.code);
    setActiveStep(1); 
    setEditStaff(true);
    handleFetchLga(row.province)
    setStaffName(row.staffName)
    setStaff([row])
structure.current.scrollIntoView({ behavior: "smooth" });

    }
  

   

    const handleOnClickStepper = (step) => {
        setActiveStep(step);   
        window.scrollTo(0,0);
    }
    const handleOnClickNext = useCallback(() => {
        setActiveStep(activeStep + 1);
        window.scrollTo(0,0);
    }, [activeStep])
    
    const handleOnClickBack = useCallback(() => {
        setActiveStep(activeStep - 1);
        window.scrollTo(0,0);
       
    }, [activeStep])

    const handleCheckbox =e=>{
        const std =JSON.parse(JSON.stringify(staff))    
        const value = !std[0].status
       std[0][e.target.name] =value;
       setStaff(std);
        }


  

         
          const handleChangeGeneral = event =>{
            const {name, value} = event.target
            setGeneral({...general, [name] : value });
            setErrors({...errors, [name]:''})
         }

          const handleSubmitQualification = event =>{
            event.preventDefault();
            setErrors(ValidateQualification(general));
            setIsSubmittingQualification(true);
        }
    
    
     const ValidateQualification=(values)=>{
        let errors = {};     
        if(!values.institution){
            errors.institution ='Institution /Board field is required';
        }
        if(!values.awardYear){
            errors.awardYear ='Year of Award field is required';
        }
        if(!values.program){
            errors.program ='Program field is required';
        }
        return errors;
        }
    
        const submitQualification=()=>{
    
            const allQualificaion = JSON.parse(staff[0].qualification)
            const newQualification = [...allQualificaion, {code:'q'+Math.random().toString(36).substr(2,9), institution:general.institution, program:general.program, grade:general.grade, awardYear:general.awardYear, remarks:general.remarks }]
    
            const newData = staff
            newData[0].qualification = JSON.stringify(newQualification)
            setStaff(newData)
    
            setGeneral({...general,
            institution:'',
            program:'',
            grade:'',
            remarks:'',
            awardYear:''
            
            })
            setIsSubmittingQualification(false);
        }
        

        const handleSubmitDocument = event =>{
            event.preventDefault();
            setErrors(ValidateDocument(general));
            setIsSubmittingDocument(true);
        }
        
        const ValidateDocument=(values)=>{
            let errors = {};     
            if(!values.documentTitle){
                errors.documentTitle ='Document title field is required';
            }
            if(values.documentType.length===0){
                errors.documentType ='Please select document type';
            }
            if(!values.documentFile){
                errors.documentFile ='Please upload Document';
            }
            return errors;
            }



            const submitDocument=()=>{

                setNotice({...notice, 
                    isLoading: true}) 
        
                    const fd = new FormData();
                    fd.append('jwt', Token);  
                
              var fileUrl = 'f'+Math.random().toString(36).substr(2,9)+'.png'
        
                const allDocument = JSON.parse(staff[0].document)
                const newDocument = [...allDocument, {code:'d'+Math.random().toString(36).substr(2,9), documentTitle:general.documentTitle, documentType:general.documentType.value, fileUrl:fileUrl }]
        
                const newData = staff
                newData[0].document = JSON.stringify(newDocument)
                
                fd.append('staffCode', staffId);
                fd.append('qualification', newData[0].qualification);
                fd.append('jobHistory', newData[0].jobHistory);
                fd.append("image", general.documentFile);
                fd.append('document', JSON.stringify(newDocument));
                fd.append('fileUrl', fileUrl);
                setStaff(newData)
        
                
                let url = ServerUrl+'/update_controller/tbl_staff_document'
                axios.post(url, fd, config)
                .then(response =>{
                    if (response.data.type ==='info'){
                        Alerts('Saved!', 'info', response.data.message)
                    } else{
                        Alerts('Error!', 'danger', JSON.stringify(response.data))
                }  
                })
                .catch((error)=>{
                    Alerts('Error!', 'danger', JSON.stringify(error.message)) 
                })
                .finally(()=>{
                  setNotice({...notice, isLoading: false}) 
        
                setGeneral({...general,
                    documentTitle:'',
                documentType:[],
                documentFile:''
                })
                document.getElementById('documentFile').value = ''
                setIsSubmittingDocument(false);
                    
            })
                
            }


            const handleSubmitHistory = event =>{
                event.preventDefault();
                setErrors(ValidateHistory(general));
                setIsSubmittingHistory(true);
            }
        
        
         const ValidateHistory=(values)=>{
            let errors = {};     
            if(!values.dateStart){
                errors.dateStart ='Start Date field is required';
            }
            
            if(values.departmentHistory.length===0){
                errors.departmentHistory ='department field is required';
            }
            return errors;
            }
        
            const submitHistory=()=>{
        
                const allHistory = JSON.parse(staff[0].jobHistory)
                const newHistory = [...allHistory, {code:'h'+Math.random().toString(36).substr(2,9), remark:general.remarkHistory, department:general.departmentHistory.value, dateStart:general.dateStart, dateEnd:general.dateEnd}]
        
                const newData = staff
                newData[0].jobHistory = JSON.stringify(newHistory)
                setStaff(newData)
        
                setGeneral({...general,
                    remarkHistory:'',
                    department:'',
                    dateStart:'',
                    dateEnd:''
                
                })
                setIsSubmittingHistory(false);
            }
            


        const handleRemove = (code, table)=>{  

            if(table==='qualification'){
                const records = JSON.parse(staff[0].qualification)
               var allData = records.filter(item=>item.code !== code)
               
                const newData = JSON.parse(JSON.stringify(staff)) 
                   newData[0].qualification = JSON.stringify(allData, null, 2)
                   setStaff(newData)
    
           } else if(table==='document'){
    
            const records = JSON.parse(staff[0].document)
               var allData = records.filter(item=>item.code !== code)
               
                const newData = JSON.parse(JSON.stringify(staff)) 
                   newData[0].document = JSON.stringify(allData, null, 2)
                   setStaff(newData)
    
           }  else{
            const records = JSON.parse(staff[0].jobHistory)
               var allData = records.filter(item=>item.code !== code)
               
                const newData = JSON.parse(JSON.stringify(staff)) 
                   newData[0].jobHistory = JSON.stringify(allData, null, 2)
                   setStaff(newData)
           } 
        }
       
        const tableQualificationHeader = [
            {dataField: 'code', text: 'x', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleRemove(row.code, 'qualification')}><i className="fa fa-trash"></i></button>, editable: false},
            {dataField: 'institution', text: 'Institution', validator: (newValue, row, column) => {
                if (!newValue) {
                  return {
                    valid: false,
                    message: 'Institution field is required'
                  };
                }
                return true;
              }},
            {dataField: 'program', text: 'Program',validator: (newValue, row, column) => {
                if (!newValue) {
                  return {
                    valid: false,
                    message: 'Program field is required'
                  };
                }
                return true;
              }},
            {dataField: 'grade', text: 'Grade'},
           
            {dataField: 'awardYear', text: 'Year of Award',  sort: true,validator: (newValue, row, column) => {
                if (!newValue) {
                  return {
                    valid: false,
                    message: 'Award Year field is required'
                  };
                }
                return true;
              } },
            {dataField: 'remarks', text: 'Remarks',  sort: true, formatter:(cell)=><span style={{display:'block',  width:100, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span> }
                  
         ];


         const tableDocumentHeader = [
            {dataField: 'code', text: 'x', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleRemove(row.code, 'document')}><i className="fa fa-trash"></i></button>, editable: false},
            {dataField: 'documentTitle', text: 'Title', validator: (newValue, row, column) => {
                if (!newValue) {
                  return {
                    valid: false,
                    message: 'Document Title field is required'
                  };
                }
                return true;
              }},
              {dataField: 'documentType', text: 'Type', formatter: (cell)=>fetchNameByField(cell, documents, 'documentName'),  editable: false },
            {dataField: 'fileUrl', text: 'View', formatter: (cell)=> <a href={ImagesUrl+'/staff/documents/'+cell} target="_new"    title="View"><i className="fa fa-download"></i>Download </a>,  editable: false }
                  
         ];


         const tableJobHistoryHeader = [
            {dataField: 'code', text: 'x', formatter: (cell, row)=><button type="button" className="btn btn-danger btn-sm" onClick={()=>handleRemove(row.code, 'jobHistory')}><i className="fa fa-trash"></i></button>, editable: false},
            {dataField: 'remark', text: 'Remark', validator: (newValue, row, column) => {
                if (!newValue) {
                  return {
                    valid: false,
                    message: 'Remark field is required'
                  };
                }
                return true;
              }, formatter:(cell)=><span style={{display:'block',  width:100, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},
              {dataField: 'department', text: 'Type', formatter: (cell)=>getDepartment(cell),  editable: false },
              
            {dataField: 'dateStart', text: 'Date', formatter: (cell, row)=> <span>{longDate(row.dateStart) + ' - '+longDate(row.dateEnd)}</span>,  editable: false }
                  
         ];


         const BasicTable=(props)=>{
            const handleUpdate=(column, newValue, code)=>{   
                
                var table = props.table
        
                 if(table==='qualification'){
                     const records = JSON.parse(staff[0].qualification)
                    var allData = records.filter(item=>item.code!==code)
                    let edit = records.filter(item=>item.code===code)
                        edit[0][column.dataField] = newValue
                        edit.concat(allData)
    
                     const newData = staff
                        newData[0].qualification = JSON.stringify(records)
                        setStaff(newData)
    
                }  else if(table==='document'){
    
                    const records = JSON.parse(staff[0].document)
                    var allData = records.filter(item=>item.code!==code)
                    let edit = records.filter(item=>item.code===code)
                        edit[0][column.dataField] = newValue
                        edit.concat(allData)
    
                     const newData = staff
                        newData[0].document = JSON.stringify(records)
                        setStaff(newData)
        
                }else{
                    const records = JSON.parse(staff[0].jobHistory)
                    var allData = records.filter(item=>item.code!==code)
                    let edit = records.filter(item=>item.code===code)
                        edit[0][column.dataField] = newValue
                        edit.concat(allData)
    
                     const newData = staff
                        newData[0].jobHistory = JSON.stringify(records)
                        setStaff(newData)
                } 
              }
        
        
                 
                  const customTotal = (from, to, size) => (
                     <span >&nbsp;Showing { from } to { to } of { size } items</span>
                   );
                const options = {
                    showTotal: true,
                paginationTotalRenderer: customTotal,
                sizePerPageList: [{text: '16', value: 16}, {text: '32', value: 32}, {text: '64', value: 64},
                { text: 'All', value: props.data.length }]
                   
                  };
          
             return  <ToolkitProvider search  className="table-responsive"
                         keyField='code' data={ props.data } columns={ props.columns } >
                             
                             {
                                 props => (
                                 <div >
                                   
                                     
                                     <BootstrapTable
                                     { ...props.baseProps }
                                                 striped
                                             hover
                                             condensed
                                             noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                         pagination={paginationFactory(options) }
                                         cellEdit={ cellEditFactory({
                                           mode: 'dbclick',
                                           blurToSave: true,
                                           autoSelectText: true,
                                 beforeSaveCell: (oldValue, newValue, row, column) => {
                                   if(oldValue !==newValue){
                                   handleUpdate(column, newValue, row.code);
                                   
                                  return '';
                                 }
                                 
                               }
                                         }) }
                                     />
                                 </div>
                                 )
                             }
          </ToolkitProvider>
          }



     

      useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmittingQualification){
        submitQualification()
        }
        },[errors])

  
useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmittingDocument){
        submitDocument()
    }
    },[errors])

    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmittingHistory){
            submitHistory()
        }
        },[errors])


      

    


const  submit=()=>{  
    let formData = staff[0];
    const fd = new FormData();
    fd.append('jwt', Token); 
        for(let k in formData){
            fd.append(k,formData[k]);
        }; 

          //  fd.append('passport', general.passport);
    

        setNotice({...notice, 
          isLoading: true}) 
           let url = ServerUrl+'/update_controller/tbl_staff'
          axios.post(url, fd, config)
          .then(response =>{
            
            if (response.data.type ==='info'){
                Alerts('Saved!', 'info', response.data.message)
            } else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
        }  })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        .finally(()=>{
            handleFetchAllStaff()
          setNotice({...notice, isLoading: false}) 
          setEditStaff(false); 
          setIsSubmitting(false);
    } )

}

    
useEffect(()=>{
	if(Object.keys(errors).length === 0 && isSubmitting){
	submit()
	}
    },[errors])
    
    const handleSubmit = event =>{
		event.preventDefault();
		setErrors(ValidateInput(staff[0]));
		setIsSubmitting(true);
    }
     

 const ValidateInput=(values)=>{
     let errors = {};     

     if(!values.staffName){
         errors.staffName ='Please enter staff name';
     }
     
     

    return errors; 
    }


    
   
    
    const  handleFile = function(fieldName){
        return function(newValue){                 
            const value = newValue.target.files[0]
          // setImage(value)
       readURL(newValue);
       }
       
      } 
    const  handleDocumentFile = function(fieldName){
        return function(newValue){  
          //  const value = newValue.target.files[0]
         //  setImage({...image, [fieldName]:value})
       readDocument(newValue);
       }
       
    } 
    const readDocument = (input)=>{
       
        let displayMessage = '';
       // const PreviewImage = input.target.name;

            var fileName = document.getElementById(input.target.name).value;

        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
        if (extFile==="jpg" || extFile==="jpeg" || extFile==="png"  || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" || extFile==="pdf"){
            //TO DO    
            
             var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
        if (FileSize > 1) {
            displayMessage = input.target.name +': File uploaded is more than maximum size Allow. it exceeds 1 Mb';
           document.getElementById(input.target.name).value = '';
           setGeneral({...general, documentFile:''})
        } else {
            if (input.target.files && input.target.files[0]) { //Check if input has files.

                
                setGeneral({...general, documentFile:input.target.files[0]})
                setErrors({...errors, documentFile:''})                
            }
        }
        }else{

            displayMessage = 'Only pdf|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 500kb'
            document.getElementById(input.target.name).value = '';
            setGeneral({...general, documentFile:''})
        }   
        
        if(displayMessage.length > 0){
            Alerts('Error!', 'danger', displayMessage)
          }
        
    } 

    const readURL = (input)=>{
       
        let displayMessage = '';
        const PreviewImage = input.target.name;
            let newImage = "viewPassport";
            var fileName = document.getElementById(PreviewImage).value;
        var idxDot = fileName.lastIndexOf(".") + 1;
        var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();

        if (extFile==="jpg" || extFile==="jpeg" || extFile==="png"  || extFile==="JPG" || extFile==="JPEG" || extFile==="PNG" ){
            //TO DO    
            
             var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
        if (FileSize > 1) {
            displayMessage = PreviewImage +': File uploaded is more than maximum size Allow. it exceeds 1 Mb';
            document.getElementById(PreviewImage).value = '';
           setImage({...image, passport:''})
           setGeneral({...general, passport:''})
        } else {
            if (input.target.files && input.target.files[0]) { //Check if input has files.

            
                var reader = new FileReader();//Initialize FileReader.
                reader.onload = function (e) {
            if(PreviewImage==='passport'){
                                setGeneral({...general, passport:input.target.files[0]})
                                setImage({...image, passport:e.target.result})
                            }else{
                                setGeneral({...general, documentFile:input.target.files[0]})
                            }
                };
                reader.readAsDataURL(input.target.files[0]);
            }
        }
        }else{

            displayMessage = 'Only jpg|png|jpeg|JPEG|PNG|JPG  files are allowed with maximum of 1 Mb'
            document.getElementById(input.target.name).value = '';
            setImage({...image, passport:''})
            setGeneral({...general, passport:''})
        }  
         if(displayMessage.length > 0){
            Alerts('Error!', 'danger', displayMessage)
          }
        
    } 


       
       
           
       
       
    const tableHeader = [
    
        {dataField: 'passport', text: 'Photo', editable: false, formatter:(cell, row)=><img className="img-70" style={{ height:'70px', width:'70px' }} onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/"+row.gender+".png"}} src={ImagesUrl+'/staff/'+cell} title={row.staffName}  alt={row.staffName} />, editable:false},
        {dataField: 'staffName', text: 'Staff Name', sort: true, validator: (newValue, row, column) => {
            if (!newValue) {
              return {
                valid: false,
                message: 'Staff Name field is required'
              };
            }
            return true;
          } },

        {dataField: 'department',  text: 'Department', editable:false, formatter:(cell)=>getDepartment(cell),  sort: true},
        {dataField: 'position',  text: 'Position', editable:false, formatter:(cell)=> getPosition(cell),  sort: true},
        {dataField: 'userType',  text: 'Group', editable:false, formatter:(cell)=>getUserType(cell),  sort: true},
       
          {dataField: 'fileNo',  text: 'File No',  sort: true},
        {dataField: 'email',  text: 'Email',  sort: true},
        {dataField: 'mobile',  text: 'Mobile',  sort: true},
        {dataField: 'birthDate',   text: 'Age',  sort: true, editor: {
            type: Type.DATE
          }, formatter:(cell)=>getAge(cell)},
        {dataField: 'fatherName',  text: 'Father Name', hidden: true,  sort: true},
        {dataField: 'motherName',  text: 'Mother Name', hidden: true,  sort: true},
        {dataField: 'gender',  text: 'Gender', hidden: true,  sort: true},
        {dataField: 'altMobile',  text: 'Alt Mobile', hidden: true,  sort: true},
        {dataField: 'presentAddress',  text: 'Present Address', hidden: true,  sort: true},
        
        {dataField: 'permanentAddress',  text: 'Permanent Address', hidden: true, sort: true},
        {dataField: 'employmentStatus',  text: 'status',   formatter: (cell)=>cell==='Working'? <span className="pcoded-badge label label-success">Working</span>: <span className="pcoded-badge label label-danger">{cell}</span>,
        editor: {
            type: Type.SELECT, options:[
                { value: 'Working', label: 'Working' },
                { value: 'Resigned', label: 'Resigned' },
                { value: 'Releived', label: 'Releived' }
            ] }, sort: true},
        {dataField: 'joinDate', hidden: true, text: 'Join Date',  sort: true, editor: {
            type: Type.DATE
          } },
          {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>

          <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row.code)} mID={row.code} /> 
          
          <div className="btn-group dropdown-split-success ">
                  <button type="button" className="btn btn-sm btn-outline-success dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      <i className="fa fa-hand"></i>Action
                  </button>
                  <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
                    
          
                      <a className="dropdown-item waves-effect waves-light"  href={"/staff/profile/"+row.code} >  <i className='fa fa-graduation-cap'></i> Preview Staff</a>
          
                     {allowEdit==='Yes'?<a className="dropdown-item waves-effect waves-light" onClick={()=>handleEditStaff(row)} href="#!"><i className="icofont icofont-edit-alt"></i> Edit Staff</a>:[]} 
          
                    {row.sectionID!==''?  <a className="dropdown-item waves-effect waves-light"  href="#!" onClick={()=>loginAsStaff(row)} >  <i className='fa fa-user'></i> Login as this Staff</a>:''}

                      <div className="dropdown-divider"></div>
                      {allowDelete==='Yes'?  <a href="#!" data-toggle="modal" className="dropdown-item "  data-target={`#warningdialog-${row.code}`} title="Delete"><i className="fa fa-trash"></i>Delete Staff</a>:[]}
                  </div>
              </div>
          
                    </div>, editable: false}
       
     ];

    const StaffTable=(props)=>{

        const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
          }) => ( 
          <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
              {
                columns
                  .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                  .map(column => (
                    
                    <button
                      type="button"
                      key={ column.dataField }
                      className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                      data-toggle="button"
                      aria-pressed={ column.toggle ? 'true' : 'false' }
                      onClick={ () => onColumnToggle(column.dataField) }
                    >
                      { column.text }
                    </button>
                  ))
              }
            </div>
            
          );
  
        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
            fd.append('jwt', Token); 
             fd.append('columnField', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
           
            let url = ServerUrl+'/updateById_controller/tbl_staff'
           axios.post(url, fd, config)
           //.then(result => console.log(result.data))
           .then()
           .catch((error)=>console.log(error)) 
           
         } 
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                 <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'250px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>

      <div className="dt-buttons btn-group pull-right"> 
      <a href="/staff/staff_registration" className="btn btn-outline-success waves-effect waves-light" >
            <i className="fa fa-plus"></i>Add New </a>	


    <div className="btn-group dropdown-split-primary ">
        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>


        <form method='POST' action={ServerUrl+'/excel_controller.php?api=staff'} target='_blank'>
        <input type='hidden' name='gender' value ={general.gender.value} />
                <input type='hidden' name='status' value ={general.status.value} />
                <input type='hidden' name='position' value ={general.position.value} />

                <input type='hidden' name='fileNo' value ={general.fileNo} />
                <input type='hidden' name='department' value ={general.department.value} />

                <input type='hidden' name='jwt' value ={Token} />
               
        <button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>
        
        </form>

        <form method='POST' action={ServerUrl+'/printout/staff_records.php?token='+userToken} target='_blank'>
                <input type='hidden' name='gender' value ={general.gender.value} />
                <input type='hidden' name='status' value ={general.status.value} />
                <input type='hidden' name='position' value ={general.position.value} />

                <input type='hidden' name='fileNo' value ={general.fileNo} />
                <input type='hidden' name='department' value ={general.department.value} />

                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
        
        </form>
        
        </div>
    </div> 
    </div> 
      
                                 </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
    

      function updatePassport(){  

        if(general.passport===''){
          Alerts('Error!', 'danger', 'Kindly upload  passport')
        }else{
                  setNotice({...notice,  isLoading: true})        
            
                  const fd = new FormData();
                  fd.append('jwt', Token);
                  
                    
                fd.append('staffName', staff[0].staffName);
                fd.append('staffCode', staff[0].code);
                fd.append('imageUrl', general.passport);

                     let url = ServerUrl+'/save_controller/tbl_teacher_passport'
                    axios.post(url, fd, config)
                    .then(response =>{
                     if(response.data.type ==='success'){
                      Alerts('Saved!', 'success', response.data.message)
                        } else{
                            setErrors({...errors, errorMessage: JSON.stringify(response.data)})
                            window.scrollTo(0,0);
                        }   
                    })
                    .catch((error)=>{
                        setErrors({...errors, errorMessage: error.message})
                    }).finally(()=>{
                        handleFetchAllStaff()
                        setStaff([])
                      setNotice({...notice,  isLoading: false })
                  setGeneral({...general, passport:''})
                  setImage({...image, passport:''})
                    }) 
                }
              }


      const loginAsStaff =(row)=>{


       //1000 * 60 * SESSION_IDEL_MINUTES


            var inSixtyMinutes = Date.now() + 59 * 60 * 1000;
		var inOneMinutes = Date.now() + 58 * 60 * 1000;
		var inOneHours = new Date(new Date().getTime() + 59 * 60 * 1000);
       

      
        Cookies.set('_kayus_expt', inSixtyMinutes, {expires: inOneHours, domain: domain  })
		Cookies.set('_kayus_bexpt', inOneMinutes, {expires: inOneHours, domain: domain})


		Cookies.set('kayus_code', encrypt(row.code), {expires: inOneHours, domain: domain })
		Cookies.set('kayus_token', encrypt(Token),  {expires: inOneHours, domain: domain }) 
		Cookies.set('kayus_class', encrypt(row.classID), {expires: inOneHours, domain: domain })
		Cookies.set('kayus_section', encrypt(row.sectionID),  {expires: inOneHours, domain: domain })



            window.open(teacherUrl+'/dashboard', '_blank')

      }

    

    useEffect(()=>{  
       
    setIsAuthenticated(menuLink.includes(location.pathname))
        fetchGroup() 
        fetchDepartment();
    },[]);



        return (  <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }

        <PageHeader title="Staff">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-users"></i> </a>
                            </li>
                    <li className="breadcrumb-item"><a href="#!">View Staff</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">





                {errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}




                               {/*  <!-- Edit Staff start here  --> */}
                               <div ref={structure} />
                               {editStaff ?   <div className="row">
                                <div className="col-sm-12">
                              
                                                
<div className="card z-depth-0">

    <div className="card-header">
        <h5><a href="#!" > <i className="fa fa-print" ></i> </a> Modify Details of {staffName}</h5>
        <div className="card-header-right">
            <ul className="list-unstyled card-option">
                <li><i className="feather icon-maximizes full-card"></i></li>
                <li><i className="feather icon-minus minimize-card"></i></li>
                <li><i className="feather icon-trash-2 close-card"></i></li>
            </ul>
        </div>
    </div>
        <div className="card-block">
                                        
    <div className="container">
        
        <div className="stepwizard col-md-offset-3">
            <div className="stepwizard-row setup-panel">
           {steps.map((item, idx)=>{

               return <div key={idx} className="stepwizard-step">
                <a href="#!" type="button" onClick={()=>handleOnClickStepper(idx+1)}  className={activeStep === (idx+1) ? "btn btn-primary btn-circle" :"btn btn-default btn-circle"}><b>{idx+1}</b></a>
                <p>{item.title}</p>
            </div>
           })}
           
            </div>

        </div>
       {staff&&staff.map((st, idx)=> <div key={idx} >

{activeStep === 1 ? <div className="row setup-content" id="Staff" >
                
                <div className="col-md-12">
                <div className="card z-depth-0">
            <div className="card-block">
                <div className="row">
                <section className="col-md-4">
                <div className="form-group">
                        <label >File Number</label>
                        <input type="text" className="form-control" name="fileNo" onChange={handleChange} value={st.fileNo} />
                                   </div>

                                <div className="form-group">
                        <label >Staff Name <span style={{color:'red'}}>*</span></label>
                     <input type="text" className={errors.staffName  ? 'form-control form-control-danger' : 'form-control'} name='staffName' onChange={handleChange} value={st.staffName} />
                     <span style={{color:'red'}}>{errors.staffName && errors.staffName}</span>
                                 </div>
                               
                                 <div className="form-group">
						<label > Position<span style={{color:'red'}}>*</span></label> <a href="/staff/master_entry" className="pull-right">Add More Position</a>
						<Select options={
                            position&&position.map((list, id)=> {
                               return {key:id, value: list.code, label: list.positionName}
                             })} 
onChange={handleSelect} className={errors.position ? 'form-control form-control-danger' : ''} name="position"  value={{value:st.position, label:getPosition(st.position)}}  /> <span style={{color:'red'}}>{errors.position && errors.position}</span>
		</div>

                                           

        <input id="passport"  onChange={handleFile('passport')} className="form-control file" name="passport" type="file" accept="image/*" /> 

<div className="form-group">  

<a href="#!" htmlFor="passport" onClick={()=>document.getElementById('passport').click()} className="profile-image" title='change profile'>
{st.passport!==''?image.passport===''?

<img id="viewPassport2" className="image" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+st.gender+'.png'}} src={`${ImagesUrl}/staff/${st.passport}`}  style={{display:'block', height:'150px',width:'150px'}} title="Staff Passport"  alt="Staff Passport" /> :

<img id="viewPassport" className="image" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+st.gender+'.png'}} src={image.passport}  style={{display:'block',height:'150px',width:'150px'}} title="Staff Passport"  alt="Staff Passport" /> :  

<img id="viewPassport" className="image" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/'+st.gender+'.png'}} src={image.passport}  style={{display:'block',height:'150px',width:'150px'}} title="Staff Passport"  alt="Staff Passport" />        } 

 </a>               
                
{general.passport===''? <label  htmlFor="passport" title="Change Passport"   className='btn btn-sm'><i className="fa fa-camera fa-2x"></i> Change Passport</label>:<button type='button' onClick={updatePassport} className='btn btn-success btn-sm'><i className="fa fa-image"></i> Save</button>}

</div>
                                           
                                        </section>	

                                        <section className="col-md-4">
                                        <div className="form-group">
                        <label >Joining Date</label>
                        <div className="input-group input-group-primary">
                    <span className="input-group-addon">
            <i className="fa fa-calendar"></i>
            </span>
                    <input type="date" className="form-control" name="joinDate"  onChange={handleChange} value={st.joinDate} /><span>{errors.joinDate && errors.joinDate}</span>
                </div> </div>

                <div className="form-group">
                        <label >Date of Birth</label>
                        <div className="input-group input-group-primary">
                    <span className="input-group-addon">
            <i className="fa fa-calendar"></i>
            </span>
                    <input type="date" className="form-control " name="birthDate"  onChange={handleChange} value={st.birthDate} />
                </div>
                                                
                     </div> 

                         <div className="form-group">
                        <label > Gender</label>
                        <Select  options={[
                    { value: 'Male', label: 'Male' },
                    { value: 'Female', label: 'Female' }
                ]} 
                        onChange={handleSelect}  name="gender" value={{value: st.gender, label: st.gender}}  /> 
             </div>
                                           
            
             <div className="form-group">
						<label > Department<span style={{color:'red'}}>*</span></label> <a href="/staff/master_entry" className="pull-right">Add More Department</a>
						<Select options={
                            department&&department.map((list, id)=> {
                               return {key:id, value: list.code, label: list.departmentName }
                             })} 
onChange={handleSelect} className={errors.department ? 'form-control form-control-danger' : ''} name="department" value={{value:st.department, label:getDepartment(st.department)}}  /> <span style={{color:'red'}}>{errors.department && errors.department}</span>
												
											</div>

                                            {st.sectionID!==''?  <div className="form-group">
                         <button type='button' onClick={()=>loginAsStaff(st)} className="btn btn-danger"  > <i className="fa fa-key"></i>Login as this staff </button>
					
										</div>  :''}                    
                                           
                                           
                                   
                  
                                        </section>

                                        <section className="col-md-4">
                                        <div className="form-group">
                        <label >Religion</label>
                        <Select  options={[
                    { value: 'Christianity', label: 'Christianity' },
                    { value: 'Islam', label: 'Islam'},
                ]} 
onChange={handleSelect}  name="religion" value={{value: st.religion, label: st.religion}} styles={selectStyles} />
                                                
                                            </div> 
                                     
                                          
                                            <div className="form-group">
						<label > Group</label> 
						<Select options={
                            group&&group.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.groupName }
                             })} 
onChange={handleSelect} className={errors.userType ? 'form-control form-control-danger' : ''} name="userType" value={{value:st.userType, label:getUserType(st.userType)}}  /> 
<span style={{color:'red'}}>{errors.userType && errors.userType}</span>
												
											</div>
                                           
                                            <div className="form-group">
                        <label >Blood Group</label>
                        <Select  options={[
                { value: 'A Plus', label: 'A+' },
                { value: 'A Minus', label: 'A-'},
                { value: 'AB Plus', label: 'AB+'},
                { value: 'AB Minus', label: 'AB-' },
                { value: 'B Plus', label: 'B+'  },
                { value: 'B Minus', label: 'B-' },
                { value: 'O Plus', label: 'O+' },
                { value: 'O Minus', label: 'O-' }
                ]} 
onChange={handleSelect}  name="bloodGroup" value={{value: st.bloodGroup, label: st.bloodGroup}} styles={selectStyles} />  </div>


                        
                                            <div className="form-group">
                        <label > Employment Status</label>
                        <Select  options={[
                    { value: 'Working', label: 'Working' },
                    { value: 'Resigned', label: 'Resigned' },
                    { value: 'Releived', label: 'Releived' }
                ]} 
                        onChange={handleSelect}  name="employmentStatus" value={{value: st.employmentStatus, label: st.employmentStatus}}  /> 
             </div>
             <div className="form-group">
                                            <label>Status</label>
<div className="border-checkbox-section">
					
                    <div className="border-checkbox-group border-checkbox-group-primary">
                        <input className="border-checkbox" type="checkbox" onChange={(e)=>handleCheckbox(e, idx)} name="status" value={st.status} checked={st.status} id="status" />
                                <label className="border-checkbox-label" htmlFor="status">Active</label>
                            </div>
                            
                            </div> </div>     
                                        </section>
                                        </div>
                                                
            </div></div>
            
                </div>
                
    </div> 
   
   : activeStep === 2 ? <div className="row setup-content" id="contact" >
                
   <div className="col-md-12">

<div className="card-block">
           {/* <!-- document details here  --> */}
           <div className="card z-depth-0">

<div className="card-block">
   <div className="row">
   <section className="col-md-4">

<div className="form-group">
<label >Country</label>

<Select  options={countries.map((data, idx)=> {
                                    return {key:idx, value: data.name.common, label: data.name.common };
                                  })}
onChange={handleSelect}  name="country" value={{value: st.country, label: st.country}} styles={selectStyles} /> 

                     
                 </div>
                 
</section>	

   <section className="col-md-4">
   <div className="form-group">
						<label > State</label> 
                        <Select   options={states&&states.map((data, idx)=> {
                                    return {key:idx, value: data.alias, label: data.name };
                                  })}
onChange={handleSelect}  name="province" value={{value: st.province, label: fetchNameByState(st.province, states, 'name')}} styles={selectStyles} /> 


											</div> </section>

                         
                           <section className="col-md-4">
                           <div className="form-group">
                        <label >City</label>
                        <Select   options={lga&&lga.map((data, idx)=> {
								return {key:idx, value:data.name, label:data.name}
                                  })}
onChange={handleSelect}  name="lga" value={{value: st.lga, label:st.lga}} styles={selectStyles} /> 
                                                
                                            </div>
                           </section>
                           <section className="col-md-4">
                          
                        <div className="form-group">
                     <label >Current Address</label>
                    <input type="text" name="presentAddress" onChange={handleChange} value={st.presentAddress} className="form-control" />  </div>
                           </section>
                          

                           <section className="col-md-4">
                          
                        <div className="form-group">
                       
                       <label >Permananet Address</label>
                       <input  type="text" name="permanentAddress" onChange={handleChange} value={st.permanentAddress} className="form-control" />  </div>
                             </section>


                             <section className="col-md-4">
                          
                             <div className="form-group">
                        <label >Email</label>
                        <input type="text" className="form-control" name="email" readOnly={true} onChange={handleChange} value={st.email} />
                                                
                                            </div>
                           </section>

                           <section className="col-md-4">
                          
                           <div className="form-group">
                        <label >Mobile</label>
                        <input  type="text" name="mobile" onChange={handleChange} value={st.mobile} className="form-control" /><span className='error'> {errors.mobile && errors.mobile}</span> </div>
                        </section>



                        <section className="col-md-4">
                        <div className="form-group">
                        <label >Alternate Mobile</label>
                        <input  type="text" name="altMobile" onChange={handleChange} value={st.altMobile} className="form-control" />  </div>

                      
                           </section>                     
                                           
                         
                     <section className="col-md-4">
                     <div className="form-group">
                        <label >National Identity Number</label>
                        <input type="text" className="form-control" name="nin" onChange={handleChange} value={st.nin} />
                                   </div>
                                   </section>

                                   <section className="col-md-4">
                                    <div className="form-group">
                        <label >Father Name</label>
                        <input type="text" className="form-control" name="fatherName" onChange={handleChange} value={st.fatherName} />
                                                
                                            </div>
                                            </section>       	
                                            <section className="col-md-4">
                                            <div className="form-group">
                        <label >Mother Name</label>
                        <input type="text" className="form-control" name="motherName" onChange={handleChange} value={st.motherName} />
                                                
                                            </div>

                                            </section>

                                            <section className="col-md-4">
                                            <div className="form-group">
                        <label >Username</label>
                        <input type="text" className="form-control"  name="username" onChange={handleChange} value={st.username} />
                                                
                                            </div>
                                            </section>
                           </div>
                           </div>
   
   </div>

</div>
   
                  
   </div>
</div>:activeStep===3?



<div className="row setup-content" id="qualification" >
                
                <div className="col-md-12">
                <div className="card z-depth-0">
        
        <div className="card-block">
              	
                       {/*  <!-- Qulaification details here  --> */}
                        <div className="card z-depth-0">
        
        <div className="card-block">
                <div className="row">
                                    
                <section className="col-md-4">
                                        <div className="form-group">
                        <label >Name of Institution<span style={{color:'red'}}>*</span></label>
                    
                    <input type="text" className={errors.institution  ? 'form-control form-control-danger' : 'form-control'} onChange={handleChangeGeneral} name="institution" value= {general.institution} placeholder="Institution" /><span style={{color:'red'}}>{errors.institution && errors.institution}</span>
                </div>
                                           <div className="form-group">
                        <label >Grade/Class</label>
                        <input type="text" onChange={handleChangeGeneral} name="grade" value= {general.grade} className="form-control " />
                                                
                                            </div>
                                           
                                        </section>	
                                        <section className="col-md-4">
                                        <div className="form-group">
                        <label >Program<span style={{color:'red'}}>*</span></label>
                        <input type="text" className={errors.program  ? 'form-control form-control-danger' : 'form-control'} onChange={handleChangeGeneral} name="program" value= {general.program} />
                        <span style={{color:'red'}}>{errors.program && errors.program}</span>             
                                            </div>
                                        <div className="form-group">
                        <label >Year of Award<span style={{color:'red'}}>*</span></label>
                        <input type="text" className={errors.awardYear  ? 'form-control form-control-danger' : 'form-control'} onChange={handleChangeGeneral} name="awardYear" value= {general.awardYear} /><span style={{color:'red'}}>{errors.awardYear && errors.awardYear}</span>
                                                
                                            </div>
                                            
                                        </section>
                                        <section className="col-md-4">
                                        <div className="form-group">
                        <label >Remarks</label>
                        <input type="text" className="form-control " onChange={handleChangeGeneral} name="remarks" value= {general.remarks}  />
                                                
                                            </div>
                                        
                                            <br/>
                                                <footer className="pull-right">
                        <button type="button" onClick={handleSubmitQualification} className="btn btn-inverse" ><i className="fa fa-save" ></i> Save </button>
            </footer>
                                            
                                        </section>
                                        </div>
                                        </div>
                
                </div>	
                
                
                <div className="card z-depth-0">
        
        <div className="card-block">
                <div className="row">
                                    
                <section className="col-md-12 table-responsive">
                    
                    <BasicTable data={staff&&JSON.parse(staff[0].qualification)}  columns={tableQualificationHeader}  />
  </section>
                                        </div>
                                        </div>
                
                </div>
                </div>
                
                </div>	
                            
                </div>
                
    </div>    
    
   : activeStep === 4 ?  <div className="row setup-content" id="document" >
                
                <div className="col-md-12">
                <div className="card z-depth-0">
        
        <div className="card-block">
                <div className="card z-depth-0">
        
        <div className="card-block">
                <div className="row">
                                    
                <section className="col-md-12 table-responsive">
                  
                    <BasicTable data={staff&&JSON.parse(staff[0].document)} columns={tableDocumentHeader}  />
                      </section>
                                        </div>
                                        </div>
                
                </div>	
                        {/* <!-- document details here  --> */}
                        <div className="card z-depth-0">
        
        <div className="card-block">
                <div className="row">
                                    
                <section className="col-md-4">
                                        <div className="form-group">
                        <label >Title </label>
                    
                    <input type="text" className={errors.documentTitle  ? 'form-control form-control-danger' : 'form-control'} name="documentTitle" onChange={handleChangeGeneral} value= {general.documentTitle} placeholder="Document title" /><span style={{color:'red'}}>{errors.documentTitle && errors.documentTitle}</span>
                </div>  </section>	
                                        <section className="col-md-4">
                                        <div className="form-group">
                        <label >Document</label>
                        <Select   options={documents.map((data, idx)=> {
                                    return {key:idx, value: data.code, label: data.documentName };
                                  })}
onChange={handleSelectGeneral} className={errors.documentType  ? 'form-control form-control-danger' : ''} name="documentType" value={general.documentType}  /> 
                  <span style={{color:'red'}}>    {errors.documentType && errors.documentType}</span>          
                                            </div>
                                            
                                        </section>
                                        <section className="col-md-4">
                                        <div className="form-group">
                                            <label >Document</label><br/>
                   
                    <input id="documentFile"  onChange={handleFile('documentFile')}   className={errors.documentFile  ? 'form-control form-control-danger' : 'form-control'} name="documentFile" type="file"  /> 
					<span style={{color:'red'}}>    {errors.documentFile && errors.documentFile}</span> 
                    	<div className="note" style={{color:'red'}}>
			<p><strong>Size 300 kb</strong>, jpg|png|jpeg Only Allow</p>
		</div>	
										</div>
                                        </section>
                                       
                                        </div>
                                        </div>
                
                </div>
            
                <footer className="pull-right">
                        <button type="button" className="btn btn-inverse" onClick={handleSubmitDocument} ><i className="fa fa-save" ></i> Save </button>
            </footer>
            </div>
                
                </div>	
                               
                </div>
    </div>
    
  :  <div className="row setup-content" id="InOutTime">
                
                <div className="col-md-12">
                <div className="card z-depth-0">
        
        <div className="card-block">
               
                        {/* <!-- document details here  --> */}
                        <div className="card z-depth-0">
        
        <div className="card-block">
                <div className="row">

                <section className="col-md-6">
                <div className="form-group">
						<label > Department</label>
						<Select options={
                            department&&department.map((list, id)=> {
                               return {key:id, value: list.code, label: list.departmentName }
                             })} 
onChange={handleSelectGeneral} className={errors.departmentHistory ? 'form-control form-control-danger' : ''} name="departmentHistory" value={general.departmentHistory}  /> 				
											</div>
                </section>
                

                <section className="col-md-6">
                <div className="form-group">
                        <label >Remark </label>
                    
                    <input type="text" className={errors.dateStart  ? 'form-control form-control-danger' : 'form-control'} name="remarkHistory" onChange={handleChangeGeneral} value= {general.remarkHistory} />
                </div>       
                </section>

                <section className="col-md-4">
                                        <div className="form-group">
                        <label >Date Start </label>
                    
                    <input type="date" className={errors.dateStart  ? 'form-control form-control-danger' : 'form-control'} name="dateStart" onChange={handleChangeGeneral} value= {general.dateStart} /><span style={{color:'red'}}>{errors.dateStart&&errors.dateStart}</span>
                </div>  </section>

                <section className="col-md-4">
                                        <div className="form-group">
                        <label >Date End </label>
                    
                    <input type="date" className={errors.dateEnd  ? 'form-control form-control-danger' : 'form-control'} name="dateEnd" onChange={handleChangeGeneral} value= {general.dateEnd}  /><span style={{color:'red'}}>{errors.dateEnd&&errors.dateEnd }</span>
                </div>  </section>

                                           	
                                        <section className="col-md-4">
                                        <div className="form-group">
                                        <footer className="pull-right"><br/>
                        <button type="button" className="btn btn-inverse" onClick={handleSubmitHistory} ><i className="fa fa-save" ></i> Save </button>
            </footer>
                                       </div>      
                                        </section>
                                      
                                       
                                        </div>
                                        </div>		  
                                       
                                       </div>

                                       <div className="card z-depth-0">
        
        <div className="card-block">
                <div className="row">
                                    
                <section className="col-md-12 table-responsive">
                    <BasicTable data={staff&&JSON.parse(staff[0].jobHistory)} columns={tableJobHistoryHeader}  />
                            </section>
                                        </div>
                                        </div>
                
                </div>	
                </div>		  
                                       
                </div></div>

        </div> }
        </div>
       )}
        </div>

    <button onClick={activeStep === steps.length ? handleSubmit : handleOnClickNext} className={activeStep === steps.length ? 'btn btn-success pull-right':'btn btn-primary  btn-round pull-right'}  type="button" >{activeStep === steps.length ? <i className="icofont icofont-check-circled"> Update Changes</i> : ' Continue'}</button>

{activeStep ===1 ? '' : <button className="btn btn-primary  btn-round  pull-left" type="button" onClick={handleOnClickBack} >Previous</button> }  

</div>
        </div>
    </div></div>:''}
    
       
    

    
                
    {!editStaff ?<div className="row  no-print">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Search Staff</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
        <div className="row">
        <section className="col-md-4">
    
        <div className="form-group">
						<label > Search By Department</label> 
						<Select options={
                            department&&department.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.departmentName }
                             })} 
onChange={handleSelectSearch}  name="department" value={general.department}  /> 				
											</div>
			</section>


           <section className="col-md-4">
           <div className="form-group">
						<label > Search By Position</label> 
						<Select options={
                            position&&position.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.positionName }
                             })} 
onChange={handleSelectSearch}  name="position" value={general.position}  /> 
												
											</div>       </section>


                                            <section className="col-md-4">
           <div className="form-group">
						<label > Search By Group</label> 
						<Select options={
                            group&&group.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.groupName }
                             })} 
onChange={handleSelectSearch}  name="userType" value={general.userType}  /> 
												
											</div>       </section>


                                    

									</div>
                   <div className="row">

                   <section className="col-md-4">
                   <div className="form-group">
				<label > Search by Class Tutor </label>
				<Select  options={
                            schoolClass&&schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelectSearch}  name="studentClass" value={general.studentClass}  /> 



</div>
</section>

                   <section className="col-md-4">
                                            <div className="form-group">
						<label > Search By Status</label> 
						<Select  options={[
                             { value: 'All', label: 'All' },
                     { value: 'Working', label: 'Working' },
                     { value: 'Resigned', label: 'Resigned' },
                     { value: 'Releived', label: 'Releived' }
                ]} 
                        onChange={handleSelectSearch}  name="status" value={general.status}   /> 
											</div>
                       </section>

                      

                       <section className="col-md-4">
                                            <div className="form-group">
						<label > Search By File Number</label> 
						<input type="text" className="form-control" name="fileNo" value={general.fileNo}  onChange={handleChangeSearch} />
											</div>
                       </section>

                       </div>
                       <div className="row">

                       <section className="col-md-4">
                                            <div className="form-group">
						<label > Search By Gender</label> 
						<Select  options={[
                            { value: 'All', label: 'All' },
                    { value: 'Male', label: 'Male' },
                    { value: 'Female', label: 'Female' },
                ]} 
                        onChange={handleSelectSearch}  name="gender" value={general.gender}   /> 
											</div>
                       </section>


                       <section className="col-md-4">
                                            <div className="form-group">
						<label > Search By Staff Name</label> 
						<input type="text" className="form-control" name="staffName" value={general.staffName}  onChange={handleChangeSearch} />
											</div>
                       </section>


                       <section className="col-md-4">
                                            <div className="form-group">
						<label > Page Layout</label> 
						<Select  options={[
                    { value: 'card', label: 'Card View' },
                    { value: 'list', label: 'List View' },
                ]} 
                        onChange={handleSelectSearch}  name="cardView" value={general.cardView}   /> 
											</div>
                       </section>
                         </div>


                <div className="row">
                <section className="col-md-6">
<button type="button"  className="btn btn-danger  pull-right" onClick={handleFetchAllStaff}><i className="fa fa-search"></i> Search Staff</button>


<button type="button"  className="btn btn-inverse  pull-right mr-3" onClick={()=>setGeneral(Initials)}><i className="fa fa-refresh"></i> Reset</button>


</section>	                </div>
							</div>
							</div>

							</div>
                </div>:''}


{general.cardView.value==='card'?
                <div className="card z-depth-0">
                    
       {allStaff.length!==0?     <div className="card-block">
    <div className="row">
        {allStaff&&dataList.currentData().map((stf, id)=>
        <section key={id} className="col-md-3">

           

            <button type="button" title='Edit staff'   className='btn btn-default' style={{width:'100%'}}> 
            <img style={{ textAlign:'center', height:'100px', width:'100px'}} className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/staff/'+stf.gender+'.png'}} src={`${ImagesUrl}/staff/${stf.passport}`} alt="" />

            <div ><b>{ stf.staffName.length>20?stf.staffName.slice(0, 20)+'...':stf.staffName}</b></div>
            <label>({(getPosition(stf.position).slice(0, 20))})</label>
            <div>{getDepartment(stf.department).slice(0,20)}</div>
            
<div style={{width:'50%', textAlign:'left', float:'left', fontSize:'13px'}}>File No <br/>Gender<br/>Status</div>
<div style={{width:'50%', textAlign:'right', float:'right', fontSize:'13px'}}>{stf.fileNo} <br/>{stf.gender}<br/> {stf.employmentStatus==='Working'?<span className="pcoded-badge label label-success">Working</span>: <span className="pcoded-badge label label-danger">{stf.employmentStatus}</span>}</div>
            </button>
                <div className="input-group" >

              
                    
                {allowEdit==='Yes'?   <button type="button"  onClick={()=>handleEditStaff(stf)}  className="btn btn-warning form-control btn-sm" >  <i className='fa fa-edit'></i> Edit</button>:[]}


                {allowDelete==='Yes'?   <button type="button" name="delete" data-toggle="modal"  data-target={`#warningdialog-${stf.code}`}   className="btn btn-danger btn-sm" >  <i className='fa fa-trash'></i> </button>:[]}

                    <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(stf.code)} mID={stf.code} /> 
                    <a href={"/staff/profile/"+stf.code}   className="form-control btn btn-inverse btn-sm" > <i className='fa fa-print'></i> Preview</a>
                </div>
            </section>
            
)}
</div>







{allStaff && allStaff.length!==0?
<div className="row">
    <div className="col-xs-12 col-sm-12 col-md-5">
        <div className=' form-group'>
        <select name='perPage' value={general.perPage}  style={{width:'60px', height:'30px'}} onChange={handleChangeGeneral}>
            <option value='32'>32</option>
            <option value='64'>64</option>
            <option value='128'>128</option>
        </select>&nbsp;
        <span >Showing 1 to { dataList.currentData().length } of { allStaff.length } items
        </span>
        </div>

        </div>
        
        <div className="col-xs-12 col-sm-12 col-md-7">
            <div className="dataTables_paginate paging_simple_numbers" id="base-style_paginate">
                <ul className="pagination">

                <li className={page === 1 ? ' paginate_button page-item previous disabled' : 'paginate_button page-item previous'}  >
          <a className="page-link" href="#!" onClick={(e) =>handleChangePage(e, page - 1)} ><i className="czi-arrow-left mr-2"></i>Previous</a></li>

    {pages.map((item, index) =>
    <li key={index} className={page===item?' paginate_button page-item active':'paginate_button page-item'}><a className="page-link" href="#!" onClick={(e) =>handleChangePage(e, item)}>{item}</a></li>
            
         )}
              
        <li className={page === dataList.maxPage  ? ' paginate_button page-item next disabled' : 'paginate_button page-item next'}  ><a className="page-link" href="#!" onClick={(e) =>handleChangePage(e, page + 1)} aria-label="Next">Next</a></li>

                </ul>
                </div>
                
                
                </div>


           </div>:''}

    </div>:''}</div>




   : <div className="row  ">
       {allStaff.length!==0?     <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5>Staff List</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
    <div className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
        <StaffTable data={allStaff}  columns={tableHeader}  />}
            </div></div>
</div>
</div>:''}

    </div> }


    
    </div>
               
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(ViewStaff) 