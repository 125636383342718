import React, {useState, useEffect, useRef} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl, Token, config,  FormatNumber, allowDelete, allowEdit, menuLink, API_URL} from '../component/include'
import Select from 'react-select'
import { formatGroupLabel, getRank, longDate} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Error403 from '../settings/error403'

const SubjectMarkSummary =()=> {
    const structure = useRef(null)
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
    
let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [resultSession, setResultSession]=useState([])
    const [examtype, setExamtype]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [allResult, setAllResult]=useState([])
    const [subject, setSubject]= useState([])
    const [result, setResult] = useState({
        studentClass:[],
        subject:[],
        sectionClass:[],
        examtype:[],
        session:[]
    })


    const handleSearch = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(result));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};   
        if(values.studentClass.length===0 && values.sectionClass.length===0 ){
            errors.sectionClass ='Please select class';
        }   
       
        if(values.subject.length===0){
            errors.subject ='Please select subject';
        } 

        if(values.session.length===0){
            errors.session ='Please select session';
        } 

        return errors;
        }




        const fetchAllResultSession =()=>{
            let url = API_URL+'/result/session/all';
            axios.get(url, config).then(res=>{
                setResultSession(res.data.data)
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
            })
        }

        const fetchExamType =()=>{
            let url = API_URL+'/exam/result/examtype';
            axios.get(url,  config).then(result=>{
                setExamtype(result.data.examType)
            })
            .catch((error)=>{
                Alerts('Error!', 'danger', error.message)
            })
            }
       




    const fetchSubject =async(type, section)=>{
        setNotice({...notice, isLoading: true}) 
    let url = API_URL+'/exam/subject_list/'+section+'/'+type
    return await axios.get(url, config).then((res)=>res.data.data).finally(()=>{
      setNotice({...notice, isLoading:false})
    })
    }


    const fetchClassSubject =async(type, section)=>{
        setNotice({...notice, isLoading: true}) 
    let url = API_URL+'/exam/subject_list/'+section+'/'+type
    return await axios.get(url, config).then((res)=>res.data.data).finally(()=>{
      setNotice({...notice, isLoading:false})
    })
    }


    const mergeAllClassSubject =async(section)=>{

        let first = await fetchClassSubject(result.session.firstTerm, section);
        let second = await fetchClassSubject(result.session.secondTerm, section);
        let third = await fetchClassSubject(result.session.thirdTerm, section);
    
        const allSubject = [...first, ...second, ...third]
        
        const newSubject = allSubject.map(e=>e['subjectCode'])
        .map((e,i,final)=>final.indexOf(e)===i&&i)
        .filter(e=>allSubject[e])
        .map(e=>allSubject[e])
        setSubject(newSubject)
    }


    const mergeAllSubject =async(section)=>{

        let first = await fetchSubject(result.session.firstTerm, section);
        let second = await fetchSubject(result.session.secondTerm, section);
        let third = await fetchSubject(result.session.thirdTerm, section);
    
        const allSubject = [...first, ...second, ...third]
        
        const newSubject = allSubject.map(e=>e['subjectCode'])
        .map((e,i,final)=>final.indexOf(e)===i&&i)
        .filter(e=>allSubject[e])
        .map(e=>allSubject[e])
        setSubject(newSubject)
    }

  

    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmitting){
       
            if(result.sectionClass.length!==0){
                mergeAllResult()
            }else if(result.studentClass.length!==0){
                mergeAllClassResult()
            }
        }
        },[errors])


    

        const handleSelectClass = (option, action)=>{
            if(action.name==='sectionClass'){
                setResult({...result, [action.name]: option, studentClass:[], subject:[] });
                mergeAllSubject(option.value)
            }else{
                setResult({...result, [action.name]: option, sectionClass:[], subject:[] });
                mergeAllClassSubject(option.value)
            }
            setErrors({...errors, [action.name]:''})
           setAllResult([])
          
        }




const handleSelect = (option, action)=>{
    if(action.name==='session'){
    setResult({...result, [action.name]: option, studentClass:[], sectionClass:[], subject:[] });
    }else{
        setResult({...result, [action.name]: option  });
    }
    setErrors({...errors, [action.name]:''})
   setAllResult([])
  
}






const getResultScore =(studentCode, dataList, field)=>{
    const ans = dataList.filter(item=>item.code===studentCode)
return ans.length!==0?ans[0][field]:0
    }

    const fetchSectionResult = async(examID)=>{
        setNotice({...notice, isLoading:true})
        let sql =   "SELECT  s.code, s.admissionNumber, s.gender, s.registerNumber, s.studentName, s.termination, s.termination_date, s.termination_remark, e.examCode, e.firstCA, e.absent, e.secondCA, e.exam, e.total from tbl_students s, tbl_exam_result e where s.code = e.studentCode and s.admissionStatus ='Studying' and e.subjectCode = '"+result.subject.value+"' and e.examCode ='"+examID+"' and e.sectionID ='"+result.sectionClass.value+"'  order by s.studentName ASC";
    
    
    //bring loader here 
        const fd = new FormData();
      
      fd.append("sql", sql);
      let url = API_URL+'/exam/result/fetch_raw';

      return await axios.post(url, fd, config).then((res)=>res.data.data).finally(()=>{
        setNotice({...notice, isLoading:false})
      })
        }


const fetchClassResult = async(examID)=>{
    setNotice({...notice, isLoading:true})
    let sql =   "SELECT  s.code, s.admissionNumber, s.gender, s.registerNumber, s.studentName, s.termination, s.termination_date, s.termination_remark, e.examCode, e.firstCA, e.absent, e.secondCA, e.exam, e.total from tbl_students s, tbl_exam_result e where s.code = e.studentCode and s.admissionStatus ='Studying' and e.subjectCode = '"+result.subject.value+"' and e.examCode ='"+examID+"' and e.classID ='"+result.studentClass.value+"'  order by s.studentName ASC";


//bring loader here 
    const fd = new FormData();
      
    fd.append("sql", sql);
    let url = API_URL+'/exam/result/fetch_raw';
  return await axios.post(url, fd, config).then((res)=>res.data.data).finally(()=>{
    setNotice({...notice, isLoading:false})
  })
    }


    const mergeAllClassResult =async()=>{

        let first = await fetchClassResult(result.session.firstTerm);
        let second = await fetchClassResult(result.session.secondTerm);
        let third = await fetchClassResult(result.session.thirdTerm);
    
        const allResult = [...first, ...second, ...third]
        
        const newResult = allResult.map(e=>e['code'])
        .map((e,i,final)=>final.indexOf(e)===i&&i)
        .filter(e=>allResult[e])
        .map(e=>allResult[e])
    
    
        var list = [];
        for (var i = 0; i< newResult.length;  i++) {
    
            list.push({
                code:newResult[i].code,
                admissionNumber:newResult[i].admissionNumber,
                studentName:newResult[i].studentName,
                gender:newResult[i].gender,
                registerNumber:newResult[i].registerNumber,
                termination_remark:newResult[i].termination_remark,
                termination:newResult[i].termination,
                termination_date:newResult[i].termination_date,
                f_cat:((Number(getResultScore(newResult[i].code, first, 'firstCA'))/20)*100).toFixed(0),
                s_cat:((Number(getResultScore(newResult[i].code, second, 'firstCA'))/20)*100).toFixed(0),
                t_cat:((Number(getResultScore(newResult[i].code, third, 'firstCA'))/20)*100).toFixed(0),
    
                f_exam:getResultScore(newResult[i].code, first, 'total'),
                s_exam:getResultScore(newResult[i].code, second, 'total'),
                t_exam:getResultScore(newResult[i].code, third, 'total') 
    
            })
    
        }
        setAllResult(list)
    }



const mergeAllResult =async()=>{

    let first = await fetchSectionResult(result.session.firstTerm);
    let second = await fetchSectionResult(result.session.secondTerm);
    let third = await fetchSectionResult(result.session.thirdTerm);

    const allResult = [...first, ...second, ...third]
    
    const newResult = allResult.map(e=>e['code'])
    .map((e,i,final)=>final.indexOf(e)===i&&i)
    .filter(e=>allResult[e])
    .map(e=>allResult[e])


    var list = [];
    for (var i = 0; i< newResult.length;  i++) {

        list.push({
            code:newResult[i].code,
            admissionNumber:newResult[i].admissionNumber,
            studentName:newResult[i].studentName,
            gender:newResult[i].gender,
            registerNumber:newResult[i].registerNumber,
            termination_remark:newResult[i].termination_remark,
            termination:newResult[i].termination,
            termination_date:newResult[i].termination_date,
            f_cat:((Number(getResultScore(newResult[i].code, first, 'firstCA'))/20)*100).toFixed(0),
            s_cat:((Number(getResultScore(newResult[i].code, second, 'firstCA'))/20)*100).toFixed(0),
            t_cat:((Number(getResultScore(newResult[i].code, third, 'firstCA'))/20)*100).toFixed(0),

            f_exam:getResultScore(newResult[i].code, first, 'total'),
            s_exam:getResultScore(newResult[i].code, second, 'total'),
            t_exam:getResultScore(newResult[i].code, third, 'total') 

        })

    }
    setAllResult(list)
}


const handleRankName = ()=>{

    const newData = [...allResult]
    let newResult = newData.sort((a,b)=>b.studentName > a.studentName?1:-1)
    setAllResult(newResult)
}

const handleRank = ()=>{
    const newData = [...allResult]
    let newResult = newData.sort((a,b)=>b.admissionNumber > a.admissionNumber?1:-1)
    setAllResult(newResult)
}

useEffect(()=>{
    
    //setIsAuthenticated(menuLink.includes(location.pathname)) 
    fetchSubject()
    fetchExamType()
    fetchAllResultSession()
},[]);

        return (  <>{notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
   <div  className="main-body">
   {isAuthenticated?  <div className="page-wrapper">
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Subject Marks Analysis">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Result Analysis</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  no-print">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i>Subject Marks Summary</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		<div className="row">

                    <section className="col-md-6">



                    <div className="form-group">
         <label> Session<span style={{color:'red'}}>*</span></label>
         <Select  options={resultSession&&resultSession.map((list, id)=>{
             return {key:id, value:list.code, label:list.startYear, firstTerm:list.firstTerm, secondTerm:list.secondTerm, thirdTerm:list.thirdTerm}
         })}    
         getOptionLabel={option =>option.label}
 onChange={handleSelect} className={errors.session ? 'form-control form-control-danger' : ''} name="session" value={result.session} id='session'  />

 <span style={{color:'red'}}>{errors.session && errors.session}</span>
            </div>


            <div className="form-group">
         <label> Student Class</label>
         <Select  options={
                            schoolClass&&schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname} 
							 })
							 
                        } 
onChange={handleSelectClass}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass}  /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>

            </div>


          
     
     </section>    

       <section className="col-md-6">
          
       <div className="form-group">
         <label> Student Section<span style={{color:'red'}}>*</span></label>
         <Select  options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelectClass}  className={errors.sectionClass ? 'form-control form-control-danger' : ''} name="sectionClass" value={result.sectionClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.sectionClass && errors.sectionClass}</span>

            </div>
     

            <div className="form-group">
         <label> Subject<span style={{color:'red'}}>*</span></label>
         <Select options={
                            subject&&subject.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.subjectName, abr:list.abbreviation }
                             })} 
                             getOptionLabel={option =>`${option.label} - ${option.abr}`}
onChange={handleSelect} className={errors.subject ? 'form-control form-control-danger' : ''} name="subject" value={result.subject}  /> <span style={{color:'red'}}>{errors.subject && errors.subject}</span>	

</div>
     </section>    
   	

				</div>	

	<footer className="pull-right">
	
	<button type="button"  onClick={handleSearch} className="btn btn-inverse "><i className="fa fa-search"></i> Search</button>
								</footer>				
							</div>
                            </div>
							</div></div>
</div>

</div>
	




{allResult.length!==0?

<div className="card z-depth-0">

    

		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                   <div className="row" key={id}>

<table className="table  table-full-width">
   <tbody>
   <tr>
   <td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
 </td>
            </tr>
        </tbody>
   </table>

</div> 
                   )}
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>SUBJECT MARK DETAILS </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Class: <b>{result.sectionClass.length!==0?result.sectionClass.label:result.studentClass.label}</b></td>
                    <td colSpan={2}>Session: <b> {result.session.label} </b></td>
                    <td>Subject: <b> {result.subject.label}</b></td>
                    
                <td >Total Student: <b>{allResult.length}</b></td>
                </tr>
            </tbody>
       </table>
        <table className="table table-striped table-bordered  table-full-width">
    

        <thead>
           
           <tr>
             <th colSpan={3}></th>
               <th colSpan={4} className='text-center'>HALF TERM SCORES</th>
               <th></th>
               <th className='text-center' colSpan={4}>END OF TERM SCORES</th>

           </tr>
           <tr>
           <th>SN</th>
           <th onClick={handleRank} style={{ cursor:'pointer' }}>Admission No. <i className="fa fa-caret-down" ></i></th>
               <th onClick={handleRankName} style={{ cursor:'pointer' }}>Name of Students <i className="fa fa-caret-down" ></i></th>
               <th >T1</th>
               <th >T2</th>
               <th >T3</th>
               <th>Descriptor</th>
<th></th>
               <th >T1</th>
               <th >T2</th>
               <th >T3</th>
               <th>Descriptor</th>
           </tr>
       </thead>
            <tbody>{allResult.length!==0?allResult.map((row, idx)=>
                <tr key={idx}>
                    <td>{idx+1}</td>

                    <td>{row.admissionNumber}</td>
                <td> {row.studentName} <br/>

                {row.termination==='Yes'?<span className="pcoded-badge label label-danger">Admission Suspended on {longDate(row.termination_date)} {row.termination_remark}</span>:''}</td>

                    <td>{row.f_cat}</td>
                    <td>{row.s_cat}</td>
                    <td>{row.t_cat}</td>
                    <td>
                       <div className="row" style={{alignItems:'end', marginLeft:'4px'}} > <div style={{backgroundColor:'#3a8925', height:row.f_cat/2+'px', width:'20%'}}></div>&nbsp;
                    <div style={{backgroundColor:'#3a8925', height:row.s_cat/2+'px', width:'20%'}}></div>&nbsp;
                    <div style={{backgroundColor:'#3a8925', height:row.t_cat/2+'px', width:'20%'}}></div>
                    </div>
                    </td>
                    <td ></td>
                    <td>{row.f_exam}</td>
                    <td>{row.s_exam}</td>
                    <td>{row.t_exam}</td>
                    
                    <td>
                       <div className="row" style={{alignItems:'end', marginLeft:'4px'}} > <div style={{backgroundColor:'#262261', height:row.f_exam/2+'px', width:'20%'}}></div>&nbsp;
                    <div style={{backgroundColor:'#262261', height:row.s_exam/2+'px', width:'20%'}}></div>&nbsp;
                    <div style={{backgroundColor:'#262261', height:row.t_exam/2+'px', width:'20%'}}></div>
                    </div>
                    </td>
                 
                </tr>):[]}
            </tbody> 
        </table>
 </div>	 </div> 

 <div ref={structure} className="row no-print">
        <div className="col-md-12">
       

       
        <form method='POST' action={ServerUrl+'/printout/subject_marks_analysis.php?token='+userToken} target='_blank' className='pull-right' >
            
        <input type='hidden' name='classresult' value ={JSON.stringify(allResult, null, 2)} />
        <input type='hidden' name='subject' value ={result.subject.value} />
        <input type='hidden' name='classID' value ={result.sectionClass.length!==0?result.sectionClass.classID:result.studentClass.value} />
        <input type='hidden' name='sectionID' value ={result.sectionClass.length!==0?result.sectionClass.value:result.studentClass.value} />
        <input type='hidden' name='resultSession' value ={result.session.label} />

        
                <input type='hidden' name='examCode' value ={result.examtype.value} />
                <input type='hidden' name='jwtToken' value ={Token} />
                
            <input type='hidden' name='total' value ={allResult.length} />
                
                
                <button type="submit" className="btn btn-inverse  " > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>
        </div>:''}




   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(SubjectMarkSummary) 