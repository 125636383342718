import React, {useState, useEffect, useCallback} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config, ImagesUrl, Currency, FormatNumber, selectStyles, allowDelete, allowEdit, menuLink} from '../component/include'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import {Type} from 'react-bootstrap-table2-editor';
import Select from 'react-select'
import { userToken } from '../component/authentication'
import { useDispatch, useSelector } from 'react-redux'
import { formatGroupLabel, longDate } from '../component/globalFunction'

const HostelApplication =()=> {
    
const [errors, setErrors] = useState({})
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})

const hostel = useSelector(state => state.rootReducer.hostel);
const [bookings, setBookings]= useState([]) 

let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);

const [search, setSearch] = useState({
    fromDate:'',
    toDate:'',
    admissionNumber:'',
    hostelCode:'',

})

    const fetchAllocation =()=>{
        setNotice({...notice,   isDataFetching: true})
        let sql = "Select a.ID, a.code, a.amountPaid, a.dateCreate, a.isApproved, a.allocationDate, a.vacateDate, a.status, a.currentSession, r.roomName, h.hostelName, s.studentName, s.admissionNumber from  tbl_hostel_allocation a, tbl_hostel_rooms r, tbl_hostel h, tbl_students s where a.hostelCode =  h.code and a.roomCode = r.code and s.code = a.studentCode and a.isApproved ='false'  "
    
        if(search.fromDate.length!==0 && search.toDate.length!==0 ){
            sql = sql + ' and a.dateCreate between  "'+search.fromDate+'" and "'+search.toDate+'"'
        }
    
        if(search.hostelCode.length!==0 ){
            sql = sql + '  and a.hostelCode = "'+search.hostelCode.value+'" '
        }
        

        if(search.admissionNumber!==''){
            sql = sql + '  and s.admissionNumber Like "%'+search.admissionNumber+'%" '
        }

        sql = sql + 'order by h.hostelName, r.roomName  '


        const fd = new FormData();
        fd.append('jwt', Token);
        fd.append("sql", sql);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>setBookings(result.data))
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice,   isDataFetching: false})
        })
        }




    const handleSelectSearch = (option, action)=>{
        setSearch({...search, [action.name]: option});
         } 
     const handleChangesearch = (event)=>{
        const {name, value} = event.target
 setSearch({...search, [name]: value}); 
 }




    const handleDelete = (code)=>{  
        setNotice({...notice,  isLoading: true}) 
           let close =   document.getElementById('btnWarningDialog-'+code)
              close.click();
           const fd = new FormData();
           fd.append('ID', 'code')
            fd.append('data', code)
            fd.append("jwt", Token);
            let url = ServerUrl+'/delete_controller/tbl_hostel_allocation';
           axios.post(url, fd, config)
           .then(response =>{
            if (response.data.type ==='success'){
                setErrors({...errors, successMessage: response.data.message})
                
            } else{
                setErrors({...errors, errorMessage: JSON.stringify(response.data)})
            
                }   
           })
           .catch((error)=>{
             Alerts('Error!', 'danger', error.message)
           }).finally(()=>{
               setNotice({...notice,  isLoading: false })
           }) 
    }




    const TableRecord=(props)=>{

        const CustomToggleList = ({
            columns,
            onColumnToggle,
            toggles
          }) => ( 
          <div  className="btn-group btn-group-toggle btn-group-vertical" data-toggle="buttons">
              {
                columns
                  .map(column => ({
                    ...column,
                    toggle: toggles[column.dataField]
                  }))
                  .map(column => (
                    
                    <button
                      type="button"
                      key={ column.dataField }
                      className={ ` btn btn-primary ${column.toggle ? 'active' : ''}` }
                      data-toggle="button"
                      aria-pressed={ column.toggle ? 'true' : 'false' }
                      onClick={ () => onColumnToggle(column.dataField) }
                    >
                      { column.text }
                    </button>
                  ))
              }
            </div>
            
          );
    
        const handleUpdate=(column, newValue, ID)=>{       
            const fd = new FormData();
            fd.append('jwt', Token);
             fd.append('columnField', newValue);
             fd.append('column', column.dataField);
             fd.append('ID', ID);
           
            let url = ServerUrl+'/updateById_controller/tbl_hostel_allocation'
           axios.post(url, fd, config)
           .then(result => Alerts('success!', 'success', result.data.message))
           .then()
           .catch((error)=>console.log(error)) 
           
         } 
         const { SearchBar } = Search;
     
         const customTotal = (from, to, size) => (
            <span >&nbsp;Showing { from } to { to } of { size } items</span>
          );
         const options = {
             showTotal: true,
         paginationTotalRenderer: customTotal,
         sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
         { text: 'All', value: props.data.length }]
            
           };
      
         return <ToolkitProvider search columnToggle 
                     keyField='ID' data={ props.data } columns={ props.columns } >
                         
                         {
                             props => (
                             <div >
                               <div className="form-group">
                                 <SearchBar
                                 { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                 &nbsp;
                               
                                
                                 <div className="btn-group dropdown-split-success">
      
      <button type="button" className="btn btn-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <i className="fa fa-search-plus"></i> Columns
      </button>
      
      <div className="dropdown-menu" x-placement="bottom-start" style={{overflow:'scroll', height:'260px', width:'50px', position: 'absolute', transform: 'translate3d(113px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
        <CustomToggleList { ...props.columnToggleProps } />
      </div>
      </div>


      <div className="dt-buttons btn-group pull-right"> 
    
        
    
    <div className="btn-group dropdown-split-primary ">
        <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <i className="fa fa-download"></i>Export
        </button>
        <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
    
    
                <form method='POST' action={ServerUrl+'/excel_controller.php?api=hostel_allocation'} target='_blank'>
                    <input type='hidden' name='jwt' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>
            
            </form>
    
            <form method='POST' action={ServerUrl+'/printout/hostel_application.php?token='+userToken} target='_blank'>
    
                    <input type='hidden' name='jwtToken' value ={Token} />
            <button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>
            
            </form>
        </div>
    </div> 
    </div> 
        </div>
                                 
                          
                 
                                 <BootstrapTable
                                 { ...props.baseProps }
                                             striped
                                         hover
                                         condensed
                                         noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                     pagination={paginationFactory(options) }
                                     cellEdit={ cellEditFactory({
                                       mode: 'dbclick',
                                       blurToSave: true,
                                       autoSelectText: true,
                             beforeSaveCell: (oldValue, newValue, row, column) => {
                               if(oldValue !==newValue){
                               handleUpdate(column, newValue, row.ID);
                               
                              return '';
                             }
                             
                           }
                                     }) }
                                 />
                             </div>
                             )
                         }
      </ToolkitProvider>
      }
      
    var i =1;
    const tableHeader = [
        {dataField: 'ID', text: 'SN', editable:false, formatter:(cell)=>i++},
                {dataField: 'currentSession', text: 'Session', editable:false},
                {dataField: 'hostelName', text: 'Hostel', editable:false, formatter:(cell, row)=><div style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }} >{row.hostelName + ' => ' +row.roomName}</div>}, 
                {dataField: 'amountPaid', text: 'Amount Paid', formatter:(cell)=>Currency+FormatNumber(cell)}, 
                {dataField: 'studentName', text: 'Student', editable:false, formatter:(cell, row)=><div style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }} >{row.admissionNumber + ' ' +row.studentName}</div>}, 
                
                {dataField: 'allocationDate', text: 'Application Date', editor: {
                    type: Type.DATE
                  }, formatter:(cell)=>longDate(cell)},
                  {dataField: 'vacateDate', text: 'Vacation Date', editor: {
                    type: Type.DATE
                  }, formatter:(cell)=>longDate(cell)},
                {dataField: 'isApproved', text: 'Approve', formatter: (cell, row)=>row.isApproved==='true'? <span className="pcoded-badge label label-success">APPROVED</span>: <span className="pcoded-badge label label-danger">PENDING</span>, editor: {
                    type: Type.SELECT, options:[
                        {value:'true', label:'Approved'},
                    {value:'false', label:'Pending'}]
                  }},
            
        {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
        
        <button type="button" className="btn btn-danger btn-sm delete" data-toggle="modal"   data-target={`#warningdialog-${cell}`}     title="Delete"><i className="icofont icofont-delete-alt"></i></button>
        &nbsp;&nbsp;
        
        <WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(cell)} mID={cell} /> 
        </div>, editable: false}
         ]

    
useEffect(()=>{
  setIsAuthenticated(menuLink.includes(location.pathname))
    fetchAllocation()
},[]);



        return (   <>
          <div  className="main-body">
          {isAuthenticated?   <div className="page-wrapper">
                        {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Hostel Request">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-bed"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!" >Hostel</a> </li>
                            <li className="breadcrumb-item"><a href="#!" >Application</a> </li>

                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">
              

                {errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}

                           
                
                            <div className="row  no-print">
								<div className="col-sm-12">
	<div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Search Hostel Application</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		
        <div className="row">


        <section className="col-md-6">
                                            <div className="form-group">
						<label > Hostel Name</label> 
                        <Select options={
                               hostel&&hostel.map((list, idx)=> {
                                   return {key:idx, value: list.code, label: list.hostelName, location:list.location  }
                                 })} 
    onChange={handleSelectSearch}  name="hostelCode" value={search.hostelCode} styles={selectStyles} /> 
											</div>
                       </section>
									

        <section className="col-md-6">
                            <div className="form-group">
        <label > Admission Number</label> 
        <input type="text" className="form-control" name="admissionNumber" value={search.admissionNumber}  onChange={handleChangesearch} />
                            </div>
        </section>

        <section className="col-md-6">
                            <div className="form-group">
        <label > From Date</label> 
        <input type="date" className="form-control" name="allocationDate" value={search.allocationDate}  onChange={handleChangesearch} />
                            </div>
        </section>
                      
        <section className="col-md-6">
                            <div className="form-group">
        <label > To Date</label> 
        <input type="date" className="form-control" name="allocationDate" value={search.allocationDate}  onChange={handleChangesearch} />
                            </div>
        </section>

                         </div>


                <div className="row">
                <section className="col-md-6">
<button type="button"  className="btn btn-danger pull-right " onClick={fetchAllocation} ><i className="fa fa-search"></i> Search Student</button>
</section>	                </div>
							</div>
							</div>

							</div>
                </div>

                            <div className="row">
  <div className="col-sm-12">
                               
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Listing All Application</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            <div className="col-md-12 table-responsive">

         {notice.isDataFetching ? <TableLoader />:
         <TableRecord data={bookings}  columns={tableHeader}  /> 
        } 

            </div></div>
    
    </div>
    
    </div>
    </div>



   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(HostelApplication) 