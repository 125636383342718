import React, {useState, useEffect,  useRef} from 'react'
import Template from '../component/template'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal } from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, LocalUrl,  Token, config, ImagesUrl, allowDelete, allowEdit, menuLink } from '../component/include'
import {Banner} from '../component/images'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import {Type} from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { userToken } from '../component/authentication'

import { useSelector } from 'react-redux'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'

const Sliders =()=> {
    const [notice, setNotice] = useState({
        isLoading : false,
        isDataFetching: false
    });
    const [addSlide, setAddSlide] = useState(false)
   const [image, setImage] = useState('');
    const [content, setContent] = useState({
      title:'',  
      caption:'',
        link:'',
        action:'none'
        
    })
    
    const sName = useRef(null) 
    const [slideDetails, setSlideDetails] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false); 
    const [errors, setErrors] = useState({});
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    const ValidateInput =(values)=>{
      let errors = {};   
     /*  if(!values.caption){
          errors.caption ='This field is required.';
      } */
      if(!values.title){
        errors.title ='This field is required.';
    }
    if(values.action==='readMore' && !values.link){
      errors.link ='This field is required.';
  }

      if(!image){
          Alerts('Error!', 'danger', 'Please choose an image')
          errors.title ='Please enter image.';
      }
         
      return errors;
      }


    const fetchSlides =()=>{

       
     var sql ="Select title, imageUrl, link, ID, action from tbl_slider order by ID DESC";  
   
      const fd = new FormData();
      fd.append("sql", sql);
      fd.append("jwt", Token);
      let url = ServerUrl+'/fetchBySql_controller.php';
         axios.post(url, fd, config).then(result=>{  
           
      setSlideDetails(result.data)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
  }


  
 
    const handleChange = event =>{		
      let {name, value} = event.target;	
     
      setContent({...content, [name] : value });
      setErrors({...errors, [name]:''});
      }


    function submit(){  
      setNotice({...notice,  isLoading: true})     
         
        let formData = content;
        const fd = new FormData();
        for(let k in formData){
          fd.append(k, formData[k])
        }
        fd.append("image", image);
        fd.append("jwt", Token);
           let url = ServerUrl+'/save_controller/tbl_slider';
          axios.post(url, fd, config)
          .then(response =>{
            if(response.data.type ==='success'){
              Alerts('Saved!', 'success', response.data.message);
                  } else{
              Alerts('Error!', 'danger', response.data.message)
                  }   
          })
          .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
          }).finally(()=>{
              setNotice({...notice, isLoading: false  })

              setContent({
                title:'',  
              caption:'',
                link:'',
                action:'none',
            })
            setImage('');
            document.getElementById('slider').value=''
              setIsSubmitting(false);
              fetchSlides()
          }) 
  }
  

  const handleSubmit = event =>{
    event.preventDefault();         
    setErrors(ValidateInput(content));
        setIsSubmitting(true);
  }
  
useEffect(()=>{
  if(Object.keys(errors).length === 0 && isSubmitting){
  submit()
  }
  },[errors, isSubmitting])

  useEffect(()=>{
    fetchSlides()
   },[]);


   const  handleFile = function(fieldName){
    return function(newValue){                 
        const value = newValue.target.files[0]
      // setImage(value)
   readURL(newValue);
   }
   
  } 

   const readURL = (input)=>{       
    let displayMessage = '';
      var fileName =  document.getElementById('slider').value;
    var idxDot = fileName.lastIndexOf(".") + 1;
    var extFile = fileName.substr(idxDot, fileName.length).toLowerCase();
    if (extFile ==="jpg" || extFile ==="jpeg" || extFile ==="png" || extFile ==="gif" || extFile ==="JPG" || extFile ==="JPEG" || extFile ==="PNG" || extFile ==="GIF"){
        //TO DO    
        
         var FileSize = input.target.files[0].size / 1024 / 1024; // in MB
    if (FileSize > 3) {
        displayMessage = ' File uploaded is more than maximum size Allow. it exceeds 3mb';
       document.getElementById('slider').value = '';
       setImage('')
    } else {
        if (input.target.files && input.target.files[0]) { //Check if input has files.
          var reader = new FileReader();//Initialize FileReader.
          reader.onload = function (e) {
       
          const img = document.getElementById('viewSlider');
                     img.src = e.target.result             
              
          };
          reader.readAsDataURL(input.target.files[0]); 
          setImage(input.target.files[0])  
        }
    }
    }else{
        displayMessage = 'Only gif|jpg|png|jpeg|jpe|JPEG|PNG|JPG  files are allowed with maximum of 3mb'
       document.getElementById(input.target.name).value = '';
       setImage('');
    }   
    
    if(displayMessage.length > 0){
        Alerts('Error!', 'danger', displayMessage)
      }
} 


const SlidesTable =(props)=>{
  const customTotal = (from, to, size) => (
    <span >&nbsp;Showing { from } to { to } of { size } entries</span>
  );

  const handleUpdate=(column, newValue, ID)=>{   
    
    const fd = new FormData();
    fd.append('jwt', Token);
    fd.append('newValue', newValue);
    fd.append('column', column.dataField);
    fd.append('ID', ID);


let url = ServerUrl+'/updateCustom_controller/tbl_slider';
         axios.post(url, fd, config)
         .then(response =>{          
           if (response.data.type ==='info'){  
             Alerts('info!', 'info', response.data.message)            
           }        
         })
         .catch((error)=>Alerts('Error!', 'danger', error.message))   
       } 
   
    const options = {
        showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [{text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100}, {text: '500', value: 500}, { text: 'All', value: props.data.length }]
       
      };
 

  return <React.Fragment>  
 
          <ToolkitProvider keyField='ID' data={ props.data } columns={ props.columns } >                  
                  {
                      props => (                               
                        <BootstrapTable
                         { ...props.baseProps }
                                     striped
                                 hover
                                 condensed
                                 noDataIndication={<div className="alert alert-danger">No Record Found</div>}
                             pagination={paginationFactory(options) } 
                             cellEdit={ cellEditFactory({
                              mode: 'dbclick',
                              blurToSave: true,
                              autoSelectText: true,
                    beforeSaveCell: (oldValue, newValue, row, column) => {
                      if(oldValue !==newValue){
                      handleUpdate(column, newValue, row.ID);                        
                     return props.fetchdata;
                    }                      
                  }
                         }) }                              
                         />
                      )
                  }
</ToolkitProvider>
</React.Fragment>
}

const handleDelete = (row)=>{  
    setNotice({...notice, isLoading: true}) 

       let close =   document.getElementById('btnWarningDialog-'+row.ID)
          close.click();

          const fd = new FormData();
          fd.append('jwt', Token); 
       fd.append('ID', 'ID')
		fd.append('data', row.ID)
    fd.append('imageLink', '../images/sliders/'+row.imageUrl)

        let url = ServerUrl+'/deleteSingleWithImage_controller/tbl_slider'
       axios.post(url, fd, config)
       .then(response =>{
        if(response.data.type ==='success'){
           Alerts('Saved!', 'success', response.data.message)
               } else{
           Alerts('Error!', 'danger', JSON.stringify(response.data))
               }   
       })
       .catch((error)=>{
         Alerts('Error!', 'danger', error.message)
       }).finally(()=>{
        fetchSlides()
        setNotice({...notice,  isLoading: false  })
       })      
}





const tableHeader = [
    
    {dataField: 'imageUrl', text: 'Slider', editable:false, formatter:(cell,row)=><img id={"slider"+row.ID} className="image" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/no.jpg"}} src={`${ImagesUrl}/sliders/${cell}`}  style={{ height:'50px',width:'100px'}} title={row.title}  alt={row.title} /> },


{dataField: 'title', text: 'Slide Caption', validator: (newValue, row, column) => {
        if (!newValue) {
          return {
            valid: false,
            message: 'This field is required'
          };
        }
        return true;
      }, formatter:(cell)=><span style={{display:'block', width:200, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>, editor: {
        type: Type.TEXTAREA}},

      {dataField: 'action', text: 'Action', validator: (newValue, row, column) => {
        if (!newValue) {
          return {
            valid: false,
            message: 'This field is required'
          };
        }
        return true;
      }, editor: {
        type: Type.SELECT, options:[{value:'none', label:'None'}, {value:'readMore', label:'Read More'}]
      }},

      {dataField: 'link', text: 'Link', validator: (newValue, row, column) => {
        if (!newValue) {
          return {
            valid: false,
            message: 'This field is required'
          };
        }
        return true;
      }, formatter:(cell)=><span style={{display:'block', width:100, whiteSpace:'normal', wordWrap:'break-word' }}>{cell}</span>},

    {dataField: 'ID', text: 'Action', formatter: (cell, row)=><div>  
        <a href="#!" data-toggle="modal"   data-target={`#warningdialog-${row.ID}`} className='btn btn-danger btn-sm'    title="Delete"><i className="icofont icofont-delete-alt"></i></a>
<WarningModal message="This is very dangerous, you shouldn't do it! are you really really sure.?" handleAction={()=>handleDelete(row)} mID={row.ID} /> 

        </div>, editable: false, hidden:allowDelete==='No'?true:false},  
 ];


 
 useEffect(()=>{
  setIsAuthenticated(menuLink.includes(location.pathname))
    
 },[]);

        return (   <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
               
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Sliders">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-image"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Gallery</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Slider</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


                {errors.successMessage?
<div className="alert alert-success background-success">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.successMessage}</strong> 
    </div>:''}

{errors.errorMessage?
<div className="alert alert-danger background-danger">
        <button type="button" className="close" data-dismiss="alert" aria-label="Close">
            <i className=" fa fa-times text-white"></i>
        </button>
        <strong> {errors.errorMessage}</strong> 
    </div>:''}

         
                <div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Add Slides</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
            
<section className="col-md-6">
   

   <div className="form-group">
<label >Slide Caption</label>

<input type="text" name="title" onChange={handleChange} 
value={content.title} className={errors.title? 'form-control form-control-danger form-control-lg' : 'form-control form-control-lg'} /> 


<span style={{color:'red'}}>{errors.title&& errors.title}</span>
   </div>

</section>
</div>




<div className="row">

<div className="input-field col-sm-6"><label >Select Image</label>
  <input id="slider"  onChange={handleFile('slider')} className="form-control" name="slider" type="file" accept="image/*" />
  
</div>
<div className="col-sm-6">
<img id="viewSlider" className="image" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/no.jpg"}} src={image}  style={{display:'block',height:'150px',width:'200px'}} title="Slide Name"  alt="Slide Image" />  

</div>

</div><br/>

 <div className="row">
               
                <div className="col-md-6">
                <label >Action Button</label>
                    <p>
                      <input name="action" type="radio" checked={content.action==='none'?true:false} onChange={handleChange} id="none" value="none" />
                      <label htmlFor="none">None</label>&nbsp;&nbsp;
                   
                      <input className="with-gap" name="action" checked={content.action==='readMore'?true:false} onChange={handleChange} type="radio"  id="ReadMore" value='readMore' />
                      <label htmlFor="ReadMore">Read More</label>
                    </p>
                </div>
              

              {content.action==='readMore'?   

            <div className="input-field col-sm-6">
            <label htmlFor="link" className="">Link</label>
            <input id="link" autoComplete="off" ref={sName} name="link" onChange={handleChange} value={content.link} type="text" className="form-control form-control-lg" />

            <span className="error">{errors.link&& errors.link}</span>
           
            </div> :''}
            </div> 
         

<hr/>

   <footer className="pull-right">
   
   <button type="button" className="btn btn-inverse " ><i className="fa fa-refresh"></i> Back</button>&nbsp;&nbsp;
   <button type="button" id="submit" onClick={handleSubmit}  className="btn btn-success "><i className="fa fa-save"></i> Save</button>
            </footer>
 </div> 
			

 </div>
                    </div>
                      </div>
                    </div></div>					
           </div>
                            
       </div>


                <div className="row  ">

	<div className="col-sm-12">
      
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Slide List</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<div className="col-md-12">
        <div className="table-responsive">

        {notice.isDataFetching ? <TableLoader />:
  <SlidesTable data={slideDetails} columns={tableHeader}  />} 

    </div></div></div>
</div>


</div>

</div>
	

   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Sliders) 