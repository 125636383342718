import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, InfoModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, config,  allowDelete, allowEdit, menuLink} from '../component/include'
import Select from 'react-select'
import{longDate } from '../component/globalFunction'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory from 'react-bootstrap-table2-editor';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { userToken } from '../component/authentication'

const IssueReturn =()=> {
    const [errors, setErrors] = useState({});
    
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });

    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [history, setHistory] = useState([]);
    const [allBooks, setAllBooks]= useState([])
    const [students, setStudents] = useState([]);
    const [settings, setSettings]=useState([]);
    const [visibleReturn, setVisibleReturn] = useState(false);
    const [returnBook, setReturnBook] = useState({
            returnDate:'',
            penalty:'0',
            studentName:'',
            bookName:'',
            code:'',
            dueDate:'',
            minDate:'',
            daysFine:''
    });

    const [allIssuedBooks, setAllIssuedBooks] = useState([]);
    const [issued, setIssued] = useState({
        
        books:'',
        student:[],
        dateIssue:'',
        dateReturn:'',
        remarks:''
    })
    
    const fetchStudents =()=>{
        setNotice({...notice, isLoading: true}) 
        var sql ="SELECT admissionNumber, registerNumber, studentName, classID, sectionID, code, fatherName, father_mobile from tbl_students where admissionStatus='Studying' and termination <> 'Yes'"
    
        const fd = new FormData();
        fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller'
    axios.post(url, fd, config).then(result=>{
        
    setStudents(result.data)
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
        
    })
    }

    const fetchAllBooks =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_books'
        axios.post(url, fd, config).then(result=>setAllBooks(result.data))
    } 

    const fetchSettings =()=>{
        const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_library_settings/settings/ID/1'
        axios.post(url, fd, config)
        .then(result =>{
            setSettings(result.data)
            
        })
    }

    const getToday =()=>{
        var date = new Date();
            var day = date.getDate();
            var month = date.getMonth() + 1;
            var year = date.getFullYear();
            
            if (month < 10) month = "0" + month;
            if (day < 10) day = "0" + day;
            
            var today = year + "-" + month + "-" + day; 
            return today;
    }


    const getDateFormat =()=>{
        document.getElementById('dateIssue').max = getToday()
        document.getElementById('dateReturn').min = getToday()
        setIssued({...issued,
            dateIssue:getToday()
            })
        }


        const handleSelect = (option, action) =>{
            setIssued({...issued, [action.name]: option});
           setErrors({...errors, [action.name]:''}); 
        }

        const handleSelectStudent = (option, action) =>{
            setIssued({...issued, [action.name]: option, dateReturn:handleReissue()});
           setErrors({...errors, [action.name]:''});
           fetchIssuedHistory(option.value);
        }


        const handleReissue=()=>{

            var num = settings&&settings.map(itm=>Number(itm.studentDays))
            var returnDat = new Date(Date.now() + num * 86400000);
            var newMonth = returnDat.getMonth() + 1;
            var newDay = returnDat.getDate();
            if (newMonth < 10) newMonth = "0" + newMonth;
            if (newDay < 10) newDay = "0" + newDay;
            
        var newReturn = returnDat.getFullYear() + "-" + newMonth + "-" +newDay;
        return newReturn;
        
        }

        const fetchIssuedHistory=(userCode)=>{
   
            let sql = "Select bookCode from tbl_book_issued where returnStatus = 'No' and userType='student' and userCode = '"+userCode+"'"
        
            const fd = new FormData();
        fd.append('jwt', Token);
            fd.append("sql", sql);
            let url = ServerUrl+'/fetchBySql_controller'
            axios.post(url, fd, config).then(result=>{ 
                setHistory(result.data)
            })
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            })
    
            }

            const getBookName =(code)=>{
                const result = allBooks&&allBooks.filter(list=>list.code===code);
                const answer = result.map(c=> c.bookName+' - '+c.authorName);
                return  String(answer)
            }

            const handleChange = event =>{
                const {name, value} = event.target
                setIssued({...issued, [name] : value });
               setErrors({...errors, [name]:''});
            }



            const handleSubmit = event =>{
                event.preventDefault();
            setErrors(ValidateInput(issued));
                setIsSubmitting(true);
            } 
        
        
                const ValidateInput=(values)=>{
                    let errors = {};  
                    var borrow = allIssuedBooks.filter(item=>item.userCode===values.student.value&&item.returnStatus==='No')
                    let total = settings.map((itm)=>Number(itm.studentMaxBook))
                    if(!values.dateIssue){
                        errors.dateIssue ='Please select date Issued';
                    }

                    if(!Array.isArray(values.books)){
                        errors.books ='Please select book';
                    }

                    if(Array.isArray(values.books) && values.books.length > total){
                        errors.books ='A student cannot borrowed more than '+ total + ' book(s)';
                    }

                    if(borrow.length >= total){
                        errors.student ='This student already borrows upto maximum limit of  '+ total + ' book(s)';
                    }


                    if(values.student.length===0){
                        errors.student ='Please select Student';
                    }
                    if(!values.dateReturn){
                        errors.dateReturn ='Please select date return';
                    }
                
                    return errors;
                    }
        
        
        function submit(){  
              
            const fd = new FormData();
            fd.append('jwt', Token);
        
            var options = issued.books;
            var value = [];
            for (var i = 0, l = options.length; i < l; i++) {
              value.push(options[i].value);
            }
        
             fd.append('books', JSON.stringify(value, null, 2));
            fd.append('student', issued.student.value);
            fd.append('dateIssue', issued.dateIssue);
            fd.append('dateReturn', issued.dateReturn); 
            fd.append('remarks', issued.remarks); 
            fd.append('usertype', 'Student'); 

 setNotice({...notice, 
              isLoading: true}) 
        
               let url = ServerUrl+'/save_controller/tbl_book_issued'
              axios.post(url, fd, config)
              .then(response =>{
                if (response.data.type ==='success'){
                    Alerts('Saved!', 'success', response.data.message)
                } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
            }  
               })
               .catch((error)=>{
                Alerts('Error!', 'danger', JSON.stringify(error.message)) 
            })
            .finally(()=>{
                fetchIssuedBooks()
              setNotice({...notice, isLoading: false}) 
              setIsSubmitting(false); 
              setIssued({ 
                books:[],
                student:[],
                dateIssue:getToday(),
                dateReturn:'',
                remarks:''}); 
                
              }) 
              setHistory('') 
           
        }
        
    
const fetchIssuedBooks =()=>{
  
    let sql = "Select b.bookName, b.authorName, s.studentName, i.bookCode, i.userType, s.admissionNumber, s.mobile, i.ID, i.code,  i.dateIssue, i.issuedCount, i.dateReturn, i.userCode,   i.remarks,   i.returnedDate, i.returnStatus from tbl_book_issued i, tbl_students s, tbl_books b where i.bookCode = b.code and i.userCode = s.code and i.usertype='Student' "

    const fd = new FormData();
        fd.append('jwt', Token);
            fd.append("sql", sql);
            let url = ServerUrl+'/fetchBySql_controller'
            axios.post(url, fd, config).then(result=>{ 
                setAllIssuedBooks(result.data)
            })
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            })
    
}
    
const handleChangeReturnDate =event=>{
const {name, value} = event.target
    let fine = 0
   let rate = settings.map(itm=>itm.studentFine);

   let returnDate = new Date(value); 
   let expectDateReturn = new Date(returnBook.dueDate)
   let difTime = returnDate.getTime() - expectDateReturn.getTime();
   let difDays = difTime / (1000*3600*24);
   if(difDays>=0)fine = rate*difDays;
   
//document.getElementById('daysFine').innerHTML = difDays>=0?difDays:0;
setReturnBook({...returnBook, [name] : value, penalty:fine, daysFine:difDays>=0?difDays:'' });
setErrors({...errors, [name]:''});

 }

 const handleChangeReturn =event=>{
    const {name, value} = event.target
    setReturnBook({...returnBook, [name] : value });
   setErrors({...errors, [name]:''});
 }



const handleReturnBook=()=>{

if(!returnBook.returnDate){
setErrors({...errors, returnDate:'Please select return date'})

}else {

    let formData = returnBook;
    const fd = new FormData();
        fd.append('jwt', Token);
    for(let k in formData){
        fd.append(k,formData[k]);
    }; 
    setNotice({...notice, 
      isLoading: true}) 

       let url = ServerUrl+'/update_controller/tbl_book_issued'
      axios.post(url, fd, config)
      .then(response =>{
        if(response.data.type ==='info'){
            Alerts('Update!', 'default', response.data.message)
                } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
                }   
        })
        .catch((error)=>{
          Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            fetchIssuedBooks()
            setNotice({...notice, 
                isLoading: false})
      setVisibleReturn(false);
    })
    
}
}


useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
    submit()
    }
    },[errors])


    const handleReturn=(row)=>{
          setReturnBook({...returnBook,
                    code:row.code,
                    minDate:'0000-00-00',
                    dueDate:row.dateReturn,
                    penalty:0,
                    returnDate:'',
                    daysFine:'',
                    studentName:`(${row.admissionNumber}) ${row.studentName}`,
                    bookName:`${row.bookName} (${row.authorName}) ` })
        
                    setVisibleReturn(true);
            
            window.scrollTo(0,0)
        }



const handleReissueBook=(row)=>{
     let counting = settings.map((itm)=>Number(itm.studentReissue))
     let close =   document.getElementById('btnInfoDialog-'+row.code);
     
            close.click();



            var issuedCount = allIssuedBooks.filter(item=>item.userCode===row.userCode&&item.bookCode===row.bookCode)

        if(issuedCount.length >= counting){

        alert('The book maximum re-issue Limit to the particular student has been reached');
        }else if(row.returnStatus==='No'){
            alert('This book must be returned first before it can be reissued');
        }else{            
            const fd = new FormData();
            fd.append('jwt', Token);

            var value = [];
            value.push(row.bookCode);
             fd.append('books', JSON.stringify(value, null, 2));
            fd.append('dateReturn', handleReissue());
            fd.append('dateIssue', getToday());
            fd.append('student', row.userCode);
            fd.append('usertype', row.userType);
            fd.append('remarks', row.remarks);

            setNotice({...notice, 
              isLoading: true}) 
               let url = ServerUrl+'/save_controller/tbl_book_issued'
              axios.post(url, fd, config)
              .then(response =>{
                if(response.data.type ==='success'){
                    Alerts('Saved!', 'success', response.data.message)
                        } else{
                    Alerts('Error!', 'danger', JSON.stringify(response.data))
                        }   
                })
                .catch((error)=>{
                  Alerts('Error!', 'danger', error.message)
                }).finally(()=>{
                    fetchIssuedBooks()
                    setNotice({...notice, 
                        isLoading: false}) 
                })
              
        }

    }

   

   


    const tableHeader = [
        {dataField: 'code', text: 'Action', formatter: (cell, row)=><div>
           
           
         { row.returnStatus==='No'? <button type='button' className='btn-inverse' onClick={()=>handleReturn(row)}  title="Return Book"><i className="fa fa-mail-reply"></i></button> :''} 
            &nbsp;&nbsp;
        
        <button type='button' data-toggle="modal"  className='btn-info'  data-target={'#infoDialog-'+cell}     title="Reissue"><i className="fa fa-refresh"></i></button>

         <InfoModal title="Are you sure you want to reissue this book, this will extend the return date.?" handleConfirm={()=>handleReissueBook(row)} mID={cell} /> 
        </div>, editable: false},
        {dataField: 'studentName', text: 'Issued To',  editable:false, formatter: (cell, row)=>`(${row.admissionNumber}) ${cell}  `},
        {dataField: 'bookName', text: 'Books', editable:false, formatter: (cell, row)=>row.returnStatus==='Yes'?<div>{`${cell} (${row.authorName}) `}<br/><span className="pcoded-badge label label-success">Returned on {longDate(row.returnedDate)}</span></div>: `${cell} (${row.authorName}) `},       
        {dataField: 'dateIssue', text: 'Date of Issue', editable:false, formatter:(cell,row)=>
        longDate(cell)},
    {dataField: 'dateReturn', text: 'Date of Return', editable:false, formatter:(cell,row)=>
    longDate(cell)}, 
    {dataField: 'remarks', text: 'Remarks'}
     ];




     

 const BookTable=(props)=>{


    const handleUpdate=(column, newValue, ID)=>{       
        const fd = new FormData();
        fd.append('jwt', Token);
         fd.append('columnField', newValue);
         fd.append('column', column.dataField);
         fd.append('ID', ID);
       
        let url = ServerUrl+'/updateById_controller/tbl_book_issued'
       axios.post(url, fd, config)
       //.then(result => console.log(result.data))
       .then()
       .catch((error)=>console.log(error)) 
       
     } 
     const { SearchBar } = Search;
 
     const customTotal = (from, to, size) => (
        <span >&nbsp;Showing { from } to { to } of { size } items</span>
      );
     const options = {
         showTotal: true,
     paginationTotalRenderer: customTotal,
     sizePerPageList: [{text: '100', value: 100}, {text: '200', value: 200}, {text: '500', value: 500},
     { text: 'All', value: props.data.length }]
        
       };
  
     return <ToolkitProvider search columnToggle 
                 keyField='ID' data={ props.data } columns={ props.columns } >
                     
                     {
                         props => (
                         <div >
                           <div className="form-group">
                             <SearchBar
                             { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                             &nbsp;
                           
                            

  <div className="dt-buttons btn-group pull-right"> 
  	


<div className="btn-group dropdown-split-primary ">
    <button type="button" className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split waves-effect waves-light" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i className="fa fa-download"></i>Export
    </button>
    <div className="dropdown-menu" x-placement="bottom-start" style={{ position: 'absolute', transform: 'translate3d(110px, 40px, 0px)', top: '0px', left: '0px', willChange: 'transform'}}>
      

        <form method='POST' action={ServerUrl+'/excel_controller.php?api=issueStudent'} target='_blank'>

<input type='hidden' name='jwt' value ={Token} />

<button type="submit" className="btn dropdown-item waves-effect waves-light" > Export To Excel</button>

</form>

<form method='POST' action={ServerUrl+'/printout/books_issue_student.php?token='+userToken} target='_blank'>

<input type='hidden' name='jwtToken' value ={Token} />
<button type="submit" className="btn dropdown-item waves-effect waves-light" > Print Preview</button>

</form>
    </div>
</div> 
</div> 
  
                             </div>
                             
                      
             
                             <BootstrapTable
                             { ...props.baseProps }
                                         striped
                                     hover
                                     condensed
                                     noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                                 pagination={paginationFactory(options) }
                                 cellEdit={ cellEditFactory({
                                   mode: 'dbclick',
                                   blurToSave: true,
                                   autoSelectText: true,
                         beforeSaveCell: (oldValue, newValue, row, column) => {
                           if(oldValue !==newValue){
                           handleUpdate(column, newValue, row.ID);
                           
                          return '';
                         }
                         
                       }
                                 }) }
                             />
                         </div>
                         )
                     }
  </ToolkitProvider>
  }




     useEffect(()=>{
        setIsAuthenticated(menuLink.includes(location.pathname)) 
        fetchAllBooks();
        fetchIssuedBooks();    
        fetchStudents();
        fetchSettings();
        getDateFormat();
    
    },[]);
        return (   <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Issue Books">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-book"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Library</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Issue & Return</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


                    <div className="row  ">
   <div className="col-sm-12">

<div className="card z-depth-0">
    <div className="card-block">
    <div className="card z-depth-0">
        <div className="card-block">

        <a href="/library/issues_return/student" className="btn btn-success "><i className="fa fa-graduation-cap"></i> Student</a>&nbsp;&nbsp;
<a href="/library/issues_return/staff"  className="btn btn-danger "><i className="fa fa-user"></i> Staff</a>



</div>
</div></div>
</div>

</div>  </div>

                <div className="row  ">
						   <div className="col-sm-12">

                           {visibleReturn?
                               <div  className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Return Book</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		<div className="row">
									
										<section className="col-md-6">
                                    
                                        <div className="form-group">
						<label >Student</label><br/>
                        <label><b>{returnBook.studentName}</b></label>
			</div>

            </section>
            <section className="col-md-6">
    <div className="form-group">
						<label > Book</label><br/>
                        <label><b>{returnBook.bookName}</b></label>
			</div>

            </section>
            <section className="col-md-6">
            <div className="form-group">
						<label >Date of Return<span style={{color:'red'}}>*</span></label>
		<input type="date" name="returnDate" id="returnDate" min={returnBook.minDate} value={returnBook.returnDate}  onChange={handleChangeReturnDate} className={errors.returnDate  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.returnDate && errors.returnDate}</span>	
			</div>
            <br/>
<label><b>Due Book Fine Details</b></label>
<div className="table-responsive">
<table className="table"><thead>
            <tr>
            <th>Penalty</th>
            <th>Due Date</th>
            <th>Days Fine</th>
            
       </tr>
       </thead>
    <tbody>
        <tr>
        <td><input type="number" min="1" className='form-control' value={returnBook.penalty} name="penalty"  id="penalty"  onChange={handleChangeReturn} /></td>
        <td><span id="bookDate">{returnBook.dueDate}</span></td>
            <td><span >{returnBook.daysFine}</span></td>
            
        </tr>
    </tbody>
</table>
</div>
</section>
				</div>	
<br/>
	<footer className="pull-right">
	
	<button type="button" onClick={()=>setVisibleReturn(false)} className="btn btn-inverse "><i className="fa fa-refresh"></i> Cancel</button>&nbsp;&nbsp;
	<button type="button" id="submit" onClick={handleReturnBook} className="btn btn-primary ">Return Book</button>
	
								</footer>				
							</div>
                            </div>
							</div></div>:''}





                            <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Issue Books To Student</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
		<div className="row">
									
										<section className="col-md-6">
                                    

<div className="form-group">
            <label >Select Book <span style={{color:'red'}}>*</span></label>
            <Select isMulti options={
                allBooks&&allBooks.map((list, idx)=> {
                   return {key:idx, value: list.code, label: list.bookName, aut:list.authorName, barcode:list.barcode }
                 })} 
                 getOptionLabel={option =>`${option.barcode}  ${option.label} (${option.aut})`}
onChange={handleSelect} className={errors.books ? 'form-control form-control-danger' : ''} name="books" value={issued.books}  /> <span style={{color:'red'}}>{errors.books && errors.books}</span>	
</div>

</section>
<section className="col-md-6">
<div className="form-group">
						<label>Select Student<span style={{color:'red'}}>*</span></label>
						<Select  options={
                            students.map((list, idx)=> {
                               return {key:idx, value: list.code, label: list.admissionNumber, studentName:list.studentName, co:list.fatherName, phone:list.father_mobile  }
                             })} 
                        getOptionLabel={option =>`(${option.label}) ${option.studentName} C/O  ${option.co}  ${option.phone}`}
onChange={handleSelectStudent} className={errors.student ? 'form-control form-control-danger' : ''} name="student" value={issued.student}  /> <span style={{color:'red'}}>{errors.student && errors.student}</span>
											</div>


        <div style={{display:history.length===0?'none':'block'}} className="form-group">
<div className="alert alert-info alert-block background-info" >
					<h4 className="alert-heading " style={{textAlign:'center'}}> <i className="fa-fw fa fa-info"></i> Due Books</h4><hr/>
                    {history&&history.map((item, index)=>
<span key={index} >{index+1+'. '+getBookName(item.bookCode)} <br/></span>)}
										</div>
</div>

</section>
<section className="col-md-6">
	<div className="form-group">
						<label >Date of Issue<span style={{color:'red'}}>*</span></label>
		<input type="date" name="dateIssue" id="dateIssue" min="2020-01-01" max="" value={issued.dateIssue} onChange={handleChange} className={errors.dateIssue  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.dateIssue && errors.dateIssue}</span>	
			</div>

          
</section>
<section className="col-md-6">  <div className="form-group">
						<label >Date of Return<span style={{color:'red'}}>*</span></label>
		<input type="date" id="dateReturn" name="dateReturn" value={issued.dateReturn} min="2020-01-01" max="" onChange={handleChange} className={errors.dateReturn  ? 'form-control form-control-danger' : 'form-control'} /> <span style={{color:'red'}}>{errors.dateReturn && errors.dateReturn}</span>	
			</div>

            </section>
<section className="col-md-12">
            <div className="form-group">
						<label >Remarks</label>
		<input type="text" name="remarks" value={issued.remarks} onChange={handleChange} className='form-control' /> 
			</div>
			
            </section>
										</div>	

	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary ">Issue Book</button>
	
								</footer>				
							</div>
							</div>


                            </div>
		
	

	<div className="col-sm-12">
      
	<div className="card z-depth-0">
		<div className="card-header">
			<h5> <i className="fa fa-th" ></i> Listing All Issued Book to Student</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
		<div className="card-block">
        <div className="col-md-12 table-responsive">

        {notice.isDataFetching ? <TableLoader />:
   <BookTable data={allIssuedBooks}   columns={tableHeader}  />} 

    </div></div></div>
</div>


</div>
	

   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(IssueReturn) 