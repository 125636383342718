import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl, Token, config, allowDelete, allowEdit, menuLink} from '../component/include'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'

import { useSelector } from 'react-redux'
const SchoolClass = ()=>{
    
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 

    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
const [school, setSchool] = useState([]);
const [validate, setValidate] = useState([]);
const [isDisplay, setIsDisplay] = useState({ "0": true });

    const  handleFetchData =()=>{
      const fd = new FormData();
      fd.append("jwt", Token); 
        let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1'
      setNotice({...notice, 
          isDataFetching: true
      });
      axios.post(url,  fd, config)
      .then(result => {       
       setSchool(result.data.sort((a, b) =>(Number(a.order) > Number(b.order)) ? 1 : -1))
       setValidate(result.data)
          
      })
      .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    })
      
          }

 const removeClick=(index)=>{
    setSchool(school.filter((_, i) => i !== index)); 
   }


  const removeSection =(sub, main) =>{
    setSchool(school.map((list, id) => 
    main === id
      ? { ...list, section: list.section.filter((_,i)=>i!==sub) }
      : list
)); 
  }

  

 const  handleSectionChange = (e, subIndex, mainIndex) => {
  const { name, value } = e.target;

  const schools =JSON.parse(JSON.stringify(school))

if(schools.length && schools[mainIndex] && schools[mainIndex].section.length && schools[mainIndex].section[subIndex]) {
    schools[mainIndex].section[subIndex][name] = value;

       setSchool(schools); 
   } 

  }



  const handleAddSection=(mainIndex)=>{

     setSchool(school.map((list, id) => 
        mainIndex === id
          ? { ...list, section: [...list.section, {sname:'', ID:Math.random().toString(36).substr(2, 9) }] }
          : list
    )); 

  }

  const  handleChange=(e, i)=>{
    const { name, value } = e.target;
  let newSchool = [...school];
  newSchool[i] = {...newSchool[i], [name]: value};
  setSchool(newSchool);

}

  const addClick =()=>{
    setSchool(school.concat({arms:'', order:school.length+1, cname: "", ID:Math.random().toString(36).substr(2, 9), section:[{sname:'', ID:Math.random().toString(36).substr(2, 9)}]}))
    window.scrollTo(0, document.body.scrollHeight)
  }
   

 const  handleSubmit=(event)=> {
     event.preventDefault();
     let close =   document.getElementById('btnWarningDialog-1')
          close.click();

     if (validate === school){
      Alerts('Info!', 'info', 'You have mad eno changes')
     }else{

       setNotice({...notice, 
      isLoading: true})    

      const fd = new FormData();
      fd.append("jwt", Token); 
            fd.append('schoolClass', JSON.stringify(school))   
             
            
       let url = ServerUrl+'/update_controller/tbl_school_class'
      axios.post(url,  fd, config)
      .then(response =>{
        if (response.data.type ==='success'){
            Alerts('Saved!', 'success', response.data.message)
        } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
    }  
              
      })
      .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    })
    .finally(()=>{
      setNotice({...notice, isLoading: false}) 
    handleFetchData()
    })  
    }
   }


const handleToggleDisplay=(id)=>{
    setIsDisplay({...isDisplay, [id]: !isDisplay[id]});
}



useEffect(()=>{
  
  setIsAuthenticated(menuLink.includes(location.pathname)) 
    handleFetchData();
},[]);

    const createUI =()=>{
        return school && school.map((data, index) => (
 
  <div key={index} className="col-md-8">
         
         <div className="card-block">
         <div className="card z-depth-0"><br/>
             <div className="alert alert-default">



<div className="form-grop">
<div className="input-group">
  
  <select onChange={(e)=>handleChange(e, index)} required  name="arms"  value={data.arms} className="form-control mr-2">

<option  value="Creche" >Creche</option> 
<option  value="Pre-Nursery" >Pre-Nursery</option>
<option  value="Nursery" >Nursery</option> 
<option  value="Primary" >Primary</option>
<option  value="Junior Secondary School" >Junior Secondary School</option> 
<option  value="Senior Secondary School" >Senior Secondary School</option>
    </select>   
                        	
							

              <input type="number" name="order" value={data.order} onChange={(e)=>handleChange(e, index)} className="form-control" placeholder="Order" />

              </div>	</div>

             <div className="form-group">
             <div className="input-group">
             




            {isDisplay[index] ? <button type="button" onClick={()=>handleToggleDisplay(index)} className="btn btn-danger "> <i className="fa fa-minus"></i></button>
            :
          <button type="button" onClick={()=>handleToggleDisplay(index)} className="btn btn-primary "> <i className="fa fa-plus"></i></button>
          }
          
          &nbsp; 
       <input type="text" name="cname" value={data.cname||''} onChange={(e)=>handleChange(e, index)} className="form-control" placeholder="Class Name" />&nbsp;
        <button type="button"  className="btn btn-default " onClick={()=>handleAddSection(index)} > Add Section</button>&nbsp;
        {allowDelete==='Yes'?<button type="button" onClick={()=>removeClick(index)} className="btn btn-danger "><i className="fa fa-trash"></i></button>:[]}
       </div>
       </div>
       
       {/* Class section here */}
       
       <div className="row" >
       <div className="col-sm-2 " >
       </div>
           <div className="col-sm-8 " >
       {/* <!-- Sub section here --> */}
       <div id={index} style={{display: isDisplay[index] ? 'block': 'none'}}>
       {data.section && data.section.map((sec, i)=>{
                   return (
 
 <div key={i} className="form-group">
     <div className="input-group">
 <input type="text" name="sname" value={sec.sname} onChange={(e)=>handleSectionChange(e, i, index)} className="form-control" placeholder="Section Name" />&nbsp;

 {allowDelete==='Yes'?
 <button type="button" onClick={()=>removeSection(i, index)} className="btn btn-danger "><i className="fa fa-trash"></i></button>
 :[]}
 </div></div>
 
 )})} 
      
 </div>
        
       
       {/* <!-- it end here --> */}
       </div>
       <div className="col-sm-2 " >
       </div>
       </div>
       {/* class sections end here */}
       </div>
       </div>
       </div>
       </div>   
      
        ))
     }

    return(<>
    
      <div  className="main-body">
      {isAuthenticated?   <div className="page-wrapper">
                    {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                      
                        {/* <!-- Page-header start --> */}
     
             <PageHeader title="Class & Section">
             <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-edit"></i> </a>
                                 </li>
                         <li className="breadcrumb-item"><a href="#!">Class & Section</a> </li>
                         </PageHeader>
     {/* The Page Body start here */}
                     <div className="page-body">
     
                    
                     {/* <!-- Edit student start here  --> */}
     
     <div className="row">
     <div className="col-sm-12">
     
                    {/* <!-- Warning Dialog Start here--> */}
	<WarningModal message="This will modify all the changes you made including classes and sections. Are you really sure.?" handleAction={handleSubmit} mID='1' />
 
 {/* <!-- Warning Dialog End here--> */}
                     
         <div className="card z-depth-0">	
             <div className="card-header">
                 <h5>Manage Class</h5>
                 <div className="card-header-right">
                     <ul className="list-unstyled card-option">
                         <li><i className="feather icon-maximizes full-card"></i></li>
                         <li><i className="feather icon-minus minimize-card"></i></li>
                         <li><i className="feather icon-trash-2 close-card"></i></li>
                     </ul>
                 </div>
             </div>
             
             <div className="card-block">
             
                     <div className="card z-depth-0"><br/>
                 <div className="alert alert-default">
                 <button type="button" onClick={addClick} className="btn btn-default "> Add Class</button>&nbsp;
     <button type="button" data-toggle="modal" data-target="#warningdialog-1" className="btn btn-primary waves-effect"  id="saveClass"  >Update Class & Section</button>

             <a href="/staff/assign_class_teacher" className="btn" >Add Class Teacher</a>
                     </div>
                 </div>
                 
                 {createUI()}   
                 
     </div>
     
     <div>
     
                     </div>
                 </div>
                 </div> 
                     </div>
     
     
                     </div>
               {/* The Page Body close here */}
     
                 </div>:
            <Error403 />}
                 </div>
     
     </> 
     );
}



export default React.memo(SchoolClass) 