import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl, Token, Currency, config, allowDelete, allowEdit, menuLink} from '../component/include'
import { FormatNumber, formatGroupLabel, longDate} from '../component/globalFunction'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { userToken } from '../component/authentication'
const PrintVoucher =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    
    const [voucher, setVoucher] = useState({
        studentClass:[]

    })
    const [result, setResult]=useState([])
    const [total, setTotal]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
   
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);


    const fetchResult =()=>{
        setNotice({...notice, isLoading: true}) 
        const fd = new FormData();
    fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_class_fees/feesDetail/sectionID/'+voucher.studentClass.value
        
        axios.post(url, fd, config).then(result =>{
            if(result.data.length!==0){
                setResult(result.data)
                                
                var amount = 0;
                result.data.map(dat=>amount+=Number(dat.price))
                setTotal(amount)
                    }else{
                        Alerts('Info!', 'info', 'There are no record found')
                    }
                })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
        .finally(()=>{
            setNotice({...notice, isLoading: false})
        })
    }


    const handleSearch = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(voucher));
        setIsSubmitting(true);
    }  
    const ValidateErrors=(values)=>{
        let errors = {};   
        if(values.studentClass.length===0){
            errors.studentClass ='Please select class';
        } 
        return errors;
        }

    useEffect(()=>{
        if(Object.keys(errors).length === 0 && isSubmitting){
        fetchResult()
        }
        },[errors])


const handleSelect = (option, action) =>{
    setVoucher({...voucher, [action.name] : option });
   setErrors({...errors, [action.name]:''})
   setResult('')
}

useEffect(()=>{
    setIsAuthenticated(menuLink.includes(location.pathname))
}, [])

        return ( 
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Class Fees Structure">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-money"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Fees</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Class Fee Structure</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  no-print">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i>Fees Structure</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		<div className="row">
        
                      

      <section className="col-md-6">
      <div className="form-group">
         <label>Class<span style={{color:'red'}}>*</span></label><a href="/setup/school_classes" className="pull-right" >Add more classes</a>
        <Select options={
                            schoolClass.map((list, idx)=> {
                               return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                   return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                               }) }
							 })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={voucher.studentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>

            </div>
                    </section>

                <section className="col-md-6">
	<br/>
	<button type="button"  onClick={handleSearch} className="btn btn-inverse "><i className="fa fa-search"></i> Search Fees</button>
    </section>			
    </div>	</div>
                            </div>
							</div></div>
</div>

</div>
	


{result.length!==0?
<div className="card z-depth-0">
		<div className="card-block">
	
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <table className='table' key={id}>
       <tbody>
                <tr>
                <td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
 </td>
                </tr>
            </tbody>
       </table>  )} 
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Class Fees Structure</b></u></h5>
        </div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Class: <b>{voucher.studentClass.label}</b></td>
                    <td>Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
            </tbody>
       </table>
        <table className="table table-striped table-bordered  table-full-width">
    

            <thead>
                <tr>
                <th>SN</th>
                <th>Fee Type</th>
                <th>Amount ({Currency})</th>

                </tr>
            </thead>
            <tbody>{result.length!==0?result.map((row, idx)=> 
                <tr key={idx}>
                    <td>{idx+1}</td>
                    <td>{row.fees}</td>
                    <td>{FormatNumber(row.price)}</td>

                </tr>):[]}
            </tbody> 
            <tfoot>
                <tr>
                    <td className="text-right" colSpan="2"><b>Total</b> </td>
                    <td><b>{FormatNumber(total)}</b></td>
                </tr>
            </tfoot>
        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">
        <form method='POST' action={ServerUrl+'/printout/class_fee.php?token='+userToken} target='_blank'>
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='total' value ={FormatNumber(total)} />
                <input type='hidden' name='class' value ={voucher.studentClass.label} />
                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>
        </div></div>

 </div>
        </div>:''}
   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(PrintVoucher) 