import React, {useState, useEffect} from 'react'
import {LoadEffect}  from '../component/loader'
import PageHeader from '../component/pageheader'

import {ServerUrl, Token, config, allowDelete, allowEdit, menuLink} from '../component/include'
import axios from 'axios'
import {  convertDate,  getMonth} from '../component/globalFunction'
import {Bar, Line} from 'react-chartjs-2'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
const Chart =()=> {
    
const [notice, setNotice] = useState({
    isLoading : false, 
    isDataFetching: false,
})
     

    var date = new Date()
    var first = new Date(date.getFullYear(), date.getMonth(), 1, 23, 59, 59)
    var last = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59)

const [subject, setSubject] = useState([])
const [books, setBooks] = useState([])

let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);
const [statistics, setStatistics] = useState({
    books:0,
    ebook:0,
    borrowed:0,
    returned:0,
    subject:0
});

    const fetchBooks =()=>{
       
      var sql = "select dateIssue as label, sum(case when returnStatus = 'Yes'  then 1 else 0 end) AS returned, sum(case when returnStatus = 'No'  then 1 else 0 end) AS issued from tbl_book_issued where dateIssue >=  '"+convertDate(first)+"' and dateIssue <= '"+convertDate(last)+"'   GROUP BY dateIssue order by label "
      
               const fd = new FormData();
               fd.append("sql", sql);
               fd.append('jwt', Token)
               let url = ServerUrl+'/fetchBySql_controller';
               axios.post(url, fd, config).then(result=>{ 
                
                setBooks(result.data)
               })
           } 

           const dataBar = {
            labels: books.map(data=>data.label),
            datasets: [
              {
                label: 'Issued ',
                data: books.map(data=>data.issued),
                backgroundColor: 'rgb(10, 76, 181)',
                
                
              },
              {
                label: 'Return ',
                data: books.map(data=>data.returned),
                backgroundColor: 'rgb(49, 168, 13)',
              }
            ],
          }
          
          const optionsBar = {
            scales: {
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                    },
                  },
                ],
              },
          }; 
          

    const fetchSubject =()=>{
       
      var sql = "select s.abbreviation as label, b.bookName, sum(b.totalBook) as data from tbl_subject s, tbl_books b where b.subjectid = s.code  GROUP BY b.subjectId order by label  "
      
               const fd = new FormData();
               fd.append("sql", sql);
               fd.append('jwt', Token)
               let url = ServerUrl+'/fetchBySql_controller';
               axios.post(url, fd, config).then(result=>{ 
                 
                setSubject(result.data)
               })
           } 

           const dataSubject = {
            labels: subject.map((data)=>data.label),
            datasets: [
              {
                label: 'Total ',
                fill: false,
                lineTension: 0.5,
                backgroundColor: 'rgba(75,192,192,1)',
                borderColor: 'rgba(0,0,0,1)',
                borderWidth: 2,
                data: subject.map((data)=>data.data)
              }
            ]
          }; 

          
         
          const fetchStatistics = async ()=>{
           
            let sqls = "Select  (select sum(totalBook) from tbl_books ) as books, (select count(*) from tbl_book_issued)  as borrowed, (select count(*) from tbl_book_issued where returnedDate <> '0000-00-00')  as returned, (select count(*) from tbl_elibrary ) as ebook, (select count(*) from tbl_books ) as subject   "
            
             const fds = new FormData();
             fds.append("sql", sqls);
             fds.append('jwt', Token)
             let urls = ServerUrl+'/fetchBySql_controller';
             axios.post(urls, fds, config).then(result=>{ 
                
                setStatistics({
                  books:result.data[0].books,
                  ebook:result.data[0].ebook,
                  borrowed:result.data[0].borrowed,
                  returned:result.data[0].returned,
                  subject:result.data[0].subject
                })
             })
         } 
         


            









     

   useEffect(()=>{
    setIsAuthenticated(menuLink.includes(location.pathname)) 
     fetchStatistics()
     fetchSubject() 
     fetchBooks()
},[]);

        return (   <>
          <div  className="main-body">
          {isAuthenticated?   <div className="page-wrapper">
                        {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title='Chart'>
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-dashboard"></i> </a>
                            </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


<div className="row">


<div className="col-xl-3 col-md-6">
    <div className="card bg-c-yellow text-white">
        <div className="card-block">
            <div className="row align-items-center">
                <div className="col">
                    <p className="m-b-5">Total Books</p>
                    <h4 className="m-b-0">{statistics.books}</h4>
                </div>
                <div className="col col-auto text-right">
                    <i className="fa fa-file f-50 text-c-yellow"></i>
                </div>
            </div>
        </div>
    </div>
</div>


<div className="col-xl-3 col-md-6">
                <div className="card bg-c-blue text-white">
                    <div className="card-block">
                        <div className="row align-items-center">
                            <div className="col">
                            <p className="m-b-5">Total Borrowed</p>
                        <h4 className="m-b-0">{Number(statistics.borrowed) - Number(statistics.returned)}</h4>
                            </div>
                            <div className="col col-auto text-right">
                                <i className="fa fa-wpforms f-50 text-c-blue"></i>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


<div className="col-xl-3 col-md-6">
        <div className="card bg-c-green text-white">
            <div className="card-block">
                <div className="row align-items-center">
                    <div className="col">
                        <p className="m-b-5">Total Ebook</p>
                        <h4 className="m-b-0">{statistics.ebook}</h4>
                    </div>
                    <div className="col col-auto text-right">
                        <i className=" fa fa-file-pdf-o f-50 text-c-green"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

<div className="col-xl-3 col-md-6">
            <div className="card bg-c-pink text-white">
                <div className="card-block">
                    <div className="row align-items-center">
                        <div className="col">
                            <p className="m-b-5">Subject Books</p>
                            <h4 className="m-b-0">{statistics.subject}</h4>
                        </div>
                        <div className="col col-auto text-right">
                            <i className="fa fa-book f-50 text-c-pink"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>






      </div>

  



        <div className="row">
      <div className="col-12 col-lg-12 col-xl-12">
          <div className="card">
		   <div className="card-header"><strong>
     Subject Book </strong>
				</div>
             <div className="card-body">

              <Line
          data={dataSubject}
          options={{
            legend:{
              display:false,
              position:'right'
            }
          }}
          
        /> 

             </div>
          </div>
        </div></div> 





        <div className="row">
        <div className="col-12">
          <div className="card">
		   <div className="card-header">
            <strong> Issued & Return ({getMonth(new Date().getFullYear())})</strong>
				</div>
             <div className="card-body">

              <Bar
          data={dataBar}
          options={optionsBar}
        />  
             </div>
          </div>
        </div></div> 



   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(Chart) 