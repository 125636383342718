import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl,  Token, config, allowDelete, allowEdit, menuLink} from '../component/include'

import Error403 from '../settings/error403'
import Select from 'react-select'
import { longDate} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'


const DisplayStudentAttendance =()=> {
    const [errors, setErrors] = useState({});
    const [result, setResult] = useState([])
    const [sqlQuery, setSqlQuery] = useState([]);
    
    let location =  useLocation()
    
    const [isAuthenticated, setIsAuthenticated] = useState(true);
const [isSubmitting, setIsSubmitting] = useState(false); 
const staff  = useSelector(state => state.rootReducer.staff);
const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
   
    const [attendance, setAttendance] = useState({
       fromDate:'',
       toDate:'',
       staff:[]
    })

      
        
        const handleSelect= (option, action)=>{
            setAttendance({...attendance, [action.name]:option});
            setErrors({...errors, [action.name]:''})
            setResult('')
        }

        const handleChange=(event)=>{
            const {name, value} = event.target;
            setAttendance({...attendance, [name]:value})

            if(name==='fromDate'){
            getDaysBetween(value, attendance.toDate)
            }else if(name==='toDate'){
                getDaysBetween(attendance.fromDate, value)
            }

            setResult('')
            setErrors({...errors, [name]:''})
        }
       
        const getDaysBetween =(startDate, endDate)=>{
            const start = new Date(startDate)
            const end =  new Date(endDate)
        
            var result = []
            while (start <= end){
                result.push(new Date(start).toISOString().slice(0,10))
                start.setDate(start.getDate()+1)
            }
    
           
            let final =   'SELECT s.staffName, s.fileNo, sum(case when a.attendance  = "P" then 1 else 0 end) as P, sum(case when a.attendance  = "A" then 1 else 0 end) as A, sum(case when a.attendance  = "H" then 1 else 0 end) as H  from tbl_staff s, tbl_staff_attendance a where   s.code = a.staffCode and a.attendanceDate >= "'+startDate+'" and a.attendanceDate <= "'+endDate+'"'

            setSqlQuery(final)
        }
        
    
            const fetchResult =()=>{
                    var sql = sqlQuery

            if(attendance.staff.length!==0){
                sql = sql + '  and a.staffCode = "'+attendance.staff.value+'"'
            }
            
            sql = sql+ '  group by a.staffCode '
            
      setNotice({...notice, isLoading: true}) 
      const fd = new FormData();
      fd.append('jwt', Token); 
            fd.append("sql", sql);
            let url = ServerUrl+'/fetchBySql_controller'
            axios.post(url, fd, config).then(result=>{
                if(result.data.length!==0){
                    setResult(result.data)
                    
        window.scrollTo({top:document.body.scrollHeight, left:0, behavior:'smooth'})
                }else{
                    Alerts('Info!', 'default', 'No record found for the selected criterials')
                }
                
            })
            .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
                setNotice({...notice, isLoading: false})
                
            })  
        } 
        


            const validateInput =(values)=>{
                let errors = {};     
                
                if(!values.fromDate){
                    errors.fromDate ='This field is required';
                }
                if(!values.toDate){
                    errors.toDate ='This field is required';
                }
                return errors;
                }
            
            

            const handleSubmit =(event)=>{
                event.preventDefault();
                setErrors(validateInput(attendance));
                setIsSubmitting(true);
            } 


            useEffect(()=>{
                if(Object.keys(errors).length === 0 && isSubmitting){
                fetchResult()
                }
            },[errors])

useEffect(()=>{
    setIsAuthenticated(menuLink.includes(location.pathname))
},[])

        return (  
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Staff Attendance Summary">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-calendar"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Attendance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Staff Attendance Summary</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row no-print ">
						
                        <div className="col-sm-12">
                    
                        <div className="card z-depth-0">
                            <div className="card-header">
                                <h5><i className="fa fa-edit" ></i> Staff Attendance Summary</h5>
                                <div className="card-header-right">
                                    <ul className="list-unstyled card-option">
                                        <li><i className="feather icon-maximizes full-card"></i></li>
                                        <li><i className="feather icon-minus minimize-card"></i></li>
                                        <li><i className="feather icon-trash-2 close-card"></i></li>
                                    </ul>
                                </div>
                            </div>
                            
                            
                            <div className="card-block">
            

        <div  className="card-block">
        <div  className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
      <section className="col-md-6" >
      <div className="form-group">
        
        <label >Select Staff </label>
        <Select  options={staff&&staff.map((list, idx)=> {
                                       return {key:idx, value: list.code, label:list.staffName, fileNo:list.fileNo }
                                     })
                                }    
                    getOptionLabel={option =>option.label +' '+ option.fileNo}    
        onChange={handleSelect}  name="staff" value={attendance.staff}  />   
                    </div>

</section>


      <section className="col-md-3">
      <div className="form-group">
   <label >From Date <span style={{color:'red'}}>*</span></label>
   <input name='fromDate' value={attendance.fromDate} onChange={handleChange} className={errors.fromDate? 'form-control form-control-danger' : 'form-control'}  type="date" />
                    
                    <span style={{color:'red'}}>{errors.fromDate && errors.fromDate}</span>
               </div>

   

</section>

<section className="col-md-3">
<div className="form-group">
                    <label >To  Date <span style={{color:'red'}}>*</span></label>
                    <input name='toDate' value={attendance.toDate} onChange={handleChange} className={errors.toDate? 'form-control form-control-danger' : 'form-control'}  type="date" />
                    
   <span style={{color:'red'}}>{errors.toDate && errors.toDate}</span>
                    </div>

</section>		
     


				</div>	

<div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit} className="btn btn-primary "><i className="fa fa-search"></i> Search Attendance</button>
								</footer>		</section>		
                                </div>
                            
                            
                            </div></div> 
							</div></div>
</div>
</div>
</div>



{result.length!==0?
    <div className="card z-depth-0">
		<div className="card-block">
	
        <div className="row" >
        {schoolInfo&&schoolInfo.map((st, id)=>
                       <table className='table' key={id}>
       <tbody>
                <tr>
                <td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
 </td>
                </tr>
            </tbody>
       </table>  )} 

</div> 
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Staff Attendance Summary</b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Date Range: <b>{longDate(attendance.fromDate) + ' - ' +longDate(attendance.toDate)}</b></td>
                    <td>Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
            </tbody>
       </table>
       <table className="table table-striped table-bordered">
        <thead>
                <tr>
                <th>S/N</th>
                <th>File No</th>
                    <th>Staff Name</th>
                    <th>Total Attendance</th>
                    <th>Total Present</th>
                    <th>Total Half Day</th>
                    <th>Total Absent</th>
                    <th>Present (%)</th>
                    <th>Half Day (%)</th>
                    <th>Absent (%)</th>
                </tr>
              </thead>
              <tbody>
              {result.length!==0?result.map((st, idx)=> 
                <tr key={idx}>
                <td>{idx+1}</td>
                <td>{st.fileNo}</td>
                    <td>{st.staffName}</td>
                    <td>{Number(st.P) + Number(st.A) + Number(st.H)}</td>
                    <td>{st.P}</td>
                    <td>{st.H}</td>
                    <td>{st.A}</td>
                    <td>{((100*Number(st.P)) / (Number(st.P) + Number(st.A)  + Number(st.H) ))}</td>
                    <td>{((100*Number(st.H)) / (Number(st.P) + Number(st.A)  + Number(st.H) ))}</td>
                    <td>{((100*Number(st.A)) / (Number(st.P) + Number(st.A)  + Number(st.H) ))}</td>
                </tr>):[]}
              </tbody>

        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">
        <form method='POST' action={ServerUrl+'/printout/staff_attendance.php?token='+userToken} target='_blank'>
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='dateRange' value ={longDate(attendance.fromDate) + ' - ' +longDate(attendance.toDate)} />
                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>
        
        </form>

        </div></div>

 </div>
        </div>:''}



   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(DisplayStudentAttendance) 