import React, {useState, useEffect } from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl,  Token, config, allowDelete, allowEdit, menuLink} from '../component/include'
import { longDate } from '../component/globalFunction'


import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import { useSelector } from 'react-redux'

const DisplayStaffAttendance =()=> {
    const [result, setResult]= useState([])
    const [sqlQuery, setSqlQuery] = useState([]);
    
    let location =  useLocation()
    
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [attendance, setAttendance] = useState({
        attendanceMonth:''
    })
   
    const [head, setHead]= useState([])



    const handleChangeDate=(event)=>{
        const {name, value} = event.target;
        setAttendance({...attendance, attendanceMonth:value})
        const days =  new Date(value.slice(0,4), value.slice(5,7), 0).getDate();
        const start = value+'-01';
        const end = value+'-'+days;
        getDaysBetween(start, end)
        setResult('')
    }
   
    const getDaysBetween =(startDate, endDate)=>{
        const start = new Date(startDate)
        const end =  new Date(endDate)
    
        var result = []
        while (start <= end){
            result.push(new Date(start).toISOString().slice(0,10))
            start.setDate(start.getDate()+1)
        }

        let sqlString = result.map(data=>"max(case when a.attendanceDate  = '"+data+"'  then a.attendance else '-' end) AS '"+data.slice(8,10)+getDays(data)+"'")
        setHead(result.map(data=>data.slice(8,10)+getDays(data)))

        let final =   'SELECT s.staffName, sum(case when a.attendance  = "P" then 1 else 0 end) as P, sum(case when a.attendance  = "H" then 1 else 0 end) as H, sum(case when a.attendance  = "A" then 1 else 0 end) as A, '+String(sqlString)+ ' from tbl_staff s, tbl_staff_attendance a where s.code = a.staffCode and s.employmentStatus = "Working" and a.attendanceDate >= "'+startDate+'" and a.attendanceDate <= "'+endDate+'" group by a.staffCode'
        setSqlQuery(final)
    }
    
    const getDays=(date)=>{
        let day;
        switch (new Date(date).getDay()){
            case 0:
                day =" Sun";
                break;
            case 1:
                day =" Mon";
                break;
            case 2:
                day =" Tue";
               break;
            case 3:
                day =" Wed";
                    break;
            case 4:
                day =" Thu";
                    break;
            case 5:
                day =" Fri";
                break;
            case 6:
                day =" Sat"
                break;
            default:
                day=" Sun"
        }
            return day
        }

        const fetchResult =()=>{
            if(attendance.attendanceMonth===''){
                Alerts('Error!', 'danger', 'Please select attendnace month')
            }else{

            
            setNotice({...notice, isLoading: true}) 

            const fd = new FormData();
            fd.append('jwt', Token);  
        fd.append("sql", sqlQuery);
        let url = ServerUrl+'/fetchBySql_controller'
        axios.post(url, fd, config).then(result=>{
            if(result.data.length!==0){
                setResult(result.data)
            }else{
                Alerts('Info!', 'default', 'No record found for the selected month')
            }
            
        })
        .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
        }).finally(()=>{
            setNotice({...notice, isLoading: false})
            
        }) 
    }
        }


useEffect(()=>{
   
  setIsAuthenticated(menuLink.includes(location.pathname))
}, [])

        return (  
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Attendance">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-calendar"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Attendance</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Staff Attendance</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row no-print ">
							
	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Display Staff Attendance</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximize full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div>
        
        <div className="card-block">
            
        <section className="col-md-12">
        <span className="pcoded-badge label bg-simple-c-pink">Color Icon For Absent</span>
        <span className="pcoded-badge label label-info">Color Icon For Half Day</span>
        <span className="pcoded-badge label bg-simple-c-green">Color Icon For Present</span>
        </section>


        <div  className="card-block">
        <div  className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">

        <section className="col-md-6">
   

               <div className="form-group">
   <label >Select Attendance Month</label>
   <input type="month" className='form-control' name="attendanceDate"  onChange={handleChangeDate} value={attendance.attendanceDate} />

               </div>
</section>	
<section className="col-md-6"><br/>
	<footer className="pull-left">
	<label></label>
	<button type="button" id="submit" onClick={()=>fetchResult()} className="btn btn-primary "><i className="fa fa-search"></i> Proceed</button>
								</footer>		</section>		
							</div>
                            
                            
                            </div></div> 
							</div></div>
</div>
</div>
</div>
	

{result.length!==0?
    <div className="card z-depth-0">
		<div className="card-block">
	
                       <div className="row">

                       {schoolInfo&&schoolInfo.map((st, id)=>
                       <table className='table' key={id}>
       <tbody>
                <tr>
                <td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
 </td>
                </tr>
            </tbody>
       </table>  )} 

</div> 
                       
 <div className="row">
        <div className="col-md-12">
        <h5 style={{textAlign:'center'}}><u><b>Staff Attendance </b></u></h5>
        </div></div><br/>



<div className="row">
<div className="col-md-12 table-responsive">

       <table className="table table-bordered  table-full-width">
       <tbody>
                <tr>
                    <td >Month: <b>{attendance.attendanceMonth}</b></td>
                    <td>Date Printed: <b>{longDate(new Date())} </b></td>
                </tr>
            </tbody>
       </table>
       <table className="table table-striped table-bordered">
        <thead>
                <tr>
                <th>S/N</th>
                    <th>Staff Name</th>
                    <th><span className="pcoded-badge label label-success">P</span></th>
                    <th><span className="pcoded-badge label label-info">H</span></th>            
                    <th><span className="pcoded-badge label label-danger">A</span></th>
                    {head&&head.map((data, id)=><th key={id} >{data}</th>)}
                </tr>
              </thead>
              <tbody>
              {result.length!==0?result.map((st, idx)=> 
                <tr key={idx}>
                <td>{idx+1}</td>
                <td>{st.staffName}</td>
                <td><span className="pcoded-badge label label-success">{st.P}</span></td>
                    <td><span className="pcoded-badge label label-info">{st.H}</span></td>            
                    <td><span className="pcoded-badge label label-danger">{st.A}</span></td>
                {head&&head.map((data, id)=><td key={id} >
                    {st[data]==='P'?
                    <span className="pcoded-badge label label-success">{st[data]}</span>:
                    st[data]==='A'?
                    <span className="pcoded-badge label label-danger">{st[data]}</span>:
                    st[data]==='H'?
                    <span className="pcoded-badge label label-info">{st[data]}</span>:
                    st[data]} </td>)} 
                </tr>):[]}
              </tbody>

        </table>
 </div>	 </div> 

 <div className="row">
        <div className="col-md-12">

       <form method='POST' action={ServerUrl+'/printout/staff_attendance_excel.php'} target='_blank'>
                <input type='hidden' name='head' value ={JSON.stringify(head, null, 2)} />
                <input type='hidden' name='result' value ={JSON.stringify(result, null, 2)} />
                <input type='hidden' name='api' value ='staff' />
                <input type='hidden' name='jwtToken' value ={Token} />
        <button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-table"></i> Export to Excel</button>
        
        </form>
        </div></div>

 </div>
        </div>:''}



   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(DisplayStaffAttendance) 