import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts, WarningModal} from '../component/notify'
import PageHeader from '../component/pageheader'
import { config, selectStyles, allowDelete, allowEdit, menuLink, API_URL} from '../component/include'
import Select from 'react-select'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { formatGroupLabel } from '../component/globalFunction'
import { useSelector } from 'react-redux'

import { useLocation } from 'react-router-dom'
import Error403 from '../settings/error403'

 const DisableStudentResult =()=>{

    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 

    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [exams, setExams]=useState([])
    const students  = useSelector(state => state.rootReducer.students);
   
    const group  = useSelector(state => state.rootReducer.group);
    
    const [disabledStudent,  setDisabledStudent] = useState([]);
    const [remainStudents, setRemainStudents] = useState([]);
    const [studentList, setStudentList]=useState([])

    const [disabledResult, setDisabledResult]=useState([])


    const [examType, setExamType] = useState({
        resultClass:[],
        resultTerm:[],
        missingStudents:[]
        
    })


    const [errors, setErrors] = useState({});
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    


  

    const fetchExams =()=>{
       
        let url = API_URL+'/exam/result/examtype';
        axios.get(url,  config).then(result=>{
            setExams(result.data.examType)
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', error.message)
        })
        }
    
  


    const getStudent =(code, field)=>{
        const result = students.filter(list=>list.code===code);
      const answer = result.map(ans=>ans[field])
        return String(answer)
      }




const handleSelect = (option, action)=>{
   
   
    
    if (action.name==='resultClass'){
        fetchStudentList(examType.resultTerm.value, option.value)
        setExamType({...examType, [action.name]: option});
    }else{
        setExamType({...examType, [action.name]: option, resultClass:[]});
        setDisabledStudent([])
        setDisabledResult([])
        setRemainStudents([])
        setStudentList([])
    }
   setErrors({...errors, [action.name]:''})
}




    const fetchStudentList =(exam, section)=>{
    
            setNotice({...notice, isLoading: true}) 
          
    let url = API_URL+'/student/disabled/'+section+'/'+exam;
    axios.get(url, config).then(result=>{
       
        if(result.data.data.length!==0 && Array.isArray(result.data.data)){
            setStudentList(result.data.data)

                let value = [];
                let studentList = result.data.studentList
                let std = studentList.length!==0?studentList[0].studentList:[]

               
            const options = std.length!==0? JSON.parse(std):[]
        
            for (var i = 0, l = options.length; i < l; i++) {
              value.push({
                studentCode:options[i].studentCode, 
                admissionNumber:getStudent(options[i].studentCode, 'admissionNumber'),  
                studentName:getStudent(options[i].studentCode, 'studentName'), 
                termination:getStudent(options[i].studentCode, 'termination'),
                termination_date:getStudent(options[i].studentCode, 'termination_date'), 
                termination_remark:getStudent(options[i].studentCode, 'termination_remark'), 
            });

        }
        
        setDisabledResult(options)
        setDisabledStudent(value)

        }else{
   setStudentList([])
   }
    })
    .catch((error)=>{
    Alerts('Error!', 'danger', error.message)
    }).finally(()=>{
        setNotice({...notice, isLoading: false})
        
    })
    }


    const handleSelectStudent = (option, action)=>{
        setExamType({...examType, [action.name]: option });
       setErrors({...errors, [action.name]:''})
    }



const filterStudent =()=>{

const std = [...studentList]
const disabledList = [...disabledStudent]
var newList = std.filter(function(objFromA) {
    return !disabledList.find(function(objFromB) {
      return objFromA.studentCode === objFromB.studentCode
    })
  })

  if(disabledList.length!==0){
    setRemainStudents(newList)
  }else{
setRemainStudents(std)
  }


}



const DisabledSelected =()=>{
    
     let errors = {};   
     
      if(examType.resultClass.length===0){
          errors.resultClass ='Please select class';
      }   
      if(examType.resultTerm.length===0){
          errors.resultTerm ='Please select exam type';
      } 
      
      if(examType.missingStudents.length===0){
        errors.missingStudents ='Please select student';
    } 
      
      setErrors(errors) 
  
     if(Object.keys(errors).length === 0){

        var std = examType.missingStudents;

        var studentCode = [];
        for (var i = 0; i < std.length; i++){
            studentCode.push(
                    {
                        studentCode:std[i].value
                        
                    });
        }

const oldStudent =[...disabledResult];
const allStd = oldStudent.concat(studentCode)

const newStudent =	allStd.map(e=>e['studentCode'])
                        .map((e,i,final)=>final.indexOf(e)===i&&i)
                        .filter(e=>allStd[e])
                        .map(e=>allStd[e])
                        submitResult(newStudent) 
    
                    }
}


const handleDeleteStudentResult =(code)=>{
    let close =   document.getElementById('btnWarningDialog-'+code)
    close.click();
    
    const dislist = disabledResult.filter(item=>item.studentCode!==code)
    submitResult(dislist)
}


const submitResult =(newStudent)=>{
  
    setNotice({...notice, isLoading: true})
    
    const fd = new FormData(); 
fd.append('studentList', JSON.stringify(newStudent, null, 2));
fd.append('code', Math.random().toString(36).substring(2,9))
fd.append('classID', examType.resultClass.classID)
fd.append('examType', examType.resultTerm.value);
fd.append('sectionID', examType.resultClass.value)


let url = API_URL+'/exam/result/disabled';
        axios.post(url, fd, config)
    .then(response =>{
        if (response.data.status ===200){
            Alerts('Saved!', 'success', response.data.message)
        } else{
            Alerts('Error!', 'danger', JSON.stringify(response.data))
    }       
    })
    .catch(error=>Alerts('Error!', 'danger', JSON.stringify(error.message)) )
.finally(()=>{
setNotice({...notice, isLoading: false})

fetchStudentList(examType.resultTerm.value, examType.resultClass.value)
filterStudent() 

setExamType({
    ...examType,
    missingStudents:[],
})  
}) 
}






    const TableStudentList=(props)=>{
       
            
    
    const { SearchBar } = Search;
    
    const customTotal = (from, to, size) => (
    <span >&nbsp;Showing { from } to { to } of { size } items</span>
    );
    const options = {
    showTotal: true,
    paginationTotalRenderer: customTotal,
    sizePerPageList: [{text: '50', value: 50}, {text: '200', value: 200}, {text: '500', value: 500}, {text: '1000', value: 1000},
    { text: 'All', value: props.data.length }]
    
    };
    return  <ToolkitProvider search columnToggle 
                keyField='studentCode' data={ props.data } columns={ props.columns } >
                    
                    {
                        props => (
                        
                        <div className="form-group">
                            <SearchBar
                            { ...props.searchProps } style={{height:'40px'}} className="form-control" placeholder="Type to search!!!" />
                                
                                <div className="btn-group pull-right">
  
  </div>         
                <BootstrapTable
                            { ...props.baseProps }
                            striped
                            hover
                            condensed
                            noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
                        pagination={paginationFactory(options) }
                       
                            />
                        </div>
                        )
                    }
</ToolkitProvider>
}

const tableResultHeader = [
            
    {dataField: 'admissionNumber', text: 'Admission No',  editable: false},

        {dataField: 'studentName', text: 'Name',  editable: false, formatter:(cell, row)=>cell},
        {dataField: 'studentCode', text: 'Remove', formatter: (cell, row)=><div>
        <WarningModal  handleAction={()=>handleDeleteStudentResult(row.studentCode)} mID={row.studentCode} message='The student will be removed from the disabled list. Are you realy sure?' />
        <button type="button" className="btn btn-danger  btn-sm" data-toggle="modal"   data-target={`#warningdialog-${row.studentCode}`} title="Delete"><i className="fa fa-trash"></i> </button>
        
        </div>, editable: false, hidden:allowDelete==='No'?true:false},        
     ]


      


    useEffect(()=>{
       
        setIsAuthenticated(menuLink.includes(location.pathname))
        fetchExams()
    },[]);
  

        return (  
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
        <PageHeader title="Disable Result">
    <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>       
                    <li className="breadcrumb-item"><a href="#!">Disable Student Result</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">


                    


    <div className="row">
    <div className="col-sm-12">
	<div className="card z-depth-0">
	
                    <div className="card-header">
                        <h5>Class Result</h5>
                        <div className="card-header-right">
                            <ul className="list-unstyled card-option">
                                <li><i className="feather icon-maximizes full-card"></i></li>
                                <li><i className="feather icon-minus minimize-card"></i></li>
                                <li><i className="feather icon-trash-2 close-card"></i></li>
                            </ul>
                        </div>
                    </div>
	  <div className="row setup-content" id="resultList">
    

<div className="col-md-12">


<div className="card-block">
<div className="card z-depth-0">


<div className="card-block">

<div className="row">
  <section className="col-md-6">
           <div className="form-group">
         <label> Exam Type<span style={{color:'red'}}>*</span></label>
         <Select  options={exams.map((list, id)=>{
             return {key:id, value:list.examCode, label:list.exam}
         })}               
 onChange={handleSelect} className={errors.resultTerm ? 'form-control form-control-danger' : ''} name="resultTerm" value={examType.resultTerm}  />
 <span style={{color:'red'}}>{errors.resultTerm && errors.resultTerm}</span>
            </div>
     
     </section>

     <section className="col-md-6">
         <div className="form-group">
				<label > Select Class</label><span style={{color:'red'}}>*</span>
				<Select  options={
                        schoolClass.map((list, idx)=> {
                            return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                            }) }
                          })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.resultClass? 'form-control form-control-danger' : ''} name="resultClass" value={examType.resultClass} /> 
<span style={{color:'red'}}>{errors.resultClass && errors.resultClass}</span>
										
			</div>
     
     </section>
     
     
<section className="col-md-12">
<div className="form-group">
<label className="bold "> Select Student <span style={{color:'red'}}>*</span></label>
<Select isMulti
 options={
       remainStudents&&remainStudents.map((list, idx)=> {
                               return {key:idx, value: list.studentCode, label: list.admissionNumber, studentName:list.studentName }
                             })} 
                        getOptionLabel={option =>option.label +' '+ option.studentName}

onChange={handleSelectStudent} placeholder="Select student.." onMenuOpen={filterStudent}  className={errors.missingStudents ? 'form-control form-control-danger' : ''} styles={selectStyles} name="missingStudents" value={examType.missingStudents}  /> <span style={{color:'red'}}>{errors.missingStudents && errors.missingStudents}</span>


</div>
</section>

        <div className="col-md-12 "> 
     <button type="button" id='btnCreate'  className="btn btn-danger waves-effect pull-right" onClick={DisabledSelected}  > <i className="fa fa-times"></i> Disabled Selected Result</button>
     </div>


     
</div></div>


    </div>

{examType.resultTerm.length!==0 &&disabledStudent.length!==0? 
    <div className="card-block">
       <div className="card z-depth-0">

       <div className="card-block">
      
  <div className="row">
                           
       <section className="col-md-12 table-responsive">
        {notice.isDataFetching ? <TableLoader />:
   <TableStudentList data={disabledStudent}  columns={tableResultHeader}  />} 
       </section>
               </div>
               </div>
       
       </div>
       </div>
       
     :''}




    </div>
                               
       </div>	

       
       </div>
     
     
     

                </div>
                
                
                
                </div>
                </div>
                </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(DisableStudentResult) 