import React, {useState, useEffect} from 'react';
import Preloader from '../component/preloader'
import Footer from '../component/footer'
import Header from '../component/header'
import Sidebar from './sidebar'
import { Token, config, ServerUrl, staffCode } from './include';
import axios from 'axios'
import { Alerts } from './notify';
import { useDispatch } from 'react-redux';

import { useSelector } from 'react-redux'
import { BrowserRouter as Router} from 'react-router-dom'
import ProtectedView from '../protected_view';
import { compare } from './globalFunction';


const Template = (props) =>
{
    const [user, setUser] = useState({
		userPassword:'',
        loading:false
    })
    const dispatch = useDispatch()
    const users  = useSelector(state => state.rootReducer.user);
    const [errors, setErrors] = useState({});
    const handleChange = event =>{		
        let {name, value} = event.target;	
       setUser({...user, [name] : value });
    }    

    const fetchStaff = async ()=>{
        const fd = new FormData();
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetch_controller/tbl_staff'
        const {data} = await axios.post(url, fd, config)
        dispatch({
            type:'SET_RECORDS',
            name:'staff',
            data:data
        })
    }
    const fetchCountry = async ()=>{

    let url = 'https://restcountries.com/v3.1/all'
        const {data} = await axios.get(url, config)
        dispatch({
            type:'SET_RECORDS',
            name:'countries',
            data:data
        })
    }
   
    const handleFetchState = async()=>{
        let url = 'https://locus.fkkas.com/api/states'
        const {data} = await axios.get(url, config)
        dispatch({
            type:'SET_RECORDS',
            name:'states',
            data:data.data
        })

     }


    const fetchHostel = async ()=>{
        const fd = new FormData();
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetch_controller/tbl_hostel'
        const {data} = await axios.post(url, fd, config)
        dispatch({
            type:'SET_RECORDS',
            name:'hostel',
            data:data
        })
    }

    const fetchClass = async ()=>{
        const fd = new FormData();
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetchField/tbl_school_class/schoolClass/ID/1'
        const {data} = await axios.post(url, fd, config)
        dispatch({
            type:'SET_RECORDS',
            name:'schoolClass',
            data:data.sort((a, b) =>(Number(a.order) > Number(b.order)) ? 1 : -1)
        })
    }


    const fetchStudents = async ()=>{
        const fd = new FormData();
    fd.append('jwt', Token);
    let url = ServerUrl+'/fetch_controller/tbl_students'
        const {data} = await axios.post(url, fd, config)
        dispatch({
            type:'SET_RECORDS',
            name:'students',
            data:data
        })
    }
    
   

    const fetchSchoolInfo = async ()=>{

       /*  const fd = new FormData();
        fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_basic_details'
            const {data} = await axios.post(url, fd, config)
            dispatch({
                type:'SET_RECORDS',
                name:'schoolInfo',
                data:data
            }) */
    }


    const fetchSession = async ()=>{

        var sql ="Select  startYear, ID from tbl_school_session  order by startYear DESC"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        const {data} = await axios.post(url, fd, config)
            dispatch({
                type:'SET_RECORDS',
                name:'schoolSession',
                data:data
            })
        }

    const getTotalStudent= async()=>{
        const fd = new FormData();
        fd.append('jwt', Token)
        let url = ServerUrl + '/fetchCount_controller.php?tablename=tbl_students' 
        const {data} = await axios.post(url, fd, config)
            dispatch({
                type:'SET_RECORDS',
                name:'totalStudent',
                data:Number(data)
            })
    }

	const submit=()=>{
       
		if(!user.userPassword){
            setErrors({errors, userPassword:'Please enter your password'})
        }else{

             setUser({...user, loading:true})
        const fd = new FormData();
       
		fd.append('jwt', Token)
        
        fd.append('userCode', staffCode)
		fd.append('userPassword', user.userPassword)
           let url = ServerUrl+'/update_controller/tbl_unlock_screen';
		axios.post(url, fd, config)
		.then(response =>{
	    if (response.data.type ==='success'){
            localStorage.removeItem('kayus_lcksr')
            document.getElementById('closeLockscreen').click()
			
			}else{
                setErrors({errors, userPassword:response.data.message})
               
			}    
		})
		.catch((error)=>{
			Alerts('Error!', 'danger', error.message)
            window.location.href='/'
			 
		}).finally(()=>{
            setUser({...user, loading:false})
        })     
} 

    }


   
    const fetchSidebar = async()=>{
    
        var sql ="Select r.roles, n.menu from tbl_usertype r, tbl_navigation n  where r.code ='"+users.userType+"'";

          
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        
       await axios.post(url, fd, config).then(result=>{ 
            if(result.data.length!==0){
    
            let response = JSON.parse(result.data[0].menu).sort(compare)
            let newMenu = JSON.parse(result.data[0].roles)
      
          let roles = []
          let menuLink = []
           for (var i in newMenu){
            if(newMenu[i].status===true){
            roles.push(newMenu[i].ID)
            menuLink.push(newMenu[i].menuUrl)
            }
    
     for(var k in newMenu[i].submenu){
            if(newMenu[i].submenu[k].status===true){
            roles.push(newMenu[i].submenu[k].ID)
            menuLink.push(newMenu[i].submenu[k].subUrl)
            }
    
         for(var m in newMenu[i].submenu[k].subsubmenu){
            if(newMenu[i].submenu[k].subsubmenu[m].status===true){
            roles.push(newMenu[i].submenu[k].subsubmenu[m].ID)
            menuLink.push(newMenu[i].submenu[k].subsubmenu[m].subsubUrl) 
            }
         }}
    }
    
    dispatch({
        type:'SET_RECORDS',
        name:'menuLink',
        data:menuLink
    })
    dispatch({
        type:'SET_RECORDS',
        name:'roles',
        data:roles
    })
    dispatch({
        type:'SET_RECORDS',
        name:'sidebar',
        data:response
    })
            }
        })
    
    }


   
    useEffect(()=>{ 
        fetchSidebar()
    }, [users])

    useEffect(()=>{ 
        fetchStaff();
        fetchSession()
        fetchStudents();
        fetchHostel()
        fetchClass()
        fetchCountry()
        getTotalStudent()
        handleFetchState()
    }, [])


    return (<Router>

        {/* <!-- Pre-loader start --> */}
        <Preloader />
        {/* <!-- Pre-loader end --> */}


        <div id="pcoded" className="pcoded">
      
    <div className="pcoded-overlay-box"></div>

    <div className="pcoded-container navbar-wrapper">

       {/* Header Component Here */}

       <Header />

{/* The  header End here */}
  <a href="#!" data-toggle="modal" id='openlockscreenModal' data-target='#lockscreen' ></a>
  <div className="modal fade " data-backdrop="static" data-keyboard="false" id='lockscreen' tabIndex="-1" role="dialog">
        <div className="modal-dialog " role="document">
            <div className="modal-content">
               
                <div className="modal-body">
                <div className="row m-b-20">
			<div className="col-md-12">
				<h3 className="text-center"><i className="feather icon-lock text-primary f-60 p-t-15 p-b-20 d-block"></i></h3>
			</div>
</div>
			<div className="form-group form-primary">
        <button type="button" className="close" id='closeLockscreen' data-dismiss="modal" aria-label="Close"></button>
				<input type="password"  name="userPassword"  value={user.userPassword} placeholder="Enter your password to unlock" onChange={handleChange} className={errors.userPassword  ? 'form-control form-control-danger' : 'form-control'} /> 
				
				
			</div><span className=" form-bar error">{ errors.userPassword}</span>
			<div className="row">
				<div className="col-md-12">
				{user.loading===false?	<button type="button" onClick={submit}  className="btn btn-primary btn-md btn-block "><i className="icofont icofont-lock"></i> UnLock Screen </button>:<button type="button" disabled='true' className="btn btn-warning btn-md "><i className="icofont icofont-unlock"></i> Please wait... </button>}
				</div>
			</div>
                </div>
            </div>
        </div>
    </div>

 {/* 
 <!-- Sidebar inner chat end--> */}
 <div className="pcoded-main-container">
     <div className="pcoded-wrapper">

     
        {/* Navbar start here */}

        <Sidebar menu = {props.menu}
        submenu= {props.submenu} /> 

{/* Navbar end here */}

    <div className="pcoded-content">
        <div className="pcoded-inner-content">


       {/*  <div id="advert" className="box-body">
        <div className="alert background-warning alert-warning">
          <p className="marquee text-white"><span> <i className="fa fa-fw fa-info-circle"></i>This is a demo version. Data will be reset in every 24 hours interval. <a  href="#!" className="text-black">Buy Now ? </a>Call +234-803-295-0881</span></p>
        </div>
      </div> */}
      
            {/* <!-- Main-body start --> */}
        <ProtectedView />
            

            {/* Footer Component Here */}
                <Footer />

{/* The Main Body close here */}
            </div>
            </div> 
           {/* The Main Body close here */}            
           </div></div>

            


</div>
            </div> 
        
        
        
            </Router>)
}
export default React.memo(Template)