import React, {useState, useEffect, useRef} from 'react'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ImagesUrl, ServerUrl, Token, config} from '../../component/include'
import Select from 'react-select'
import { FormatNumber, getCAPoint, longDate} from '../component/globalFunction'
import { formatGroupLabel} from '../component/globalFunction'
import { userToken } from '../component/authentication'

import { useLocation } from 'react-router-dom'
import { verifyRouter } from '../component/authentication'
import Error403 from '../settings/error403'
import { useSelector } from 'react-redux'
const ClassSessionCummulative =()=> {
    const [errors, setErrors] = useState({});
    const structure = useRef(null)
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    const [examtype, setExamtype]=useState([])
  
    const [scores, setScores]=useState([])
const [student, setStudent]=useState([])
let location =  useLocation()
const [isAuthenticated, setIsAuthenticated] = useState(true);  
const staff  = useSelector(state => state.rootReducer.staff);
    const schoolClass  = useSelector(state => state.rootReducer.schoolClass);
    const schoolInfo  = useSelector(state => state.rootReducer.schoolInfo);

    const [uniqueSubject, setUniqueSubject]=useState([]);

    const [classSubject, setClassSubject]=useState([]);
    const [classSubjectB, setClassSubjectB]=useState([]);
    const [classSubjectC, setClassSubjectC]=useState([]);

    const [allSubject, setAllSubject]=useState([]);
    const [sqlQuery, setSqlQuery] = useState([]);
    const [resultSession, setResultSession]=useState([])
    const [subjectAverage,  setSubjectAverage] = useState([]);
    const [classTeacher, setClassTeacher] = useState([]);
    const [classresult, setClassresult]= useState([])
    const [grossPoint, setGrossPoint]= useState([])

    const [studentList, setStudentList] = useState([]);
    const [grademark, setGrademark]=useState([])
    const [result, setResult] = useState({
        studentClass:[],
        examtype:[],
        resultHead:'Secondary',
        orderBy:[],
        session:[]
    })

    
    const [firstTerm, setFirstTerm]=useState([])
    const [secondTerm, setSecondTerm]=useState([])
    const [thirdTerm, setThirdTerm]=useState([])

    const handleSearch = event =>{
        event.preventDefault();  
    var errormessage = [];

    
    if(result.studentClass.length===0){
        var studentClass ='Please select class';
        setErrors({...errors, studentClass:studentClass})
        errormessage.push(studentClass);

    }
    if(result.session.length===0){

        var session ='Please select session';
        setErrors({...errors, session:session})
        errormessage.push(session);
    }
    if (errormessage.length<=0) {
       mergeAllResult()
    }

    }  




    const fetchGrossPoint =()=>{
        var sql ="SELECT grossPoint, grade, gradeMark from tbl_exam_settings where ID="+1;
const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
    if(result.data.length!==0){
    var grossP = result.data[0].grossPoint
    setGrossPoint(JSON.parse(grossP))
    }
})
.catch((error)=>{
    Alerts('Error!', 'danger', error.message)
})
}





const handleSelectSession =(option, action)=>{     
    setResult({...result, [action.name]:option, studentClass:[], orderBy:[]});
   
    setStudentList([])
    setScores([])
    setStudent([])
    setErrors({...errors, [action.name]:''}) 

}


const handleSelect =(option, action)=>{     

    setResult({...result, [action.name]:option, orderBy:[]});
  
    fetchClassSubject(option.value, result.session.firstTerm)
    fetchClassSubjectB(option.value, result.session.secondTerm)
    fetchClassSubjectC(option.value, result.session.thirdTerm)
    fetchClassTeaher(option.classID, option.value)
       fetchGradeMark(option.classtype)
        setStudent([])
    setErrors({...errors, [action.name]:''})
    fetchStudentList(option.value)  
        
    setScores([])
}



const fetchStudentList =(section)=>{
    setNotice({...notice, isLoading: true}) 
   var sql ="SELECT s.passport, s.code, s.gender, s.admissionNumber, s.house, s.dateOfBirth, s.registerNumber, s.studentName, e.examCode from tbl_students s, tbl_exam_result e where s.code = e.studentCode and  e.sectionID ='"+section+"' group by s.code"


const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token);
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(result=>{
    if(result.data.length!==0){
   setStudentList(result.data)

}else{
setStudentList([]) 
} 
})
.catch((error)=>{
Alerts('Error!', 'danger', error.message)
}).finally(()=>{
    setNotice({...notice, isLoading: false})
   
})
}
/* Get unique subject */



const getResultScore =(studentCode, subjectName, dataList)=>{
    const ans = dataList.filter(item=>item.studentCode===studentCode)
return ans.length!==0?ans[0][subjectName]:0
    }

    const getResultScoreNum =(studentCode, subjectName, dataList)=>{
        const ans = dataList.filter(item=>item.studentCode===studentCode)

        if(ans.length!==0 && Number(ans[0][subjectName])>0){return 1}else{
    return 0}
        }


const mergeAllResult =async()=>{
    const allSubjects = [...classSubject, ...classSubjectB, ...classSubjectC]
    
const newSubject =	allSubjects.map(e=>e['subjectCode'])
.map((e,i,final)=>final.indexOf(e)===i&&i)
.filter(e=>allSubjects[e])
.map(e=>allSubjects[e])
setUniqueSubject(newSubject)


    var list = [];
    for (var i = 0; i< newSubject.length;  i++) {

        studentList.forEach(st=>{


            var first = Number(getResultScore(st.code, newSubject[i].subjectName, firstTerm))
            var second = Number(getResultScore(st.code, newSubject[i].subjectName, secondTerm))
            var third = Number(getResultScore(st.code, newSubject[i].subjectName, thirdTerm))
            var overallTotal = Number(first+ second+ third)


            var firstNum = Number(getResultScoreNum(st.code, newSubject[i].subjectName, firstTerm))
            var secondNum = Number(getResultScoreNum(st.code, newSubject[i].subjectName, secondTerm))
            var thirdNum = Number(getResultScoreNum(st.code, newSubject[i].subjectName, thirdTerm))





            var totalCummulativeNum = Number(firstNum+ secondNum+ thirdNum)
            var average = (overallTotal/totalCummulativeNum)>0?(overallTotal/totalCummulativeNum).toFixed(2):'-'
            var grade =getGrade(average, 'grade')
            var remark = getGrade(average, 'remark') 



            list.push({
                subjectName:newSubject[i].subjectName,
                subjectCode:newSubject[i].subjectCode,
                studentCode:st.code,
                studentName:st.studentName,
                first:first,
                second:second,
                third:third,
               average:average,
               grade:grade,
               remark:remark,
               overallTotal:overallTotal,
               cummulative:totalCummulativeNum,
               totalSubject:''  
    
            })
 
        })
        
    }
    
    setScores(list)
}

const fetchGradeMark =(classType)=>{
    const fd = new FormData();
    fd.append('jwt', Token);
    let url = ServerUrl + '/fetchField/tbl_exam_settings/gradeMark/ID/1';
    axios.post(url, fd, config)
    .then(result =>{

        if(result.data.length!==0){
            var grade = result.data.filter(item=>item.classtype===classType)
            setGrademark(grade.sort((a, b) =>(parseFloat(b.maximum) > parseFloat(a.maximum)) ? 1 : -1))
        }else{
            setGrademark([])
        }
    })             
}
const fetchAllResultSession =()=>{

    var sql ="SELECT r.code, r.firstTerm, r.secondTerm, r.thirdTerm, r.sessionCode, s.startYear from tbl_result_session r, tbl_school_session s where r.sessionCode =s.ID " ;

    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
        setResultSession(result.data)
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
}

const fetchClassTeaher =(classID, sectionID)=>{
    var newStaff = staff&&staff.filter((item=>item.classID===classID&&item.sectionID===sectionID))
    setClassTeacher(newStaff)
}


    const getCgpa =[{"min":"0", "max":"29.49", "remark":"UNGRADED", "gp":"0"},
        {"min":"29.50", "max":"39.49", "remark":"UNGRADED", "gp":"0"},
        {"min":"39.50", "max":"49.49", "remark":"POOR", "gp":"0.50"},
        {"min":"49.50", "max":"54.49", "remark":"UNSATISFACTORY", "gp":"1.00"},
        {"min":"54.5", "max":"59.49", "remark":"UNSATISFACTORY", "gp":"1.50"},
        {"min":"59.5", "max":"64.49", "remark":"SATISFACTORY", "gp":"2.00"},
        {"min":"64.5", "max":"69.49", "remark":"SATISFACTORY", "gp":"2.50"},
        {"min":"69.5", "max":"74.49", "remark":"SATISFACTORY", "gp":"2.75"},
        {"min":"74.5", "max":"79.49", "remark":"GOOD", "gp":"3.00"},
        {"min":"79.5", "max":"84.49", "remark":"GOOD", "gp":"3.50"},
        {"min":"84.5", "max":"90.49", "remark":"GOOD", "gp":"3.75"},
        {"min":"90.5", "max":"94.49", "remark":"EXCELLENT", "gp":"4.00"},
        {"min":"94.5", "max":"100", "remark":"EXCELLENT", "gp":"4.00"}]

      


    const roundNum =(num)=> (Math.round(parseFloat(num)*100)/100)
    const getGrade =(score, field)=>{
   
        const ans = grossPoint&&grossPoint.filter(itm=>parseFloat(score) >= parseFloat(itm.min) && parseFloat(score) <= parseFloat(itm.max));


        const remark = ans.length!==0? ans[0].remark:''
        const grade = ans.length!==0? ans[0].gp:''
       
    return field ==='grade'?grade:remark
    }

        const fetchExamType =()=>{
            var sql ="SELECT CONCAT(t.session, ' ', t.term) as exam, r.examCode  from tbl_exam_result r, tbl_exam_type t where r.examCode = t.code and t.status='Active'  group by r.examCode order by exam ASC" ;
    
    const fd = new FormData();

    fd.append('jwt', Token);
    fd.append("sql", sql);
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(result=>{
        setExamtype(result.data)
    })
    .catch((error)=>{
        Alerts('Error!', 'danger', error.message)
    })
    }

    const  handleFetchAllSubject =()=>{
        
    const fd = new FormData(); 
    fd.append('jwt', Token);
        let url = ServerUrl + '/fetch_controller/tbl_subject'
        axios.post(url, fd, config)
    .then(result => setAllSubject(result.data))
    .catch((error)=>{
        Alerts('Error!', 'danger', JSON.stringify(error.message)) 
    }) }


    const getSubjectName = (code, field) =>{        
        const response = allSubject&&allSubject.filter(list=>list.code === code);
        return   String(response.map(c=>c[field]));
        }
        

        const fetchClassSubjectB =(sectionID, exam)=>{
            setNotice({...notice, isLoading: true}) 
            var sql ="SELECT  subjectCode  from tbl_exam_result  where examCode ='"+exam+"' and sectionID ='"+sectionID+"' group by subjectCode";
    
            
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(response=>{
        if(response.data.length!==0 && Array.isArray(response.data)){
    
                var subj = response.data;
        var subjectList = [];
        for (var i = 0; i< subj.length;  i++) {
            subjectList.push(
                {  subjectCode:subj[i].subjectCode,
                    subjectName:getSubjectName(subj[i].subjectCode, 'subjectName')
                });
                }
    
                setClassSubjectB(subjectList)
    
    
            let sqlString = subjectList.map(data=>"sum(case when e.subjectCode = '"+data.subjectCode+"'  then e.total else 0 end) AS '"+data.subjectName+"'")
                   
          let final =   'SELECT  sum(case when e.total >0 then 1 else 0 end) as subject, s.code as studentCode, s.admissionNumber, s.termination, s.termination_date, s.termination_remark,  sum(case when e.total >0 and e.absent="No" then e.total else 0 end) as obtained, sum(case when e.total >0 and e.absent="No" then 100 else 0 end) as obtainable, cast(sum(case when e.total >0 and e.absent="No" then e.total else 0 end)/sum(case when e.total >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) as average, s.registerNumber, s.studentName, e.maxMark, e.absent, e.code, '+String(sqlString)+ ' from tbl_students s, tbl_exam_result e where s.code = e.studentCode and s.admissionStatus ="Studying" and  e.examCode ="'+exam+'" and e.sectionID ="'+sectionID+'" group by e.studentCode order by s.studentName ASC '
    
          
              const fd = new FormData();
              fd.append("sql", final);
              fd.append('jwt', Token)
              let url = ServerUrl+'/fetchBySql_controller';
              axios.post(url, fd, config).then(response=>{
                   if(response.data.length!==0){
                    setSecondTerm(response.data)
                  }else{
                      setSecondTerm([]) 
                  }  
              }).finally(()=>{
                setNotice({...notice, isLoading: false})
               
            })
            
            }
            })
        }

        

        const fetchClassSubjectC =(sectionID, exam)=>{
            setNotice({...notice, isLoading: true}) 
            var sql ="SELECT  subjectCode  from tbl_exam_result  where examCode ='"+exam+"' and sectionID ='"+sectionID+"' group by subjectCode";
    
            
    const fd = new FormData();
    fd.append("sql", sql);
    fd.append('jwt', Token)
    let url = ServerUrl+'/fetchBySql_controller';
    axios.post(url, fd, config).then(response=>{
        if(response.data.length!==0 && Array.isArray(response.data)){
    
                var subj = response.data;
        var subjectList = [];
        for (var i = 0; i< subj.length;  i++) {
            subjectList.push(
                {  subjectCode:subj[i].subjectCode,
                    subjectName:getSubjectName(subj[i].subjectCode, 'subjectName')
                });
                }
    
                setClassSubjectC(subjectList)
    
    
            let sqlString = subjectList.map(data=>"sum(case when e.subjectCode = '"+data.subjectCode+"'  then e.total else 0 end) AS '"+data.subjectName+"'")
                   
          let final =   'SELECT  sum(case when e.total >0 then 1 else 0 end) as subject,  s.admissionNumber, s.code as studentCode, s.termination, s.termination_date, s.termination_remark,  sum(case when e.total >0 and e.absent="No" then e.total else 0 end) as obtained, sum(case when e.total >0 and e.absent="No" then 100 else 0 end) as obtainable, cast(sum(case when e.total >0 and e.absent="No" then e.total else 0 end)/sum(case when e.total >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) as average, s.registerNumber, s.studentName, e.maxMark, e.absent, e.code, '+String(sqlString)+ ' from tbl_students s, tbl_exam_result e where s.code = e.studentCode and s.admissionStatus ="Studying" and  e.examCode ="'+exam+'" and e.sectionID ="'+sectionID+'" group by e.studentCode order by s.studentName ASC '
    
          
              const fd = new FormData();
              fd.append("sql", final);
              fd.append('jwt', Token)
              let url = ServerUrl+'/fetchBySql_controller';
              axios.post(url, fd, config).then(response=>{
                   if(response.data.length!==0){
                    setThirdTerm(response.data)
                  }else{
                    setThirdTerm([]) 
                  }  
              }).finally(()=>{
                setNotice({...notice, isLoading: false})
               
            })
            
            }
            })
        }

    const fetchClassSubject =(sectionID, exam)=>{
        setNotice({...notice, isLoading: true}) 
        var sql ="SELECT  subjectCode  from tbl_exam_result  where examCode ='"+exam+"' and sectionID ='"+sectionID+"' group by subjectCode";

        
const fd = new FormData();
fd.append("sql", sql);
fd.append('jwt', Token)
let url = ServerUrl+'/fetchBySql_controller';
axios.post(url, fd, config).then(response=>{
    if(response.data.length!==0 && Array.isArray(response.data)){

            var subj = response.data;
    var subjectList = [];
    for (var i = 0; i< subj.length;  i++) {
        subjectList.push(
            {  subjectCode:subj[i].subjectCode,
                subjectName:getSubjectName(subj[i].subjectCode, 'subjectName')
            });
            }

            setClassSubject(subjectList)


        let sqlString = subjectList.map(data=>"sum(case when e.subjectCode = '"+data.subjectCode+"'  then e.total else 0 end) AS '"+data.subjectName+"'")
               
      let final =   'SELECT  sum(case when e.total >0 then 1 else 0 end) as subject, s.code as studentCode, s.admissionNumber, s.termination, s.termination_date, s.termination_remark,  sum(case when e.total >0 and e.absent="No" then e.total else 0 end) as obtained, sum(case when e.total >0 and e.absent="No" then 100 else 0 end) as obtainable, cast(sum(case when e.total >0 and e.absent="No" then e.total else 0 end)/sum(case when e.total >0 and e.absent="No" then 1 else 0 end) as decimal(10,2)) as average, s.registerNumber, s.studentName, e.maxMark, e.absent, e.code, '+String(sqlString)+ ' from tbl_students s, tbl_exam_result e where s.code = e.studentCode and s.admissionStatus ="Studying" and  e.examCode ="'+exam+'" and e.sectionID ="'+sectionID+'" group by e.studentCode order by s.studentName ASC '

      
          const fd = new FormData();
          fd.append("sql", final);
          fd.append('jwt', Token)
          let url = ServerUrl+'/fetchBySql_controller';
          axios.post(url, fd, config).then(response=>{
               if(response.data.length!==0){
          setFirstTerm(response.data)
              }else{
                  setFirstTerm([]) 
              }  
          }).finally(()=>{
            setNotice({...notice, isLoading: false})
           
        })
        
        }
        })
    }






        const fetchResult = (subjectName)=>{
            let ns = [...scores]
           

            var rs = ns.filter(item=>item.subjectName===subjectName)
            var total =0;
            var i =0;
            rs.forEach(item=>{

                if(Number(item.average)>0){
                total+=Number(item.average)
                    i++;
                }
            })

            var num = i;
            var average = (total/num)>0? (total/num).toFixed(2):''

        return {average:average, total:total, score:i}

  

        }



        const getTotalSubject =(code)=>{
            let scr =  [...scores];
            const rs =  scr.filter(item=>item.studentCode===code)

            var i=0;
            var obt=0;
            var cumNum =0;
            rs.forEach(item=>{

               if(item.overallTotal>0){
                i++;
                obt+=Number(item.overallTotal);
                cumNum+=Number(item.cummulative)
               }
            })

            var average = (obt/cumNum).toFixed(2);
            var gpa = getGrade(parseFloat(average), 'grade')
             var  remark = getGrade(parseFloat(average), 'remark')


           return {subject:cumNum, obtainable:cumNum*100, obtained:obt.toFixed(2), average:average, gpa:gpa, remark:remark};
        }

        const getScores =(code, subjectCode)=>{
            let scr =  [...scores];
            const rs = scr.filter(item=>item.studentCode===code&&item.subjectCode===subjectCode)   
            
           if (rs.length!==0){
                return rs[0]
            }else{
                return {
                    first:0,
                    average: 0,
                    cummulative: 0,
                    first: 0,
                    grade: 0,
                    overallTotal: 0,
                    remark: 0,
                    second: 0,
                    studentCode: 0,
                    studentName: 0,
                    subjectCode: 0,
                    subjectName: 0,
                    third: 0,
            }
            } 
    
        }


        const menu  = useSelector(state => state.rootReducer.sidebar);
        useEffect(()=>{   
       // setIsAuthenticated(menu.includes(location.pathname)?true:false) 
    fetchGrossPoint()
    fetchExamType()
    handleFetchAllSubject()
    fetchAllResultSession() 
},[]);


        return ( 
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Class Session Cummulative">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-file-text"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Exams</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Class Cummulative</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  no-print">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Class Cummulative</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
		
        <div className="row">

        <section className="col-md-6">

         <div className="form-group">
         <label> Session<span style={{color:'red'}}>*</span></label>
         <Select  options={resultSession&&resultSession.map((list, id)=>{
             return {key:id, value:list.code, label:list.startYear, firstTerm:list.firstTerm, secondTerm:list.secondTerm, thirdTerm:list.thirdTerm}
         })}    
         getOptionLabel={option =>option.label}
 onChange={handleSelectSession} className={errors.session ? 'form-control form-control-danger' : ''} name="session" value={result.session} id='session'  />

 <span style={{color:'red'}}>{errors.session && errors.session}</span>
            </div>
     
     </section>

                    <section className="col-md-6">
           <div className="form-group">
         <label> Student Class<span style={{color:'red'}}>*</span></label>
         <Select  options={
                        schoolClass.map((list, idx)=> {
                            return {key:idx, classtype:list.arms, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                return sec.sname !==''? {key:id, classID:list.ID, classtype:list.arms, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classtype:list.arms, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                            }) }
                          })
							 
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect}  className={errors.studentClass ? 'form-control form-control-danger' : ''} name="studentClass" value={result.studentClass} id='sessionId' /> 
<span style={{color:'red'}}>{errors.studentClass && errors.studentClass}</span>
            </div>
     
     </section>     
    
      </div>

      <div className="row">
      <div className="col-md-12 ">  
	<button type="button" id="submit" onClick={handleSearch} className="btn btn-primary pull-right"><i className="fa fa-search"></i> Search Result</button>
		 </div>
			</div>	             
				
							</div>
                            </div>
							</div></div>
</div>

</div>


{scores.length!==0? 
<div className="card z-depth-0">

<div className="card-block">
	
{schoolInfo&&schoolInfo.map((st, id)=>
               <div className="row" key={id}>

<table className="table  table-full-width">
<tbody>
<tr>
<td className="text-center" ><img id="viewSchoolLogo2" className="img-100" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+'/logo.png'}} src={`${ImagesUrl}/about/${st.schoolLogo}`}  title="School Logo" alt=''  />
<h4 style={{textAlign:'center'}}><b>{st.schoolName}</b></h4>
<h6 style={{textAlign:'center'}}><b>Motto: {st.schoolMotto}</b></h6>
<div style={{textAlign:'center'}}>  {st.address}  </div>
<div style={{textAlign:'center'}}> {st.mobile}, {st.altMobile}, {st.email} </div>

<div style={{textAlign:'center'}}>  Website: {st.website}  </div>
</td>
        </tr>
    </tbody>
</table>

</div> 
               )}

<div className="row">
<div className="col-md-12">
<h5 style={{textAlign:'center'}}><u><b> {result.session.label} CUMMULATIVE SPREADSHEET </b></u></h5>
</div></div><br/>

<div className="row">
<div className="col-md-12 table-responsive">

<table className="table table-bordered  table-full-width">
<tbody>
        <tr>
            <td >Class: <b>{result.studentClass.label}</b></td>
            <td >Form Tutor: <b>{classTeacher&&classTeacher.map(item=>item.staffName+' ')}</b></td>
        </tr>
    </tbody>
</table></div>
<div className="col-md-12 table-responsive">
<table className="table table-striped table-bordered">


    <thead>
        <tr>
            <th>S/N</th>
            <th >Admission No.</th>
            <th >Name of Students</th>
            
            {uniqueSubject&&uniqueSubject.map((data, id)=><th key={id} className="vertical-header"><span>{data.subjectName}</span></th>)}

             <th className="vertical-header"><span>No. of Subjects</span></th>
            <th className="vertical-header"><span>Mark Obtainable</span></th>
            <th className="vertical-header"><span>Mark Obtained</span></th>
            <th className="vertical-header"><span>Cummulative Average</span></th>
            <th className="vertical-header"><span>G.P.A</span></th> 
            <th className="vertical-header"><span>REMARK</span></th> 
        </tr>
    </thead>
    <tbody>
    <tr>
                    <td></td>
                    <th colSpan={2}>MARK OBTAINABLE</th>
                    {uniqueSubject&&uniqueSubject.map((data, id)=><td key={id} >100%</td>)}
                    
                    <th>{uniqueSubject.length*3}</th>
                    <th>{uniqueSubject.length*300}</th>
                    <th></th>
                    <th>100</th>
                    <th>4.0</th>
                </tr>
         {scores.length!==0?studentList.map((st, idx)=> 
        <tr key={idx}>
        <td>{idx+1}</td>
            <td>{st.admissionNumber}</td>
            <td>{st.studentName} <br/>{st.termination==='Yes'?<span className="pcoded-badge label label-danger">Admission Suspended on {longDate(st.termination_date)} {st.termination_remark}</span>:''}</td>

         
        {uniqueSubject&&uniqueSubject.map((data, id)=><td key={id}>{ getScores(st.code, data.subjectCode).average}</td>)}  
            

             <td>{getTotalSubject(st.code).subject}</td>
             <td>{getTotalSubject(st.code).obtainable}</td>
             <td>{getTotalSubject(st.code).obtained}</td>
             <td>{getTotalSubject(st.code).average}</td>
             <td>{getTotalSubject(st.code).gpa}</td>
             <td>{getTotalSubject(st.code).remark}</td>

        </tr>):[]}   
         <tr>
        <td></td>
                    <th colSpan={2} >SUBJECT AVERAGE</th>
                    
                   {uniqueSubject&&uniqueSubject.map((st, id)=><th key={id} className="vertical-header">
                   {FormatNumber(fetchResult(st.subjectName).average)}
                   

                        </th>)} 
                    
                </tr> 
                <tr>
              <td></td>
                    <th colSpan={2}>TOTAL SCORE</th>
                    
                   {uniqueSubject&&uniqueSubject.map((st, id)=><th key={id} className="vertical-header">
                   {FormatNumber(fetchResult(st.subjectName).total)}
                   

                        </th>)} 
                    
                </tr> 

                <tr>
              <td></td>
                    <th colSpan={2}>No. of Students</th>
                    
                   {uniqueSubject&&uniqueSubject.map((st, id)=><th key={id} className="vertical-header">
                   {fetchResult(st.subjectName).score}
                   

                        </th>)} 
                    
                </tr>  
    </tbody> 
</table>
</div>	 </div> 

<div className="row no-print" ref={structure}>
<div className="col-md-12">
    <form method='POST' action={ServerUrl+'/printout/class_marks_cummulative.php?token='+userToken} target='_blank'>

       <input type='hidden' name='classSubject' value ={JSON.stringify(uniqueSubject, null, 2)} />
        <input type='hidden' name='classresult' value ={JSON.stringify(studentList, null, 2)} />
        <input type='hidden' name='classID' value ={result.studentClass.classID} />
        <input type='hidden' name='sectionID' value ={result.studentClass.value} />
        <input type='hidden' name='teacher' value ={classTeacher&&classTeacher.map(item=>item.staffName+' ')} /> 
        <input type='hidden' name='resultSession' value ={result.session.label} />
        <input type='hidden' name='scores' value ={JSON.stringify(scores, null, 2)} />
        <input type='hidden' name='grossPoint' value ={JSON.stringify(grossPoint, null, 2)} />

        <input type='hidden' name='jwtToken' value ={Token} />
<button type="submit" className="btn btn-inverse pull-right no-print" > <i className="fa fa-print"></i> Print</button>

</form>
</div></div>

</div>


        </div>
        :''}  
 
   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(ClassSessionCummulative) 