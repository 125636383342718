import React, {useState, useEffect} from 'react'
import Template from '../component/template'
import {LoadEffect}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token,  config, allowDelete, allowEdit, menuLink} from '../component/include'
import Select from 'react-select'

import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import {formatGroupLabel} from '../component/globalFunction'
const TimetableDay =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    });
    const [schoolClass, setSchoolClass]=useState([]);
    const [subject, setSubject]= useState([]) 
    const [periodList, setPeriodList] = useState([]);
    const [staff, setStaff]= useState([])
    const [code, setCode]= useState('')
    
   
    const [timetable, setTimetable]=useState({
    schoolClass:[],
    day:[]
    })
    const [activities, setActivities]=useState([])
    const [isSubmitting, setIsSubmitting] = useState(false);
    let location =  useLocation()
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [CurrentSession, setCurrentSession] = useState('')
    const  handleSession =()=>{
    
        var sql ="Select  startYear from tbl_school_session where status ='Active'"
        const fd = new FormData();
        fd.append("sql", sql);
        fd.append("jwt", Token);
        let url = ServerUrl+'/fetchBySql_controller';
        axios.post(url, fd, config)
        .then(result => {
            if(result.data.length!==0){
            setCurrentSession(result.data[0].startYear)
        }
        })
        .catch((error)=>{
            Alerts('Error!', 'danger', JSON.stringify(error.message)) 
        })
        } 
    const fetchSchoolClass =()=>{
        
const fd = new FormData();
fd.append('jwt', Token);
        let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1'
        axios.post(url, fd, config)
        .then(result => setSchoolClass(result.data))
        .catch((error)=>Alerts('Error!', 'danger', JSON.stringify(error.message))) 

    }

    const fetchSubject =()=>{
        
const fd = new FormData();
fd.append('jwt', Token);
        let url = ServerUrl+'/fetch_controller/tbl_subject'
        axios.post(url, fd, config).then(result=>setSubject(result.data))
    }

    const  fetchActivities =()=>{  
        
        
const fd = new FormData();
fd.append('jwt', Token);
   let sql = "Select ID, activity from tbl_activities order by activity ASC"

       let url = ServerUrl+'/fetchBySql_controller'
     
       fd.append("sql", sql)
       
       axios.post(url, fd, config).then(result =>setActivities(result.data)) 
   
    }  
    

    const  fetchStaff =()=>{  
        
        
const fd = new FormData();
fd.append('jwt', Token);
   let sql = "Select code, staffName, fileNo from tbl_staff"
       let url = ServerUrl+'/fetchBySql_controller'
       fd.append("sql", sql)
       
       axios.post(url, fd, config).then(result =>setStaff(result.data)) 
   
    }    

   

    const handleSelect = (option, action)=>{
        setTimetable({...timetable, [action.name]: option});
        setErrors({...errors, [action.name]:''})  
        setPeriodList('')           
    }

    const handleSubmit = event =>{
        event.preventDefault();    
        setErrors(ValidateErrors(timetable));
        setIsSubmitting(true);
    } 

    const ValidateErrors=(values)=>{
        let errors = {};  
       if(timetable.day.length===0){
            errors.day ='Please select day';
        }
         if(values.schoolClass.length===0){
                errors.schoolClass ='Please select class';
            }
        return errors;
        }


useEffect(()=>{
    if(Object.keys(errors).length === 0 && isSubmitting){
        fetchTableList()
    }
    },[errors])


    const  fetchTableList =()=>{  
        
        setNotice({...notice, 
            isLoading: true})
    
            var value = [];
            for (var i = 1; i <= 6; i++) {
              value.push({code:Math.random().toString(36).substr(2, 9), periodName:i, startTime:'', endTime:'', periodType:'', subject:'', staff:'', chapter:'', topic:''}
              );
            }      
    
       let sql = "Select code, periodList from tbl_timetable where day = '"+timetable.day.value+"' and  sessionYear = '"+CurrentSession+"' and sectionID ='"+timetable.schoolClass.value+"'"

       
const fd = new FormData();
fd.append('jwt', Token);
       fd.append("sql", sql);
       let url = ServerUrl+'/fetchBySql_controller'
       axios.post(url, fd, config).then(result =>{
            if(result.data.length===0){
                setPeriodList(value)
                }else{
                    const res = result.data[0].periodList
                    setCode(result.data[0].code)
                    setPeriodList(JSON.parse(res))
                }
    
        setNotice({...notice, 
            isLoading: false})
            }) 
    
            setIsSubmitting(false)
        }  

        const createNewRow =()=>{
            setPeriodList(periodList.concat({ID:Math.random().toString(36).substr(2, 9), periodName:periodList.length+1, startTime:'', endTime:'', periodType:'', subject:'', staff:'', chapter:'', topic:''}))
            setErrors({...errors, periodList:''})
        }

        const removeRow=(index)=>{
            setPeriodList(periodList.filter((_, i) => i !== index)); 
           }

    const handleChangePeriod = (e, index)=>{
        const std =JSON.parse(JSON.stringify(periodList))   
            const value =e.target.value;
           std[index][e.target.name] = value;
        setPeriodList(std);
    }


    
    const  submit=()=>{  
              
    setNotice({...notice, 
        isLoading: true})   

        
const fd = new FormData();
fd.append('jwt', Token); 
        fd.append('classID',  timetable.schoolClass.classID)
        fd.append('sectionID',  timetable.schoolClass.value)
        fd.append('day',  timetable.day.value)
        fd.append('sessionYear',  CurrentSession)  
        fd.append('code',  code)        
     fd.append('periodList',  JSON.stringify(periodList, null, 2))

     let url = ServerUrl+'/save_controller/tbl_timetable' 
        axios.post(url, fd, config)
        .then(response =>{
            if(response.data.type ==='success'){
                Alerts('Saved!', 'success', response.data.message)
                    }else if(response.data.type ==='info'){
                        Alerts('Update!', 'default', response.data.message)
                            }else{
                Alerts('Error!', 'danger', JSON.stringify(response.data))
                    }   
            })
            .catch((error)=>{
              Alerts('Error!', 'danger', error.message)
            }).finally(()=>{
        setTimetable({
            schoolClass:[],
            day:'',
        })
        setNotice({...notice, 
            isLoading: false}) 
        setPeriodList([])
    })
     }    



useEffect(()=>{
    setIsAuthenticated(menuLink.includes(location.pathname))
    fetchSchoolClass()
    fetchSubject()
    fetchStaff()    
    fetchActivities()
    handleSession()
},[]);



        return (    <>
            <div  className="main-body">
            {isAuthenticated?   <div className="page-wrapper">
                          {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Timetable By Day">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-table"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Timetable</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Define Timetable</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

         
<div  className="row  ">   
<div className="col-sm-12">                                 
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Setup Class Timetable</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">
            
            <div className="row  ">
							

	<div className="col-sm-12">

        <div className="card z-depth-0" >

		<div  className="card-block">
		
        <div className="row">
       
     
      <section className="col-md-6">
      <div className="form-group">
						<label >Select Class<span style={{color:'red'}}>*</span></label>
                        <Select  options={
                             schoolClass.map((list, idx)=> {
                                return {key:idx, value: list.ID, label: list.cname, options:list.section.map((sec, id)=>{ 
                                    return sec.sname !==''? {key:id, classID:list.ID, isSection:'1', value: sec.ID, label: sec.sname}:{key:id, classID:list.ID, value: list.ID, label: list.cname, isSection:'0'}
                                }) }
                              })
                        } 
                        formatGroupLabel={formatGroupLabel}
onChange={handleSelect} className={errors.schoolClass ? 'form-control form-control-danger' : ''}  name="schoolClass" value={timetable.schoolClass}   /> 
								
<span style={{color:'red'}}>{errors.schoolClass && errors.schoolClass}</span>				
											</div>	
                                      
</section>

<section className="col-md-6">
   
<div  className="form-group">
                        <label > Day</label>
                        <Select  options={[
                    { value: 'Monday', label: 'Monday' },
                    { value: 'Tuesday', label: 'Tuesday' },
                    { value: 'Wednessday', label: 'Wednessday' },
                    { value: 'Thursday', label: 'Thursday' },
                    { value: 'Friday', label: 'Friday' },
                    { value: 'Saturday', label: 'Saturday' },
                    { value: 'Sunday', label: 'Sunday' }
                ]} 
                className={errors.day ? 'form-control form-control-danger' : ''}               onChange={handleSelect}  name="day" value={timetable.day}  /> 
                 <span style={{color:'red'}}>{errors.day && errors.day}</span>
             </div>

</section>


				</div>	
             
                <div className="row" >
<section className="col-md-12">
	<footer className="pull-right">
	
	<button type="button" id="submit" onClick={handleSubmit}  className="btn btn-primary "><i className="fa fa-search"></i> Search</button>
								</footer>		</section>		
							</div>
 </div> </div> 
							
</div>

</div>   

{periodList.length!==0?

<div  className="card z-depth-0" >
		<div  className="card-block">
        <div className="row">
       
        <div className="table-responsive">
        <table className="table">
            <thead>
                <tr>
    <th><a onClick={createNewRow} href="#!"><i className="fa fa-plus"></i> Add</a></th>
                    <th>Period Name</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Period Type</th>  
                    <th>Subject</th>
                    <th>Teacher</th> 
                </tr>
            </thead>
            <tbody>


                {periodList&&periodList.map((pl, id)=>
            <tr key={id}>
                <td> <button type="button" onClick={()=>removeRow(id)} className="btn btn-danger btn-sm"><i className="fa fa-trash"></i></button></td>

                    <td><input type="text" className="form-control" name='periodName' value={pl.periodName}  onChange={(e)=>handleChangePeriod(e, id)} /></td>

                    <td><input type="time" className="form-control" name='startTime' value={pl.startTime}  onChange={(e)=>handleChangePeriod(e, id)} /></td>

                    <td><input type="time" className="form-control" name='endTime' value={pl.endTime}  onChange={(e)=>handleChangePeriod(e, id)} /></td>
                    <td>
                    <select onChange={(e)=>handleChangePeriod(e, id)}  name="periodType"  value={pl.periodType} className="form-control">
                        <option value="">Select Type</option>
                        { activities&&activities.map((list, idx)=> {
                               return  <option key={idx} value={list.ID} >{list.activity}</option> })}
                        </select>
                   
                    </td>
                    <td>
                    <select onChange={(e)=>handleChangePeriod(e, id)}  name="subject"  value={pl.subject} className="form-control">

                    <option value="">Select</option>
                   { subject&&subject.map((list, idx)=> {
                               return  <option key={idx} value={list.code} >{list.subjectName} ({list.abbreviation})</option> })}
                        </select>
                    

                    </td>
                    <td>
                    <select onChange={(e)=>handleChangePeriod(e, id)}  name="staff"  value={pl.staff} className="form-control">

<option value="">Select</option>
{ staff&&staff.map((list, idx)=> {
           return  <option key={idx} value={list.code} >{list.fileNo} {list.staffName}</option> })}
    </select>

                    </td>
                   
                </tr>)}
               
            </tbody> 
            <tfoot>
                <td colSpan='7'>
                <footer className="pull-right">
	
	<button type="button" id="submit" onClick={()=>submit()}  className="btn btn-inverse "><i className="fa fa-save"></i> Save Timetable</button>
								</footer>
                </td>
            </tfoot>
        </table>

        </div>
        </div>
        </div>
</div>:''}


     

     
     </div>  </div>
							
                            </div>
                            
                            </div>

   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(TimetableDay) 