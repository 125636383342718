import React, {useState, useEffect} from 'react'
import {LoadEffect, TableLoader}  from '../component/loader'
import axios from 'axios'
import { Alerts} from '../component/notify'
import PageHeader from '../component/pageheader'
import {ServerUrl,  Token, studentImagesUrl, ImagesUrl, config, allowDelete, allowEdit, menuLink} from '../component/include'
import Select from 'react-select'
import { longDate } from '../component/globalFunction'

import { useDispatch, useSelector } from 'react-redux'
import Error403 from '../settings/error403'
import { useLocation } from 'react-router-dom'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search, ColumnToggle } from 'react-bootstrap-table2-toolkit';

const BirthdayUpcomings =()=> {
    const [errors, setErrors] = useState({});
    const [notice, setNotice] = useState({
        isLoading : false, 
        isDataFetching: false,
    }); 
    

    let location =  useLocation()

    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [schoolClass, setSchoolClass]=useState([]);
    const [staff, setStaff]= useState([]) 
    const [students, setStudents] = useState([]);
    const [birthday, setBirthday] = useState({
       duration:[],
        user:'Student'
    })
    const [isSubmitting, setIsSubmitting] = useState(false);

    const fetchStudent =()=>{
        if(birthday.duration.length===0){
         Alerts('Error!', 'danger', 'Please select duration')
        }else{
         setNotice({...notice, isLoading: true  });
          var sql = "select code, studentName, mobile, email, gender, classID, sectionID, passport, dateOfBirth from tbl_students where admissionStatus ='Studying' and date_format(dateOfBirth, '%m%d') >= date_format(now(), '%m%d') and date_format(dateOfBirth, '%m%d') < date_format(DATE_ADD(NOW(), INTERVAL "+birthday.duration.value+" DAY), '%m%d')"
          const fd = new FormData();
      fd.append('jwt', Token); 
          fd.append("sql", sql);
          let url = ServerUrl+'/fetchBySql_controller'
          axios.post(url, fd, config).then(result=> setStudents(result.data)
         )
         .catch((error)=>
         Alerts('Error!', 'danger', error.message)).finally(()=>{
             setNotice({...notice,  isLoading: false
             });
         }) 
       }}



    const fetchStaff =()=>{
        if(birthday.duration.length===0){
         Alerts('Error!', 'danger', 'Please select duration')
        }else if(birthday.user==='Staff'){
         setNotice({...notice, isLoading: true  });
          var sql = "select code, staffName, mobile, email, gender, position, department, passport, birthDate from tbl_staff where employmentStatus='Working' and date_format(birthDate, '%m%d') >= date_format(now(), '%m%d') and date_format(birthDate, '%m%d') < date_format(DATE_ADD(NOW(), INTERVAL "+birthday.duration.value+" DAY), '%m%d')"
          const fd = new FormData();
      fd.append('jwt', Token); 
          fd.append("sql", sql);
          let url = ServerUrl+'/fetchBySql_controller'
          axios.post(url, fd, config).then(result=> setStaff(result.data))
         .catch((error)=>
         Alerts('Error!', 'danger', error.message)).finally(()=>{
             setNotice({...notice,
                 isLoading: false
             });
         }) 
       }else{
           fetchStudent()
       }}



    const handleChange = event =>{
        const {name, value} = event.target
        setBirthday({...birthday, [name] : value })
        
    }



        const fetchSchoolClass =()=>{
            const fd = new FormData();
      fd.append('jwt', Token); 
            let url = ServerUrl + '/fetchField/tbl_school_class/schoolClass/ID/1'
            axios.post(url, fd, config).then(result=>setSchoolClass(result.data))
        }
const handleSelect =(option, action)=>{     
          
            setBirthday({...birthday, [action.name]:option});
            setErrors({...errors, [action.name]:''})
        }


const getClassName = (ID) =>{        
       
    const result = schoolClass && schoolClass.filter((list,_)=>list.ID===ID);
    const answer = result.map((c)=>c.cname);
    return  answer
    }

    const getSectionName = (cID, sID) =>{       
       const result = schoolClass && schoolClass.filter((list,_)=>list.ID===cID); 
       const section = result.map((data) =>
    data.section.filter((list,_)=>list.ID===sID))

    const newResult = section[0]?section[0]:[]
    const answer = newResult.map((c)=>c.sname); 
    const ClassName = getClassName(cID);
    const finalAnswer = answer.length===0?ClassName:answer
    return finalAnswer
    
  }

    

const tableStudentHeader = [
    {dataField: 'passport', text: 'Picture', editable: false, formatter:(cell, row)=><img className="img-70" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/students/"+row.gender+".png"}} src={ImagesUrl+'/students/'+cell} title={row.studentName}  alt={row.studentName} />},
    {dataField: 'studentName', text: 'Student Name', editable: false},
    {dataField: 'mobile', text: 'Mobile', sort: true, editable:false}, 
    {dataField: 'email',  text: 'Email',  sort: true,editable: false},
    {dataField: 'sectionID',  text: 'Class', sort: true, editable:false, formatter: (cell, row) => getSectionName(row.classID, row.sectionID)}, 
    {dataField: 'dateOfBirth', text: 'Birth Date', formatter:(cell)=>cell!=='0000-00-00'?longDate(cell):'', sort: true, editable: false}
   
 ]
 
const tableStaffHeader = [
    {dataField: 'passport', text: 'Picture', editable: false, formatter:(cell, row)=><img className="img-70" onError={(e)=>{e.target.onerror = null; e.target.src=ImagesUrl+"/staff/"+row.gender+".png"}} src={ImagesUrl+'/staff/'+cell} title={row.staffName}  alt={row.staffName} />},
    {dataField: 'staffName', text: 'Staff Name', editable: false},
    {dataField: 'mobile', text: 'Mobile', sort: true, editable:false}, 
    {dataField: 'email',  text: 'Email',  sort: true,editable: false},
    {dataField: 'position',  text: 'Position', sort: true, editable:false, formatter: (cell, row) => getSectionName(row.startFromClass, row.startFromSection)}, 
    {dataField: 'birthDate', text: 'Birth Date', formatter:(cell)=>cell!=='0000-00-00'?longDate(cell):'', sort: true, editable: false}
   
 ]





 const TableBirthday=(props)=>{
    const { SearchBar } = Search;
    const { ToggleList } = ColumnToggle;
    //const { ExportCSVButton } = CSVExport;
    const customTotal = (from, to, size) => (
       <span >&nbsp;Showing { from } to { to } of { size } items</span>
     );
    const options = {
       showTotal: true,
     paginationTotalRenderer: customTotal,
     sizePerPageList: [{text: '7', value: 7}, {text: '15', value: 15}, {text: '20', value: 20}, {text: '50', value: 50}, {text: '100', value: 100},
     { text: 'All', value: props.data.length }]
      
     };
    return  <ToolkitProvider search columnToggle 
                keyField='ID' data={ props.data } columns={ props.columns } >
                    
                    {
                        props => (
                        <div >
                            
                <BootstrapTable 
                   { ...props.baseProps }
                   striped
               hover
               condensed
               noDataIndication={<div className="alert alert-danger background-danger">No Record Found</div>}
           pagination={paginationFactory(options) }
         
                            />
                        </div>
                        )
                    }
 </ToolkitProvider>
 }


useEffect(()=>{
   
	setIsAuthenticated(menuLink.includes(location.pathname))
    fetchSchoolClass();

},[]);



        return (  
            <>
           <div  className="main-body">
           {isAuthenticated?   <div className="page-wrapper">
                         {notice.isLoading ?<LoadEffect className="overlay floatLoad" /> : "" }
                
                 
                   {/* <!-- Page-header start --> */}

        <PageHeader title="Communication">
        <li className="breadcrumb-item"><a  href="#!"> <i className="fa fa-fw fa-commenting-o"></i> </a>
                            </li>
                            <li className="breadcrumb-item"><a href="#!">Communication</a> </li>
                    <li className="breadcrumb-item"><a href="#!">Upcoming Birthdays</a> </li>
                    </PageHeader>
{/* The Page Body start here */}
                <div className="page-body">

                <div className="row  ">
							

	<div className="col-sm-12">

    <div className="card z-depth-0">
		<div className="card-header">
			<h5><i className="fa fa-edit" ></i> Upcoming Birthdays</h5>
			<div className="card-header-right">
				<ul className="list-unstyled card-option">
					<li><i className="feather icon-maximizes full-card"></i></li>
					<li><i className="feather icon-minus minimize-card"></i></li>
					<li><i className="feather icon-trash-2 close-card"></i></li>
				</ul>
			</div>
		</div><div className="card-block">
        <div className="card z-depth-0">
		<div className="card-block">
	
    	<div className="row">
     <section className="col-md-6">
     <div className="form-group">
                       <label > Duration</label>
                       <Select  options={[
                  {value:'5', label:'Upcoming 5 Days'},
                  {value:'10', label:'Upcoming 10 Days'},
                  {value:'15', label:'Upcoming 15 Days'},
                  {value:'20', label:'Upcoming 20 Days'}
               ]} 
             onChange={handleSelect}  name="duration" value={birthday.duration}  /> 
            </div>
     
     </section> 
     <section className="col-md-6">



     <div className="form-group">
							<label > Users </label>
				
									
						
			<div className="form-radio">
						<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="user" onChange={handleChange} value='Staff' checked={birthday.user==='Staff'?true:false} />
						<i className="helper"></i>Staff
					</label>
				</div>
				<div className="radio radiofill radio-inline">
					<label>
						<input type="radio" name="user" onChange={handleChange} value='Student' checked={birthday.user==='Student'?true:false} />
						<i className="helper"></i>Student
					</label>
				</div>
				</div>

					</div>



</section>
     
     </div><hr/>

     <div className="row"><section className="col-md-12">
     <footer className="pull-right">
	
	<button type="button" id="submit" onClick={fetchStaff} className="btn btn-primary "><i className="fa fa-search"></i> Search</button>
								</footer>
     </section>
				</div>	</div>
				
                </div></div>

</div>				
</div>

</div>
	

<div className="row  ">   <div className="col-sm-12">
                                                
<div className="card z-depth-0">
            <div className="card-header">
                <h5><i className="fa fa-th" ></i> Listing All Birthdays</h5>
                <div className="card-header-right">
                    <ul className="list-unstyled card-option">
                        <li><i className="feather icon-maximizes full-card"></i></li>
                        <li><i className="feather icon-minus minimize-card"></i></li>
                        <li><i className="feather icon-trash-2 close-card"></i></li>
                    </ul>
                </div>
            </div>
            <div className="card-block">     
        <div className="col-md-12 table-responsive">
        {birthday.user==='Staff' ? <TableBirthday data={staff}  columns={tableStaffHeader}  />:
        <TableBirthday data={students}  columns={tableStudentHeader}  />} 

</div>

</div>
    
    </div>
    
    </div>
    </div>




   </div>
          {/* The Page Body close here */}

            </div>:
            <Error403 />}
            </div>

</> 
 );
        
}

export default React.memo(BirthdayUpcomings) 